import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModal } from 'react-modal-hook'

import SpotUpload from './spot/SpotUpload'
import HeaderWithCancelButton from '../../shared/HeaderWithCancelButton'
import ReactModalWrapper from '../../shared/ReactModalWrapper'
import { useSpot } from '../../shared/SpotProvider'

function useSpotUploadModal() {
  const { t } = useTranslation()
  const { onDeleteLoaded } = useSpot()

  const [bookingId, setBookingId] = useState(null)
  const [bookingIdStr, setBookingIdStr] = useState(null)
  const [bookingName, setBookingName] = useState(null)

  // SpotUpload as a modal dialog.
  const [showModal, hideModal] = useModal(() => {
    const close = () => {
      onDeleteLoaded()
      hideModal()
    }

    return (
      <ReactModalWrapper onRequestClose={close}>
        <HeaderWithCancelButton
          title={t('content.booking.spots.addNew')}
          handleCancel={close}
        />
        <br />
        <SpotUpload
          bookingId={bookingId}
          bookingIdStr={bookingIdStr}
          bookingName={bookingName}
          handleCancel={close}
        />
      </ReactModalWrapper>
    )
  }, [bookingId])

  const handleUploadNewSpot = useCallback(
    (bookingId, bookingIdStr, bookingName) => {
      setBookingId(bookingId)
      setBookingIdStr(bookingIdStr)
      setBookingName(bookingName)
      showModal()
    },
    [showModal]
  )

  return {
    handleUploadNewSpot: handleUploadNewSpot,
  }
}

export { useSpotUploadModal }
