import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Category from "./Category";
import Priorities from "./Priorities";
import Settings from "./Settings";

import { FullscreenContent } from '../styling/Fullscreen'
import { StyledText } from '../styling/TextStyling'

import Background from '../../images/background.jpg'

function Dashboard(props) {
  const { t } = useTranslation();
  const { authCall, isAuthenticated, clientId } = props.auth

  const channels = [
    { id: 1, name: 'dashboard', icon: 'dashboard' },
    { id: 2, name: 'email', icon: 'email' }
  ];

  const priorities = [
    { id: 1, name: 'warning', color: 'rgba(255, 0, 0, 0.9)', icon: 'warning' },
    { id: 2, name: 'notice', color: 'rgba(255, 200, 0, 0.9)', icon: 'notification_important' },
    { id: 4, name: 'info', color: 'rgba(34, 205, 34, 0.9)', icon: 'info' }
  ];

  // Default data structures - used before data is fetched or fetch failure
  const defaultData = useMemo(() => {
    return {
      items_per_priority: { '1': 0, '2': 0, '4': 0 },
      categories: [],
      settings: []
    }
  }, [])

  const [data, setData] = useState(defaultData)
  const [settingsLocal, setSettingsLocal] = useState([])
  const [settingsRemote, setSettingsRemote] = useState([])
  const [settingsChanged, setSettingsChanged] = useState(false)
  const [userPriorities, setUserPriorities] = useState(1)

  const updateData = useCallback(() => {
    authCall('dashboard/items?client_id=' + clientId, defaultData)
      .then(data => {
        //console.log(JSON.stringify(data))
        setData(data)
        setSettingsLocal(data.settings)
        setSettingsRemote(data.settings)
        setSettingsChanged(false)
        setUserPriorities(data.user_priorities)
      })
  }, [authCall, defaultData, clientId])

  // Load data on mount
  useEffect(() => {
    if(isAuthenticated && clientId !== -1)
      updateData()
  }, [isAuthenticated, clientId, updateData])

  const handlePriorityChange = (id) => {
    // Compute and set new priorities internally
    const newUserPriorities = userPriorities ^ id
    setUserPriorities(newUserPriorities);

    // Store new priorities to server. Omit refresh of data.
    const endPoint = 'dashboard/userPriorities?' +
          'client_id=' + clientId +
          '&priorities=' + newUserPriorities
    authCall(endPoint, [], { method: 'PUT' })
  };

  const handleItemHidden = (id) => {
    setData(prev => {
      const next = {...prev}
      next.categories.forEach(category => {
        category.sub_categories.forEach(subCategory => {
          subCategory.items.forEach(item => {
            if(item.id === id)
              item.hidden = 1
          })
        })
      })
      return next
    })

    // Set item hidden on server. Omit refresh of data.
    const endPoint = 'dashboard/hideItem?client_id=' + clientId + '&item_id=' + id
    authCall(endPoint, [], { method: 'PUT' })
};

  const handleSettingsChange = (id, channel) => {
    // Find setting for id and update its channels
    const settings = settingsLocal.map((setting) => {
      if (setting.id === id) {
        const wasSet = setting.channels & channel;
        let channels = setting.channels ^ channel;
        if (wasSet && channel === 1) channels = null;
        else if (!wasSet && channel !== 1 && !(setting.channels & 1))
          channels = 1 | channel;

        return {
          ...setting,
          channels: channels,
        };
      } else {
        return setting;
      }
    });

    // Test if remote and local settings differ
    const identical = settings.every(
      (setting, index) => setting.channels === settingsRemote[index].channels
    );

    setSettingsLocal(settings);
    setSettingsChanged(!identical);
  };

  const handleSaveSettings = () => {
    // Accumulate all changes to setting where there options are possible:
    // added: subscription_id === null
    // deleted: subscription_id !== null && channels === 0
    // updated: subscription_id !== 0 && null < channels
    let subscriptions = []

    settingsLocal.forEach((local, index) => {
      const remote = settingsRemote[index]
      if(local.channels !== remote.channels) {
        let subscription = {
          id: local.subscription_id,
          client_dashboard_id: local.id,
          dashboardchannels: local.channels
        }
        subscriptions.push(subscription)
      }
    })

    // Store new subscriptions to server
    const options = {
      method: 'POST',
      headers: { "content-type": "application/json" },
      data: {
        client_id: clientId,
        subscriptions: subscriptions
      }
    }

    authCall('dashboard/subscriptions', [], options)
      .then(data => {
        if(data.error === "")
          updateData()
      })
  };

  const categories = data.categories.map((category, index) => {
    const hasVisibleItems = priorities.some(priority => {
      return 0 < category.items_per_priority[priority.id]
    })

    return (hasVisibleItems &&
      <Category
        key={index}
        category={category}
        priorities={priorities}
        userPriorities={userPriorities}
        handleItemHidden={handleItemHidden}
        close={props.close}
      />
    )
  })

  return (
    <FullscreenContent>
      <div style={{
        display: 'flex',
        flexGrow: 1,
        background: 'linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(' + Background + ') no-repeat center center fixed',
        backgroundSize: 'cover',
      }}>
        {/* DASHBOARD */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 25,
          flexGrow: 1,
        }}>
          {/* HEADER */}
          <div style={{
            display: 'flex',
            alignItems: 'flex-end'
          }}>
            {/* TITLE */}
            <StyledText bold upper fontSize={20} style={{
              flexGrow: 1
            }}>
              {t("dashboard.title")}
            </StyledText>
            {/* PRIORITIES */}
            <Priorities
              priorities={priorities}
              itemsPerPriority={data.items_per_priority}
              userPriorities={userPriorities}
              handlePriorityChange={handlePriorityChange}
            />
          </div>
          {/* CATEGORIES */}
          <div style={{
             marginTop: 25,
             overflowY: 'auto'
          }}>
            {categories}
          </div>
        </div>
        {/* SETTINGS */}
        <Settings
          priorities={priorities}
          userPriorities={userPriorities}
          channels={channels}
          local={settingsLocal}
          remote={settingsRemote}
          handleSettingsChange={handleSettingsChange}
          settingsChanged={settingsChanged}
          handleSaveSettings={handleSaveSettings}
        />
      </div>
    </FullscreenContent>
  )
}

export default Dashboard
