import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import UploadProgressBar from '../../../../utils/UploadProgressBar'
import CancelSave from '../../../shared/CancelSave'
import Icon from '../../../shared/Icon'
import InputContainer from '../../../shared/InputContainer'
import { useSpot } from '../../../shared/SpotProvider'
import { StyledText } from '../../../styling/TextStyling'
import Dropzone from '../dropzone/Dropzone'
import './SpotUpload.css'

export default function SpotUpload(props) {
  const { t } = useTranslation()
  const msg = t('shared.no_files_selected')
  const {
    progress,
    next,
    files,
    loaded,
    uploaded,
    status,
    onSubmit,
    onChange,
  } = useSpot(msg)

  const translationPrefix = 'content.booking.spotUpload'

  return (
    <form>
      <InputContainer
        left
        header={t(translationPrefix + '.title')}
        marginBottom={'20px'}
      >
        <SpotUploadWrapper>
          <Dropzone onFilesAdded={onChange} disabled={status === 'PENDING'} />
        </SpotUploadWrapper>
      </InputContainer>

      {0 < loaded.length && (
        <InputContainer left header={t(translationPrefix + '.selectedFiles')}>
          <StyledFiles>
            <StyledList>
              {loaded.map(({ name, id }, index) => {
                return (
                  <StyledListElem key={`${name}-${index}`} className="Filename">
                    {next && next.id === id && (
                      <UploadProgressBar progress={progress} />
                    )}
                    <StyledText dark centered large>{name}</StyledText>
                    <div style={{width: 4}}></div>
                    {uploaded[id] && (
                      <Icon icon={'check_circle'} color={'green'} size={20} />
                    )}
                  </StyledListElem>
                )
              })}
            </StyledList>
          </StyledFiles>
          {status === 'FILES_UPLOADED' && (
            <SuccessBox>
              <StyledText bold upper centered>
                {t(translationPrefix + '.successfull')}
              </StyledText>
            </SuccessBox>
          )}
        </InputContainer>
      )}

      <br />
      <CancelSave
        canSave={files.length >= 1 && status !== 'FILES_UPLOADED'}
        handleCancel={props.handleCancel}
        handleSave={() => onSubmit(props.bookingId, props.bookingIdStr, props.bookingName)}
        cancelLabel={t('shared.close')}
        saveLabel={t('shared.upload')}
      />
    </form>
  )
}

const SuccessBox = styled.div`
  background: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.themeColor};
  padding: 0.75em;
  letter-spacing: 0.15em;
`

const SpotUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const StyledFiles = styled.div``

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;

  & > li {
    position: relative;
    list-style: none;
    line-height: 1.5;
    margin: 0;
    text-align: center;
    padding: 0.75em 0;
    width: 100%;
  }
`

const StyledListElem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
