import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Setting from './Setting'
import SettingsHidden from './SettingsHidden'
import CancelSave from '../shared/CancelSave'
import HideButton from '../shared/HideButton'
import Icon from '../shared/Icon'

import { StyledText } from '../styling/TextStyling'

function Settings(props) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  if(!visible) {
    return (
      <SettingsHidden
        handleClick={() => setVisible(true)}
      />
    )
  }
  
  const styling = {
    iconFontSize: 14,
    iconTopMargin: 4,
    tableHeaderOuterMargin: 23,
    tableHeaderInnerMargin: 5,
    tableHeaderStyle: {padding: '0 0 10px 0', borderBottom: '1px solid grey'},
    tableHeaderDescriptionStyle: {width: '100%', textAlign: 'left', padding: '0 0 14px 0', borderBottom: '1px solid grey'},
    tableStyle: {padding: '5px 0', borderBottom: '1px solid grey'},
  }

  const channels = props.channels.map((channel, index) => {
    return (
      <th key={index} style={styling.tableHeaderStyle}>
        <Icon
          icon={channel.icon}
          size={styling.iconFontSize}
        />
      </th>
    )
  })
  
  const settings = props.local.map((setting, index) => {
    return (
      <Setting
        key={setting.id}
        styling={styling}
        priorities={props.priorities}
        channels={props.channels}
        setting={setting}
        handleSettingsChange={props.handleSettingsChange}
      />
    )
  })
  
  return (
    <div style={{
      background: 'rgba(0, 0, 0, 0.8)',
      width: '30%',
      maxWidth: 340,
      height: '100%',
    }}>
      <div style={{
         display: 'flex',
         alignItems: 'center',
         margin: 25,
      }}>
        <StyledText bold upper fontSize={20} style={{flexGrow: 1}}>
          {t('dashboard.settings.title')}
        </StyledText>
        <HideButton
          handleClick={() => setVisible(false)}
        />
      </div>
      <table style={{borderSpacing: 0}}>
        <thead>
          <tr>
            <th style={styling.tableHeaderStyle}>
              <Icon
                icon={'view_headline'}
                size={styling.iconFontSize}
              />
            </th>
            <th style={styling.tableHeaderDescriptionStyle}>
              <StyledText bold upper>
                {t('dashboard.settings.description')}
              </StyledText>
            </th>
            {channels}
          </tr>
        </thead>
        <tbody>
          {settings}
        </tbody>
      </table>
      {props.settingsChanged &&
        <div style={{marginTop: styling.tableHeaderOuterMargin}}>
          <CancelSave
            handleCancel={() => setVisible(false)}
            handleSave={props.handleSaveSettings}
          />
        </div>
      }
    </div>
  )
}

export default Settings
