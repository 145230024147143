import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import InputContainer, { InputContainerHeader } from '../../shared/InputContainer'

import { StyledInput } from '../../styling/InputStyling'
import { StyledText } from '../../styling/TextStyling'

function RotationInput(props) {
  const { t } = useTranslation()
  const { inputs, setInputs } = props

  // Convert hour mask to number of seconds
  const seconds = (hours) => {
    let count = -1
    while(hours) {
      count += hours & 1
      hours >>= 1
    }
    return count * 60 * 60
  }

  // Compute rotations from hour mask and repeats per day
  const rotation = (hours, repeatsPerDay) => {
    return 0 < repeatsPerDay ? seconds(hours) / repeatsPerDay : 0
  }

  // User inout to the rotation input field needs to be treated differently,
  // because input['rotation'] does not exists. Instead input['repeats_per_day']
  // is updated.
  const handleRotationChange = (event) => {
    const rotation = event.target.value
    const repeatsPerDay = 0 < rotation ? seconds(inputs['hours']) / rotation : 0
    setInputs(inputs => ({...inputs, repeats_per_day: repeatsPerDay}))
  }

  const Header = () => {
    // Create preset buttons corresponding to rotation in minutes, i.e. we need
    // to multiply the value by 60 and handle it as a standard rotation change
    const rotations = [1.5, 3, 6]
    const buttons = rotations.map((rotation, index) => {
      return (
        <Button
          key={index}
          onMouseUp={() => handleRotationChange({ target: { value: rotation * 60 } })}
        >
          <StyledText>{rotation}</StyledText>
        </Button>
      )
    })

    return (
      <HeaderContainer>
        <StyledInputContainerHeader left>
          {t('shared.rotation')}
        </StyledInputContainerHeader>
        <ButtonContainer>
          {buttons}
        </ButtonContainer>
      </HeaderContainer>
    )
  }

  return (
    <InputContainer dark={props.dark}
      style={{ flexGrow: 1, flexBasis: 0 }}
      marginTop={'0px'}
      marginBottom={'0px'}
      header={<Header />}
    >
      <StyledInput
        type = "number"
        value = {rotation(inputs['hours'], inputs['repeats_per_day'])}
        onChange = {handleRotationChange}
      />
    </InputContainer>
  )
}

export default RotationInput

const StyledInputContainerHeader = styled(InputContainerHeader)`
  flex: 1 0 auto;
  background: rgba(255, 255, 255, 0.15);
`

const HeaderContainer = styled.div`
  display: flex;
`

const ButtonContainer = styled.div`
  display: flex;
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0px 8px;
  cursor: pointer;
  background: ${props => props.theme.colors.themeBackground};
  color: ${props => props.theme.colors.themeColor};

  &:hover {
    filter: brightness(120%);
  }
`
