import React, { useCallback, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { useTranslation } from 'react-i18next'

import CancelSave from './CancelSave'
import ReactModalWrapper from './ReactModalWrapper'

import { StyledText } from '../styling/TextStyling'

function useDialog() {
  const { t } = useTranslation()

  const [text, setText] = useState('')
  const [confirmHandler, setConfirmHandler] = useState(undefined)

  const [showModal, hideModal] = useModal(() => (
    <ReactModalWrapper
      layout={'dialog'}
      overlayColor={'rgba(0, 0, 0, 0.85)'}
      onRequestClose={hideModal}
    >
      <div style = {{
        background: '#FFF',
        padding: 1
      }}>
        <div style = {{
          textAlign: 'center',
          padding: 20
        }}>
          <StyledText dark large>{text}</StyledText>
        </div>

        <CancelSave
          isCloseButton={confirmHandler === undefined}
          cancelLabel={confirmHandler === undefined ? t('shared.ok') : undefined}
          saveLabel={t('shared.ok')}
          handleCancel={hideModal}
          handleSave={() => {
            if(confirmHandler)
              confirmHandler()
            hideModal()
          }}
        />
      </div>
    </ReactModalWrapper>
  ), [text, confirmHandler]);

  const show = useCallback((text, confirmHandler) => {
    setText(text)
    setConfirmHandler(() => confirmHandler)
    showModal()
  }, [setText, showModal])

  return {
    showModal: show,
  }
}

export { useDialog };
