import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import UploadProgressBar from '../../utils/UploadProgressBar'
import request from '../shared/request'
import TextButton from '../shared/TextButton'
import { StyledFileInput } from '../styling/InputStyling'

const BookingProofUpload = ({
  refreshData,
  buttonHeight,
  bookingId,
  handleCancel,
}) => {
  const { t } = useTranslation()

  const [selectedFiles, setSelectedFiles] = useState(undefined)

  const [message, setMessage] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [fileInfos, setFileInfos] = useState([])

  const [progress, setProgress] = useState(0)

  const selectFile = (event) => {
    setSelectedFiles(event.target.files)
  }

  const upload = async () => {
    if (0 < progress) return
    setProgress(1)

    let currentFile = selectedFiles[0]

    let formData = new FormData()

    formData.append('bookingProof', currentFile)

    const { data } = await request('/bookingProof', {
      data: formData,
      method: 'POST',
      params: {
        bookingId,
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent

          let percentCompleted = Math.round((loaded * 100) / total)

          setProgress(percentCompleted)
        }
      },
    })

    refreshData()

    setProgress(0)

    setMessage(data)

    setSelectedFiles(undefined)
  }

  return (
    <div>
      <ButtonWrapper>
        <StyledTextButton
          buttonHeight={buttonHeight}
          handleClick={handleCancel}
        >
          {t('shared.close')}
        </StyledTextButton>

        {!selectedFiles && (
          <StyledLabel htmlFor="fileSelector">
            {t('content.booking_proof.addNew')}
          </StyledLabel>
        )}
        {!selectedFiles && (
          <StyledFileInput
            type="file"
            name="files"
            id="fileSelector"
            accept="image/*"
            onChange={selectFile}
          />
        )}

        {selectedFiles && (
          <StyledTextButton
            green
            buttonHeight={buttonHeight}
            handleClick={upload}
          >
            <UploadProgressBar progress={progress} bgColor="white" />
            {t('shared.upload')}
          </StyledTextButton>
        )}
      </ButtonWrapper>

      <div className="alert alert-light" role="alert">
        {message}
      </div>

      <div className="card">
        <div className="card-header">List of Files</div>
        <ul className="list-group list-group-flush">
          {fileInfos &&
            fileInfos.map((file, index) => (
              <li className="list-group-item" key={index}>
                <a href={file.url}>{file.name}</a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default BookingProofUpload

const ButtonWrapper = styled.div`
  position: relaitve;
  display: flex;
  justify-content: space-between;
`

const StyledTextButton = styled(TextButton)`
  width: 50%;
  flex-grow: 1;
`

const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 50%;
  padding: 0px 30px;
  background: ${(props) => props.theme.colors.themeBackground};
  color: ${(props) => props.theme.colors.themeColor};
  font-family: Roboto-Light;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  filter: ${(props) =>
    props.disabled ? 'brightness(50%)' : 'brightness(100%)'};

  &:hover {
    filter: ${(props) =>
      props.disabled ? 'brightness(50%)' : 'brightness(120%)'};
  }
`
