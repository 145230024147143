import React from 'react'
import { useTranslation } from "react-i18next";

import { StyledTextLink } from '../styling/TextStyling'

function Priorities(props) {
  const { t } = useTranslation()
  const { priorities, itemsPerPriority, userPriorities, handlePriorityChange } = props

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center'
    }}>
      {priorities.map(priority => {
        const name = t('dashboard.priorities.' + priority.name)
        const count = priority.id & userPriorities ?
              '' :
              ' (' + itemsPerPriority[priority.id] + ')'

        return (
          <div key={priority.id} style={{
            display: 'flex',
            marginLeft: 25
          }}>
            <StyledTextLink light upper
              onClick={() => handlePriorityChange(priority.id)}
            >
              {name + count}
            </StyledTextLink>
            <input style={{marginLeft: 8}}
              type="checkbox"
              checked={priority.id & userPriorities}
              onChange={() => handlePriorityChange(priority.id)}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Priorities
