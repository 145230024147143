import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FixedSizeList as List } from 'react-window'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'
import useResizeWindow from '../../utils/useResizeWindow'
import { useAuth } from '../auth/Authentication'
import CategoryHeader from '../nav/CategoryHeader'
import {
  StyledClearSearch,
  StyledData,
  StyledDataHeader,
  StyledHeaders,
  StyledSearch,
} from '../styling/DataComponentStyling'
import { useDataInput } from './DataInput'
import { useDataInterface } from './DataInterface'
import DataRow from './DataRow'
import DataSortableHeader from './DataSortableHeader'
import NewItemButton from './NewItemButton'

export default function DataView(props) {
  const { t } = useTranslation()
  const { userHasPermissions } = useAuth()

  const itemSize = props.rowHeight ? props.rowHeight : 72
  const { height } = useResizeWindow()

  // DataInterface
  const {
    dataId,
    data,
    handleDuplicate,
    handleDelete,
    searchText,
    handleSearchTextChange,
    handleSaveEdit,
    sortData,
    sortProperty,
    sortOrder,
    refreshData,
  } = useDataInterface({
    endPoint: props.endPoint,
    externalData: props.externalData,
  })

  useEffect(() => {
    if(props.refreshId) {
      console.log('refresh')
      refreshData()
    }
  }, [props.refreshId, refreshData])

  // DataInput
  const { handleNew, handleEdit } = useDataInput({
    translationPrefix: props.translationPrefix,
    fields: props.fields,
    handleSaveEdit: handleSaveEdit,
    customProcessBeforeSaveEdit: props.customProcessBeforeSaveEdit,
  })

  const { clearQueryParams, isQueryParamSet } = useQueryEndPoint()
  const clearQueryButton = !isQueryParamSet() ? null : (
    <StyledClearSearch onClick={() => clearQueryParams()}>
      {t('shared.clearSearch')}
    </StyledClearSearch>
  )

  const Headers = props.columns.map((column, index) => (
    (column.isHidden === undefined || !column.isHidden()) &&
    <DataSortableHeader
      key={index}
      centered={column.centered}
      style={column.style}
      sortProperty={column.defaultProperty}
      sortStringFunction={column.sortStringFunction}
      activeSortProperty={sortProperty}
      activeSortOrder={sortOrder}
      handleSort={sortData}
      searchHidden={props.searchAndActionsHidden}
    >
      {column.header}
    </DataSortableHeader>
  ))

  // Compile actions once from custom actions and standard actions (edit and delete)
  const actions = useMemo(() => {
    const getEndPoint = (action) => {
      return props.standardActionEndPoints !== undefined &&
        props.standardActionEndPoints.has(action)
        ? props.standardActionEndPoints.get(action)
        : props.endPoint
    }
    const getPermissionOk = (action, row) => {
      return props.standardActionPermissionOk !== undefined &&
        props.standardActionPermissionOk.has(action)
        ? props.standardActionPermissionOk.get(action)(row)
        : true
    }

    const acts =
      props.customActions !== undefined ? [...props.customActions] : []

    // Duplication is a standard action, but must be activated explicitly
    if (
      props.standardActions !== undefined &&
      props.standardActions.includes('duplicate')
    ) {
      acts.push({
        type: 'duplicate',
        permissionsOk: (d) => canDeleteRowFromTable(d) && userHasPermissions(getEndPoint('add'), 'add'),
        confirmTranslationPostfix: '.confirmDuplicate',
        handleClick: handleDuplicate,
      })
    }

    if (
      props.standardActions === undefined ||
      props.standardActions.includes('edit')
    ) {
      acts.push({
        type: 'edit',
        permissionsOk: (d) => canDeleteRowFromTable(d) && userHasPermissions(getEndPoint('edit'), 'edit'),
        handleClick: (id) => {
          const rowData = data.find((r) => r.id === id)
          if (props.customHandleEdit !== undefined)
            props.customHandleEdit(rowData)
          else handleEdit(rowData)
        },
      })
    }

    if (
      props.standardActions === undefined ||
      props.standardActions.includes('delete')
    ) {
      acts.push({
        type: (d) => canDeleteRowFromTable(d) ? (canDisableRowFromTable(d) ? 'disable' : 'delete') : 'restore',
        permissionsOk: (d) => {
          const action = canDeleteRowFromTable(d) ? 'delete' : 'add'
          if(props.standardActionPermissionOk === undefined)
            return userHasPermissions(getEndPoint(action), action)
          else
            return (row) => getPermissionOk(action, row) && userHasPermissions(getEndPoint(action), action)
        },
        confirmTranslationPostfix: (d) => canDeleteRowFromTable(d) ? '.confirmDelete' : '.confirmRestore',
        handleClick: handleDelete,
      })
    }

    return acts
    // Disable warning for dependency on data - depend on dataId instead,
    // because data can change due to user interaction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.customActions,
    props.standardActions,
    props.standardActionEndPoints,
    props.standardActionPermissionOk,
    props.customHandleEdit,
    handleEdit,
    dataId,
    handleDelete,
    userHasPermissions,
  ])

  const filteredData = useMemo(() => {
    if(searchText !== '') {
      const regex = new RegExp(searchText, 'ig')
      const excluded_keys = ['index', '_id', 'ids', 'timestamp', 'invoice_type']

      return data.filter((o) => {
        if(Object.entries(o).some(([k, v]) => !excluded_keys.some((e) => k.endsWith(e)) && regex.test(v)))
          return true

        const enums = new Map([['state', 'shared.']])
        return [...enums.keys()].some((k) => {
          return o.hasOwnProperty(k) && regex.test(o[k] !== null ? t(enums.get(k) + o[k]) : 'n.a.')
        })
      })
    } else {
      return data
    }
  }, [data, searchText, t])

  const Row = ({ index, style }) => {
    return (
      <DataRow
        key={filteredData[index].id}
        index={index}
        data={filteredData[index]}
        columns={props.columns}
        translationPrefix={props.translationPrefix}
        actions={actions}
        style={style}
        actionsHidden={props.searchAndActionsHidden || props.actionsHidden}
        handleSaveEdit={handleSaveEdit}
      />
    )
  }

  return (
    <StyledData>
      {!props.categoryHeaderHidden &&
        <CategoryHeader
          items={[
            t(props.translationPrefix.split('.')[0] + '.title'),
            props.title ? props.title : t(props.translationPrefix + '.title'),
          ]}
        >
          {props.categoryHeaderItem !== undefined && props.categoryHeaderItem}
          {!props.noNewItemButton && userHasPermissions(props.endPoint, 'add') && (
            <NewItemButton
              text={t(props.translationPrefix + '.new')}
              handleClick={handleNew}
            />
          )}
        </CategoryHeader>
      }
      {!props.clearQueryButtonHidden && clearQueryButton}
      <StyledHeaders>
        <DataSortableHeader
          centered
          style={{ justifyContent: 'center' }}
          sortProperty={'index'}
          activeSortProperty={sortProperty}
          activeSortOrder={sortOrder}
          handleSort={sortData}
        >
          {'Idx'}
        </DataSortableHeader>
        {Headers}
        {!props.searchAndActionsHidden &&
          <StyledDataHeader>
            <StyledSearch
              type="text"
              value={searchText}
              placeholder={t('shared.search')}
              onChange={handleSearchTextChange}
            />
          </StyledDataHeader>
        }
      </StyledHeaders>
      <List
        itemSize={itemSize}
        itemCount={filteredData.length}
        height={props.fitHeightToItems ? filteredData.length * itemSize + 40 : height}
      >
        {Row}
      </List>
    </StyledData>
  )
}


export const canDeleteRowFromTable = (d) => d.state === undefined || d.state !== 'deleted'
export const canDisableRowFromTable = (d) => d.state !== undefined && d.state === 'active'
