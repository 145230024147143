import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { cShowPreviousSpots } from '../../../Theme'
import BookingUnits from './BookingUnits'
import SpotComposition from './SpotComposition'
import Spots from './Spots'
import CategoryHeader from '../../nav/CategoryHeader'
import NewItemButton from '../../shared/NewItemButton'

import { useMetaDataEdit } from './MetaData'
import { useSpotUploadModal } from './SpotUploadModal'
import { useAuth } from '../../auth/Authentication'
import { useDataInterface } from '../../shared/DataInterface'

import { FullscreenContent } from '../../styling/Fullscreen'
import { StyledText } from '../../styling/TextStyling'

import { getBookingType } from '../../../utils/BookingUnitHelper'
import { toBookingId } from '../../../utils/TimestampHelper'

import { useQueryEndPoint } from '../../../utils/QueryEndPoint'

function Booking(props) {
  const { t } = useTranslation()
  const location = useLocation()
  const { endPointFromQueryPath, getQueryParam, queryPath } = useQueryEndPoint()

  const { authCall, userHasPermissions } = useAuth()
  const endPoint = 'content/' + props.endPoint
  const translationPrefix = 'content.booking'
  const isValidId = (id) => id !== null && id !== -1
  const isOverrule = window.location.pathname === '/content/overrule'
  const userCanEdit = userHasPermissions('content/' + props.endPoint.split('/')[0], 'edit')

  // booking id: parsed from window.location.search on change of authCall
  // and history
  const [bookingId, setBookingId] = useState(null);
  const [newBookingCreated, setNewBookingCreated] = useState(false);
  const updateBookingId = useCallback(() => {
    const id = isOverrule ? 1 : getQueryParam(endPoint, 'id');
    //console.log('updateBookingId', id)
    setBookingId(id);
  }, [isOverrule, getQueryParam, endPoint]);
  useEffect(() => updateBookingId(), [location, updateBookingId]);
  useEffect(() => updateBookingId(), [authCall, updateBookingId]);

  // Data interface for booking id
  const bookingDataInterface = useDataInterface({
    // In the case where isValidId is false, we still need to provide an endPoint
    // for handleSaveEdit to work - a bit hacky!
    endPoint: isOverrule ? 'content/booking?id=1' : endPointFromQueryPath(endPoint),
    autoUpdate: isValidId(bookingId),
    handleNewIds: (data) => {
      setSelectedBookingUnitId(-1);
      setSelectedBookingUnit(null);
      setNewBookingCreated(true);
      queryPath(endPoint, 'id', data.id);
    },
  });
  const { handleEdit } = useMetaDataEdit(bookingDataInterface);
  const { handleUploadNewSpot } = useSpotUploadModal();

  // Data interface for resolutions
  const resolutionsDataInterface = useDataInterface({
    endPoint: 'admin/resolutions',
  });

  // booking unit id and associated player data use for the booking unit
  const [selectedBookingUnitId, setSelectedBookingUnitId] = useState(-1)
  const [selectedBookingUnit, setSelectedBookingUnit] = useState(null)
  const [usedPlayers, setUsedPlayers] = useState([])
  const [usedResolutions, setUsedResolutions] = useState([])
  const filterResolutions = useCallback(
    (bookingUnitsPlayers) => {
      const usedIds = [
        ...new Set(bookingUnitsPlayers.map((item) => item.resolution_id)),
      ]
      const usedResolutions = resolutionsDataInterface.data.filter(
        (resolution) => {
          return usedIds.includes(resolution.id)
        }
      )
      setUsedResolutions(usedResolutions)
    },
    [setUsedResolutions, resolutionsDataInterface.data]
  )
  const updateUsedPlayers = useCallback(
    (id) => {
      if (0 <= id) {
        authCall('content/bookingUnitPlayers?booking_unit_id=' + id, []).then(
          (data) => {
            filterResolutions(data)

            const usedPlayers = []
            data.forEach((item) => {
              item['isUpdate'] = true
              item['checked'] = true
              usedPlayers[item.player_id] = item
            })
            setUsedPlayers(usedPlayers)
          }
        );
      } else {
        setUsedResolutions([])
        setUsedPlayers([])
      }
    },
    [authCall, setUsedResolutions, filterResolutions, setUsedPlayers]
  );
  useEffect(() => {
    updateUsedPlayers(selectedBookingUnitId);
  }, [
    updateUsedPlayers,
    selectedBookingUnitId,
    resolutionsDataInterface.dataId,
  ])

  // Data interface for booking unit id
  const bookingUnitsDataInterface = useDataInterface({
    endPoint: isValidId(bookingId)
      ? 'content/bookingUnits?booking_id=' + bookingId
      : '',
    handleNewIds: (data) => {
      if(newBookingCreated)
        setNewBookingCreated(false)
      handleSelectedBookingUnitId(data.id)
    },
    handleDeleteNotification: (id) => setSelectedBookingUnitId(id),
    handleSaveEditNotification: () => updateUsedPlayers(selectedBookingUnitId),
  })
  const startTimestamp = selectedBookingUnit
    ? selectedBookingUnit.start_timestamp
    : undefined
  const endTimestamp = selectedBookingUnit
    ? selectedBookingUnit.end_timestamp
    : undefined

  useEffect(() => {
    if(selectedBookingUnitId !== -1 && bookingUnitsDataInterface.data) {
      setSelectedBookingUnit(
        bookingUnitsDataInterface.data.find((bookingUnit) => {
          return bookingUnit.id === selectedBookingUnitId
        })
      )
    } else {
      setSelectedBookingUnit(null)
    }
    // Disable warning for dependency on data - depend on dataId instead,
    // because bookingUnitsDataInterface.data can change due to user interaction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBookingUnitId, bookingUnitsDataInterface.dataId])

  // Update used players and player formats on change of selected booking unit id
  const handleSelectedBookingUnitId = useCallback(
    (id) => {
      if (selectedBookingUnitId !== id) {
        setSelectedBookingUnitId(id)
        updateUsedPlayers(id)
      }
    },
    [selectedBookingUnitId, setSelectedBookingUnitId, updateUsedPlayers]
  )

  // Data interface for spots associated with selected booking unit
  const bookingUnitSpotsDataInterface = useDataInterface({
    endPoint: 'content/bookingUnitSpots?booking_unit_id=' + selectedBookingUnitId,
    translationPrefix: 'content.booking.spotComposition',
  })

  const bookingType = isValidId(bookingId) && bookingDataInterface.data[0]
        ? getBookingType(bookingDataInterface.data[0]) : ''

  const bookingIdStr = bookingType === '' ? '' : t('shared.' + bookingType) + ' ' +
        toBookingId(bookingDataInterface.data[0]['creation_timestamp'], bookingDataInterface.data[0]['id'])
  const bookingName = bookingType === '' ? '' : bookingDataInterface.data[0]['name']
  const headerItems = isOverrule ? [t('content.overrule.title')] : [t('content.title'), bookingIdStr, bookingName]
  const headerCopyText = isOverrule ? undefined : bookingIdStr + ': ' + bookingName

  const centerHeaderText =
    t('content.booking.spotComposition.title') +
    (isValidId(selectedBookingUnitId) && selectedBookingUnit
      ? ' | ' +
        t('content.booking.bookingUnits.bookingUnit') +
        ' ' +
        (selectedBookingUnit.index + 1)
      : '')

  const hasSpots = isValidId(selectedBookingUnitId) && selectedBookingUnit &&
        !['subscription', 'reservation'].includes(getBookingType(selectedBookingUnit))

  if(isValidId(bookingId) && 0 < bookingDataInterface.data.length) {
    return (
      <FullscreenContent>
        <OuterWrapper>
          {/* HEADER */}
          <HeaderWrapper>
            <CategoryHeader
              items={headerItems}
              copyText={headerCopyText}
            >
              {userCanEdit && !isOverrule && (
                <NewItemButton
                  icon={'edit'}
                  text={t(translationPrefix + '.editExistingBooking')}
                  handleClick={handleEdit}
                />
              )}
            </CategoryHeader>
          </HeaderWrapper>
          <CenterWrapper>
            {/* LEFT */}
            <LeftColumn>
              <BookingUnitWrapper>
                <BookingUnits
                  bookingId={bookingId}
                  booking={bookingDataInterface.data[0]}
                  newBookingCreated={newBookingCreated}
                  dataInterface={bookingUnitsDataInterface}
                  usedPlayers={usedPlayers}
                  selectedBookingUnitId={selectedBookingUnitId}
                  userCanEdit={userCanEdit}
                  handleSelectedBookingUnitId={handleSelectedBookingUnitId}
                  revertUsedPlayers={() =>
                    updateUsedPlayers(selectedBookingUnitId)
                  }
                />
              </BookingUnitWrapper>
            </LeftColumn>
            {/* CENTER */}
            <CenterColumn>
              {hasSpots && isValidId(selectedBookingUnitId) && selectedBookingUnit && (
                <SpotCompositionWrapper>
                  <SpotComposition
                    headerText={centerHeaderText}
                    bookingUnitId={selectedBookingUnitId}
                    bookingUnit={selectedBookingUnit}
                    dataInterface={bookingUnitSpotsDataInterface}
                    resolutions={usedResolutions}
                    startTimestamp={startTimestamp}
                    endTimestamp={endTimestamp}
                    userCanEdit={userCanEdit}
                  />
                  <BottomSpacer centerColumn/>
                </SpotCompositionWrapper>
              )}
            </CenterColumn>
            {/* RIGHT */}
            <RightColumn>
              {hasSpots && (
                <Spots
                  bookingId={bookingId}
                  userCanEdit={userCanEdit}
                  handleNew={() => handleUploadNewSpot(bookingId,
                                                       headerItems[1],
                                                       headerItems[2])}
                  onSpotDeleted={() => bookingUnitSpotsDataInterface.refreshData()}
                />
              )}
              {hasSpots && cShowPreviousSpots && (
                <Spots
                  previous
                  bookingId={bookingId}
                  userCanEdit={userCanEdit}
                />
              )}
            </RightColumn>
          </CenterWrapper>
          <FooterWrapper></FooterWrapper>
        </OuterWrapper>
      </FullscreenContent>
    )
  } else {
    return (
      <div style={{height: 58, padding: 18}}>
        <StyledText large bold>{t('info.invalid_booking_id', { booking_id: bookingId })}</StyledText>
      </div>
    )
  }
}

export default Booking;

// Rows
const OuterWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  background: rgba(176, 196, 222, 0.5);
`;

const CenterWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  width: 100%;
  overflow-y: auto;
`;

const FooterWrapper = styled.div`
  display: flex;
  background: #fff;
`;

// Columns in CenterWrapper
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.2);
  width: ${(props) => props.theme.bookingUnit.width};
  min-width: ${(props) => props.theme.bookingUnit.width};
  padding: 20px 15px 20px 20px;
  min-height: calc(100vh - 40px - ${props => props.theme.header.height} - ${props => props.theme.header.height});
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 15px 20px 15px;
  min-height: calc(100vh - 40px - ${props => props.theme.header.height} - ${props => props.theme.header.height});
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.theme.bookingUnit.width};
  width: 30%;
  padding: 20px 20px 20px 15px;
  min-height: calc(100vh - 40px - ${props => props.theme.header.height} - ${props => props.theme.header.height});
`;

// Individual components
const BookingUnitWrapper = styled.div`
  display: inline-block;
  vertical-align: text-top;
  width: ${(props) => props.theme.bookingUnit.width};
`;

const SpotCompositionWrapper = styled.div`
`;

const BottomSpacer = styled.div`
  height: ${props => props.centerColumn ? '40px' : '20px'};
`;
