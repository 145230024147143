import React, { useState } from 'react'
import styled from 'styled-components'

import { useTutorial } from '../tutorial/TutorialProvider'

import { StyledText } from '../styling/TextStyling'

function Section(props) {
  const { tutorialElemValid, getTutorialElemRect } = useTutorial()
  const { title, steps } = props.section

  const [idx, setIdx] = useState(-1)

  const handleMouseOver = (i) => {
    setIdx(i)

    const rect = getTutorialElemRect(steps[i].id)
    if(rect) {
      props.setHighlight({
        hidden: false,
        top:    rect.top,
        left:   rect.left,
        width:  rect.width,
        height: rect.height,
      })
    } else {
      props.setHighlight({
        hidden: false,
        top:    steps[i].t,
        left:   steps[i].l,
        width:  steps[i].w,
        height: steps[i].h,
      })
    }
  }

  const handleMouseOut = () => {
    setIdx(-1)
    props.setHighlight(prev => ({
      ...prev,
      hidden: true,
    }))
  }

  return (
    <Container>
      <DescriptionContainer>
        {title !== undefined && (
          <StyledHeader bold>
            {title}
          </StyledHeader>
        )}
        <StyledOl>
          {steps.map((s, i) => (s.isActive === undefined || s.isActive) && tutorialElemValid(s.id) && (
            <StyledText
              key={i}
              bold={i === idx}
              fontSize={14}
            >
              <StyledLi
                onMouseOver={() => handleMouseOver(i)}
                onMouseOut={() => handleMouseOut()}
              >
                {s.text}
              </StyledLi>
            </StyledText>
          ))}
        </StyledOl>
        {idx !== -1 && steps[idx].hint && (
          <StyledHint>
            <StyledText bold>{steps[idx].hintHeader + ':'}</StyledText>
            <StyledText>
              {steps[idx].hint}
            </StyledText>
          </StyledHint>
        )}
      </DescriptionContainer>
    </Container>
  )
}

export default Section

const Container = styled.div`
  display: flex;
  margin-top: 0px;
  overflow-x: hidden;
`

const DescriptionContainer = styled.div`
  margin: 0;
`

const StyledHeader = styled(StyledText)`
  margin: 0 0 8px 16px;
  font-size: 14px;
`

const StyledOl = styled.ol`
  margin: 0;
`

const StyledLi = styled.li`
  color: ${props => props.theme.colors.themeColor};
`

const StyledHint = styled.div`
  margin: 24px;
  width: 240px;
`
