import React from 'react'

import PriorityIndicator from './PriorityIndicator'

import { StyledText } from '../styling/TextStyling'

function SubCategoryHeader(props) {
  const { priorities, priority, title } = props

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        background: '#EEE',
        padding: '15px 10px 15px 25px'
      }}
    >
      <PriorityIndicator priorities={priorities} priority={priority} />
      <StyledText dark style={{marginLeft: 10}}>{title}</StyledText>
    </div>  
  )
}

export default SubCategoryHeader
