import React, { useState } from 'react'
import styled from 'styled-components'

import Clear from './Clear'
import Icon from './Icon'
import ListButtonSource from './ListButtonSource'
import Tooltip from './Tooltip'

import { StyledText } from '../styling/TextStyling'

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${props => props.theme.colors.black};
  width: 100%;
  min-width: 90px;
  height: 28px;
  border-radius: 3px;
  padding: 0;
  margin: 0;
`

const StyledCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  background: ${props => props.theme.colors.themeBackground};
  color: ${props => props.theme.colors.themeColor};
  border-radius: 3px;
  min-width: 14px;
  height: 28px;
  padding: 0px 4px;
`

const StyledCounterBorder = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  background: ${props => props.theme.colors.themeBackground};
  border-radius: 0px;
  width: 3px;
  height: 28px;
`

function ListButton(props) {
  const [color, setColor] = useState('white')

  const dataValid = props.data && props.data.length ? true : false
  const numData = dataValid ? props.data.length : 0
  const dataContent = dataValid && props.data.map((v, i) => <StyledText key = {i}>{v}</StyledText>)

  const disabledCount = props.disabledCount !== undefined ? props.disabledCount : 0
  const counter = props.counter !== undefined ? props.counter : numData
  const content = props.source ?
        <ListButtonSource endPoint={props.source} />
        : dataContent

  const handleClick = () => {
    if(disabledCount < counter)
      props.handleClick()
  }

  return (
    <Tooltip listButton
      disabled = {counter === disabledCount}
      content = {content}
      handleOnMouseEnter = {() => setColor('lightgrey')}
      handleOnMouseLeave = {() => setColor('white')}
    >
      <StyledButton
        onClick = {handleClick}
      >
        <StyledCounter>
          <StyledCounterBorder />
          {counter}
        </StyledCounter>
        <Icon
          icon={props.spots ? 'play_arrow' : (props.warning ? 'warning' : (props.users ? 'person' : 'tv'))}
          color={color}
          size={16}
        />
        <Clear />
      </StyledButton>
    </Tooltip>
  )
}

export default ListButton
