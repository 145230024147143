import React from 'react'
import { useTranslation } from 'react-i18next'

import InputContainer from '../../shared/InputContainer'
import Select from '../../shared/Select'

import { useDataInterface } from '../../shared/DataInterface'

function ReservationTeamInput(props) {
  const { t } = useTranslation()
  const { inputs, handleInputChange } = props

  const { data } = useDataInterface({ endPoint: 'admin/teams' })

  return 0 < data.length && (
    <InputContainer
      dark={props.dark}
      marginTop={'20px'}
      marginBottom={'20px'}
      header={t('content.booking.reservation_team')}
      tutorialId={'ReservationTeamInput'}
    >
      <Select
        searchable
        type='number'
        value={inputs['bookingattributes']}
        onChange={handleInputChange}
        name='bookingattributes'
        options={data.map(t => ({ value: t.id, text: t.name }))}
      />
    </InputContainer>
  )
}

export default ReservationTeamInput
