import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ActionButton from '../../shared/ActionButton'
import { standardButtonStyle } from '../../shared/StandardButton'

import { StyledText } from '../../styling/TextStyling'

function ColumnHeader(props) {
  const { t } = useTranslation();

  const newItemButton = props.hasNewItemButton ? (
    <div ref={props.handleRef}>
      <ActionButton
        icon={'add'}
        text={t(props.translationPrefix + '.addNew')}
        style={{float: 'right', background: 'rgba(0, 0, 0, 0.2)'}}
        handleClick={props.handleNew}
      />
    </div>
  ) : (
    <div style={{float: 'right', ...standardButtonStyle, width: 0}}></div>
  )

  return (
    <Header
      hasNewItemButton={props.hasNewItemButton}
    >
      <StyledText medium bold centered stretch>
        {props.text}
      </StyledText>
      {newItemButton}
    </Header>
  )
}

export default ColumnHeader

const Header = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background: ${props => props.theme.colors.themeBackground};
  padding-left: ${props => props.hasNewItemButton ? '36px' : '0'};
  margin-bottom: 20px;
`;
