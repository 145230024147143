import styled from "styled-components";

// TABLE

export const StyledData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledNewButton = styled.div`
  float: right;
  width: 58px;
  height: 58px;
  background: ${(props) => props.selected ? 'rgba(0, 0, 0, 0.2)' : 'transparent'};
  color: ${(props) => props.theme.colors.themeNavColor};

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const StyledClearSearch = styled.div`
  background: #000;
  color: #fff;
  padding: 4px 20px 4px 0;
  text-align: right;
  cursor: pointer;
  font-family: Roboto-Light;
  font-size: 12px;

  &:hover {
    color: #f00;
  }
`;

// HEADER

export const StyledHeaders = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${(props) => props.theme.header.height};
`;

export const StyledDataHeader = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.themeBackground};
  color: ${(props) => props.theme.colors.themeColor};
  font-family: Roboto-Medium;
  font-size: 12px;
  padding: 20px;
  user-select: none;
  width: 100%;

  &:first-child {
    flex: none;
    width: ${(props) => props.theme.table.idxColWidth};
  }

  ${({ searchHidden }) =>
    searchHidden === undefined || searchHidden === false ?
    `
    &:last-child {
      flex: none;
      width: 8%;
      max-width: ${(props) => props.theme.table.actionColWidth};
    }
    `
    :
    `
    &:last-child {
      flex: ${props => props.fixedWidth ? 'none' : '0 1 auto'};
    }
    `
  }

  &:not(:first-child):not(:last-child) {
    flex: ${props => props.fixedWidth ? 'none' : '0 1 auto'};
  }

  .sticky & {
    position: sticky;
    top: 0;
    z-index: 99;
  }
`;

export const StyledDataHeaderContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  align-items: center;
`;

export const StyledSearch = styled.input`
  background: ${(props) => props.theme.colors.lightBackground};
  color: ${(props) => props.theme.colors.lightColor};
  width: 100%;
  padding: 2px 4px;
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-family: Roboto-Medium;
  font-size: 12px;
`;

// ROWS

export const StyledDataRowContainer = styled.div`
  position: relative;
  display: flex;
  background: ${(props) => (props.index % 2 === 0 ? "#ffffff" : "#f9f9f9")};

  ${({ noHover }) =>
    noHover === undefined &&
    `
    &:hover {
      background: #ccc;
    }
  `}
`;

export const StyledDataCell = styled.div`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.lightColor};
  font-family: Roboto-Light;
  font-size: 12px;
  padding: 20px;
  margin: 0;
  user-select: none;
  text-overflow: ellipsis;
  width: 100%;

  &:first-child {
    flex: none;
    background: ${(props) => (props.index % 2 === 0 ? "#fafafa" : "#f1f1f1")};
    width: ${(props) => props.theme.table.idxColWidth};
  }

  ${({ actionsHidden }) =>
    actionsHidden === undefined || actionsHidden === false ?
    `
    &:last-child {
      flex: none;
      width: 8%;
      max-width: ${(props) => props.theme.table.actionColWidth};
    }
    `
    :
    `
    &:last-child {
      flex: ${props => props.fixedWidth ? 'none' : '0 1 auto'};
    }
    `
  }

  &:not(:first-child):not(:last-child) {
    flex: ${props => props.fixedWidth ? 'none' : '0 1 auto'};
  }
`;

export const StyledActionGrid = styled.div`
  display: grid;
  grid-gap: 2;
  visibility: hidden;

  ${StyledDataRowContainer}:hover & {
    visibility: visible;
  }
`;
