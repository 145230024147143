import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import HardwareId from './HardwareId'
import { cPricingUnit } from '../../Theme'
import Icon from '../shared/Icon'
import Select from '../shared/Select'
import Table from '../shared/Table'
import { useDataInterface } from '../shared/DataInterface'

import { currencyUnit, localePricePerSecond } from '../../utils/Locale'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Players() {
  const { t } = useTranslation();
  const { endPointFromQueryPath } = useQueryEndPoint()

  const endPoint = endPointFromQueryPath('admin/players')
  const translationPrefix = 'admin.players'

  // Data interface for sites
  const siteDataInterface = useDataInterface({ endPoint: 'admin/sites' })
  const [sitesById, setSitesById] = useState({})
  useEffect(() => {
    setSitesById(siteDataInterface.data.reduce(function(map, site) {
      map[site.id] = site.name;
      return map;
    }, {}));
    // Disable warning for dependency on data - depend on dataId instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDataInterface.dataId])

  // Data interface for resolutions
  const resolutionsDataInterface = useDataInterface({ endPoint: 'admin/resolutions' })

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      rowHeight={122}

      columns={[
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name'
        },
        {
          header: t(translationPrefix + '.site'),
          defaultProperty: 'site_id',
          sortStringFunction: (data) => {
            return siteDataInterface.data.find(site => site.id === data.site_id).name
          },
          visualizationFunction: (data) => {
            return (
              <div>
                {sitesById[data.site_id]}
              </div>
            )
          }
        },
        {
          header: t(translationPrefix + '.resolution'),
          defaultProperty: 'resolution',
          style: {justifyContent: 'center'},
        },
        {
          header: t(translationPrefix + '.memory'),
          defaultProperty: 'hd_used',
          sortStringFunction: (data) => Math.round(100 * data['hd_used'] / data['hd_total']),
          style: {justifyContent: 'center'},
          visualizationFunction: (data) => {
            return (
              <div>
                {Math.round(100 * data['hd_used'] / data['hd_total']) + '%'}
              </div>
            )
          }
        },
        {
          header: t(translationPrefix + '.status'),
          defaultProperty: 'online',
          sortStringFunction: (data) => (data['online'] === 1 ? 'a' : 'b') + data['name'],
          style: {justifyContent: 'center'},
          visualizationFunction: (data) => {
            const online = data['online'] === 1
            return (
              <Icon
                icon={online ? 'done' : 'warning'}
                color={online ? 'green' : 'red'}
                size={20}
              />
            )
          }
        },
        {
          header: t(translationPrefix + '.on_off'),
          defaultProperty: 'switchontime',
          sortStringFunction: (data) => {
            const hours = parseInt(data['switchontime'].substr(0,2))
            const minutes = parseInt(data['switchontime'].substr(3,5))
            return hours * 60 + minutes
          },
          style: {justifyContent: 'center'},
          visualizationFunction: (data) => {
            return (
              <div style={{textAlign: 'center'}}>
                {data['switchontime'].substr(0,5) + '-' + data['switchofftime'].substr(0,5)}
                <br />
                {'(' + t('shared.weekday0') + '-' + t('shared.weekday6') + ')'}
              </div>
            )
          }
        },
        {
          header: t('shared.price_per_' + cPricingUnit) + currencyUnit(),
          defaultProperty: 'price_per_second',
          style: {width: '160px', justifyContent: 'center'},
          visualizationFunction: (data) => {
            return <div>{localePricePerSecond(data['price_per_second'])}</div>
          }
        },
      ]}
    
      fields={[
        {
          name: t(translationPrefix + '.uniqueId'),
          isActive: (inputs) => inputs['unique_id'] !== undefined,
          input: (inputs) => <HardwareId hardwareId = {inputs['unique_id']} />
        },
        {
          name: t(translationPrefix + '.name'),
          property: 'name',
          validator: 'name'
        },
        {
          name: t(translationPrefix + '.site'),
          property: 'site_id',
          type: 'int',
          defaultValue: -1,
          validator: 'selected',
          input: (inputs, handleInputChange) => (
            <Select
              searchable
              value={inputs['site_id']}
              onChange={handleInputChange}
              name={'site_id'}
              options={siteDataInterface.data.map(site => (
                { value: site.id, text: site.name }
              ))}
            />
          ),
        },
        {
          name: t(translationPrefix + '.resolution'),
          property: 'resolution_id',
          type: 'int',
          defaultValue: -1,
          validator: 'selected',
          input: (inputs, handleInputChange) => (
            <Select
              value={inputs['resolution_id']}
              onChange={handleInputChange}
              name={'resolution_id'}
              options={resolutionsDataInterface.data.map((resolution, index) => (
                { value: resolution.id, text: resolution.name }
              ))}
            />
          ),
        },
        {
          name: t(translationPrefix + '.interactive'),
          property: 'interactive',
          type: 'int',
          defaultValue: 0,
          input: (inputs, handleInputChange) => (
            <Select
              value={inputs['interactive']}
              onChange={handleInputChange}
              name='interactive'
              options={[
                { value: 0, text: t('shared.no') },
                { value: 1, text: t('shared.yes') },
              ]}
            />
          ),
        },
        {
          name: t(translationPrefix + '.switchOnTime'),
          property: 'switchontime',
          type: 'time',
          validator: 'time'
        },
        {
          name: t(translationPrefix + '.switchOffTime'),
          property: 'switchofftime',
          type: 'time',
          validator: 'time'
        },
        {
          name: t('shared.price_per_' + cPricingUnit) + currencyUnit(),
          property: 'price_per_second',
          type: 'float',
          optional: true
        }
      ]}
    />
  )
}

export default Players
