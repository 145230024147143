import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import SitesAndPlayers from '../content/booking/SitesAndPlayers'

import { useDialog } from '../shared/Dialog'
import { usePlayerData } from '../shared/PlayerData'

function PlayerIdInput(props) {
  const { t } = useTranslation()

  const { playerIds, auth, handleInputChange } = props

  const dialog = useDialog()
  const [dialogPlayerDataId, setDialogPlayerDataId] = useState(-1)

  // Data interface site and player selection
  const [usedPlayers, setUsedPlayers] = useState([])
  const { playerDataId, sites, players, handleSiteChange, handlePlayerChange,
          handleSiteExpanderClick
  } = usePlayerData(auth, usedPlayers, /*selectedBookingUnitId*/undefined, /*filtered*/false)

  // Initialize used players once based on incoming player ids
  useEffect(() => {
    const usedPlayers = []
    playerIds.forEach(playerId => {
      usedPlayers[playerId] = {
        player_id: playerId,
        isUpdate: true,
        checked: true
      }
    })
    setUsedPlayers(usedPlayers)
    // Disable warning for dependency on playerIds - must happen only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // On user input, filter out the selected player ids and submit the changes
  // to the input data structure of DataEdit
  useEffect(() => {
    if(playerDataId) {
      let strLen = 0
      const playerIds = []
      usedPlayers.forEach(usedPlayer => {
        if(usedPlayer.checked) {
          strLen += usedPlayer['player_id'].toString().length + 1
          playerIds.push(usedPlayer['player_id'])
        }
      })

      if(strLen < 4096) {
        const event = {
          persist: () => {},
          target: {
            name: 'player_ids',
            value: playerIds,
            type: 'array',
            checked: false
          }
        }

        handleInputChange(event)
      } else {
        if(playerDataId !== dialogPlayerDataId) {
          setDialogPlayerDataId(playerDataId)
          dialog.showModal(t('info.player_ids_len_exceeded'))
        }
      }
    }
    // Disable warning for dependency on usedPlayers - depend on playerDataId instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerDataId, handleInputChange, dialogPlayerDataId, dialog.showModal])

  return (
    <SitesAndPlayers
      sites = {sites}
      handleSiteExpanderClick = {handleSiteExpanderClick}
      handleSiteChange = {handleSiteChange}
      players = {players}
      handlePlayerChange = {handlePlayerChange}
    />
  )
}

export default PlayerIdInput
