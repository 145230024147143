import React from 'react'

import { StyledLink } from '../styling/TextStyling'

import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function QueryLink(props) {
  const margin = 2

  const { queryPath } = useQueryEndPoint()
  const handleClick = () => {
    let endPoint = ''
    
    switch(props.view) {
    case 'booking_proof':
    case 'booking_problem':
      endPoint = 'content/bookings'
      break
    case 'player_status':
      endPoint = 'admin/players'
      break
    case 'reporting':
      endPoint = 'reporting/reports'
      break
    default:
      endPoint = 'content/bookings/booking'
    }

    console.log(endPoint, props.id)
    props.close()
    queryPath(endPoint, 'id', props.id)
  }
  
  return (
    <div
      style={{marginLeft: margin, marginRight: margin}}
      onClick={handleClick}
    >
      <StyledLink>{props.text}</StyledLink>
    </div>
  )
}

export default QueryLink
