import React from 'react'
import { useLocation } from 'react-router-dom'

import NavColumn from './NavColumn'

import { FullscreenNavbar } from '../styling/Fullscreen'

import { useAuth } from '../auth/Authentication'

function Navbar() {
  const location = useLocation();
  const { canViewAdmin, canViewContent, canViewReporting } = useAuth();

  if(location.pathname === '/')
    return null

  const path = location.pathname
  const categories = [
    { path: 'admin', canView: canViewAdmin },
    { path: 'content', canView: canViewContent },
    { path: 'reporting', canView: canViewReporting }
  ]

  const columns = categories.map((category, index) => {
    if(!category.canView || path.startsWith('/' + category.path))
      return null
    else
      return (<NavColumn key={index} path={category.path} />)
  })

  return (
    <FullscreenNavbar isLandingPage={false}>
      {columns}
    </FullscreenNavbar>
  )
}

export default Navbar
