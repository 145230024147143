import React from 'react'
import { useModal } from "react-modal-hook"

import ActionButton from './ActionButton'
import CancelSave from './CancelSave'
import ReactModalWrapper from './ReactModalWrapper'

import { StyledText } from '../styling/TextStyling'

function ConfirmButton(props) {
  const handleClick = () => {
    if(props.handleClick !== undefined) {
      if(props.confirmMessage !== undefined)
        showModal()
      else
        props.handleClick()
    }
  }

  const handleConfirm = () => {
    hideModal()
    props.handleClick()
  }

  const [showModal, hideModal] = useModal(() => (
    <ReactModalWrapper
      layout={'dialog'}
      overlayColor={'rgba(0, 0, 0, 0.85)'}
      onRequestClose={hideModal}
    >
      <div style = {{
        background: '#FFF',
        padding: 1
      }}>
        <div style = {{
          textAlign: 'center',
          padding: 20
        }}>
          <StyledText dark large>{props.confirmMessage}</StyledText>
        </div>

        <CancelSave
          saveLabel = {props.text}
          handleCancel = {hideModal}
          handleSave = {handleConfirm}
        />
      </div>
    </ReactModalWrapper>
  ));

  return (
    <ActionButton
      icon = {props.icon}
      text = {props.text}
      hoverColor = {props.hoverColor}
      handleClick = {handleClick}
      style = {props.style}
    />
  )
}

export default ConfirmButton
