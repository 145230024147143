import { instanceOneOf } from '../Theme'
import { disabledHours, weekdayMask } from './BookingUnitHelper'

export function activeDays(start, end, weekdays) {
  if(start === undefined || start === null || end === undefined || end === null)
    return 0

  const current = new Date(start.getTime())
  let count = 0

  while(current < end) {
    const mask = weekdayMask(current.getDay())

    if(mask & weekdays)
      count++
    current.setDate(current.getDate() + 1)
  }

  return count
}


export function applyHourTargeting(price, hours) {
  if(instanceOneOf('railscreen')) {
    const setHours = countSetBits(hours) - 1 // Need to subtract leading bit of mask
    const maxHours = 24 - countSetBits(disabledHours())
    if(setHours === 0 || setHours === maxHours)
      return price
    else
      //return 1.3 * price
      return Math.min(maxHours / setHours, 1.3) * price
  } else {
    return price
  }
}


export function pricePerSecond(player, sites, clusters) {
  //console.log(player.name, playerPrice(player, sites), clusterPrice(player, clusters))
  // The players's price is the minimum of
  // - its own price, which may be derived from the site the player belongs to
  // - the minimum price of any cluster that is completely selected and to which
  //   the player is part of
  const pPrice = playerPrice(player, sites)
  const cPrice = clusterPrice(player, clusters)
  return Math.min(pPrice, cPrice)
}


function playerPrice(player, sites) {
  // Use player's price, if it was set, otherwise use the site's price, which
  // is always valid, because the price for a site must be set and a player
  // must always be assigned to a site.
  if(player.price_per_second !== undefined && 0 < player.price_per_second)
    return player.price_per_second
  else
    return sites.find(site => site.id === player.site_id).price_per_second
}


function clusterPrice(player, clusters) {
  return clusters.reduce((min, cluster) => {
    // Continue with current minimum, if
    // - cluster is not completely selected
    // - cluster's price is not set
    // - cluster's price is higher than current minimum
    // - cluster does not contain the player in question
    if(!cluster.checked
    || cluster.price_per_second === null
    || min <= cluster.price_per_second
    || !cluster.player_ids.includes(player.id))
      return min
    else
      return cluster.price_per_second
  }, Number.MAX_VALUE)
}


export function weekdaysFromDates(dates) {
  let weekdays = 0b10000000
  dates.forEach(date => {
    weekdays |= weekdayMask(date.getDay())
  })
  return weekdays
}


function countSetBits(val) {
  let count = 0
  while(val) {
    count += val & 1
    val >>= 1
  }
  return count
}
