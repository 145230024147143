import React from 'react'
import { useTranslation } from 'react-i18next'

import BookingOverview from '../BookingOverview'
import InputContainer from '../../shared/InputContainer'
import ClickArea from '../../shared/ClickArea'

import { instanceOneOf } from '../../../Theme'


function BookingUnit(props) {
  const { t } = useTranslation();

  const handleEdit = () => {
    props.handleClick(props.data.id)
    props.handleEdit(props.data.id)
  }

  const handleDuplicate = () => {
    props.handleClick(props.data.id)
    props.handleDuplicate(props.data.id)
  }

  const handleDelete = () => {
    props.handleClick(props.data.id)
    props.handleDelete(props.data.id)
  }

  return (
    <ClickArea
      style={{opacity: props.selected ? 1.0 : 0.3, marginBottom: 15, padding: 0}}
      selected={props.selected}
      selectedColor={'transparent'}
      normalColor={'transparent'}
      hoverColor={'rgba(255, 255, 255, 0.2)'}
      translationPrefix={props.translationPrefix}
      buttonMargin={'-4px -2px 0px 0px'}
      handleClick={() => props.handleClick(props.data.id)}
      userCanEdit={props.userCanEdit && props.selected}
      handleEdit={handleEdit}
      userCanDuplicate={props.userCanEdit && props.selected && !instanceOneOf('marty')}
      handleDuplicate={handleDuplicate}
      userCanDelete={props.userCanEdit && props.selected}
      handleDelete={handleDelete}
    >
      <InputContainer transparent marginBottom={'20px'}
        header={t(props.translationPrefix + '.bookingUnit') + ' ' + (props.data.index + 1)}
      >
        <BookingOverview bold booking={props.data} />
      </InputContainer>
    </ClickArea>
  )
}

export default BookingUnit
