import React from "react";
import styled from "styled-components";
import {
  StyledDataHeader,
  StyledDataHeaderContainer,
} from "../styling/DataComponentStyling";

const ArrowContainer = styled.div``;

const Arrow = styled.div`
  width: 0;
  height: 0;
  margin-left: 4px;
  margin-top: 2px;
  border-width: 5px;
  border-style: solid;
  transform: ${(props) => (props.up ? "rotate(90deg)" : "rotate(-90deg)")};
  border-color: transparent transparent transparent
    ${(props) => {
      if (props.isSorting)
        return (!props.asc && props.up) || (props.asc && !props.up)
          ? "#000"
          : "transparent";
      else return "rgba(0, 0, 0, 0.3)";
    }};
`;

function DataSortableHeader(props) {
  const isSorting = props.sortProperty === props.activeSortProperty;
  const asc = props.activeSortOrder === "asc";
  const fixedWidth = props.style && props.style.width
  const centered = props.style && props.style.justifyContent &&
        props.style.justifyContent === 'center'

  const handleClick = () => {
    if (props.sortProperty)
      props.handleSort(props.sortProperty, props.sortStringFunction);
  };

  return (
    <StyledDataHeader
      style={props.style}
      fixedWidth={fixedWidth}
      searchHidden={props.searchHidden}
      onClick={handleClick}
    >
      <StyledDataHeaderContainer centered={centered}>
        {props.children}
        {props.sortProperty && (
          <ArrowContainer>
            <Arrow down isSorting={isSorting} asc={asc} />
            <Arrow up isSorting={isSorting} asc={asc} />
          </ArrowContainer>
        )}
      </StyledDataHeaderContainer>
    </StyledDataHeader>
  );
}

export default DataSortableHeader;
