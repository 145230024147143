import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Tutorial from './Tutorial'
import { useTutorial } from './TutorialProvider'
import { contentNewTutorials } from './ContentNewTutorials'
import HeaderWithCancelButton from '../shared/HeaderWithCancelButton'
import Select from '../shared/Select'
import { useDialog } from '../shared/Dialog'

import { StyledText } from '../styling/TextStyling'

function Tutorials(props) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dialog = useDialog()
  const { tutorialId } = useTutorial()

  const tutorials = useMemo(() => [
    ...contentNewTutorials(t),
  // Disable warning for dependency on tutorialId - required 
  // because the tutorials depend on refs, which do not trigger
  // a re-render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [t, tutorialId])

  const [activeTutorialIdx, setActiveTutorialIdx] = useState(0)
  const activeTutorial = useMemo(() => {
    const tutorial = tutorials.find((t, i) => {
      const active = location.pathname.startsWith(t.path)
      if(active)
        setActiveTutorialIdx(i)
      return active
    })

    return tutorial !== undefined && (
      <Tutorial
        setHighlight={props.setHighlight}
        title={tutorial.title}
        sections={tutorial.sections}
      />
    )
  }, [tutorials, location, props.setHighlight])

  const handleTutorialSelection = (e) => {
    const path = tutorials[e.target.value].path
    if(location.pathname !== path) {
      dialog.showModal(t('tutorial.change_url'), () => {
        navigate(path)
      })
    }
  }

  return (
    <Container>
      <HeaderWithCancelButton
        title={t('tutorial.title')}
        handleCancel={props.handleClose}
      />
      <Select
        searchable
        light
        bold
        value={activeTutorialIdx}
        onChange={handleTutorialSelection}
        options={tutorials.map((t, i) => ({ value: i, text: t.title }))}
      />
      <TutorialContainer>
        {activeTutorial
         ? activeTutorial
         : <StyledNote>{t('tutorial.not_available')}</StyledNote>
        }
      </TutorialContainer>
    </Container>
  )
}

export default Tutorials

const Container = styled.div`
  width: 100%;
  overflow-y: hidden;
`

const TutorialContainer = styled.div`
  height: 100%;
  overflow-y: scroll;  
`

const StyledNote = styled(StyledText)`
  margin: 20px;
`
