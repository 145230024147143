import { useEffect, useState } from 'react'

import { useMetaDataFields, MetaDataFlag } from './MetaDataFields'
import { instanceOneOf } from '../../../Theme'
import { useDataFields } from '../../shared/DataFields'
import { useDataInput } from '../../shared/DataInput'
import { useDataInterface } from '../../shared/DataInterface'

function useMetaDataEdit(dataInterface) {
  const translationPrefix = 'content.booking'
  const { data, handleSaveEdit } = dataInterface
  const { fields, customProcessBeforeSaveEdit } =
        useMetaDataFields(translationPrefix,
                          MetaDataFlag.IsSearchBooking |
                          MetaDataFlag.ShowPricing |
                          MetaDataFlag.ExcludeModeField |
                          (instanceOneOf('marty') ? MetaDataFlag.ShowSpotOrigin : 0))

  const isQuotation = data.length && data[0].state === 'quoted'

  const intermediateHandleEdit = () => {
    handleEdit(data[0])
  }

  // DataInput
  const {
    handleEdit
  } = useDataInput({
    translationPrefix: translationPrefix,
    headerTranslationPostfix: isQuotation ? 'Quotation' : 'Booking',
    fields: fields,
    handleSaveEdit: handleSaveEdit,
    customProcessBeforeSaveEdit: customProcessBeforeSaveEdit,
  })

  return {
    handleEdit: intermediateHandleEdit
  }
}

function useMetaDataInterface() {
  const [bookingId, setBookingId] = useState(-1)
  const [endPoint, setEndPoint] = useState('')
  const [refreshId, setRefreshId] = useState(0)

  const bookingDataInterface = useDataInterface({
    endPoint: endPoint,
    handleSaveEditNotification: () => setRefreshId(id => id + 1),
  })

  const { handleEdit } = useMetaDataEdit(bookingDataInterface)

  const editBooking = (data) => {
    setBookingId(data.id)
    setEndPoint('content/booking?id=' + data.id)
  }

  useEffect(() => {
    if(bookingId !== -1 && bookingDataInterface.data.length &&
       bookingDataInterface.data[0].id === bookingId) {
      setBookingId(-1)
      handleEdit()
    }
    // Disable warning for dependency on data - depend on dataId instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId, bookingDataInterface.dataId, handleEdit])

  return {
    editBooking,
    editBookingRefreshId: refreshId,
  }
}


function useMetaDataInput(metaDataFlags, debug, bookingTemplate) {
  const state = metaDataFlags & MetaDataFlag.IsSearchBooking
        ? bookingTemplate === 'quotation' ? 'quoted_created' : 'ordered'
        : 'ordered'
  const defaultMetaData = !debug
        ? { state }
        : { state, name: 'Test', customer_id: 739, sector_id: 1 }

  const metaDataFields = useMetaDataFields('content.booking', metaDataFlags, bookingTemplate)

  const { fields, canSave, inputs, updateInput } = useDataFields({
    defaultData: defaultMetaData,
    fieldData: metaDataFields.fields,
    highlightErrors: false,
    continuouslyUpdateCanSave: !debug,
  })

  // Upon creation of a new customer, select it automatically
  useEffect(() => {
    const id = metaDataFields.newCustomerId
    if (id !== -1 && inputs['customer_id'] !== id)
      updateInput('customer_id', id)
  }, [inputs, updateInput, metaDataFields.newCustomerId])

  // Upon creation of a new sector, select it automatically
  useEffect(() => {
    const id = metaDataFields.newSectorId
    if (id !== -1 && inputs['sector_id'] !== id) updateInput('sector_id', id)
  }, [inputs, updateInput, metaDataFields.newSectorId])

  return {
    metaDataFields,
    inputFields: fields,
    canSave,
    inputs,
    updateInput,
  }
}


export { useMetaDataEdit, useMetaDataInterface, useMetaDataInput }
