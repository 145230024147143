import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import Section from './Section'

function Tutorial(props) {
  const location = useLocation()

  const sections = useMemo(() => {
    return props.sections.filter(section => location.pathname.match(section.path))
      .map((section, i) => (          
        <Section
          key={i}
          setHighlight={props.setHighlight}
          section={section}
        />
      ))
  }, [props.sections, location, props.setHighlight])

  return (
    <Container>
      <SectionContainer>
        {sections}
      </SectionContainer>
    </Container>
  )
}

export default Tutorial

const Container = styled.div`
  width: 100%;
  overflow-y: scroll;
`

const SectionContainer = styled.div`
  height: 100%;
  overflow-y: scroll;  
`
