import React, { useCallback, useEffect, useRef, useState } from 'react'

const TutorialContext = React.createContext({})
TutorialContext.displayName = 'TutorialContext'

function TutorialProvider(props) {
  const refs = useRef(new Map())
  const [tutorialId, setTutorialId] = useState(0)

  const register = useCallback((tutorialId, ref) => {
    setTutorialId(prev => prev + 1)
    refs.current.set(tutorialId, ref)
  }, [refs])

  const unregister = useCallback(tutorialId => {
    setTutorialId(prev => prev + 1)
    refs.current.delete(tutorialId)
  }, [refs])

  const get = useCallback(tutorialId => {
    if(tutorialId !== undefined && refs.current.has(tutorialId)) {
      const elem = refs.current.get(tutorialId).current
      return elem.getBoundingClientRect()
    } else {
      return null
    }
  }, [refs])

  const valid = useCallback(tutorialId => {
    if(tutorialId === undefined) {
      return true
    } else if(refs.current.has(tutorialId)) {
      const elem = refs.current.get(tutorialId).current
      return !elem.hidden
    } else {
      return false
    }
  }, [refs])

  return (
    <TutorialContext.Provider
      value={{
        tutorialId,
        registerTutorialRef: register,
        unregisterTutorialRef: unregister,
        getTutorialElemRect: get,
        tutorialElemValid: valid,
      }}
    >
      {props.children}
    </TutorialContext.Provider>
  )
}

export default TutorialProvider


export function useTutorial() {
  const context = React.useContext(TutorialContext)
  if(context === undefined)
    throw new Error(`useTutorial must be used within a TutorialProvider`)

  return context
}


export function useTutorialRef(tutorialId) {
  const context = React.useContext(TutorialContext)
  if(context === undefined)
    throw new Error(`useTutorial must be used within a TutorialProvider`)

  const { registerTutorialRef, unregisterTutorialRef } = context

  const ref = useRef(null)

  useEffect(() => {
    if(tutorialId && ref.current)
      registerTutorialRef(tutorialId, ref)

    return () => {
      if(tutorialId)
        unregisterTutorialRef(tutorialId)
    }
  }, [ref, registerTutorialRef, unregisterTutorialRef, tutorialId])

  return ref
}
