import React, { useState } from 'react'
import styled from 'styled-components'

import Icon from './Icon'

import { StyledText } from '../styling/TextStyling'

import { useQueryEndPoint } from '../../utils/QueryEndPoint'


const Button = styled.div`
  display: flex;
  background: #000;
  color: #FFF;
  padding: 5px 10px;
  border-radius: 3px;
  user-select: none;
  cursor: pointer;
`

function LinkButton(props) {
  const { queryPath } = useQueryEndPoint()

  const [hover, setHover] = useState(false)

  const handleClick = () => {
    queryPath(props.target, props.param, props.value)
  }

  const icon = (() => {
    switch(props.type) {
    case 'customer':
      return 'perm_contact_calendar'
    case 'team':
      return 'group'
    case 'user':
      return 'person'
    case 'usergroup':
      return 'lock'
    default:
      return 'business'
    }
  })()

  return (
    <Button
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon
        icon={icon}
        color={hover ? 'themeHighlightColor' : 'white'}
        size={16}
      />
      <div style = {{width: 6}} />
      <StyledText>{props.text}</StyledText>
    </Button>
  )
}

export default LinkButton
