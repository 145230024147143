import React from 'react'
import { useTranslation } from 'react-i18next'

import InputContainer from '../../shared/InputContainer'
import Select from '../../shared/Select'
import { useDataInterface } from '../../shared/DataInterface'

function SubscriptionCustomerInput(props) {
  const { t } = useTranslation()
  const { inputs, setInputs } = props

  const { data } = useDataInterface({ endPoint: 'admin/customers?subscription=1&filtered=1' })

  const handleChange = (event) => {
    const value = event.target.value
    const values = value ? value.split(',') : []
    const customerId = values.length !== 2 ? undefined : parseInt(values[0])
    const bookingId = values.length !== 2 ? undefined : parseInt(values[1])

    setInputs((inputs) => ({
      ...inputs,
      subscription_customer_id: customerId,
      subscription_booking_id: bookingId,
    }))
  }

  const current = 0 < inputs['subscription_customer_id'] && 0 < inputs['subscription_booking_id']
        ? inputs['subscription_customer_id'] + ',' + inputs['subscription_booking_id'] : null

  return 0 < data.length && (
    <InputContainer
      dark={props.dark}
      marginTop={'20px'}
      marginBottom={'20px'}
      header={t('content.booking.subscription_customer')}
      tutorialId={'SubscriptionCustomerInput'}
    >
      <Select
        searchable
        value={current}
        onChange={handleChange}
        name='subscription_customer_id'
        options={[{ value: null, text: t('content.booking.no_subscription_customer') }].concat(
          data.map((c, i) => ({ value: c.id + ',' + c.booking_id, text: c.name })))}
      />
    </InputContainer>
  )
}

export default SubscriptionCustomerInput
