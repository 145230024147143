import React from 'react'
//import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { cBlocksOfPlayers } from '../../../Theme'

import InputContainer from '../../shared/InputContainer'
import Select from '../../shared/Select'
//import InputContainer, { InputContainerHeader } from '../../shared/InputContainer'

import { StyledInput } from '../../styling/InputStyling'
//import { StyledText } from '../../styling/TextStyling'

function SpotLengthInput(props) {
  const { t } = useTranslation()
  const { inputs, handleInputChange, options } = props

  //const Header = () => {
  //  const spotLengths = []//[10, 20, 30]
  //  const buttons = spotLengths.map((spotLength, index) => {
  //    const handleClick = (event) => {
  //      event.preventDefault()
  //      handleInputChange({
  //        persist: () => {},
  //        target: {
  //          name: 'spot_length_secs',
  //          value: spotLength,
  //          type: 'number',
  //          checked: 0,
  //        }
  //      })
  //    }
  //
  //    return (
  //      <Button
  //        key={index}
  //        onClick={handleClick}
  //      >
  //        <StyledText>{spotLength}</StyledText>
  //      </Button>
  //    )
  //  })
  //
  //  return (
  //    <HeaderContainer>
  //      <StyledInputContainerHeader>
  //        {t('content.booking.spotLength')}
  //      </StyledInputContainerHeader>
  //      <ButtonContainer>
  //        {buttons}
  //      </ButtonContainer>
  //    </HeaderContainer>
  //  )
  //}

  return (
    <InputContainer
      dark={props.dark}
      marginTop={'20px'}
      marginBottom={'20px'}
      //header={<Header />}
      header={t('content.booking.' + (!cBlocksOfPlayers ? 'spotLength' : 'playoutLength'))}
      tutorialId={'SpotLengthInput'}
    >
      {!cBlocksOfPlayers ? (
        <StyledInput
          type="number"
          value={inputs['spot_length_secs']}
          name={'spot_length_secs'}
          placeholder={t('content.booking.lengthInSecs')}
          onChange={handleInputChange}
        />
      ) : (
        <Select
          value={inputs['spot_length_secs']}
          onChange={handleInputChange}
          name='spot_length_secs'
          options={options.map(val => ({ value: val, text: val }))}
        />
      )}
    </InputContainer>
  )
}

export default SpotLengthInput

//const StyledInputContainerHeader = styled(InputContainerHeader)`
//  flex: 1 0 auto;
//  background: rgba(255, 255, 255, 0.15);
//`
//
//const HeaderContainer = styled.div`
//  display: flex;
//`
//
//const ButtonContainer = styled.div`
//  display: flex;
//  background: ${props => props.theme.colors.themeBackground};
//  color: ${props => props.theme.colors.themeColor};
//`
//
//const Button = styled.div`
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  height: 24px;
//  padding: 0px 8px;
//  cursor: pointer;
//`


