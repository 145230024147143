import React from 'react'
import { useTranslation } from 'react-i18next'

import ListButton from '../shared/ListButton'
import Table from '../shared/Table'
import { useDataInterface } from '../shared/DataInterface'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Resolutions() {
  const { t } = useTranslation()
  const { queryPath } = useQueryEndPoint()

  const endPoint = 'admin/resolutions'
  const translationPrefix = 'admin.resolutions'
  
  // Data interface for players
  const playerDataInterface = useDataInterface({ endPoint: 'admin/players' })

  return (
    <Table
      endPoint = {endPoint}
      translationPrefix = {translationPrefix}
    
      columns = {[
        {
          header: t(translationPrefix + '.resolution'),
          defaultProperty: 'name'
        },
        {
          header: t(translationPrefix + '.players'),
          defaultProperty: 'players',
          style: {justifyContent: 'center'},
          sortStringFunction: (data) => {
            return playerDataInterface.data.filter(player => player.resolution_id === data.id).length
          },
          visualizationFunction: (data) => {
            const playerNames = playerDataInterface.data
                  .filter(player => player.resolution_id === data.id)
                  .map(player => player.name)
            return (
              <ListButton
                data = {playerNames}
                handleClick = {() => queryPath('admin/players', 'resolution_id', data.id)}
              />
            )
          }
        }
      ]}
    
      fields = {[
        {
          name: t(translationPrefix + '.width'),
          property: 'width',
          type: 'int',
          validator: 'int>0'
        },
        {
          name: t(translationPrefix + '.height'),
          property: 'height',
          type: 'int',
          validator: 'int>0'
        }
      ]}
    />
  )
}

export default Resolutions
