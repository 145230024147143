import React from 'react'
import styled from 'styled-components'

const Button = styled.div`
  background: ${props => props.header ? (props.hover ? props.theme.colors.red : props.theme.colors.black) : props.theme.colors.white};
  width: 23px;
  height: 18px;
  border-radius: 3px;
  cursor: 'pointer';
  userSelect: 'none';

  &:hover {
    background: ${props => props.theme.colors.red};
  }
`

const Cross = styled.div`
  position: relative;
  right: 0px;
  top: 3px;
  width: 32px;
  height: 32px;
  opacity: 1.0;

  &:hover {
    ${({ header }) => header === undefined && `
      opacity: 0.7;
    `}
  }

  &:before, &:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 12px;
    width: 3px;
    background: ${props => props.header ? props.theme.colors.themeHeaderBackground : props.theme.colors.black};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

function HideButton(props) {
  const handleClick = (event) => {
    event.preventDefault()
    if(props.handleClick)
      props.handleClick()
  }

  return (
    <Button
      header={props.header}
      hover={props.hover}
      style={props.style}
      onClick={handleClick}
    >
      <Cross header={props.header} />
    </Button>
  )
}

export default HideButton
