import React from 'react'
import { useTranslation } from 'react-i18next'

import Table from '../shared/Table'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Sectors() {
  const { t } = useTranslation();
  const { endPointFromQueryPath } = useQueryEndPoint()

  const endPoint = endPointFromQueryPath('admin/sectors')
  const translationPrefix = 'admin.sectors'
  
  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
    
      columns={[
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name'
        }
      ]}
    
      fields={sectorFields(t, translationPrefix)}
    />
  )
}

export default Sectors


export function sectorFields(t, translationPrefix) {
  return [
    {
      name: t(translationPrefix + '.name'),
      property: 'name',
      validator: 'name'
    }
  ]
}
