import React from 'react'

import Icon from '../shared/Icon'
import Tooltip from '../shared/Tooltip'

import { PermissionsTableRow, PermissionsTableCell } from '../styling/PermissionsStyling'
import { StyledCheckbox } from '../styling/InputStyling'
import { StyledText } from '../styling/TextStyling'

function Permission(props) {
  const { isHeader, title, permissions } = props

  const bits = permissions.map((permission, i) => {
    return (
      <PermissionsTableCell
        key = {i}
        style = {{
          cursor: isHeader ? 'pointer' : 'normal'
        }}
        onClick = {() => isHeader && props.handleHeaderClick(i)}
      >
        { isHeader ? (
          <Tooltip standardButton
            standardButtonOffsetY={'-5px'}
            standardButtonOffsetX={'3px'}
            content={props.changeAllText}
          >
            <Icon color={'black'}
              icon={i === 0 ? 'visibility' : (i === 1 ? 'edit' : (i === 2 ? 'add' : 'remove'))}
              size={16}
            />
          </Tooltip>
        ) : (
          <StyledCheckbox
            type='checkbox'
            checked={permission}
            onChange={() => props.handleCellChange(i)}
          />
        )}
      </PermissionsTableCell>
    )
  })
  
  return (
    <PermissionsTableRow style = {{
      fontWeight: isHeader ? 'bold' : 'normal'
    }}>
      <PermissionsTableCell left>
        <StyledText dark >{title}</StyledText>
      </PermissionsTableCell>
      {bits}
    </PermissionsTableRow>
  )
}

export default Permission
