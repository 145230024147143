import React from 'react'
import styled from 'styled-components'

import { StyledText } from '../styling/TextStyling'

function Bit(props) {
  const handleOnMouseEnter = () => {
    if(!props.disabled)
      props.handleOnMouseEnter()
  }
  
  const color = props.disabled || (props.readOnly && !props.active) ? 'rgba(0, 0, 0, 0.35)' : '#FFF'
  
  return (
    <Background
      background={props.background}
      disabled={props.disabled}
      readOnly={props.readOnly}
      active={props.active}
      style = {{
        gridRow: props.row,
        gridColumn: props.column,
      }}
      onMouseDown = {props.handleOnMouseDown}
      onMouseUp = {props.handleOnMouseUp}
      onMouseEnter = {handleOnMouseEnter}
    >
      <StyledText style={{color: color}}>{props.text}</StyledText>
    </Background>
  )
}

export default Bit

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 12px;
  user-select: none;
  cursor: ${props => props.disabled || props.readOnly ? 'auto' : 'pointer'};
  pointer-events: ${props => props.disabled || props.readOnly ? 'none' : 'all'};

  background: ${props => {
    if(props.disabled || !props.active) return props.theme.colors.lightgrey;
    else return props.theme.colors.green;
  }};

  color: ${props => props.disabled || (props.readOnly && !props.active) ? 'rgba(0, 0, 0, 0.35)' : '#FFF'};

  &:hover {
    filter: ${props => props.disabled ? 'brightness(0%)' : props.active ? 'brightness(110%)' : 'brightness(80%)'};
  }
`
