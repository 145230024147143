import React from 'react'
import styled from 'styled-components'

function Highlight(props) {
  return (
    <StyledHighlight {...props} />
  )
}

export default Highlight

const StyledHighlight = styled.div`
  position: absolute;
  top: calc(${props => props.t + 'px'});
  left: calc(${props => props.l + 'px'});
  width: calc(${props => props.w + 'px'});
  height: calc(${props => props.h + 'px'});
  border-radius: 4px;  
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5) inset, 0px 0px 0px 2000px rgba(0,0,0,0.5);
`
