import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ColumnHeader from './ColumnHeader'
import Spot from './Spot'
import SpotPlaceholder from './SpotPlaceholder'
import InputContainer from '../../shared/InputContainer'

import { useDataInterface } from '../../shared/DataInterface'
import { useSpot } from '../../shared/SpotProvider'

import { getSpotDisplaySize, getDefaultDisplaySize } from '../../../utils/SpotHelper'

function Spots(props) {
  const { t } = useTranslation()

  const { previous, bookingId, userCanEdit, handleNew, onSpotDeleted } = props

  const { data, refreshData, handleDelete } = useDataInterface({
    endPoint: bookingId ? 'content/spots?booking_id=' + bookingId + (previous ? '&previous=1' : ''): '',
    handleDeleteNotification: () => onSpotDeleted && onSpotDeleted(),
  })
  const translationPrefix = 'content.booking.spots'

  // Refresh spots when an upload finished
  const { pending, lastBookingId, uploading, next, progress } = useSpot()
  if(lastBookingId === bookingId && uploading)
    refreshData()

  const universal = []
  const landscape = []
  const portrait = []
  data.forEach(spot => {
    const [width, height] = getSpotDisplaySize(spot)
    const component = (
      <Spot
        key={spot.id}
        translationPrefix={translationPrefix}
        width={width}
        height={height}
        spot={spot}
        dragAndDropOrigin={'library'}
        userCanDrag={userCanEdit}
        userCanEdit={userCanEdit}
        userCanDelete={previous === undefined}
        handleDelete={handleDelete}
        refreshSpots={refreshData}
      />
    )
    if (spot.width === 0 || spot.height === 0) universal.push(component)
    else if (height < width) landscape.push(component)
    else portrait.push(component)
  })

  if(!previous) {
    pending.forEach(({ id, bookingId, bookingName, metadata }) => {
      if(bookingId === props.bookingId) {
        const [width, height] = getDefaultDisplaySize(metadata)
        const component = (
          <SpotPlaceholder
            key={id}
            width={width}
            height={height}
            progress={next && next.id === id ? progress : 0}
          />
        )
        if(width < height)
          portrait.push(component)
        else
          landscape.push(component)
      }
    })
  }

  return (!previous || 0 < data.length) && (
    <>
      <ColumnHeader
        text={t('content.booking.spots.' + (previous ? 'previous' : 'title'))}
        translationPrefix={'content.booking.spots'}
        hasNewItemButton={!previous && userCanEdit}
        handleNew={handleNew}
      />
      <SpotsWrapper>
        {universal.length !== 0 && (
          <InputContainer
            header={t('shared.universal')}
            background={'rgba(0, 0, 0, 0.15)'}
            marginBottom={'20px'}
          >
            <StyledSpotContainer>{universal}</StyledSpotContainer>
          </InputContainer>
        )}
        {landscape.length !== 0 && (
          <InputContainer
            header={t('shared.landscape')}
            background={'rgba(0, 0, 0, 0.15)'}
            marginBottom={'20px'}
          >
            <StyledSpotContainer>{landscape}</StyledSpotContainer>
          </InputContainer>
        )}
        {portrait.length !== 0 && (
          <InputContainer
            header={t('shared.portrait')}
            background={'rgba(0, 0, 0, 0.15)'}
            marginBottom={'20px'}
          >
            <StyledSpotContainer>{portrait}</StyledSpotContainer>
          </InputContainer>
        )}
        <BottomSpacer />
      </SpotsWrapper>
    </>
  )
}

const SpotsWrapper = styled.div`
  flex: 1 0 0;
`

const BottomSpacer = styled.div`
  height: ${props => props.centerColumn ? '40px' : '20px'};
`

const StyledSpotContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default Spots
