import React from 'react'
import styled from 'styled-components'

import SpotInfo from './SpotInfo'
import Tooltip from '../../shared/Tooltip'

import { StyledText } from '../../styling/TextStyling'

import { spotUrl } from "../../../utils/UrlHelper"

function SpotView(props) {
  const { spot } = props

  const isThumbnail = props.thumbnail !== undefined
  const isImage = spot.spottype_id === 1 || (isThumbnail && spot.spottype_id === 2)
  const isVideo = !isThumbnail && spot.spottype_id === 2

  const style = {
    width: props.thumbnail ? '100%' : props.margins ? 'calc(100vw - ' + props.margins + 'px)' : '100vw',
    height: props.thumbnail ? '100%' : props.margins ? 'calc(100vh - ' + props.margins + 'px)' : '100vh',
    borderRadius: props.thumbnail ? 0 : 1,
    objectFit: 'contain',
    outline: 'none'
  }

  const src = spotUrl(spot.spot, isThumbnail)

  return (
    <Container style={props.style} colored={props.coloredBackground}>
      <Tooltip
        newButton
        height={58}
        offsetY={0}
        arrowTopMargin={-30}
        content={<SpotInfo spot={spot} />}
        dragged={props.dragged}
      >
        {(!isImage && !isVideo) &&
          <Placeholder>
            <StyledText large bold upper>
              {spot.spot.split('.')[1]}
            </StyledText>
          </Placeholder>
        }
        {isVideo &&
          <video controls autoPlay muted
            style={style}
            src={src}
            type={'video/mp4'}
          />
        }
        {isImage &&
          <img
            style={style}
            src={src}
            alt={spot.spot}
          />
        }
      </Tooltip>
    </Container>
  )
}

export default SpotView


const Container = styled.div`
  background: ${props => props.colored ? props.theme.colors.themeSpotBackground : 'transparent'};
`

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.darkgrey};
  word-wrap: break-work;
`
