import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Usage:
// - add new end point with query params to endPointMap as needed
//   example: a frontend URL for 'admin/players' of the form /0/0/0/74
//            is converted to a backend URL of the form ?bookingId=74
// - queryPath() - pushes a new frontend URL to the history (used in LinkButtons, etc.)
// - endPointFromQueryPath() - converts from frontend URL to backend URL

export const useQueryEndPoint = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const endPointMap = useMemo(() => new Map([
    ['content/bookings',                   { params: ['id'] }],
    ['content/bookings/booking',           { params: ['id'], altEndPoint: 'content/booking' }],
    ['content/booking',                    { params: ['id'] }],
    ['content/fillins',                    { params: ['id'], altEndPoint: 'content/bookings' }],
    ['content/fillins/fillin',             { params: ['id'], altEndPoint: 'content/booking' }],
    ['content/quotations',                 { params: ['id'], altEndPoint: 'content/bookings' }],
    ['content/quotations/quotation',       { params: ['id'], altEndPoint: 'content/booking' }],
    ['content/reservations',               { params: ['id'], altEndPoint: 'content/bookings' }],
    ['content/reservations/reservation',   { params: ['id'], altEndPoint: 'content/booking' }],
    ['content/subscriptions',              { params: ['id'], altEndPoint: 'content/bookings' }],
    ['content/subscriptions/subscription', { params: ['id'], altEndPoint: 'content/booking' }],
    ['content/vacancies',                  { params: ['id'], altEndPoint: 'content/bookings' }],
    ['content/vacancies/vacancy',          { params: ['id'], altEndPoint: 'content/booking' }],
    ['admin/customers',                    { params: ['id'] }],
    ['admin/groups',                       { params: ['id'] }],
    ['admin/players',                      { params: ['site_id', 'cluster_id', 'resolution_id', 'booking_id', 'id'] }],
    ['admin/sectors',                      { params: ['id'] }],
    ['admin/sites',                        { params: ['cluster_id', 'booking_id'] }],
    ['admin/users',                        { params: ['id', 'team_id'] }],
    ['admin/teams',                        { params: ['id'] }],
    ['reporting/reports',                  { params: ['id'] }],
  ]), [])

  const endPoints = useMemo(() => Array.from(endPointMap.keys()), [endPointMap])

  const queryPath = useCallback((target, param, value, forcePathChange) => {
    if(!endPointMap.has(target))
      throw new Error('queryPath: invalid target')

    const { params } = endPointMap.get(target)
    let path = '/' + target
    const i = params.indexOf(param)
    if(i !== -1) {
      const fill = '0/'
      path += '/' + fill.repeat(params.indexOf(param))
      path += value
    }

    // In case the current location path equals the new path while we forcibly
    // require a change of the end point, we append the character 'f' to the
    // path, which will be detected by endPointFromQueryPath upon which an
    // additional dummy query parameter will be added to the end point to force
    // its change.
    if(forcePathChange && location.pathname === path)
      path += '/f'

    navigate(path)
  }, [endPointMap, location.pathname, navigate])
  // queryPath


  const endPointFromQueryPath = useCallback((target) => {
    const parts = target.split(/\?(.+)/)
    if(parts.length < 1 || 3 < parts.length)
      throw new Error('endPointFromQueryPath: invalid target:', target)

    const path = parts[0]
    if(!endPointMap.has(path))
      throw new Error('endPointFromQueryPath: invalid target:', path)
    const { params, altEndPoint } = endPointMap.get(path)

    let endPoint = altEndPoint ? altEndPoint : path
    location.pathname
      .split('/')
      .slice(path.split('/').length + 1)
      .forEach((element, index) => {
        // Detection of 'forcePathChange', see queryPath below.
        if(element === 'f')
          endPoint += '&fu=1'
        else if(element !== "0")
          endPoint += "?" + params[index] + "=" + element
      })

    if(1 < parts.length && parts[1].length)
      return endPoint + (endPoint.includes('?') ? '&' : '?') + parts[1]
    else
      return endPoint
  }, [endPointMap, location.pathname])
  // endPointFromQueryPath


  const getQueryParam = useCallback((endPoint, param) => {
    let val = -1
    location.pathname
      .split('/')
      .slice(endPoint.split('/').length + 1)
      .forEach((element, index) => {
        if(element !== "0")
          val = parseInt(decodeURIComponent(element))
      })
    return val
  }, [location.pathname])
  // getQueryParam


  const isQueryParamSet = useCallback(() => {
    const path = location.pathname
    return endPoints.some(endPoint => {
      const endPointPath = '/' + endPoint
      return path.startsWith(endPointPath) &&
        endPointPath.length < path.length &&
        path[endPointPath.length] === '/'
    })
  }, [endPoints, location.pathname])
  // isQueryParamSet


  const clearQueryParams = useCallback(() => {
    const path = location.pathname
    const endPoint = endPoints.find(endPoint => path.startsWith('/' + endPoint))
    navigate('/' + endPoint)
  }, [location.pathname, endPoints, navigate])
  // clearQueryParams


  const filterEndPointById = useCallback((id) => {
    if(id) {
      const path = location.pathname.substring(1)
      if(endPoints.find(endPoint => endPoint === path))
        queryPath(path, 'id', id)
    }
  }, [location.pathname, endPoints, queryPath])
  // filterEndPointById


  return {
    queryPath,
    endPointFromQueryPath,
    getQueryParam,
    isQueryParamSet,
    clearQueryParams,
    filterEndPointById,
  }
} // useQueryEndPoint
