import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useNavigate } from 'react-router-dom'

import Login from "./Login";
import Password from "./Password";

import request from "../shared/request";
import ReactModalWrapper from "../shared/ReactModalWrapper";

import { useLoadingIndicator } from "./LoadingIndicator";
import { navPermissions, navPermissionFlag } from "../nav/Items";

import { landingPageUrl } from "../../utils/UrlHelper";
import { fromDbTimestampWithTime } from '../../utils/TimestampHelper'

const localStorageKey = "__zii_token__";
const getPersistentToken = () => window.localStorage.getItem(localStorageKey)
const setPersistentToken = (token) => window.localStorage.setItem(localStorageKey, token)
const deletePersistentToken = () => window.localStorage.setItem(localStorageKey, '')

const AuthContext = React.createContext({
  token: "",
});
AuthContext.displayName = "AuthContext";

const UserPrefs = {
  NavExpanded: 1,
  DashboardSettingsVisbile: 2,
  TutorialVisible: 4,
}
const testUserPref = (prefs, pref) => 0 < (prefs & pref)

function Authentication(props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    showLoadingIndicatorDelayed,
    hideLoadingIndicatorDelayed,
  } = useLoadingIndicator();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [clientId, setClientId] = useState(-1);
  const [clientData, setClientData] = useState({});
  const [userPrefs, setUserPrefs] = useState(1);
  const [token, setToken] = useState(getPersistentToken())

  const [showModal, hideModal] = useModal(() => {
    if (window.location.pathname !== "/password") {
      return (
        <ReactModalWrapper layout="auth">
          <Login
            handleLogin={handleLogin}
          />
        </ReactModalWrapper>
      );
    } else {
      return (
        <ReactModalWrapper layout="auth">
          <Password
            showModal={showModal}
            hideModal={hideModal}
          />
        </ReactModalWrapper>
      );
    }
  }, [window.location.pathname]);

  const deleteToken = useCallback(() => {
    deletePersistentToken()
    setIsAuthenticated(false)
    setToken("")
  }, [])

  const handleLogin = useCallback(
    async (credentials) => {
      try {
        const userData = await request("/login", {
          data: credentials,
          method: "POST",
        });

        hideModal();
        setToken(userData.token);
        setClientId(userData.id);
        setClientData(userData);
        setUserPrefs(userData.preferences)
        setIsAuthenticated(true);
        setPersistentToken(userData.token)
        navigate(0)
        console.log(t("authentication.logged_in"));
      } catch(error) {
        let errorString = "";
        switch(error.status) {
        case 400:
          errorString = "Bad request";
          break;
        case 401:
        case 423:
          deleteToken()
          if(error.status === 401) {
            errorString = "Unauthorized"
          } else {
            const s = parseInt(error.response.response.data.locked_secs)
            var ts = new Date()
            ts.setSeconds(ts.getSeconds() + s)
            const ms = 1000 * 60 // Round up to the nearest minute
            ts = new Date(Math.ceil(ts.getTime() / ms) * ms)
            alert(t('authentication.locked', {"datetime": fromDbTimestampWithTime(ts)}))
            errorString = "Locked"
          }
          break;
        default:
          errorString = "Unknown error";
        }
        console.log(error + " " + errorString);
      }
    },
    [hideModal, navigate, t, deleteToken]
  );

  const authCall = useCallback(
    async (path, dataOnError = [], options = {}) => {
      showLoadingIndicatorDelayed()

      // This is a bit of a hack to determine in which cases the user data needs
      // to be refreshed after user permissions have been updated, i.e. on a
      // PUT request, which has an ID in the body and targets the endPoints
      // 'admin/users' or 'admin/groups'.
      // In the former case, an update is required if the ID matches the
      // clientId, i.e. the user updates his own permissions. In the latter
      // case, an update is required client belongs to the updated user group.
      // Both tests are omitted, because they would introduce a dependency on
      // clientId/clientData, which in turn triggers the login dialog twice.
      const refreshPermissionsOnSuccess =
        options.method === "PUT" &&
        ((options.data && options.data.hasOwnProperty("id") &&
          (path.startsWith("admin/users")  /*&& options.body.id === clientId*/ ||
           path.startsWith("admin/groups") /*&& options.body.id === clientData['client_group_id']*/
          )
         )
         || (path.startsWith("simUser"))
        )

      // TODO - is this still required?
      // Convert the body to JSON string
      if (options.hasOwnProperty("body")) {
        options.data = JSON.stringify(options.data);
        //console.log(options.data)
      }

      try {
        const data = await request(path, options);

        // Refresh permissions as determined above
        if (refreshPermissionsOnSuccess)
          authCall("/userdata", {}).then((userData) => setClientData(userData));

        hideLoadingIndicatorDelayed()
        return data;
      } catch (error) {
        hideLoadingIndicatorDelayed()
        showModal();
        return dataOnError;
      }
    },
    [showModal, showLoadingIndicatorDelayed, hideLoadingIndicatorDelayed]
  );

  const logout = useCallback((noRedirect) => {
    // Erase user data
    deleteToken()
    setClientId(-1);
    setClientData({});

    // Redirect to home page to clear all previous user input
    if(noRedirect === undefined)
      window.open(landingPageUrl(), "_self")
  }, [deleteToken]);

  // action can be one of {view, edit, add, delete}
  const userHasPermissions = useCallback(
    (endPoint, action) => {
      if (clientData["admin"]) return true;
      if (endPoint === 'reporting/accounting' &&
          (clientData["username"] === "Test user" ||
           clientData["username"] === "Markus Kowalewski"))
        return true;

      // Remove query parameters in split endPoint into path components
      const parts = endPoint.split("?")[0].split("/");
      const property = 'permission_' + action

      if (parts.length === 1) {
        const permissions = navPermissions()
        const val = permissions.some(permission => {
          const flag = navPermissionFlag(permission.name)
          return permission.name.startsWith(endPoint + ".") &&
            clientData[property] & flag
        })
        //console.log("userHasPermissions multiple:", endPoint, action, clientData[property], val)
        return val;
      } else {
        const key = parts[0] + "." + parts[1];
        const flag = navPermissionFlag(key)
        const val = 0 < (clientData[property] & flag)
        //console.log("userHasPermissions single:", endPoint, action, clientData[property], flag, val)
        return val;
      }
    },
    [clientData]
  );

  const [canViewAdmin, setCanViewAdmin] = useState(false);
  const [canViewContent, setCanViewContent] = useState(false);
  const [canViewReporting, setCanViewReporting] = useState(false);
  useEffect(() => {
    setCanViewAdmin(userHasPermissions("admin", "view"));
    setCanViewContent(userHasPermissions("content", "view"));
    setCanViewReporting(userHasPermissions("reporting", "view"));
  }, [userHasPermissions]);

  const toggleUserPref = useCallback((pref) => {
    const prefs = userPrefs ^ pref
    setUserPrefs(prefs)
    authCall("/setPreferences?client_id=" + clientId + "&prefs=" + prefs)
  }, [userPrefs, authCall, clientId]);

  // Upon mount, check if the token was loaded. If not (undefined or ""),
  // present the login dialog, otherwise reload the user data (such as client
  // ID and permissions). In case the user logs out, the token is deleted and
  // page is reloaded, hence the authCall is only made, when the page is
  // reloaded and a token is retrieved from local storage.
  useEffect(() => {
    if(!token) {
      setIsAuthenticated(false);
      showModal();
    } else {
      authCall("/userdata", {}).then((userdata) => {
        setClientId(userdata.hasOwnProperty("id") ? userdata.id : -1);
        setClientData(userdata);
        setUserPrefs(userdata.preferences)
        setIsAuthenticated(userdata.hasOwnProperty("id"));
      });
    }
  }, [token, showModal, authCall]);

  return (
    <AuthContext.Provider
      value={{
        authCall,
        showLoadingIndicator,
        hideLoadingIndicator,
        logout,
        userHasPermissions,
        canViewAdmin,
        canViewContent,
        canViewReporting,
        isAuthenticated,
        clientId,
        username: clientData['username'],
        userPrefs,
        toggleUserPref,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function getAuthToken() {
  return getPersistentToken()
}

export default Authentication;
export { useAuth, getAuthToken, UserPrefs, testUserPref };

export function LogoutOnSetPassword() {
  const { isAuthenticated, logout } = useAuth()
  useEffect(() => {
    if(isAuthenticated)
      logout(/*noRedirect*/true)
  }, [isAuthenticated, logout])
  return null
}
