import React, { useEffect, useState } from 'react'

import SpotRotation from "./SpotRotation"
import Timeline from "./Timeline"
import { useSpot } from '../../shared/SpotProvider'

function SpotComposition(props) {
  const { bookingUnitId, bookingUnit, dataInterface, resolutions, userCanEdit } = props
  const { dataId, data, refreshData, handleDelete, handleSaveEdit } = dataInterface
  const translationPrefix = 'content.booking.spotComposition'

  // Refresh spots when an upload finished to pick up any spots that where
  // assigned on creation of a new booking
  const { pending, uploading } = useSpot()
  if(!pending.length && uploading)
    refreshData()

  const [selectedSpotChangeId, setSelectedSpotChangeId] = useState(-1)
  const [selectedSpotChangeActivationTimestamp, setSelectedSpotChangeActivationTimestamp] = useState(null)
  const [spotsPerResolution, setSpotsPerResolution] = useState([])

  const canAddSpotChange = bookingUnit['individual_dates'] === null

  const handleSelectSpotChange = (id, activationTimestamp) => {
    setSelectedSpotChangeId(id)
    setSelectedSpotChangeActivationTimestamp(activationTimestamp)
  }

  //console.log("***", selectedSpotChangeId, JSON.stringify(data), JSON.stringify(spotsPerResolution), JSON.stringify(resolutions))
  useEffect(() => {
    setSelectedSpotChangeId(-1)
    setSelectedSpotChangeActivationTimestamp(null)
  }, [bookingUnitId])

  // Map booking_unit_spots matching the selected spot change to resolutions
  useEffect(() => {
    if(selectedSpotChangeId !== -1) {
      const spots = resolutions.map(resolution => {
        return data.filter(spot => {
          return spot.spotchange_id === selectedSpotChangeId &&
            spot.resolution_id === resolution.id
        })
      })
      setSpotsPerResolution(spots)
    }
    // Disable warning for dependency on data - depend on dataId instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataId, resolutions, selectedSpotChangeId])

  const spotRotations = resolutions.map((resolution, index) => {
    return (
      <SpotRotation
        key = {resolution.id}
        translationPrefix = {translationPrefix}
        marginBottom = {index === resolutions.length - 1 ? '0px' : '20px'}
        bookingUnitId = {bookingUnitId}
        bookingUnit = {bookingUnit}
        resolution = {resolution}
        spotsPerResolution = {spotsPerResolution}
        spotIndex = {index}
        spotChangeId = {selectedSpotChangeId}
        spotChangeActivationTimestamp = {selectedSpotChangeActivationTimestamp}
        multipleResolutionsAvailable = {1 < resolutions.length}
        userCanEdit = {userCanEdit}
        handleSaveEdit = {handleSaveEdit}
        handleDelete = {handleDelete}
      />
    )
  })

  return (
    <div>
      <Timeline
        headerText={props.headerText}
        bookingUnitId={bookingUnitId}
        selectedSpotChangeId={selectedSpotChangeId}
        startTimestamp={props.startTimestamp}
        endTimestamp={props.endTimestamp}
        userCanEdit={userCanEdit && canAddSpotChange}
        handleSelectSpotChange={handleSelectSpotChange}
      />
      {spotRotations}
    </div>
  )
}

export default SpotComposition
