import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import DataRow from '../shared/DataRow'
import Select from '../shared/Select'
import TimestampPicker from '../shared/TimestampPicker'

import {
  StyledData,
  StyledDataHeader,
  StyledHeaders,
} from '../styling/DataComponentStyling'
import { StyledText } from '../styling/TextStyling'

import { durations, getDuration, rotations, getRotation, reasons, getReason, formatDate, parseDate } from '../../utils/BookingSpecialHelper'
import { fromDbTimestamp, timeFromDbTimestamp } from '../../utils/TimestampHelper'

function BookingSpecialTable(props) {
  const { t } = useTranslation();
  const {
    translationPrefix,
    bookingSpecialDataInterface,
    playerIdToValue,
    editData,
    canEdit,
    handleEdit,
    canDelete,
    handleDelete,
  } = props
  const { data } = bookingSpecialDataInterface

  const rowHeight = 72
  const fontSize = 14

  const isExpired = (bookingUnit) => bookingUnit.end_timestamp.getTime() < new Date().getTime()

  const columns = [
    {
      header: t('shared.player'),
      visualizationFunction: (data) => {
        // Disallow selection of player when editing a special booking. The list
        // of players is empty in this case, likely the visualizationFunction
        // is not updated after the playerDataInterface retrieves data. One
        // solution could be to pass the list to every entry in
        // bookingSpecialDataInterface.data.
        if(data['isEditing'] === undefined || !data['isAddingNew']) {
          const name = playerIdToValue(data['player_id'], 'name')
          return <StyledText dark>{name}</StyledText>
        } else {
          return (
            <Select
              value={data['player_id']}
              onChange={(event) => data['handleInputChange'](event, data)}
              name={'player_id'}
              style={{padding: 0, fontSize: fontSize}}
              options={data['players'].map(p => ({ value: p.id, text: p.name }))}
            />
          )
        }
      },
    },
    {
      header: t('shared.date'),
      visualizationFunction: (data) => {
        if(data['isEditing'] === undefined) {
          const date = fromDbTimestamp(data['start_timestamp'])
          return <StyledText dark>{date}</StyledText>
        } else {
          const handleDayChange = (dates) => {
            const event = {
              persist: () => {},
              target: {
                name: 'start_timestamp',
                value: dates.length === 1 ? dates[0] : null,
              }
            }
            console.log(dates.length === 1 ? dates[0] : null)
            data['handleInputChange'](event, data)
          }

          return (
            <TimestampPicker
              single
              dates={[data['start_timestamp']]}
              handleMultiChange={handleDayChange}
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder={'dd.mm.yyyy'}
            />
          )
        }
      },
    },
    {
      header: t('shared.time'),
      visualizationFunction: (data) => {
        if(data['isEditing'] === undefined) {
          const time = timeFromDbTimestamp(data['start_timestamp'])
          return <StyledText dark>{time}</StyledText>
        } else {
          return (
            <Select
              value={data['time']}
              onChange={(event) => data['handleInputChange'](event, data)}
              name={'time'}
              style={{padding: 0, fontSize: fontSize}}
              options={Array.from(Array(24).keys()).map(h => (
                { value: h, text: (h < 10 ? '0' + h : h) + ':00' }
              ))}
            />
          )
        }
      },
    },
    {
      header: t(translationPrefix + '.duration'),
      visualizationFunction: (data) => {
        if(data['isEditing'] === undefined) {
          return <StyledText dark>{getDuration(t, data).text}</StyledText>
        } else {
          return (
            <Select
              value={data['duration']}
              onChange={(event) => data['handleInputChange'](event, data)}
              name={'duration'}
              style={{padding: 0, fontSize: fontSize}}
              options={durations(t).map(d => ({ value: d.min, text: d.text }))}
            />
          )
        }
      },
    },
    {
      header: t(translationPrefix + '.rotation'),
      visualizationFunction: (data) => {
        if(data['isEditing'] === undefined) {
          return <StyledText dark>{getRotation(t, data).text}</StyledText>
        } else {
          return (
            <Select
              value={data['rotation']}
              onChange={(event) => data['handleInputChange'](event, data)}
              name={'rotation'}
              style={{padding: 0, fontSize: fontSize}}
              options={rotations(t).map(r => ({ value: r.sec, text: r.text }))}
            />
          )
        }
      },
    },
    {
      header: t(translationPrefix + '.reason'),
      visualizationFunction: (data) => {
        if(data['isEditing'] === undefined) {
          return <StyledText dark>{getReason(t, data).text}</StyledText>
        } else {
          return (
            <Select
              value={data['info']}
              onChange={(event) => data['handleInputChange'](event, data)}
              name={'info'}
              style={{padding: 0, fontSize: fontSize}}
              options={reasons(t).map(r => ({ value: r.id, text: r.text }))}
            />
          )
        }
      },
    },
  ]

  const headers = columns.map((column, index) => {
    return (
      <StyledDataHeader key={index}>
        <StyledText bold>{column.header}</StyledText>
      </StyledDataHeader>
    )
  })

  // Compile actions once from custom actions and standard actions (edit and delete)
  const actions = useMemo(() => {
    const acts = []
    if(canEdit) {
      acts.push({
        type: 'edit',
        permissionsOk: true,
        handleClick: (id) => {
          const rowData = data.find((r) => r.id === id)
          handleEdit(rowData)
        },
      })
    }

    if(canDelete) {
      acts.push({
        type: 'delete',
        permissionsOk: true,
        confirmTranslationPostfix: '.confirmDelete',
        handleClick: handleDelete,
      })
    }

    return acts
  }, [canEdit, handleEdit, data, canDelete, handleDelete])

  const expired = [{
    icon: 'info',
    text: t(translationPrefix + '.expired'),
    permissionsOk: true,
    handleClick: () => {},
  }]

  const rows = data.map(bookingUnit => {
    const isEditing = editData.player_id === bookingUnit.player_id

    return (
      <DataRow noHover={props.isEditing ? true : undefined}
        key={bookingUnit.player_id}
        data={isEditing ? editData : bookingUnit}
        columns={columns}
        translationPrefix={translationPrefix}
        actions={!isExpired(bookingUnit) ? actions : expired}
        style={{height: rowHeight}}
      />
    )
  })

  return (
    <StyledData>
      <StyledHeaders>
        <StyledDataHeader>
          <StyledText bold>{'Idx'}</StyledText>
        </StyledDataHeader>
        {headers}
        <StyledDataHeader>
          <StyledText stretch bold centered>
            {t('shared.action')}
          </StyledText>
        </StyledDataHeader>
      </StyledHeaders>
      {rows}
      {editData.isAddingNew &&
        <DataRow noHover
          data={editData}
          columns={columns}
          translationPrefix={translationPrefix}
          actions={[]}
          style={{height: rowHeight}}
        />
      }
    </StyledData>
  )
}

export default BookingSpecialTable
