import React from 'react'
import { useTranslation } from 'react-i18next'

import Table from '../shared/Table'
import { useAuth } from '../auth/Authentication'
import Spot from '../content/booking/Spot'
import { getResolutionDisplaySize } from '../../utils/SpotHelper'

function Approval() {
  const { t } = useTranslation();
  const { userHasPermissions } = useAuth();

  const endPoint = 'content/spots?approval=1'
  const translationPrefix = 'content.approval'

  const [width, height] = getResolutionDisplaySize()

  const customActions = [
    {
      type: 'approve',
      permissionsOk: userHasPermissions('content/approval', 'edit'),
      confirmTranslationPostfix: '.confirmApprove',
      handleClick: (id, data, handleSaveEdit) => {
        const rowData = {...data, status: 1}
        handleSaveEdit('PUT', rowData)
      },
    },
  ]

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      standardActions={[]}
      customActions={customActions}
      rowHeight={height}
    
      columns = {[
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name'
        },
        {
          header: t(translationPrefix + '.seconds') + ' (s)',
          defaultProperty: 'spot_length_secs'
        },
        {
          header: t(translationPrefix + '.spot'),
          defaultProperty: 'spot',
          style: {justifyContent: 'center'},
          visualizationFunction: (data) => {
            console.log(data)
            const spot = {
              spot: data['spot']
            }
            return (
              <Spot
                width={width}
                height={height}
                spot={spot}
              />
            )
          }
        }
      ]}

      fields = {[
        {
          name: t(translationPrefix + '.approved'),
          property: 'approved',
          type: 'int',
          validator: 'int'
        },
      ]}
    />
  )
}

export default Approval
