import React from 'react'

import { cZoneLabels, cZonesAllMask } from '../../../Theme'
import { StyledLabel } from '../../styling/InputStyling'

// The value of inputs.zones can implies the following three states:
// - null: Indicates that all zones are active
// - 0:    Indicates that the user has deselected all zones. A value of 0 is
//         considered invalid by BookingUnitEdit, i.e. the user must select at
//         least one zone to proceed with the booking process.
// - >= 1: Indicates that the user selected at least one zone. When the user
//         selects all zones, inputs.zones will be set to null by handleChange()
//
// As a result, inputs.zones is either null (meaning all zones are selected) or
// the following holds: 1 <= inputs.zones << cZonesAllMask. This corresponds
// directly to the value written into the database.
function Zones(props) {
  const { inputs, setInputs } = props

  const labels = ['all', ...cZoneLabels]

  const handleChange = (mask) => {
    // Enforce the above mentioned three states for inputs.zones
    var zones = 0
    if(inputs.zones === null) {
      zones = mask === 0 ? 0 : cZonesAllMask ^ mask
    } else if(mask === 0) {
      zones = inputs.zones !== cZonesAllMask ? null : 0
    } else {
      zones = inputs.zones ^ mask
      if(zones === cZonesAllMask)
        zones = null
    }

    setInputs((inputs) => ({
      ...inputs,
      zones: zones
    }))
  }

  const zones = Array(labels.length).fill({}).map((x, index) => {
    const mask = index === 0 ? 0 : 1 << (index - 1)
    var label = labels[index]
    label = label.charAt(0).toUpperCase() + label.slice(1)

    return (
      <div key={index}>
        <input
          type='checkbox'
          checked={inputs.zones === null || inputs.zones & mask}
          ref={i => { if(i && mask === 0) i.indeterminate = inputs.zones !== null && inputs.zones !== 0}}
          onChange={() => handleChange(mask)}
        />
        <StyledLabel>{label}</StyledLabel>
      </div>
    )
  })

  return (
    <div style = {{
      padding: 10
    }}>
      {zones}
    </div>
  )
}

export default Zones
