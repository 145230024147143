import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import Select from '../shared/Select'

import { useAuth } from '../auth/Authentication'
import { useDataInterface } from '../shared/DataInterface'

function SimUser() {
  const { isAuthenticated, authCall } = useAuth()
  const navigate = useNavigate()

  const [currentId, setCurrentId] = useState(-1)
  const [currentEmail, setCurrentEmail] = useState('')
  const { dataId, data } = useDataInterface({ endPoint: isAuthenticated ? 'admin/users' : ''})

  useEffect(() => {
    if(isAuthenticated) {
      authCall && authCall('simUser').then(
        (data) => {
          if(data.hasOwnProperty('id'))
            setCurrentId(data.id)
        }
      )
    }
  }, [isAuthenticated, authCall, setCurrentId])
  useEffect(() => {
    if(currentId !== -1 && data.length)
      setCurrentEmail(data.find(user => user['id'] === currentId)['email'])
    // Disable warning for dependency data - depend on dataId instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentId, dataId])

  const handleChange = (value) => {
    setCurrentEmail(value)
    authCall('simUser?email=' + value, [], { method: 'PUT' }).then(
      navigate(0)
    )
  }

  if(currentId !== -1)
    return (
      <StyledSelect
        light
        noArrow
        dropDownWidth={200}
        value={currentEmail}
        onChange={(e) => handleChange(e.target.value)}
        options={data.map(user => (
          { value: user['email'], text: user['name'] }
        ))}
      />
    )
  else
    return (
      <></>
    )
}

export default SimUser


const StyledSelect = styled(Select)`
  width: ${(props) => props.theme.header.height};
  height: ${(props) => props.theme.header.height};

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.colors.themeHighlightColor};
  }
`
