import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { customerFields } from '../admin/Customers'
import { useBookingActions } from '../content/BookingActions'
import LinkButton from '../shared/LinkButton'
import NewItemButton from '../shared/NewItemButton'
import InputContainer, { InputContainerHeaderWithNewButton } from '../shared/InputContainer'
import Select from '../shared/Select'
import Table from '../shared/Table'
import { StyledText } from '../styling/TextStyling'
import { StyledNewButton } from '../styling/DataComponentStyling'

import { useAuth } from '../auth/Authentication'
import { useDataInterface } from '../shared/DataInterface'

import { currencyUnit, localePricePerSecond } from '../../utils/Locale'
import { dateRange, fromDbTimestamp, parseDateRange } from '../../utils/TimestampHelper'

function Invoices() {
  const { t } = useTranslation();
  const { authCall, userHasPermissions } = useAuth()

  const periods = dateRange()
  const [periodIdx, setPeriodIdx] = useState(0)
  const period = periods[periodIdx]
  const endPoint = 'reporting/invoices?month=' + period[0] + '&year=' + period[1]
  const translationPrefix = 'reporting.invoices'

  const exportInvoices = () => {
    authCall(endPoint + '&export=1')
      .then((data) => {
        const file = new Blob([data], { type: 'text/pdf' })
        const fileURL = URL.createObjectURL(file)
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.download = 'invoices' + (period[0] === -1 ? '' : ('-' + period[0] + '-' + period[1])) + '.csv'
        fileLink.click()
        URL.revokeObjectURL(fileURL)
      })
  }

  const headerItems = (
    <>
      <StyledContainer>
        <Select
          categoryHeader
          searchable
          value={periodIdx}
          onChange={(e) => setPeriodIdx(e.target.value)}
          options={periods.map((p,i) => (
            { value: i, text: (p[0] === -1 ? t('shared.month_all') : (t('shared.month' + (p[0] - 1)) + ' ' + p[1])).toUpperCase() }
          ))}
        />
      </StyledContainer>
      {userHasPermissions(endPoint, 'view') && (
        <NewItemButton
          icon={'save_alt'}
          text={t(translationPrefix + '.export')}
          handleClick={exportInvoices}
        />
      )}
    </>
  )

  // Data interface customers
  const customerDataInterface = useDataInterface({ endPoint: 'admin/customers' })
  const idToName = (dataInterface, id) => {
    const item = dataInterface.data.find((item) => item.id === id)
    return item ? item.name : ''
  }

  const customProcessBeforeSaveEdit = (data) => {
    const { start, end } = parseDateRange(data['period'])
    data['start_timestamp'] = start
    data['end_timestamp'] = start.getTime() === end.getTime() ? null : end
    delete data.period
  }

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      categoryHeaderItem={headerItems}
      customProcessBeforeSaveEdit={customProcessBeforeSaveEdit}

      standardActions={[]}
      customActions={useBookingActions('invoiced')}

      columns={[
        {
          header: t('shared.number_abbreviated') + " / " + t('shared.date'),
          defaultProperty: 'invoice_number',
          style: {maxWidth: 120, justifyContent: 'center'},
          visualizationFunction: (data) => {
            return (
              <div>
                <StyledText dark bold centered>{data['invoice_number']}</StyledText>
                <br />
                <StyledText dark centered>{fromDbTimestamp(data['creation_timestamp'])}</StyledText>
              </div>
            )
          }
        },
        {
          header: t('shared.customer'),
          defaultProperty: 'customer_id',
          sortStringFunction: (data) => {
            return idToName(customerDataInterface, data["customer_id"])
          },
          visualizationFunction: (data) => {
            const customer = idToName(customerDataInterface, data['customer_id'])
            return customer !== "" && (
              <LinkButton
                type={"customer"}
                text={customer}
                target={"admin/customers"}
                param={"id"}
                value={data["customer_id"]}
              />
            )
          },
        },
        {
          header: t(translationPrefix + '.description'),
          defaultProperty: 'description',
        },
        {
          header: t(translationPrefix + '.net_amounts') + currencyUnit(),
          defaultProperty: 'ad_fee',
          style: { justifyContent: 'right'},
          sortStringFunction: (data) => {
            return data['ad_fee'] + data['handling_fee'] + data['production_fee']
          },
          visualizationFunction: (data) => {
            const red = data['invoice_type'].includes('reverse')
            if(data['invoice_type'] !== 'service_invoice_at') {
              const adFee = <DivR red={red}>{localePricePerSecond(data['ad_fee'])}</DivR>
              const haFee = data['handling_fee'] === 0 ? null :
                    <DivR red={red}>{localePricePerSecond(data['handling_fee'])}</DivR>
              const prFee = data['production_fee'] === 0 ? null :
                    <DivR red={red}>{localePricePerSecond(data['production_fee'])}</DivR>
              return <div>{adFee}{haFee}{prFee}</div>
            } else {
              return <DivR red={red}>{localePricePerSecond(data['ad_fee'])}</DivR>
            }
          }
        },
        {
          header: t(translationPrefix + '.tax_types') + currencyUnit(),
          defaultProperty: 'invoice_type',
          sortStringFunction: (data) => {
            return data['invoice_type'] !== 'service_invoice_at'
              ? ((data['ad_fee'] === 0 ? '' : 'a') +
                 (data['handling_fee'] === 0 ? '' : 'b') +
                 (data['production_fee'] === 0 ? '' : 'c'))
              : 'd'
          },
          visualizationFunction: (data) => {
            const vat = '20% ' + t('shared.vat')
            if(data['invoice_type'] !== 'service_invoice_at') {
              const adFee = <div>{t('shared.ad_tax_short') + ', ' + vat}</div>
              const haFee = data['handling_fee'] === 0 ? null :
                    <div>{vat}</div>
              const prFee = data['production_fee'] === 0 ? null :
                    <div>{vat}</div>
              return <div>{adFee}{haFee}{prFee}</div>
            } else {
              return <div>{vat}</div>
            }
          }
        },
        {
          header: t(translationPrefix + '.state'),
          defaultProperty: 'state',
          style: { justifyContent: 'center'},
          sortStringFunction: (data) => {
            switch(data['state']) {
            case 'reminded': return 'b'
            case 'invoiced': return 'a'
            case 'paid': return 'c'
            case 'reversed': return 'd'
            default: return 'e'
            }
          },
          visualizationFunction: (data) => {
            return data['state'] === null ? 'n.a.' : t('shared.' + data['state'])
          }
        },
      ]}

      fields={[
        {
          name: t(translationPrefix + '.customer'),
          property: 'customer_id',
          type: 'int',
          defaultValue: -1,
          validator: 'selected',
          customInput: (key, inputs, handleInputChange, fields) => {
            const Header = (
              <InputContainerHeaderWithNewButton
                translationPrefix={'admin.customers'}
                dataInterface={customerDataInterface}
                fields={customerFields(t, 'admin.customers')}
              >
                {t(translationPrefix + '.customer')}
              </InputContainerHeaderWithNewButton>
            )
            return (
              <InputContainer
                dark
                key={key}
                marginBottom={'20px'}
                header={Header}
              >
                <Select
                  searchable
                  value={inputs['customer_id']}
                  onChange={handleInputChange}
                  name='customer_id'
                  options={customerDataInterface.data.map((customer, index) => (
                    { value: customer.id, text: customer.name }
                  ))}
                />
              </InputContainer>
            )
          }
        },
        {
          name: t(translationPrefix + '.period'),
          property: 'period',
          validator: 'date_range',
        },
        {
          name: t(translationPrefix + '.description'),
          property: 'description',
          validator: 'text',
        },
        {
          name: t(translationPrefix + '.net_amount') + currencyUnit(),
          property: 'ad_fee',
          type: 'float',
          validator: 'float>0',
        },
        {
          name: t(translationPrefix + '.tax_types'),
          property: 'invoice_type',
          defaultValue: 'service_invoice_at',
          input: (inputs, handleInputChange) => (
            <Select
              value={inputs['invoice_type']}
              onChange={handleInputChange}
              name='invoice_type'
              options={[
                { value: 'service_invoice_at', text: '20% MwSt.' },
                { value: 'ad_invoice_at', text: '5% Werbeabgabe + 20% MwSt.' },
              ]}
            />
          ),
        },
      ]}
    />
  )
}

export default Invoices


const StyledContainer = styled(StyledNewButton)`
  width: 170px;
  max-width: 170px;
  padding: 0px 20px 0px 0px;
`

const DivR = styled.div`
  text-align: right;
  color: ${(props) => props.red ? props.theme.colors.red : props.theme.colors.black};
`
