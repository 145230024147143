import React from 'react'

import { StyledCheckbox, StyledLabel } from '../../styling/InputStyling'

import { cImplicitPlayers } from '../../../Theme'

function SitesAndPlayers(props) {
  // https://react.rocks/example/react-treeview
  const sites = props.sites.map((site, siteIndex) => {
    const players = cImplicitPlayers ? [] : props.players.map((player, playerIndex) => {
      // ATTEMPTED HACK TO FIX CHANGING id
      if(player.site_id !== site._id)
        return null

      return (
        <div key = {playerIndex}>
          <input
            type = 'checkbox'
            checked = {player.checked}
            onChange = {() => props.handlePlayerChange(playerIndex)}
          />
          <StyledLabel>{player.name}</StyledLabel>
        </div>
      )
    })

    return (
      <div key = {siteIndex}>
        {!cImplicitPlayers && <div
          style = {{
            display: 'inline-block',
            transform: site.collapsed ? '' : 'rotate(90deg)',
            marginRight: 2,
            userSelect: 'none'
          }}
          onClick = {() => props.handleSiteExpanderClick(siteIndex)}
        >
          {'\u25B6'}
        </div>}
        <StyledCheckbox
          type = 'checkbox'
          checked = {site.checked}
          ref = {i => {if(i) i.indeterminate = site.intermediate}}
          onChange = {() => props.handleSiteChange(siteIndex)}
        />
        <StyledLabel>{site.name}</StyledLabel>
        <div
          style = {{
            marginLeft: 26
          }}
        >
          {site.collapsed ? null : players}
        </div>
      </div>
    )
  })

  return (
    <div style = {{
      padding: 10,
      overflowY: 'hidden'
    }}>
      {sites}
    </div>
  )
}

export default SitesAndPlayers
