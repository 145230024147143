import React from "react";

import Icon from "./Icon";
import Tooltip from "./Tooltip";

import { StyledNewButton } from "../styling/DataComponentStyling";
import { StyledText } from "../styling/TextStyling";

function NewItemButton(props) {
  const handleClick = (event) => {
    event.preventDefault();
    props.handleClick();
  };

  const style = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
  };

  return (
    <StyledNewButton onClick={handleClick} selected={props.selected}>
      <Tooltip
        newButton
        style={style}
        content={<StyledText>{props.text}</StyledText>}
      >
        <Icon
          icon={!props.icon ? 'add' : props.icon}
          color={props.selected ? props.selectedColor : undefined}
          size={!props.icon ? 21 : 18}
        />
      </Tooltip>
    </StyledNewButton>
  );
}

export default NewItemButton;
