import React from 'react'
import { Outlet } from 'react-router-dom'

import NavColumn from './NavColumn'
import { navItems } from './Items'

import { FullscreenContent, RowContainer, RowContent } from '../styling/Fullscreen'

function Category(props) {
  return (
    <RowContent>
      <FullscreenContent>
        <RowContainer>
          <NavColumn
            left
            path={props.path}
            items={navItems(props.path)}
          />
          <FullscreenContent>
            <Outlet />
          </FullscreenContent>
        </RowContainer>
      </FullscreenContent>
    </RowContent>
  )
}

export default Category
