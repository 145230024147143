import { cDefaultSpotLength } from '../../Theme'

export function contentNewTutorials(t) {
  const tt = (key, options) => t('tutorial.' + key, options)
  const bookingTypes = ['quotation', 'booking', 'subscription', 'reservation', 'vacancy']

  return bookingTypes.map(bookingType => {
    const type = tt(bookingType)

    return {
      title: tt('content_new_title', { type }),
      path: '/content/new/' + bookingType,
      sections: [
        { title: tt('content_new_title0'),
          path: new RegExp('^/content/new/' + bookingType + '$'),
          steps: [
            {
              text: tt('content_new_start'),
              t: 116, l: 0, w: 58, h: 58,
            },
            {
              text: tt('content_new_type'),
              id: 'SearchTypeSelection',
              hintHeader: t('shared.hint'),
              hint: tt('content_new_type_hint', { type }),
              isActive: bookingType !== 'quotation',
            },
            {
              text: tt('booking_unit_clusters'),
              id: "BookingUnitEdit.clusters",
              hintHeader: t('shared.hint'),
              hint: tt('booking_unit_clusters_hint'),
            },
            {
              text: tt('booking_unit_sites'),
              id: "BookingUnitEdit.sites",
              hintHeader: t('shared.mandatory_input'),
              hint: tt('booking_unit_sites_hint'),
            },
            {
              text: tt('booking_unit_reservation'),
              id: "ReservationTeamInput",
              hintHeader: t('shared.mandatory_input'),
              hint: tt('booking_unit_reservation_hint'),
            },
            {
              text: tt('booking_unit_subscription'),
              id: "SubscriptionCustomerInput",
              hintHeader: t('shared.optional_input'),
              hint: tt('booking_unit_subscription_hint'),
            },
            {
              text: tt('booking_unit_dates'),
              id: "BookingUnitEdit.dates",
              hintHeader: t('shared.mandatory_input'),
              hint: tt('booking_unit_dates_hint'),
            },
            {
              text: tt('booking_unit_max_blocks'),
              id: "MaxBlocksInput",
              hintHeader: t('shared.optional_input'),
              hint: tt('booking_unit_max_blocks_hint'),
            },
            {
              text: tt('booking_unit_spot_length_secs'),
              id: "SpotLengthInput",
              hintHeader: t('shared.optional_input'),
              hint: tt('booking_unit_spot_length_secs_hint', { secs: cDefaultSpotLength }),
            },
            {
              text: tt('booking_unit_spot_origin'),
              id: "BookingUnitEdit.spot_origin",
              hintHeader: t('shared.hint'),
              hint: tt('booking_unit_spot_origin_hint'),
            },
            {
              text: tt('new_confirm0'),
              id: 'Search.confirm_button',
              hintHeader: t('shared.hint'),
              hint: tt('new_confirm_hint'),
            },
          ],
        },
        { title: tt('content_new_title1'),
          path: new RegExp('^/content/new/' + bookingType + '/checkout$'),
          steps: [
            {
              text: tt('metadata_name'),
              id: 'MetaDataFields.name',
              hintHeader: t('shared.mandatory_input'),
              hint: tt('metadata_name_hint'),
            },
            {
              text: tt('metadata_customer'),
              id: 'MetaDataFields.customer',
              hintHeader: t('shared.mandatory_input'),
              hint: tt('metadata_customer_hint'),
            },
            {
              text: tt('metadata_new_customer'),
              id: 'MetaDataFields.new_customer',
            },
            {
              text: tt('metadata_sector'),
              id: 'MetaDataFields.sector',
              hintHeader: t('shared.optional_input'),
              hint: tt('metadata_sector_hint'),
            },
            {
              text: tt('metadata_new_sector'),
              id: 'MetaDataFields.new_sector',
            },
            {
              text: tt('booking_unit_spot_origin'),
              id: "MetaDataFields.spot_origin",
              hintHeader: t('shared.hint'),
              hint: tt('booking_unit_spot_origin_hint'),
            },
            {
              text: tt('metadata_discount'),
              id: 'MetaDataFields.discount',
            },
            {
              text: tt('metadata_discounted_price'),
              id: 'MetaDataFields.discounted_price',
            },
            {
              text: tt('metadata_spot_upload'),
              id: 'MetaDataEdit.spot_upload',
            },
            {
              text: tt('new_confirm1', { type }),
              id: 'Search.confirm_button',
            },
          ],
        },
      ],
    }
  })
}
