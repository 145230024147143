import React, { useRef } from 'react'

import StandardButton from './StandardButton'

import { StyledInput } from '../styling/InputStyling'

const TextInput = React.forwardRef((props, ref) => {
  const inputRef = useRef(null)

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        alignItems: 'center',
        background: props.isInvalid ? '#FFA5A5' : 'transparent',
        ...props.style
      }}
    >
      <StyledInput
        ref={inputRef}
        type='text'
        autoFocus
        value={props.value}
        placeholder={props.placeholder}
        onFocus={props.handleFocusIn}
        onBlur={props.handleFocusOut}
        onClick={props.handleClick}
        onChange={(e) => props.onChange(e.target.value)}
        onKeyDown={(e) => props.onEnterKey && e.key === 'Enter' && props.onEnterKey()}
      />
      <StandardButton
        style={{background: 'tranparent'}}
        normalColor='grey'
        hoverColor='darkgrey'
        icon='clear'
        handleClick={() => { props.onChange(''); inputRef.current.focus() }}
      />
    </div>
  )
})

export default TextInput
