import { useLayoutEffect, useState } from 'react'
import { debounceFn } from './debounceFunc'

function useResizeWindow() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useLayoutEffect(() => {
    const resizeHandler = debounceFn(() => {
      setSize({ width: window.innerWidth, height: window.innerHeight })
    }, 100)

    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return size
}

export default useResizeWindow
