import React from 'react'

import PriorityIndicator from './PriorityIndicator'

import { StyledText } from '../styling/TextStyling'

function CategoryHeader(props) {
  const { title, itemsPerPriority, priorities, userPriorities } = props

  const indicators = priorities.map((priority, index) => {
    return (
      <PriorityIndicator
        key={index}
        style={{marginLeft: 15}}
        priorities={priorities}
        priority={priority.id}
        count={itemsPerPriority[priority.id]}
        inactive={(priority.id & userPriorities) === 0}
      />
    )
  })

  return (
    <div
      style={{
        display: 'flex',
        background: '#FFF',
        padding: '15px 15px 15px 25px'
      }}
    >
      <StyledText dark medium upper style={{
        flexGrow: 1
      }}>
        {title}
      </StyledText>
      {indicators}
    </div>
  )
}

export default CategoryHeader
