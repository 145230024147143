import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  cBlocksOfPlayers,
  cFixedPricePerSecond,
  cLoopLength,
  cImplicitPlayers,
  cPricingUnit,
  instanceOneOf,
} from '../../Theme'
import ListButton from '../shared/ListButton'
import Table, { canDeleteRowFromTable } from '../shared/Table'

import { useAuth } from '../auth/Authentication'
import { usePreview } from '../content/Preview'
import { useDataInterface } from '../shared/DataInterface'

import { currencyUnit, localePricePerSecond } from '../../utils/Locale'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Sites() {
  const { t } = useTranslation()
  const { endPointFromQueryPath, queryPath } = useQueryEndPoint()

  const endPoint = endPointFromQueryPath('admin/sites')
  const translationPrefix = 'admin.sites'

  const { authCall, userHasPermissions } = useAuth()
  const { showPreview } = usePreview(authCall, t)

  const customActions = [
    {
      type: 'preview',
      permissionsOk: (d) => canDeleteRowFromTable(d) && userHasPermissions('content/bookings', 'view'),
      handleClick: (id, data) => showPreview(id, data),
    },
  ]

  // Data interface for players
  const playerDataInterface = useDataInterface({ endPoint: 'admin/players' })

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      customActions={customActions}
      rowHeight={120}
      columns={siteColumns(t, translationPrefix, playerDataInterface, queryPath)}
      fields={siteFields(t, translationPrefix)}
    />
  )
}

export default Sites


function siteColumns(t, translationPrefix, playerDataInterface, queryPath) {
  let columns = [
    {
      header: t(translationPrefix + '.name'),
      defaultProperty: 'name',
    },
    {
      header: t(translationPrefix + '.site'),
      defaultProperty: 'country',
      sortStringFunction: (data) => addressSortFunction(data, 'info'),
      visualizationFunction: (data) => addressDisplayFunction(data, 'info'),
    },
  ]

  if(cBlocksOfPlayers) {
    columns = columns.concat([
      {
        header: t(translationPrefix + '.num_blocks'),
        defaultProperty: 'num_blocks',
        style: {justifyContent: 'center'},
      },
      {
        header: t(translationPrefix + '.block_size'),
        defaultProperty: 'block_size',
        style: {justifyContent: 'center'},
      },
    ])
  }

  if(cLoopLength) {
    columns = columns.concat([
      {
        header: t(translationPrefix + '.loop_length_secs'),
        defaultProperty: 'loop_length_secs',
        style: {justifyContent: 'center'},
      },
    ])
  }

  columns = columns.concat([
    {
      header: t('shared.price_per_' + cPricingUnit) + currencyUnit(),
      defaultProperty: 'price_per_second',
      style: {justifyContent: 'center'},
      isHidden: () => cFixedPricePerSecond,
      visualizationFunction: (data) => {
        return <div>{localePricePerSecond(data['price_per_second'])}</div>
      }
    },
    {
      header: t(translationPrefix + '.discount') + ' (%)',
      defaultProperty: 'discount',
      style: {justifyContent: 'center'},
      isHidden: () => instanceOneOf('marty'),
    },
  ])

  if(!cImplicitPlayers) {
    columns = columns.concat([
      {
        header: t(translationPrefix + '.players'),
        defaultProperty: 'players',
        style: {justifyContent: 'center'},
        sortStringFunction: (data) => {
          return playerDataInterface.data.filter(player => player.site_id === data.id).length
        },
        visualizationFunction: (data) => {
          const playerNames = playerDataInterface.data
                .filter(player => player.site_id === data.id)
                .map(player => player.name)
          return (
            <ListButton
              data={playerNames}
              handleClick={() => queryPath('admin/players', 'site_id', data.id)}
            />
          )
        }
      },
    ])
  }

  return columns
}


function siteFields(t, translationPrefix) {
  let fields = [
    {
      name: t(translationPrefix + '.name'),
      property: 'name',
      maxLength: 128,
      validator: 'name',
    },
    {
      name: t('shared.price_per_' + cPricingUnit) + currencyUnit(),
      property: 'price_per_second',
      type: 'float',
      validator: 'float>0',
      isActive: () => !cFixedPricePerSecond,
      defaultValue: instanceOneOf('marty') ? 50.0 : undefined,
    },
  ]

  if(cBlocksOfPlayers) {
    fields = fields.concat([
      {
        name: t(translationPrefix + '.num_blocks'),
        property: 'num_blocks',
        type: 'int',
        defaultValue: 1,
        validator: 'int>0',
      },
      {
        name: t(translationPrefix + '.block_size'),
        property: 'block_size',
        type: 'int',
        defaultValue: 20,
        validator: 'int>0',
      },
    ])
  }

  if(cLoopLength) {
    fields = fields.concat([
      {
        name: t(translationPrefix + '.loop_length_secs'),
        property: 'loop_length_secs',
        type: 'int',
        validator: 'int>0',
        defaultValue: 90,
      },
    ])
  }

  fields = fields.concat([
    {
      name: t(translationPrefix + '.street'),
      property: 'address',
      maxLength: 128,
      validator: 'text',
    },
    {
      name: t(translationPrefix + '.postcode'),
      property: 'postcode',
      maxLength: 8,
      validator: 'name',
    },
    {
      name: t(translationPrefix + '.city'),
      property: 'city',
      maxLength: 128,
      validator: 'name',
    },
    {
      name: t(translationPrefix + '.province'),
      property: 'province',
      maxLength: 128,
      validator: 'name',
      optional: true,
    },
    {
      name: t(translationPrefix + '.country'),
      property: 'country',
      maxLength: 128,
      validator: 'name',
      optional: true,
    },
    {
      name: t(translationPrefix + '.info'),
      property: 'info',
      maxLength: 1024,
      validator: 'text',
      optional: true,
    },
  ])

  return fields
}


export function addressSortFunction(data, field) {
  return data['country'] + data['province'] + data['postcode'] +
    data['city'] + data['address'] + data[field]
}


export function addressDisplayFunction(data, field) {
  return (
    <div>
      {
        (data['country'] !== '' || data['province'] !== '') && (
          <div>
            {
              data['country'] + (
                data['province'] === '' ? '' : (
                  (data['country'] === '' ? '' : ' (') +
                    data['province'] +
                    (data['country'] === '' ? '' : ')')
                )
              )
            }
          </div>
        )
      }
      {
        (data['postcode'] !== '' || data['city'] !== '' || data['address'] !== '') && (
          <div>
            {
              data['postcode'] + (data['postcode'] !== '' ? ' ' : '') + data['city'] + (
                data['address'] === '' ? '' : (
                  (data['postcode'] === '' && data['city'] === '' ? '' : ' - ') + data['address']
                )
              )
            }
          </div>
        )
      }
      {
        (data[field] !== '') && (
          <div>{data[field]}</div>
        )
      }
    </div>
  )
}
