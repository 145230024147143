import {
  cNewStandardBooking,
  cNewFillinBooking,
  cNewVacancyBooking,
  instanceOneOf,
} from '../Theme'
import { processInputs } from '../components/shared/DataProcessing'

export const convertIndividualDatesFromDb = (data) => {
  // Convert array of ISO strings from DB to array of dates
  const dateStrings = data.individual_dates.split(',')
  const dates = dateStrings.map((dateString) => {
    return new Date(dateString)
  })
  data.individual_dates = dates
}


export const processBookingUnitInputs = (data, usedPlayers, trackUsedPlayers) => {
  // Enforce data types of fields
  const fields = [
    { property: 'spot_length_secs', type: 'int' },
    { property: 'weekdays', type: 'int' },
    { property: 'hours', type: 'int' },
    { property: 'repeats_per_day', type: 'int' },
    { property: 'price', type: 'float' },
    { property: 'bookingattributes', type: 'int' },
  ]

  processInputs(data, fields)
  processBookingPeriod(data)
  addAndDeletePlayers(usedPlayers, data, trackUsedPlayers)
}


const processBookingPeriod = (data) => {
  // In case of date range input, clear individual_dates. Also, when individual_dates
  // are null, avoid the else branch.
  if((data.use_range !== undefined && data.use_range) || data.individual_dates === null) {
    data.individual_dates = null
  } else {
    // Set start_timestamp and end_timestamp to min and max of individual dates
    const n = data.individual_dates.length
    const start = new Date(data.individual_dates[0])
    const end = new Date(data.individual_dates[n - 1])
    data.start_timestamp = new Date(start.setHours(0, 0, 0))
    data.end_timestamp = new Date(end.setHours(23, 59, 59))
    data.weekdays = weekdaysAllMask()

    // Convert individual dates to array of ISO strings for DB
    data.individual_dates = data.individual_dates.reduce((a,date) => {
      // Ensure that ISO date is represented in local time
      date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
      return a + (a === '' ? '' : ',') + date.toISOString().substring(0, 10)
    }, '')
  }

  delete data.date_input_mode
  delete data.use_range
}

const addAndDeletePlayers = (usedPlayers, data, trackUsedPlayers) => {
  const added = []
  const deleted = []
  const used = []

  usedPlayers.forEach((item) => {
    if(item.isUpdate && !item.checked) {
      deleted.push(item.id)
    } else if(!item.isUpdate && item.checked) {
      added.push(item.player_id)
      added.push(item.resolution_id)
    }

    if(trackUsedPlayers && item.checked)
      used.push(item.player_id)
  })

  data['deleted_players'] = deleted
  data['added_players'] = added
  if(trackUsedPlayers)
    data['used_players'] = used
}

export function bookingUnitRequiresSearchRequest(booking) {
  if(booking['bookingattributes'] === 1 && !cNewStandardBooking)
    return true
  if(booking['bookingattributes'] === 2 && !cNewFillinBooking)
    return true
  if(booking['bookingattributes'] === 4 && !cNewVacancyBooking)
    return true

  return false
}

export function weekdayDefaultMask() {
  // 0b10000000
  return 128
}

export function hourDefaultMask() {
  // 0b1000000000000000000000000
  return 16777216
}

export function weekdaysAllMask() {
  // 0b11111111
  return 255
}

export function hoursAllMask() {
  // 0b1111111111111111111111111
  return 33554431
}

export function weekdayMask(day) {
  return weekdayDefaultMask() >> (day === 0 ? 7 : day)
}

export const disabledWeekdays = () => {
  // Currently no weekdays disabled
  return 0b0000000
}

export const disabledWeekdaysFromRange = (start, end) => {
  if(start === undefined || start === null) {
    return disabledWeekdays()
  } else if(end === undefined || end === null) {
    return weekdaysAllMask() ^ weekdayMask(start.getDay())
  } else {
    var mask = weekdaysAllMask()
    const current = new Date(start.getTime())
    let count = 0

    while(count < 7 && current < end) {
      mask ^= weekdayMask(current.getDay())
      current.setDate(current.getDate() + 1)
      count++
    }

    return mask
  }
}

export const disabledHours = () => {
  if(instanceOneOf('epamedia'))
    return 0b111110000000000000000011
  else if(instanceOneOf('marty'))
    return 0b000000000000000000000000
  else
    return 0b111100000000000000000000
}


export const getBookingType = (b) => {
  if(b
  && b.hasOwnProperty('bookingtype_id')
  && b.hasOwnProperty('bookingattributes')
  && b.hasOwnProperty('state'))
    return b['bookingtype_id'] === 4
    ? 'subscription'
    : (b['bookingtype_id'] === 5
       ? 'reservation'
       : (b['bookingattributes'] === 4
          ? 'vacancy'
          : (b['bookingattributes'] === 2
             ? 'fillin'
             : (b['state'].includes('quote')
                ? 'quotation'
                : 'booking'
               )
            )
         )
      )
  else
    throw new Error('getBookingType - invalid booking or booking unit: ' + b)
}
