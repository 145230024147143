import React from 'react'
import { useTranslation } from 'react-i18next'

import ActionButton from './ActionButton'
import {
  StyledActionGrid,
  StyledDataCell,
  StyledDataRowContainer,
} from '../styling/DataComponentStyling'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function DataRow(props) {
  const { t } = useTranslation()
  const { filterEndPointById } = useQueryEndPoint()

  const opacity = props.data.state === undefined || props.data.state !== 'deleted' ? 1.0 : 0.3
  const evalProp = (prop) => typeof prop === 'function' ? prop(props.data) : prop

  const columns = props.columns.map((column, index) => {
    const fixedWidth = column.style && column.style.width

    return (
      (column.isHidden === undefined || !column.isHidden()) &&
      <StyledDataCell
        key={index}
        style={{ ...column.style, opacity }}
        fixedWidth={fixedWidth}
        actionsHidden={props.actionsHidden}
      >
        {!column.visualizationFunction
          ? props.data[column.defaultProperty]
          : column.visualizationFunction(props.data)}
      </StyledDataCell>
    );
  });

  const numActs = props.actions.reduce(
    (v, a) => (evalProp(a.permissionsOk) ? v + 1 : v),
    0
  );
  const rows = Math.ceil(numActs / 3);
  const cols = rows === 0 ? 1 : Math.ceil(numActs / rows);
  let index = 0;
  const actions = props.actions.map((action) => {
    if (!evalProp(action.permissionsOk)) return null;

    const row = cols === 0 ? 1 : Math.floor(index / cols) + 1;
    const col = cols === 0 ? 1 : (index % cols) + 1;
    index++;

    const confirmMessage =
      action.confirmTranslationPostfix === undefined
        ? undefined
        : t(props.translationPrefix + evalProp(action.confirmTranslationPostfix), {
            name: props.data.name,
          });

    return (
      <ActionButton
        key={index}
        type={evalProp(action.type)}
        icon={action.icon}
        text={action.text}
        endPoint={props.endPoint}
        confirmMessage={confirmMessage}
        handleClick={() => action.handleClick(props.data.id, props.data, props.handleSaveEdit)}
        style={{ gridRow: row, gridColumn: col }}
      />
    );
  });

  const rowIndex = props.index !== undefined ? props.index + 1 : 0

  return (
    <StyledDataRowContainer
      index={rowIndex}
      style={props.style}
      noHover={props.noHover}
      onDoubleClick={() => filterEndPointById(props.data.id)}
    >
      <StyledDataCell
        index={rowIndex}
        style={{justifyContent: "center", opacity}}
      >
        {rowIndex === 0 ? '' : rowIndex}
      </StyledDataCell>
      {columns}
      {!props.actionsHidden && (
        <StyledDataCell
          style={{justifyContent: "center"}}
        >
          <StyledActionGrid>{actions}</StyledActionGrid>
        </StyledDataCell>
      )}
    </StyledDataRowContainer>
  );
}

export default DataRow;
