import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-modal-hook'

import ColumnHeader from './ColumnHeader'
import SpotChange from './SpotChange'
import SpotChangeEdit from './SpotChangeEdit'

import { useDataInterface } from '../../shared/DataInterface'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 20px 0px;
`

const TimelineContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`

const SpotChanges = styled.div`
  display: flex;
  width: 100%;
`

const SpotChangeContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`

const SpotChangeContainerMargin = styled.div`
  margin: 0 1px 0 0;
  background: ${props => props.selected ? props.theme.colors.green : 'rgba(255, 255, 255, 0.15)'};
`

function Timeline(props) {
  const [editData, setEditData] = useState({
    index: undefined,
    disabled: {},
    selected: undefined,
    top: 0,
    left: 0,
    anchor: 0
  })

  const handleSave = (date) => {
    const saveData = editData.index === undefined ?
          {
            booking_unit_id: props.bookingUnitId,
            activation_timestamp: date
          } :
          {
            id: data[editData.index].id,
            activation_timestamp: date
          }

    handleSaveEdit(editData.index === undefined ? 'POST' : 'PUT', saveData)
  }

  const [showModal, hideModal] = useModal(() => {
    return (
      <SpotChangeEdit
        onRequestClose={hideModal}
        editData={editData}
        handleSave={handleSave}
      />
    )}, [handleSave, editData])

  // Date picker positioning
  const [element, setElement] = useState(null)
  const handleRef = (element) => setElement(element ? element : null)
  const top = (r, o) => r.top + r.height + o
  const left = (r) => r.left < window.innerWidth - 290 ? r.left : r.right - 290
  const anchor = (r) => r.left < window.innerWidth - 290 ? r.left + 8 : r.right - 20 - 8

  const handleNew = () => {
    const rect = element.getBoundingClientRect()
    setEditData({
      index: undefined,
      initial: props.startTimestamp,
      disabled: { before: props.startTimestamp, after: props.endTimestamp },
      selected: undefined,
      top: top(rect, 10),
      left: left(rect),
      anchor: anchor(rect),
    })
    showModal()
  }

  const handleEdit = (index, rect, offset) => {
    setEditData({
      index: index,
      initial: data[index].activation_timestamp,
      disabled: { before: props.startTimestamp, after: props.endTimestamp },
      selected: data[index].activation_timestamp,
      top: top(rect, offset),
      left: left(rect),
      anchor: anchor(rect),
    })
    showModal()
  }

  // Automatically select the first spot change in case the selected one was deleted
  const intermediateHandleDelete = (id) => {
    // TODO - Bug: selectedSpotChangeId always equals id at this point
    if(props.selectedSpotChangeId === id)
      props.handleSelectSpotChange(data[0].id, data[0].activation_timestamp)
    handleDelete(id)
  }
  
  const handleNewIds = (data) => {
    //console.log("NEW SPOTCHANGE " + data.id)
    props.handleSelectSpotChange(data.id, data.activation_timestamp)
  }

  const endPoint = 'content/bookingUnitSpotChanges?booking_unit_id=' + props.bookingUnitId
  const translationPrefix = 'content.booking.spotChanges'
  const { dataId, data, handleDelete, handleSaveEdit } = useDataInterface({
    endPoint: endPoint,
    translationPrefix: props.translationPrefix,
    handleNewIds: handleNewIds,
    omitRefreshDataOnNewIds: false
  })

  const minSpotChanges = 1
  const showSpotChanges = minSpotChanges < data.length
  const spotChanges = showSpotChanges && data.map((spotChange, index) => {
    const selected = props.selectedSpotChangeId === spotChange.id

    return (
      <SpotChangeContainer key={spotChange.id}>
        <SpotChangeContainerMargin
          selected={selected}
          index={index}
        >
          <SpotChange
            startTimestamp={props.startTimestamp}
            index={index}
            data={spotChange}
            selected={selected}
            allowEditAndDelete={0 < index}
            translationPrefix={translationPrefix}
            userCanEdit={props.userCanEdit}
            handleClick={props.handleSelectSpotChange}
            handleEdit={handleEdit}
            handleDelete={intermediateHandleDelete}
          />
        </SpotChangeContainerMargin>
      </SpotChangeContainer>
    )
  })

  // Automatically select first spot change on data update if none is selected
  useEffect(() => {
    if(props.selectedSpotChangeId === -1 && 0 < data.length)
      props.handleSelectSpotChange(data[0].id, data[0].activation_timestamp)
    // Disable warning for dependency on data - depend on dataId instead
    // Does not depend on props to limit execution to a change of data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataId])

  return (
    <div>
      <ColumnHeader
        handleRef={handleRef}
        text={props.headerText}
        translationPrefix={translationPrefix}
        hasNewItemButton={props.userCanEdit}
        handleNew={handleNew}
      />
      {spotChanges.length &&
        <Container>
          {showSpotChanges &&
            <TimelineContainer>
              <SpotChanges>
                {spotChanges}
              </SpotChanges>
            </TimelineContainer>
          }
        </Container>
      }
    </div>
  )
}

export default Timeline
