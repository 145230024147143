import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import CategoryHeader from './CategoryHeader'
import SubCategory from './SubCategory'

function Category(props) {
  const { t } = useTranslation();
  const { category, priorities, userPriorities, handleItemHidden, close } = props

  const title = t('dashboard.categories.' + category.name)

  const subCategories = category.sub_categories.map((subCategory, index) => {
    const hasVisibleItems = userPriorities & subCategory.priority_id

    return (!hasVisibleItems ? null :
      <SubCategory
        key={index}
        subCategory={subCategory}
        priorities={priorities}
        handleItemHidden={handleItemHidden}
        close={close}
      />
    )
  })

  return (
    <CategoryMargin>
      <CategoryHeader
        title={title}
        itemsPerPriority={category.items_per_priority}
        priorities={priorities}
        userPriorities={userPriorities}
      />
      {subCategories}
    </CategoryMargin>
  )
}

export default Category


export const CategoryMargin = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`
