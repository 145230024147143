import React, { Component } from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import "./Dropzone.css";

import Icon from "../../../shared/Icon";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();
  }

  openFileDialog = () => {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  };

  onDragOver = (event) => {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  };

  onDragLeave = (event) => {
    this.setState({ hightlight: false });
  };

  onDrop = (event) => {
    event.preventDefault();
    if (this.props.disabed) return;
    if (this.props.onFilesAdded) {
      this.props.onFilesAdded({
        target: {
          files: this.fileListToArray(event.dataTransfer.files)
        }
      });
    }
    this.setState({ hightlight: false });
  };

  fileListToArray = (list) => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  render() {
    const { t } = this.props;

    return (
      <div
        className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.props.onFilesAdded}
          //accept="image/*,video/*"
          accept=".avi,.gif,.htm,.html,.jpeg,.jpg,.png,.mov,.mp4,.mpeg,.tif,.tiff,.wmv,.zfx,.zdc"
        />
        <Icon icon={'cloud_upload'} color={'darkgrey'} />
        <StyledLabel>{t("content.booking.spotUpload.dropHere")}</StyledLabel>
      </div>
    );
  }
}

export default withTranslation()(Dropzone);

const StyledLabel = styled.span`
  font-size: 0.75em;
`;
