import React, { useState } from 'react'
import styled from 'styled-components'

import Icon from '../shared/Icon'
import Tooltip from '../shared/Tooltip'
import HideButton from '../shared/HideButton'

import { StyledText } from '../styling/TextStyling'
import { animation } from '../../utils/Animations'

function HeaderButton(props) {
  const handleClick = (event) => {
    event.preventDefault()
    props.handleClick()
  }

  const [hover, setHover] = useState(false)

  return (
    <Tooltip
      newButton
      style={{ width: 'auto' }}
      handleClick={handleClick}
      content={<StyledText>{props.title}</StyledText>}
    >
      <Button
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {props.icon === 'cancel' ? (
          <HideButton header hover={hover} />
        ) : (
          <StyledIcon
            icon={props.icon}
            color={hover ? 'themeHighlightColor' : 'themeHeaderColor'}
            size={20}
            animated={props.animated}
          />
        )}
      </Button>
    </Tooltip>
  )
}

export default HeaderButton

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.theme.header.height};
  height: ${(props) => props.theme.header.height};
  color: ${(props) => props.theme.colors.themeHeaderColor};

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.colors.themeBackground};
  }
`

const StyledIcon = styled(Icon)`
  ${(props) => (props.animation ? animation : null)}
`
