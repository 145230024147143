import React, { useState } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-modal-hook'
import { useTranslation } from 'react-i18next'

import SpotView from './SpotView'
import ActionButton from '../../shared/ActionButton'
import ClickArea from '../../shared/ClickArea'
import HideButton from '../../shared/HideButton'
import ReactModalWrapper from "../../shared/ReactModalWrapper"

import { cSpotFees } from '../../../Theme'
import { useAuth } from '../../auth/Authentication'

import { getSpotDisplaySize } from '../../../utils/SpotHelper'

function Spot(props) {
  const { t } = useTranslation()
  const { authCall } = useAuth()
  const { spot, width, height } = props

  const debugRotationIndex = false

  const [dragging, setDragging] = useState(false)

  const [showModal, hideModal] = useModal(() => {
    return (
      <ReactModalWrapper
        layout={'fullscreen'}
        overlayColor={'rgba(0, 0, 0, 0.85)'}
        onRequestClose={hideModal}
      >
        <OverlayClose onClick={hideModal}>
          <OverlayMargin>
            <SpotView spot={spot} margins={60} />
            <HideButton
              style={{display: 'flex', alignSelf: 'flex-start', justifySelf: 'right'}}
              handleClick={hideModal}
            />
          </OverlayMargin>
        </OverlayClose>
      </ReactModalWrapper>
    )}, [spot])

  const handleDragStart = (event) => {
    setDragging(true);

    // In case the library is the origin, we need all properties for the
    // INSERT to booking_unit_spot. Otherwise, we only need the id from
    // booking_unit_spot (here spot.id) FOR an UPDATE or DELETE, its
    // rotation_index to calculate the spot moves, and its resolution_id
    // in case of a drag&drop within the a spot rotation.
    const spotData = props.dragAndDropOrigin === 'library' ?
          {...spot} :
          {
            id: spot.id,
            rotation_index: spot.rotation_index,
            resolution_id: spot.resolution_id,
          }
    spotData['num_spots_in_rotation'] = props.numSpotsInRotation
    // In case the library is the origin, spot['id'] refers to the column in
    // table spot. We need to move the id to spot_id for the INSERT into
    // booking_unit_spot.
    if(props.dragAndDropOrigin === 'library') {
      spotData['spot_id'] = spotData.id
      delete spotData.id
    }
    event.dataTransfer.setData('origin', props.dragAndDropOrigin)
    event.dataTransfer.setData('spot', JSON.stringify(spotData))

    if (props.handleDragStart) props.handleDragStart();
  };

  const handleDragEnd = () => {
    setDragging(false);

    if (props.handleDragEnd) props.handleDragEnd();
  };

  const [spotWidth, spotHeight] =
        width !== undefined && height !== undefined ?
        getSpotDisplaySize(spot, width, height) :
        getSpotDisplaySize(spot)

  const containerWidth = width !== undefined ? width : spotWidth
  const containerHeight = height !== undefined ? height : spotHeight
  const coloredBackground = 4 < Math.abs(spotWidth + spotHeight - containerWidth - containerHeight)

  const canDelete = !dragging && props.userCanEdit &&
        (props.userCanDelete === undefined || props.userCanDelete)

  const originToggleButton = cSpotFees && !dragging && spot['origin'] !== undefined && props.userCanEdit && (
    <ActionButton
      icon={'price_change'}
      text={t('content.booking.spots.toggle_from_' + spot['origin'])}
      style={{ float: 'right' }}
      handleClick={() => {
        const origin = spot['origin'] === 'supplied' ? 'produced' : 'supplied'
        const options = {
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          data: { id: spot['id'], origin }
        }
        authCall('spot', [], options).then(props.refreshSpots)
      }}
    />
  )

  return (
    <ClickArea
      style={{
        width: containerWidth,
        height: containerHeight,
        marginRight: 1,
        padding: 0
      }}
      cursor={props.userCanDrag ? 'pointer' : 'default'}
      normalColor={'#333'}
      hoverColor={'#333'}
      translationPrefix={props.translationPrefix}
      handleClick={showModal}
      userCanDelete={canDelete}
      handleDelete={() => props.handleDelete(spot.id)}
      draggable={props.userCanDrag}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      customButton={originToggleButton}
    >
      {debugRotationIndex && spot.rotation_index}
      <SpotView
        thumbnail
        style={{width: containerWidth, height: containerHeight}}
        spot={spot}
        dragged={dragging}
        coloredBackground={coloredBackground}
      />
    </ClickArea>
  );
}

export default Spot;


const OverlayClose = styled.div`
  width: 100%;
  height: 100%;
`

const OverlayMargin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 10px 30px 30px;
`
