import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import InputContainer, { InputContainerHeader } from '../../shared/InputContainer'
import TimestampPicker from '../../shared/TimestampPicker'

import { useDialog } from '../../shared/Dialog'


function DateInput(props) {
  const { t } = useTranslation()
  const { inputs, setInputs } = props
  const dialog = useDialog()

  const canChooseMode = inputs['date_input_mode'] === 'range_and_individual'
  const rangeSelected = canChooseMode ?
        inputs['use_range'] :
        inputs['date_input_mode'] === 'range'

  const handleClick = (event, isRange) => {
    event.preventDefault()
    setInputs(inputs => ({...inputs, use_range: isRange}))
  }

  const handleRangeChange = (start, end) => {
    setInputs(inputs => ({...inputs, start_timestamp: start, end_timestamp: end}))
  }

  const handleMultiChange = (dates) => {
    // Limit the number of booking_unit.individual_dates to 93. The database
    // column is VARCHAR(1024) which can hold 93 * len('2006-02-01,') characters.
    if(dates.length <= 93)
      setInputs(inputs => ({...inputs, individual_dates: dates}))
    else
      dialog.showModal(t('info.individual_dates_len_exceeded'))
  }

  const modeSelector = () => {
    if(canChooseMode) {
      return (
        <Container>
          <Button
            selected={rangeSelected}
            onClick={(event) => handleClick(event, /*isRange*/true)}
          >
            {t('shared.dateRange')}
          </Button>
          <Button
            selected={!rangeSelected}
            onClick={(event) => handleClick(event, /*isRange*/false)}
          >
            {t('shared.individualDates')}
          </Button>
        </Container>
      )
    } else {
      return rangeSelected ? t('shared.dateRange') : t('shared.individualDates')
    }
  }

  return (
    <InputContainer
      marginBottom={'20px'}
      header={modeSelector()}
      tutorialId={props.tutorialId}
    >
      <TimestampPicker
        range
        fullWeeksOnly={props.fullWeeksOnly}
        firstDayOfWeek={props.firstDayOfWeek}
        optional={props.optional}
        style={{ height: rangeSelected ? 'auto' : 0, overflow: 'hidden' }}
        start={inputs['start_timestamp']}
        end={inputs['end_timestamp']}
        handleRangeChange={handleRangeChange}
      />
      <TimestampPicker
        multiple
        fullWeeksOnly={props.fullWeeksOnly}
        firstDayOfWeek={props.firstDayOfWeek}
        optional={props.optional}
        style={{ height: !rangeSelected ? 'auto' : 0, overflow: 'hidden' }}
        dates={inputs['individual_dates']}
        handleMultiChange={handleMultiChange}
      />
    </InputContainer>
  )
}

export default DateInput


const Container = styled.div`
  display: flex;
`

const Button = styled(InputContainerHeader)`
  background: ${props => props.selected ? props.theme.colors.green : 'rgba(255, 255, 255, 0.15)'};
  width: 50%;
  text-align: center;
`
