import React from 'react'
import { useTranslation } from 'react-i18next'

import InputContainer from '../../shared/InputContainer'
import Select from '../../shared/Select'

function SpotOriginInput(props) {
  const { t } = useTranslation()
  const { inputs, handleInputChange } = props

  return (
    <InputContainer
      dark={props.dark}
      marginTop={'20px'}
      marginBottom={'20px'}
      header={t('content.booking.spot_origin')}
      tutorialId={props.tutorialId}
    >
      <Select
        searchable
        value={inputs['spot_origin']}
        onChange={handleInputChange}
        name='spot_origin'
        options={[
          { value: 'reuse', text: t('content.booking.spot_origin_reuse') },
          { value: 'supply', text: t('content.booking.spot_origin_supply') },
          { value: 'production', text: t('content.booking.spot_origin_production') },
        ]}
      />
    </InputContainer>
  )
}

export default SpotOriginInput
