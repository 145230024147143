import React from 'react'
import styled from 'styled-components'

import Spinner from '../../shared/Spinner'

import { StyledText } from '../../styling/TextStyling'

function SpotPlaceholder(props) {
  return (
    <Padding style={{ width: props.width, height: props.height }}>
      <Background>
        {props.progress < 100
         ? <StyledText>{props.progress + '%'}</StyledText>
         : <Spinner scale={0.5} />
        }
      </Background>
    </Padding>
  )
}

export default SpotPlaceholder


const Padding = styled.div`
  padding: 0 1px 0 0;
`

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.themeNavBackground};
  width: 100%;
  height: 100%;
`
