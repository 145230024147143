import styled from 'styled-components'

export const PermissionsTable = styled.div`
  display: table;
  width: 100%;
  background: #FFF;
  color: #111;
  font-size: 12px;
`

export const PermissionsTableRow = styled.div`
  display: table-row;
`

export const PermissionsTableCell = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: auto;
  padding: 7px;
  word-break: break-word;
  border-bottom: 1px solid #EEE;
  border-right: 1px solid #EEE;
`
