import React from 'react'
import styled, { keyframes } from "styled-components";

function Spinner(props) {
  const scale = props.scale ? props.scale : 1
  return (
    <Container scale={scale}>
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
      <Dot scale={scale} animation={Animation} />
    </Container>
  )
}

export default Spinner

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => (props.scale * 80) + 'px'};
  height: ${props => (props.scale * 80) + 'px'};
`

const Dot = styled.div` {
  position: absolute;
  width: ${props => (props.scale * 6) + 'px'};
  height: ${props => (props.scale * 6) + 'px'};
  background: #fff;
  border-radius: 50%;
  animation: ${(props) => props.animation} 1.2s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
    top: ${props => (props.scale * 37) + 'px'};
    left: ${props => (props.scale * 66) + 'px'};
  }

  &:nth-child(2) {
    animation-delay: -0.1s;
    top: ${props => (props.scale * 22) + 'px'};
    left: ${props => (props.scale * 62) + 'px'};
  }

  &:nth-child(3) {
    animation-delay: -0.2s;
    top: ${props => (props.scale * 11) + 'px'};
    left: ${props => (props.scale * 52) + 'px'};
  }

  &:nth-child(4) {
    animation-delay: -0.3s;
    top: ${props => (props.scale * 7) + 'px'};
    left: ${props => (props.scale * 37) + 'px'};
  }

  &:nth-child(5) {
    animation-delay: -0.4s;
    top: ${props => (props.scale * 11) + 'px'};
    left: ${props => (props.scale * 22) + 'px'};
  }

  &:nth-child(6) {
    animation-delay: -0.5s;
    top: ${props => (props.scale * 22) + 'px'};
    left: ${props => (props.scale * 11) + 'px'};
  }

  &:nth-child(7) {
    animation-delay: -0.6s;
    top: ${props => (props.scale * 37) + 'px'};
    left: ${props => (props.scale * 7) + 'px'};
  }

  &:nth-child(8) {
    animation-delay: -0.7s;
    top: ${props => (props.scale * 52) + 'px'};
    left: ${props => (props.scale * 11) + 'px'};
  }

  &:nth-child(9) {
    animation-delay: -0.8s;
    top: ${props => (props.scale * 62) + 'px'};
    left: ${props => (props.scale * 22) + 'px'};
  }

  &:nth-child(10) {
    animation-delay: -0.9s;
    top: ${props => (props.scale * 66) + 'px'};
    left: ${props => (props.scale * 37) + 'px'};
  }

  &:nth-child(11) {
    animation-delay: -1s;
    top: ${props => (props.scale * 62) + 'px'};
    left: ${props => (props.scale * 52) + 'px'};
  }

  &:nth-child(12) {
    animation-delay: -1.1s;
    top: ${props => (props.scale * 52) + 'px'};
    left: ${props => (props.scale * 62) + 'px'};
  }
`

const Animation = keyframes`
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`
