import React from 'react'
import { useTranslation } from 'react-i18next'

import PlayerIdInput from './PlayerIdInput'
import { cFixedPricePerSecond, cImplicitPlayers, cPricingUnit } from '../../Theme'
import ListButton from '../shared/ListButton'
import Table from '../shared/Table'

import { useAuth } from '../auth/Authentication'
import { useDataInterface } from '../shared/DataInterface'

import { currencyUnit, localePricePerSecond } from '../../utils/Locale'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Clusters() {
  const { t } = useTranslation();
  const { queryPath } = useQueryEndPoint()
  const auth = useAuth()

  const endPoint = 'admin/clusters'
  const translationPrefix = 'admin.clusters'

  // Data interface players
  const playerDataInterface = useDataInterface({ endPoint: 'admin/players' })
  const isPlayerInSite = (player, data) => player.state === 'active' && data.player_ids.includes(player.id)

  return (
    <Table
      endPoint = {endPoint}
      translationPrefix = {translationPrefix}
    
      columns = {[
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name'
        },
        {
          header: t(translationPrefix + (!cImplicitPlayers ? '.player_ids' : '.site_ids')),
          defaultProperty: 'players',
          style: {justifyContent: 'center'},
          sortStringFunction: (data) => {
            return playerDataInterface.data.filter(player => isPlayerInSite(player, data)).length
          },
          visualizationFunction: (data) => {
            const playerNames = playerDataInterface.data
                  .filter(player => isPlayerInSite(player, data))
                  .map(player => player.name)
            return (
              <ListButton
                data = {playerNames}
                handleClick = {() => {
                  if(!cImplicitPlayers)
                    queryPath('admin/players', 'cluster_id', data.id)
                  else
                    queryPath('admin/sites', 'cluster_id', data.id)
                }}
              />
            )
          }
        },
        {
          header: t('shared.price_per_' + cPricingUnit) + currencyUnit(),
          defaultProperty: 'price_per_second',
          style: {justifyContent: 'center'},
          isHidden: () => cFixedPricePerSecond,
          visualizationFunction: (data) => {
            return <div>{localePricePerSecond(data['price_per_second'])}</div>
          }
        },
//        {
//          header: t(translationPrefix + '.discount'),
//          defaultProperty: 'discount'
//        },
      ]}
    
      fields = {[
        {
          name: t(translationPrefix + '.name'),
          property: 'name',
          maxLength: 128,
          validator: 'name'
        },
        {
          name: t('shared.price_per_' + cPricingUnit) + currencyUnit(),
          property: 'price_per_second',
          type: 'float',
          validator: 'float',
          isActive: () => !cFixedPricePerSecond,
          optional: true
        },
        {
          name: t(translationPrefix + '.player_ids'),
          property: 'player_ids',
          type: 'array',
          defaultValue: [],
          input: (inputs, handleInputChange) => {
            return (
              <PlayerIdInput
                playerIds={inputs['player_ids']}
                auth={auth}
                handleInputChange={handleInputChange}
              />
            )
          }
        }
      ]}
    />
  )
}

export default Clusters
