import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";

import SpotOriginInput from './SpotOriginInput'

import { cDefaultSector, cNewStandardBooking, cNewFillinBooking } from '../../../Theme'
import { customerFields } from '../../admin/Customers'
import { sectorFields } from '../../admin/Sectors'
import InputContainer, { InputContainerHeaderWithNewButton } from '../../shared/InputContainer'
import Select from '../../shared/Select'

import { useAuth } from '../../auth/Authentication'
import { useDataInterface } from '../../shared/DataInterface'
import { parseFloatUsingLocale } from '../../shared/DataProcessing'

import { StyledInput } from '../../styling/InputStyling'
import { StyledText } from '../../styling/TextStyling'

import { currencyUnit, localePrice } from "../../../utils/Locale";
import { minMax, roundDecimal } from "../../../utils/Math";

const MetaDataFlag = {
  IsNewBooking: 1,
  ShowPricing: 2,
  IsSearchBooking: 4,
  ExcludeModeField: 8,
  ShowSpotOrigin: 16,
  CustomerReadOnly: 32,
}
const testFlag = (options, flag) => 0 < (options & flag)


function useMetaDataFields(translationPrefix, options, template) {
  const { t } = useTranslation();
  const { clientId, userHasPermissions } = useAuth()

  // Customer data interface
  const [newCustomerId, setNewCustomerId] = useState(-1)
  const customerDataInterface = useDataInterface({
    endPoint: 'admin/customers',
    handleNewIds: (data) => setNewCustomerId(data.id),
  })

  // Sector data interface
  const [newSectorId, setNewSectorId] = useState(-1)
  const sectorDataInterface = useDataInterface({
    endPoint: 'admin/sectors',
    handleNewIds: (data) => setNewSectorId(data.id),
  })

  // Create input fields
  const fields = useMemo(() => {
    const omitModeSelection = !testFlag(options, MetaDataFlag.IsNewBooking) ||
          testFlag(options, MetaDataFlag.ExcludeModeField)

    return [
      {
        name: t(translationPrefix + '.mode'),
        property: 'state',
        defaultValue: 'ordered',
        isActive: () => !omitModeSelection,
        input: (inputs, handleInputChange) => (
          <Select
            value={inputs['state']}
            onChange={handleInputChange}
            name='state'
            options={[
              { value: 'ordered', text: t(translationPrefix + '.newBooking') },
              { value: 'quoted', text: t(translationPrefix + '.newQuotation') },
            ]}
          />
        ),
      },
      {
        name: t(translationPrefix + '.template'),
        property: 'template',
        defaultValue: template ? template : (cNewStandardBooking ? 'standard' : 'vacancy'),
        isActive: () => !testFlag(options, MetaDataFlag.IsSearchBooking),
        input: (inputs, handleInputChange) => (
          <Select
            value={inputs['template']}
            onChange={handleInputChange}
            name='template'
            options={(cNewStandardBooking
                      ? [{ value: 'standard', text: t(translationPrefix + '.standard') }]
                      : []).concat((cNewFillinBooking
                                    ? [{ value: 'fillin', text: t(translationPrefix + '.fillin') }]
                                    : []).concat([{ value: 'vacancy', text: t(translationPrefix + '.vacancy') }]))}
          />
        ),
      },
      {
        name: t(translationPrefix + '.name'),
        property: 'name',
        maxLength: 128,
        validator: 'name',
        tutorialId: 'MetaDataFields.name',
      },
      {
        name: t(translationPrefix + '.customer'),
        property: 'customer_id',
        type: 'int',
        defaultValue: -1,
        validator: 'selected',
        customInput: (key, inputs, handleInputChange, fields) => {
          const customerReadOnly = testFlag(options, MetaDataFlag.CustomerReadOnly)
          const canAddNew = testFlag(options, MetaDataFlag.IsNewBooking) &&
                !customerReadOnly && userHasPermissions('admin/customers', 'add')

          const Header = (
            <InputContainerHeaderWithNewButton
              buttonText={'+ ' + t('shared.new')}
              buttonEnabled={canAddNew}
              translationPrefix={'admin.customers'}
              dataInterface={customerDataInterface}
              fields={customerFields(t, 'admin.customers')}
              tutorialId={'MetaDataFields.new_customer'}
            >
              {t(translationPrefix + '.customer')}
            </InputContainerHeaderWithNewButton>
          )
          return (
            <InputContainer
              dark
              key={key}
              marginBottom={'20px'}
              header={Header}
              tutorialId={'MetaDataFields.customer'}
            >
              <Select
                searchable
                disabled={customerReadOnly}
                value={inputs['customer_id']}
                onChange={handleInputChange}
                name='customer_id'
                options={customerDataInterface.data.map((customer, index) => ({
                    value: customer.id, text: customer.name
                }))}
              />
            </InputContainer>
          )
        }
      },
      {
        name: t(translationPrefix + '.sector'),
        property: 'sector_id',
        type: 'int',
        defaultValue: cDefaultSector ? 1 : -1,
        validator: 'selected',
        customInput: (key, inputs, handleInputChange, fields) => {
          const canAddNew = testFlag(options, MetaDataFlag.IsNewBooking) &&
                userHasPermissions('admin/sectors', 'add')

          const Header = (
            <InputContainerHeaderWithNewButton
              buttonText={'+ ' + t('shared.new')}
              buttonEnabled={canAddNew}
              translationPrefix={'admin.sectors'}
              dataInterface={sectorDataInterface}
              fields={sectorFields(t, 'admin.sectors')}
              tutorialId={'MetaDataFields.new_sector'}
            >
              {t(translationPrefix + '.sector')}
            </InputContainerHeaderWithNewButton>
          )
          return (
            <InputContainer
              dark
              key={key}
              marginBottom={'20px'}
              header={Header}
              tutorialId={'MetaDataFields.sector'}
            >
              <Select
                searchable
                value={inputs['sector_id']}
                onChange={handleInputChange}
                name='sector_id'
                options={sectorDataInterface.data.map((sector, index) => ({
                  value: sector.id, text: sector.name
                }))}
              />
            </InputContainer>
          )
        },
      },
      {
        name: t(translationPrefix + '.spot_origin'),
        property: 'spot_origin',
        isActive: () => testFlag(options, MetaDataFlag.ShowSpotOrigin),
        defaultValue: testFlag(options, MetaDataFlag.ShowSpotOrigin) ? 'reuse' : null,
        customInput: (key, inputs, handleInputChange, fields) => {
          return (
            <SpotOriginInput
              key={key}
              inputs={inputs}
              handleInputChange={handleInputChange}
              tutorialId={'MetaDataFields.spot_origin'}
            />
          )
        },
      },
      {
        name: t(translationPrefix + '.discount'),
        property: 'discount',
        isActive: () => testFlag(options, MetaDataFlag.ShowPricing),
        defaultValue: 0,
        type: 'float',
        validator: 'percent',
        customInput: (key, inputs, handleInputChange, fields) => {
          const basePrice = inputs['list_price'] !== undefined ? inputs['list_price'] : 0
          const discount = inputs['discount'] !== undefined
                ? minMax(0.0, inputs['discount'], 100.0) : 0
          const discountedPrice = inputs['userEditedPrice'] !== undefined
                ? inputs['userEditedPrice']
                : roundDecimal(basePrice * ((100.0 - discount) / 100.0))

          const handleDiscountChange = (event) => {
            delete inputs['userEditedPrice']
            handleInputChange(event)
          }

          const handleDiscountedPriceChange = (event) => {
            inputs['userEditedPrice'] = event.target.value

            const eps = 0.001
            const discountedPrice = minMax(0.0, event.target.value, basePrice)
            const discount = basePrice < eps ? 0.0
                  : 100.0 * (Math.max(0.0, basePrice - discountedPrice) / basePrice)
            const discountEvent = {
              persist: () => {},
              target: {
                name: 'discount',
                value: discount,
                type: 'number',
                checked: false
              }
            }

            handleInputChange(discountEvent)
          }

          const handleFocusOut = () => {
            const discountEvent = {
              persist: () => {},
              target: {
                name: 'userEditedPrice',
                value: parseFloatUsingLocale(inputs['userEditedPrice']).toFixed(2),
                type: 'number',
                checked: false
              }
            }

            handleInputChange(discountEvent)
          }

          return (
            <div key={key} style={{display: 'flex', width: '100%'}}>
              <InputContainer
                dark left
                style={{ flexGrow: 1, flexBasis: 0 }}
                header={t('shared.list_price')}
                marginBottom={'20px'}
              >
                <StyledText
                  dark
                  style={{ padding: 15, fontSize: 14 }}
                >
                  {localePrice(inputs['list_price'], 0)}
                </StyledText>
              </InputContainer>

              <InputContainer
                dark left
                style={{ flexGrow: 1, flexBasis: 0 }}
                header={t(translationPrefix + '.discount')}
                marginBottom={'20px'}
                tutorialId={'MetaDataFields.discount'}
              >
                <StyledInput
                  type='number'
                  min='0'
                  max='100'
                  value={inputs['discount']}
                  name={'discount'}
                  onChange={handleDiscountChange}
                />
              </InputContainer>

              <InputContainer
                dark left
                style={{ flexGrow: 1, flexBasis: 0 }}
                header={t(translationPrefix + '.discountedPrice') + currencyUnit()}
                marginBottom={'20px'}
                tutorialId={'MetaDataFields.discounted_price'}
              >
                <StyledInput
                  type='number'
                  min='0'
                  max={basePrice}
                  step='1'
                  value={discountedPrice}
                  name={'discountedPrice'}
                  onChange={handleDiscountedPriceChange}
                  onBlur={handleFocusOut}
                />
              </InputContainer>
            </div>
          )
        }
      },
      {
        name: t(translationPrefix + '.reason'),
        property: 'info',
        maxLength: 1024,
        isActive: (inputs) => testFlag(options, MetaDataFlag.ShowPricing) && inputs['discount'] !== undefined && 0 < inputs['discount'],
        validator: 'discountInfo'
      }
      // Disable warning for dependency data - depend on dataId instead
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ]}, [t, translationPrefix, customerDataInterface.dataId,
         sectorDataInterface.dataId, userHasPermissions, options, template])

  const customProcessBeforeSaveEdit = useCallback((data) => {
    // Generate bookingtype_id and bookingattributes from booking template,
    // if they do not exist
    if(!data.hasOwnProperty('bookingtype_id')) {
      switch(data.template) {
      case 'booking':
      case 'quotation':
      case 'standard':
        data['bookingtype_id'] = 1
        data['bookingattributes'] = 1
        break

      case 'fillin':
        data['bookingtype_id'] = 1
        data['bookingattributes'] = 2
        break

      case 'vacancy':
        data['bookingtype_id'] = 1
        data['bookingattributes'] = 4
        break

      case 'subscription':
        data['bookingtype_id'] = 4
        data['bookingattributes'] = 0
        break

      case 'reservation':
        data['bookingtype_id'] = 5
        if(!data['bookingattributes'])
          throw new Error('reservation requires team_id in bookingattributes')
        break

      default:
        throw new Error('unsupported booking type: ' + data.template)
      }
    }
    delete data.template

    // Add client id
    data['client_id'] = clientId
  }, [clientId])

  return {
    fields: fields,
    customProcessBeforeSaveEdit: customProcessBeforeSaveEdit,
    newCustomerId: newCustomerId,
    newSectorId: newSectorId,
  }
}


export { MetaDataFlag, useMetaDataFields }
