import React, { useCallback, useRef } from 'react'
import styled, { keyframes } from "styled-components";
import { useModal } from "react-modal-hook";

import ReactModalWrapper from "../shared/ReactModalWrapper";

function useLoadingIndicator() {
  const showDelay = 300
  const hideDelay = 500

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModalWrapper layout="fullscreen">
        <Center>
          <Container>
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
            <Spinner animation={Animation} />
          </Container>
        </Center>
      </ReactModalWrapper>
    ), []
  )

  const state = useRef({
    timeout: null,
    location: "",
    timestamp: 0,
    counter: 0,
  })

  const hide = useCallback(() => {
    if(state.current.timestamp) {
      clearTimeout(state.current.timeout)
      state.current.timeout = null
    }

    state.current.location = ""
    state.current.timestamp = 0
    state.current.counter = 0

    hideModal()
  }, [hideModal])

  const showLoadingIndicatorDelayed = useCallback(() => {
    //console.log("REQUEST SHOW", state.current)
    //debugger

    // Check if something went wrong -> hide indicator and reset
    if(state.current.counter !== 0 && window.location !== state.current.location) {
      console.log("WARNING - reset loading indicator")
      hide()
    }

    state.current.counter++

    if(state.current.timeout === null) {
      state.current.location = window.location
      state.current.timeout = setTimeout(() => {
        //console.log("SHOW", Date.now())
        state.current.timestamp = Date.now()
        showModal()
      }, showDelay)
    }
  }, [hide, showModal])

  const hideLoadingIndicatorDelayed = useCallback(() => {
    //debugger
    //console.log("REQUEST HIDE", state.current)

    state.current.counter--
    if(state.current.counter <= 0 && state.current.timeout) {
      clearTimeout(state.current.timeout)
      state.current.timeout = null

      if(0 < state.current.timestamp) {
        //console.log("HIDE", state.current.timestamp)

        const delay = hideDelay - (Date.now() - state.current.timestamp)
        if(0 < delay)
          setTimeout(hide, delay)
        else
          hide()
      }
    }
  }, [hide])

  return {
    showLoadingIndicator: showModal,
    hideLoadingIndicator: hide,
    showLoadingIndicatorDelayed,
    hideLoadingIndicatorDelayed,
  }
}

export { useLoadingIndicator }


const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`

const Spinner = styled.div` {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: ${(props) => props.animation} 1.2s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }

  &:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }

  &:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }

  &:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }

  &:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }

  &:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }

  &:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }

  &:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }

  &:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }

  &:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }

  &:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }

  &:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
`

const Animation = keyframes`
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`
