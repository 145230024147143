import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ActionButton from "./ActionButton";
import Tooltip from "./Tooltip";

function ClickArea(props) {
  const { t } = useTranslation();

  const { style, height, ...otherProps } = props;

  const padding = style && style.padding;
  if (style && style.padding) delete style.padding;

  const editButton = !props.userCanEdit ? null : (
    <ActionButton
      type={"edit"}
      style={{ float: "right" }}
      handleClick={props.handleEdit}
    />
  );

  const duplicateButton = !props.userCanDuplicate ? null : (
    <ActionButton
      type={"duplicate"}
      style={{ float: "right" }}
      confirmMessage={t(props.translationPrefix + ".confirmDuplicate")}
      handleClick={props.handleDuplicate}
    />
  );

  const deleteButton = !props.userCanDelete ? null : (
    <ActionButton
      type={"delete"}
      style={{ float: "right" }}
      confirmMessage={t(props.translationPrefix + ".confirmDelete")}
      handleClick={props.handleDelete}
    />
  );

  return (
    <Tooltip
      clickArea
      style={style}
      cursor={props.cursor}
      content={
        <div style={{ margin: props.buttonMargin }}>
          {deleteButton}
          {duplicateButton}
          {editButton}
          {props.customButton}
        </div>
      }
    >
      <ClickWrapper
        {...otherProps}
        style={{ height: height, padding: padding }}
        onClick={props.handleClick}
      >
        {props.children}
      </ClickWrapper>
    </Tooltip>
  );
}

export default ClickArea;

const ClickWrapper = styled.div`
  background: ${(props) => {
    if (props.selected)
      return props.selectedColor
        ? props.selectedColor
        : props.theme.colors.themeSelectedBackground;
    else return props.normalColor ? props.normalColor : "rgba(0, 0, 0, 0.05)";
  }};

  padding: 6px;

  &:hover {
    background: ${(props) => {
      if (props.selected)
        return props.selectedColor
          ? props.selectedColor
          : props.theme.colors.themeSelectedBackground;
      else return props.hoverColor ? props.hoverColor : "rgba(0, 0, 0, 0.02)";
    }};
  }
`;
