import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { cInvoicingEnabled } from '../../Theme'

import { useAuth } from '../auth/Authentication'
import { useDialog } from '../shared/Dialog'

import { useInvoiceStrings } from '../../utils/RequestTranslationStrings'
import { toBookingId } from '../../utils/TimestampHelper'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

export function useBookingActions(bookingState) {
  const { t } = useTranslation()
  const { authCall, userHasPermissions } = useAuth()
  const { queryPath } = useQueryEndPoint()
  const dialog = useDialog()

  const invoiceStrings = useInvoiceStrings()

  const handleStateUpdate = useCallback((id, b, action, handleSaveEdit) => {
    switch(action) {
    case 'activate':
      handleSaveEdit('PUT', { id, state: b['state'] === 'quote_cancelled' ? 'quote_created' : 'ordered' })
      break

    case 'cancel':
      handleSaveEdit('PUT', { id, state: b['state'].includes('quote') ? 'quote_cancelled' : 'order_cancelled' })
      break

    case 'email_quote':
      if(b['state'] === 'quote_created')
        handleSaveEdit('PUT', { id, state: 'quoted' })
      else if(b['state'] === 'ordered')
        handleSaveEdit('PUT', { id, state: 'invoiced' })
      break

    case 'email_invoice':
      handleSaveEdit('PUT', { id, state: 'invoiced' })
      break

    case 'mark_paid':
      handleSaveEdit('PUT', { id, state: 'paid' })
      break

    case 'publish':
      handleSaveEdit('PUT', { id, state: 'ordered' }, (response) => {
        if(!response)
          queryPath('content/bookings', 'id', id)
        else
          throw Error("BookingActions - failed to publish", response)
      })
      break

    default:
      throw Error("BookingActions - unsupported state update", action)
    }
  }, [queryPath])

  const handleAction = useCallback((id, b, action, handleSaveEdit) => {
    var invoiceId = null
    var subject = ''
    var fileName = ''
    var isCancellation = false

    // Handle different states of a booking
    switch(bookingState) {
    case 'quoted':
    case 'ordered':
      subject = action !== 'email_invoice' ? t('shared.quotation') : t('shared.invoice')
      fileName = subject + "-" + toBookingId(b['creation_timestamp'], id) + '.pdf'
      break

    case 'invoiced':
      invoiceId = id
      id = b['booking_id']
      isCancellation = b.hasOwnProperty('invoice_type') && b['invoice_type'].includes('reverse')
      subject = isCancellation ? t('shared.reverse_invoice') : t('shared.invoice')
      fileName = subject + '-' + b['invoice_number'] + '.pdf'
      break

    default:
      throw Error("BookingActions - unsupported state", bookingState)
    }

    const endPoint = '/content/quotation' + (
      action !== 'email_quote' && action !== 'email_invoice' ? '' : '?id=' + id
    )
    const options = {
      method: action !== 'email_invoice' ? 'POST' : 'PUT',
      responseType: 'blob',
      headers: { 'content-type': 'application/json' },
      data: {
        id: id,
        invoice_id: invoiceId,
        booking_id: t('shared.quotationID'),
        disclaimer: t('shared.ad_tax_disclaimer'),
        validity_disclaimer: t('shared.quotation_validity_disclaimer'),
        ...invoiceStrings,
        invoice: isCancellation ? t('shared.reverse_invoice') : t('shared.invoice'),
        players: t('shared.sites'),
      },
    }

    authCall(endPoint, [], options)
      .then((data) => {
        if(action === 'email_quote' || action === 'email_invoice') {
          handleStateUpdate(id, b, action, handleSaveEdit)
        } else if(data.size < 1000) {
          dialog.showModal(t('info.invoice_not_generated'))
        } else {
          const file = new Blob([data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)

          if(action === 'view') {
            const pdfWindow = window.open()
            pdfWindow.location.href = fileURL
          } else if(action === 'download') {
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.download = fileName
            fileLink.click()
            URL.revokeObjectURL(fileURL)
          }
        }
      })
  }, [authCall, bookingState, handleStateUpdate, dialog, invoiceStrings, t])

  const handleCancelInvoice = useCallback((id, b) => {
    // In case invoice_type is set, any invoice is turned into a reverse invoice.
    // Otherwise, we are handling a click from content/bookings and the data
    // stored in b relates to a booking and not to an invoice. In that case we
    // set invoice_type to 'query_invoice' as a flag for the backend.
    const isInvoice = b.hasOwnProperty('invoice_type')
    const bookingId = isInvoice ? b['booking_id'] : b['id']
    const invoice = isInvoice
          ? { ...b,
              invoice_type: b['invoice_type'].replace('invoice', 'reverse')
            }
          : { booking_id: bookingId,
              start_timestamp: b['start_timestamp'],
              end_timestamp: b['end_timestamp'],
              invoice_type: 'query_invoice',
            }
    const options = {
      method: 'POST',
      responseType: 'blob',
      headers: { 'content-type': 'application/json' },
      data: invoice,
    }
    authCall('reporting/invoices', [], options)
      .then(() => {
        const forcePathChange = !isInvoice
        queryPath('content/bookings', 'id', bookingId, forcePathChange)
      })
  }, [authCall, queryPath])

  const is = useCallback((s) => bookingState === s, [bookingState])

  const actions = useMemo(() => [
    {
      type: (b) => is('invoiced') || b['state'].includes('quote') ? 'view' : 'view_' + b['state'],
      permissionsOk: (b) => (is('invoiced') || !['quote_cancelled', 'order_cancelled', 'free'].includes(b['state'])) && userHasPermissions(is('invoiced') || !b['state'].includes('quote') ? 'content/bookings' : 'content/quotations', 'view'),
      handleClick: (id, b) => handleAction(id, b, 'view'),
    },
    {
      type: 'download',
      permissionsOk: !is('ordered') && userHasPermissions('content/quotations', 'view'),
      handleClick: (id, b) => handleAction(id, b, 'download'),
    },
    {
      type: 'email_quote',
      permissionsOk: (b) => is('quoted') && (b['state'] === 'quote_created' || b['state'] === 'quoted') && userHasPermissions('content/quotations', 'view'),
      confirmTranslationPostfix: '.confirm_quote',
      handleClick: (id, b, handleSaveEdit) => handleAction(id, b, 'email_quote', handleSaveEdit),
    },
    {
      type: 'cancel_quoted',
      permissionsOk: (b) => is('quoted') && (b['state'] === 'quote_created' || b['state'] === 'quoted') && userHasPermissions('content/quotations', 'delete'),
      confirmTranslationPostfix: '.confirm_cancellation_quote',
      handleClick: (id, b, handleSaveEdit) => handleStateUpdate(id, b, 'cancel', handleSaveEdit),
    },
    {
      type: 'publish',
      permissionsOk: (b) => is('quoted') && b['state'] !== 'quote_cancelled' && userHasPermissions('content/quotations', 'edit'),
      confirmTranslationPostfix: '.confirm_publication',
      handleClick: (id, b, handleSaveEdit) => handleStateUpdate(id, b, 'publish', handleSaveEdit),
    },
    {
      type: 'email_invoice',
      permissionsOk: (b) => !is('invoiced') && b['state'] === 'ordered' && userHasPermissions('content/bookings', 'view'),
      confirmTranslationPostfix: '.confirm_invoice',
      handleClick: (id, b, handleSaveEdit) => handleAction(id, b, 'email_invoice', handleSaveEdit),
    },
    {
      type: 'cancel_ordered',
      permissionsOk: (b) => !is('invoiced') && b['state'] === 'ordered' && userHasPermissions('content/bookings', 'delete'),
      confirmTranslationPostfix: '.confirm_cancellation_ordered',
      handleClick: (id, b, handleSaveEdit) => handleStateUpdate(id, b, 'cancel', handleSaveEdit),
    },
    {
      type: (b) => is('invoiced') ? 'cancel_invoice' : 'cancel_' + b['state'],
      permissionsOk: (b) => ((is('invoiced') && !b['invoice_type'].includes('reverse')) || b['state'] === 'invoiced' || b['state'] === 'paid') && userHasPermissions('content/bookings', 'edit'),
      confirmTranslationPostfix: (b) => is('invoiced') ? '.confirm_cancellation_invoiced' : '.confirm_cancellation_' + b['state'],
      handleClick: (id, b) => handleCancelInvoice(id, b),
    },
    {
      type: 'mark_paid',
      permissionsOk: (b) => is('invoiced') && !b['invoice_type'].includes('reverse') && (b['state'] === 'invoiced' || b['state'] === 'reminded') && userHasPermissions('reporting/invoices', 'edit'),
      confirmTranslationPostfix: '.confirm_mark_paid',
      handleClick: (id, b, handleSaveEdit) => handleStateUpdate(id, b, 'mark_paid', handleSaveEdit),
    },
    {
      type: (b) => 'activate_' + b['state'],
      permissionsOk: (b) => !is('invoiced') && b['state'].includes('cancelled') && userHasPermissions(b['state'] === 'quote_cancelled' ? 'content/quotations' : 'content/bookings', 'add'),
      confirmTranslationPostfix: (b) => '.confirm_activation_' + b['state'],
      handleClick: (id, b, handleSaveEdit) => handleStateUpdate(id, b, 'activate', handleSaveEdit),
    },
  ], [handleStateUpdate, handleAction, handleCancelInvoice, is, userHasPermissions])

  return cInvoicingEnabled ? actions : []
}
