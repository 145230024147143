import React from 'react'
import { useTranslation } from 'react-i18next'

import Permissions from './Permissions'
import Table from '../shared/Table'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Groups() {
  const { t } = useTranslation();
  const { endPointFromQueryPath } = useQueryEndPoint()

  const endPoint = endPointFromQueryPath('admin/groups')
  const translationPrefix = 'admin.groups'

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      standardActions={['edit']}
    
      columns={[
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name'
        }
      ]}
    
      fields={[
        {
          name: t(translationPrefix + '.name'),
          property: 'name',
          maxLength: 128,
          validator: 'name'
        },
        {
          name: t(translationPrefix + '.permissions'),
          input: (inputs, handleInputChange) => {
            return (
              <Permissions
                inputs={inputs}
                handleInputChange={handleInputChange}
              />
            )
          }
        }
      ]}
    />
  )
}

export default Groups
