import React from 'react'
import styled from 'styled-components'

import { useDataInput } from "./DataInput";
import { useTutorialRef } from '../tutorial/TutorialProvider'

const Container = styled.div`
  height: ${props => props.vFill ? '100%' : 'auto'};
  margin-top: ${props => props.marginTop ? props.marginTop : '0px'};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '10px'};
  /* margin-left: ${props => props.margin || '10px'}; */
  /* margin-right: ${props => props.margin || '10px'}; */
`

export const InputContainerHeader = styled.div`
  background: ${props => {
    if(props.themed)
      return props.theme.colors.themeBackground
    else if(props.black)
      return '#000'
    else
      return 'rgba(255, 255, 255, 0.15)'
  }};
  text-align: ${props => props.left ? 'left' : 'center'};
  margin: 0px;
  padding: 4px 15px;
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #FFF;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Input = styled.div`
  background: ${props => {
    if(props.transparent)
       return 'transparent'
    else if(props.background)
       return props.background
    else if(props.warning)
       return '#FFA5A5'
    else
       return '#F5F5F5'
  }};
`

function InputContainer(props) {
  const ref = useTutorialRef(props.tutorialId)

  return (
    <Container ref={ref} {...props}>
      {typeof props.header === 'string' ? (
        <InputContainerHeader {...props}>
          {props.header}
        </InputContainerHeader>
      ) : props.header}
      <Input {...props}>
        {props.children}  
      </Input>
    </Container>
  )
}

export default InputContainer


export function InputContainerHeaderWithNewButton(props) {
  const ref = useTutorialRef(props.tutorialId)
  const { handleSaveEdit } = props.dataInterface

  const { handleNew } = useDataInput({
    translationPrefix: props.translationPrefix,
    fields: props.fields,
    handleSaveEdit: handleSaveEdit,
  });

  const handleClick = (event) => {
    event.preventDefault()
    handleNew()
  }

  return (
    <StyledInputContainerHeader {...props}>
      <Left />
      <Center>
        {props.children}
      </Center>
      <Right
        ref={ref}
        hidden={!props.buttonEnabled}
        onClick={handleClick}
      >
        {props.buttonText}
      </Right>
    </StyledInputContainerHeader>
  )
}

const StyledInputContainerHeader = styled(InputContainerHeader)`
  display: flex;
  padding: 0;
`

const Left = styled.div`
  width: 20%;
  padding: 4px 0 4px 15px;
`

const Center = styled.div`
  width: 60%;
  padding: 4px 0;
`

const Right = styled(Left)`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  padding: 4px 15px;
  cursor: pointer;
  visibility: ${props => props.hidden ? 'hidden' : 'visible'}
`
