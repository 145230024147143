import { fromDbTimestamp } from './TimestampHelper'

export function durations(t) {
  return [
    { min: 15, text: '15 ' + t('shared.minutes') },
    { min: 30, text: '30 ' + t('shared.minutes') },
    { min: 45, text: '45 ' + t('shared.minutes') },
    { min: 60, text: '1 ' + t('shared.hour') },
    { min: 2 * 60, text: '2 ' + t('shared.hours') },
    { min: 5 * 60, text: '5 ' + t('shared.hours') },
    { min: 24 * 60, text: '1 ' + t('shared.day') },
  ]
}

export function getDuration(t, data) {
  const end = data['end_timestamp']
  const start = data['start_timestamp']

  if(end && start) {
    const min = Math.round((end - start) / (60 * 1000))
    return durations(t).reduce((p, v) => {
      return ( Math.abs(p.min - min) < Math.abs(v.min - min) ? p : v )
    }, { min: -1000000, text: '' })
  } else {
    return durations(t)[3]
  }
}

export function rotations(t) {
  return [
    { sec: 10, text: '15 ' + t('shared.seconds') },
    { sec: 30, text: '30 ' + t('shared.seconds') },
    { sec: 60, text: '1 ' + t('shared.minute') },
    { sec: 90, text: '1,5 ' + t('shared.minutes') },
    { sec: 2 * 60, text: '2 ' + t('shared.minutes') },
    { sec: 5 * 60, text: '5 ' + t('shared.minutes') },
    //{ sec: 0, text: t(translationPrefix + '.exclusive') },
  ]
}

export function getRotation(t, data) {
  const start = data['start_timestamp']
  const end = data['end_timestamp']
  const repeats = data['repeats_per_day']

  if(start && end && repeats) {
    const secs = getDuration(t, data).min * 60
    const sec = secs / repeats
    return rotations(t).reduce((p, v) => {
      return ( Math.abs(p.sec - sec) < Math.abs(v.sec - sec) ? p : v )
    }, { sec: -1000000, text: '' })
  } else {
    return rotations(t)[3]
  }
}

export function reasons(t) {
  const translationPrefix = 'content.booking_special'

  return [
    { id: 'booking_proof', text: t(translationPrefix + '.reason_booking_proof') },
    { id: 'compensation', text: t(translationPrefix + '.reason_compensation') },
  ]
}

export function getReason(t, data) {
  return reasons(t).find(reason => reason.id === data['info'])
}

export function repeatsPerDay(duration, rotation) {
  rotation = typeof rotation === 'string' ? parseInt(rotation) : rotation
  return 0 < rotation ? Math.ceil(duration / (rotation / 60)) : 0
}

export function formatDate(date, string) {
  return fromDbTimestamp(date)
}

export function parseDate(string, format) {
  if(/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(string)) {
    const parts = string.split('.')
    var day = parseInt(parts[0], 10)
    var month = parseInt(parts[1], 10)
    var year = parseInt(parts[2], 10)

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month === 0 || month > 12)
      return

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
      monthLength[1] = 29;

    // Check the range of the day
    if(day > 0 && day <= monthLength[month - 1])
      return new Date(year, month - 1, day, 0, 0, 0)
  }
}
