import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import TextButton from "./TextButton";

function CancelSave(props) {
  const { t } = useTranslation();

  return (
    <ButtonWrapper>
      <StyledTextButton
        buttonHeight={props.buttonHeight}
        handleClick={props.handleCancel}
      >
        {props.cancelLabel ? props.cancelLabel : t("shared.cancel")}
      </StyledTextButton>

      {!props.isCloseButton && <StyledTextButton
        buttonHeight={props.buttonHeight}
        disabled={props.canSave !== undefined && !props.canSave}
        handleClick={props.handleSave}
      >
        {props.saveLabel ? props.saveLabel : t("shared.save")}
      </StyledTextButton>}
    </ButtonWrapper>
  );
}

CancelSave.propTypes = {
  canSave: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  isCloseButton: PropTypes.bool,
  buttonHeight: PropTypes.number,
};

export default CancelSave;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTextButton = styled(TextButton)`
  width: 50%;
  flex-grow: 1;
`;
