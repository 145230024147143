import React from 'react'
import styled from 'styled-components'

//import { animation } from '../../utils/Animations'
//import { ColorAnimation } from '../../utils/Animations'

function Icon(props) {
  const {icon, size, ...otherProps} = props
  const s = size ? size + 'px' : '24px'

  switch(icon) {
  case 'add':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
      </StyledSvg>
    )

  case 'add_to_queue':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.11-.9-2-2-2zm0 14H3V5h18v12zm-5-7v2h-3v3h-2v-3H8v-2h3V7h2v3h3z"/>
      </StyledSvg>
    )

  case 'arrow_back_ios':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <rect fill="none" height="24" width="24"/><g><polygon points="17.77,3.77 16,2 6,12 16,22 17.77,20.23 9.54,12"/></g>
      </StyledSvg>
    )

  case 'arrow_forward_ios':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g>
      </StyledSvg>
    )

  case 'business':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/>
      </StyledSvg>
    )

  case 'calendar_day':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="m0,0l24,0l0,24l-24,0l0,-24z" fill="none"/>
        <path d="m19,3l-1,0l0,-2l-2,0l0,2l-8,0l0,-2l-2,0l0,2l-1,0c-1.11,0 -1.99,0.9 -1.99,2l-0.01,14c0,1.1 0.89,2 2,2l14,0c1.1,0 2,-0.9 2,-2l0,-14c0,-1.1 -0.9,-2 -2,-2zm0,16l-14,0l0,-11l14,0l0,11z"/>
      </StyledSvg>
    )

  case 'calendar_month':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g>
      </StyledSvg>
    )

  case 'calendar_week':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/>
      </StyledSvg>
    )

  case 'camera_alt':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/>
      </StyledSvg>
    )

  case 'cancel_presentation':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M21 19.1H3V5h18v14.1zM21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/><path d="M21 19.1H3V5h18v14.1zM21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41z"/>
      </StyledSvg>
    )

  case 'check_circle':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
      </StyledSvg>
    )

  case 'clear':
  case 'close':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
      </StyledSvg>
    )

  case 'cloud_upload':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/>
      </StyledSvg>
    )

  case 'dashboard':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/>
      </StyledSvg>
    )

  case 'delete':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
      </StyledSvg>
    )

  case 'done':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
      </StyledSvg>
    )

  case 'edit':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
      </StyledSvg>
    )

  case 'email':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
      </StyledSvg>
    )

  case 'error':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
      </StyledSvg>
    )

  case 'exit_to_app':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
      </StyledSvg>
    )

  case 'expand_less':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/>
      </StyledSvg>
    )

  case 'file_copy':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z"/>
      </StyledSvg>
    )

  case 'grid_on':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z"/>
      </StyledSvg>
    )

  case 'group':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
      </StyledSvg>
    )

  case 'help_center':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><rect fill="none" height="24" width="24"/><path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12.01,18 c-0.7,0-1.26-0.56-1.26-1.26c0-0.71,0.56-1.25,1.26-1.25c0.71,0,1.25,0.54,1.25,1.25C13.25,17.43,12.72,18,12.01,18z M15.02,10.6 c-0.76,1.11-1.48,1.46-1.87,2.17c-0.16,0.29-0.22,0.48-0.22,1.41h-1.82c0-0.49-0.08-1.29,0.31-1.98c0.49-0.87,1.42-1.39,1.96-2.16 c0.57-0.81,0.25-2.33-1.37-2.33c-1.06,0-1.58,0.8-1.8,1.48L8.56,8.49C9.01,7.15,10.22,6,11.99,6c1.48,0,2.49,0.67,3.01,1.52 C15.44,8.24,15.7,9.59,15.02,10.6z"/></g>
      </StyledSvg>
    )

  case 'import_contacts':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M17.5,4.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65c0,0.65,0.73,0.45,0.75,0.45 C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5c1.65,0,3.35,0.3,4.75,1.05 C22.66,21.26,23,20.86,23,20.6V6C21.51,4.88,19.37,4.5,17.5,4.5z M21,18.5c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8 c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z"/></g></g></g>
      </StyledSvg>
    )

  case 'info':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
      </StyledSvg>
    )

  case 'insert_chart':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"/>
      </StyledSvg>
    )

  case 'insert_chart_outlined':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4zm2.5 2.1h-15V5h15v14.1zm0-16.1h-15c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
      </StyledSvg>
    )

  case 'life_tv':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M21 6h-7.59l3.29-3.29L16 2l-4 4-4-4-.71.71L10.59 6H3c-1.1 0-2 .89-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.11-.9-2-2-2zm0 14H3V8h18v12zM9 10v8l7-4z"/>
      </StyledSvg>
    )

  case 'local_offer':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z"/>
      </StyledSvg>
    )

  case 'lock':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
      </StyledSvg>
    )

  case 'mediation':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><rect fill="none" height="24" width="24"/><path d="M18,16l4-4l-4-4v3h-5.06c-0.34-3.1-2.26-5.72-4.94-7.05C7.96,2.31,6.64,1,5,1C3.34,1,2,2.34,2,4s1.34,3,3,3 c0.95,0,1.78-0.45,2.33-1.14C9.23,6.9,10.6,8.77,10.92,11h-3.1C7.4,9.84,6.3,9,5,9c-1.66,0-3,1.34-3,3s1.34,3,3,3 c1.3,0,2.4-0.84,2.82-2h3.1c-0.32,2.23-1.69,4.1-3.58,5.14C6.78,17.45,5.95,17,5,17c-1.66,0-3,1.34-3,3s1.34,3,3,3 c1.64,0,2.96-1.31,2.99-2.95c2.68-1.33,4.6-3.95,4.94-7.05H18V16z"/></g>
      </StyledSvg>
    )

  case 'notification_important':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-5 0h-2v-2h2v2zm0-4h-2V8h2v4zm-1 10c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z"/>
      </StyledSvg>
    )

  case 'perm_contact_calendar':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z"/>
      </StyledSvg>
    )

  case 'perm_media':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M2 6H0v5h.01L0 20c0 1.1.9 2 2 2h18v-2H2V6zm20-2h-8l-2-2H6c-1.1 0-1.99.9-1.99 2L4 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7 15l4.5-6 3.5 4.51 2.5-3.01L21 15H7z"/>
      </StyledSvg>
    )

  case 'person':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
      </StyledSvg>
    )

  case 'pie_chart':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"/>
      </StyledSvg>
    )

  case 'place':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
      </StyledSvg>
    )

  case 'play_arrow':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/>
      </StyledSvg>
    )

  case 'preview':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><rect fill="none" height="24" width="24"/><path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M13.5,13 c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5c0-0.83,0.67-1.5,1.5-1.5S13.5,12.17,13.5,13z M12,9c-2.73,0-5.06,1.66-6,4 c0.94,2.34,3.27,4,6,4s5.06-1.66,6-4C17.06,10.66,14.73,9,12,9z M12,15.5c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5 c1.38,0,2.5,1.12,2.5,2.5C14.5,14.38,13.38,15.5,12,15.5z"/></g>
      </StyledSvg>
    )

  case 'price_change':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><rect fill="none" height="24" width="24"/></g><g><g/><path d="M15,18.5c-2.51,0-4.68-1.42-5.76-3.5H15l1-2H8.58c-0.05-0.33-0.08-0.66-0.08-1s0.03-0.67,0.08-1H15l1-2H9.24 C10.32,6.92,12.5,5.5,15,5.5c1.61,0,3.09,0.59,4.23,1.57L21,5.3C19.41,3.87,17.3,3,15,3c-3.92,0-7.24,2.51-8.48,6H3l-1,2h4.06 C6.02,11.33,6,11.66,6,12s0.02,0.67,0.06,1H3l-1,2h4.52c1.24,3.49,4.56,6,8.48,6c2.31,0,4.41-0.87,6-2.3l-1.78-1.77 C18.09,17.91,16.62,18.5,15,18.5z"/></g>
      </StyledSvg>
    )

  case 'price_check':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><path d="M12,13V9c0-0.55-0.45-1-1-1H7V6h5V4H9.5V3h-2v1H6C5.45,4,5,4.45,5,5v4c0,0.55,0.45,1,1,1h4v2H5v2h2.5v1h2v-1H11 C11.55,14,12,13.55,12,13z"/><polygon points="19.59,12.52 13.93,18.17 11.1,15.34 9.69,16.76 13.93,21 21,13.93"/></g></g>
      </StyledSvg>
    )

  case 'public':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
      </StyledSvg>
    )

  case 'radio_button_unchecked':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
      </StyledSvg>
    )

  case 'receipt':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"/>
      </StyledSvg>
    )

  case 'remove':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/>
      </StyledSvg>
    )

  case 'restore':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"/>
      </StyledSvg>
    )

  case 'restore_from_trash':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps} fill="#e8eaed">
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2-5V9h8v10H8v-5zm2 4h4v-4h2l-4-4-4 4h2z"/>
      </StyledSvg>
    )

  case 'save_alt':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"/>
      </StyledSvg>
    )

  case 'search':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></g>
      </StyledSvg>
    )

  case 'settings':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><path d="M0,0h24v24H0V0z" fill="none"/><path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/></g>
      </StyledSvg>
    )

  case 'shuffle':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M10.59 9.17L5.41 4 4 5.41l5.17 5.17 1.42-1.41zM14.5 4l2.04 2.04L4 18.59 5.41 20 17.96 7.46 20 9.5V4h-5.5zm.33 9.41l-1.41 1.41 3.13 3.13L14.5 20H20v-5.5l-2.04 2.04-3.13-3.13z"/>
      </StyledSvg>
    )

  case 'subscriptions':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M20 8H4V6h16v2zm-2-6H6v2h12V2zm4 10v8c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-8c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2zm-6 4l-6-3.27v6.53L16 16z"/>
      </StyledSvg>
    )

  case 'theaters':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M18 3v2h-2V3H8v2H6V3H4v18h2v-2h2v2h8v-2h2v2h2V3h-2zM8 17H6v-2h2v2zm0-4H6v-2h2v2zm0-4H6V7h2v2zm10 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V7h2v2z"/>
      </StyledSvg>
    )

  case 'timelapse':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
      </StyledSvg>
    )

  case 'tv':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z"/>
      </StyledSvg>
    )

  case 'verified':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <g><rect fill="none" height="24" width="24"/></g><g><path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"/></g>
      </StyledSvg>
    )

  case 'view_headline':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z"/>
      </StyledSvg>
    )

  case 'visibility':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
      </StyledSvg>
    )

  case 'warning':
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
      </StyledSvg>
    )

  default:
    return (
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={s} height={s} {...otherProps}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
      </StyledSvg>
    )
  }
}

export default Icon

const mapColor = (color, theme) => {
  if(!color)
    return theme.colors.white;
  else if(color === 'grey')
    return theme.colors.grey;
  else if(color === 'lightgrey')
    return theme.colors.lightgrey;
  else if(color === 'darkgrey')
    return theme.colors.darkgrey;
  else if(color === 'black')
    return theme.colors.black;
  else if(color === 'red')
    return theme.colors.red;
  else if(color === 'green')
    return theme.colors.green;
  else if(color === 'theme' || color === 'themeColor')
    return theme.colors.themeColor;
  else if(color === 'themeBackground')
    return theme.colors.themeBackground;
  else if(color === 'themeHeader' || color === 'themeHeaderColor')
    return theme.colors.themeHeaderColor;
  else if(color === 'themeHighlightColor')
    return theme.colors.themeHighlightColor;
  else if(typeof color === 'string' && (color.startsWith('#') || color.startsWith('rgba(')))
    return color
  else
    return theme.colors.white;
}

const StyledSvg = styled.svg`
  fill: ${props => mapColor(props.color, props.theme)};

  $:hover {
    fill: ${props => mapColor(props.hoverColor ? props.hoverColor : props.color, props.theme)};
  }

  animation: ${props => props.animated ? 'flash 2s linear infinite' : 'null'};

  @keyframes flash {
    0% {
      fill: ${props => props.theme.colors.black};
    }
    50% {
      fill: ${props => props.theme.colors.red};
    }
    100% {
      fill: ${props => props.theme.colors.black};
    }
  }
`
