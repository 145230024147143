import { useCallback, useEffect, useRef, useState } from 'react'

import { useAuth } from '../auth/Authentication'

function useRequestToken(endPoint) {
  const { authCall } = useAuth();

  const requestToken = useRef('')
  const [seconds, setSeconds] = useState(0)
  const [requestValidUntil, setRequestValidUntil] = useState('')

  const setRequestToken = useCallback((token, timestamp) => {
    if(token !== '') {
      requestToken.current = token
      const secs = Math.floor((timestamp.getTime() - new Date().getTime()) / 1000)
      setSeconds(secs)
    } else {
      setRequestValidUntil('invalid')
    }
  }, [])

  useEffect(() => {
    const timer = 0 < seconds && setInterval(() => setSeconds(seconds - 1), 1000)

    if(0 < seconds) {
      const m = Math.floor(seconds / 60)
      const s = seconds - 60 * m
      const str = (0 < m ? m + 'm ' : '') + s + 's'
      setRequestValidUntil(str)
    } else {
      setRequestValidUntil('')
    }

    return () => clearInterval(timer)
  }, [seconds])

  const clearRequestToken = useCallback(() => {
    requestToken.current = ''
    setSeconds(0)
  }, [])

  const releaseRequestToken = useCallback((cleanupFunction) => {
    if(requestToken.current !== '') {
      const token = requestToken.current
      clearRequestToken()

      const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        data: { request_token: token },
      }
      authCall(endPoint, [], options).then(
        () => cleanupFunction && cleanupFunction()
      )
    } else if(cleanupFunction) {
      cleanupFunction()
    }
  }, [clearRequestToken, authCall, endPoint])

  // When unmounted, we need to call releaseRequestToken to release the
  // reserved resources on the server, in case we have a valid request token.
  useEffect(() => {
    if(typeof endPoint !== 'string')
      throw new Error(`useRequestToken: endPoint is not defined`)

    return () => {
      if(requestToken.current !== '')
        releaseRequestToken()
    }
    // Disable warning for dependency data - life-cycle-method cannot depend
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint])

  return {
    requestToken: requestToken.current,
    setRequestToken,
    clearRequestToken,
    releaseRequestToken,
    requestInvalid: requestValidUntil === '' || requestValidUntil === 'invalid',
    requestValidUntil,
  }
}

export { useRequestToken }
