import React from 'react'
import styled from 'styled-components'
import { useLocation, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Icon from '../shared/Icon'
import Tooltip from '../shared/Tooltip'

import { navItemFlags, navItems, navPermissionAction } from './Items'

import { useAuth, UserPrefs, testUserPref } from '../auth/Authentication'

import { StyledText } from '../styling/TextStyling'

function NavColumn(props) {
  const { t } = useTranslation()
  const location = useLocation()
  const { userHasPermissions, userPrefs, toggleUserPref } = useAuth()

  const navExpanded = testUserPref(userPrefs, UserPrefs.NavExpanded)
  const items = props.items ? props.items : navItems(props.path)

  const iconSize = 19
  const icon = props.path === 'admin' ? 'settings' :
        props.path === 'content' ? 'perm_media' : 'pie_chart'

  const links = items.map((item, index) => {
    const path = props.path + "/" + item.path
    const action = navPermissionAction(item)

    if(item.flags & navItemFlags.HideFromNav || !userHasPermissions(path, action))
      return null

    const title = t(props.path + '.' + item.path + '.' + (item.title ? t(item.title) : 'title'))

    const showsLinkText = props.left && navExpanded
    const selected = location.pathname.startsWith('/' + path)

    return (
      <Tooltip
        newButton
        key={index}
        showRight={props.left}
        content={!showsLinkText && <StyledText>{title}</StyledText>}
      >
        <StyledNavLink
          to={"/" + path}
          style={{paddingLeft: showsLinkText ? '20px' : 0}}
          selected={selected}
        >
          {showsLinkText &&
           <LinkText upper selected={false && selected}>
             {title}
           </LinkText>
          }
          <StyledIcon>
            <Icon
              icon={item.icon}
              size={iconSize}
              color={selected ? 'themeHighlightColor' : 'white'}
            />
          </StyledIcon>
        </StyledNavLink>
      </Tooltip>
    )
  })

  return (
    <Column>
      <Header>
        <StyledIcon>
          <Icon
            icon={icon}
            size={iconSize}
            color={'themeColor'}
          />
        </StyledIcon>
      </Header>
      <LinkColumn left={props.left}>
        {links}
      </LinkColumn>
      {props.left &&
        <StyledExpander
          expanded={navExpanded}
          onClick={() => toggleUserPref(UserPrefs.NavExpanded)}
        >
          <Icon
            icon={'expand_less'}
            size={iconSize}
          />
        </StyledExpander>
      }
    </Column>
  )
}

export default NavColumn

const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${props => props.theme.header.height};
  background: ${props => props.theme.colors.themeBackground};
  color: ${(props) => props.theme.colors.themeColor};
`

const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  box-shadow: ${props => props.left ? 'none' : 'inset -1px 0px 1px 0px rgba(0, 0, 0, 0.1)'};
`

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.header.height};
  color: ${props => props.selected ? props.theme.colors.themeBackground : '#FFF'};
  background: ${props => props.selected ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
  font-weight: ${props => props.selected ? 'bold' : 'normal'};
  text-decoration: none;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    color: ${props => props.theme.colors.themeBackground};
  }  
`

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.header.height};
  height: ${props => props.theme.header.height};
`


const LinkText = styled(StyledText)`
  color: ${props => props.selected ? props.theme.colors.themeBackground : props.theme.colors.white};
  white-space: nowrap;
`
//  color: inherit;


const StyledExpander = styled(StyledIcon)`
  align-self: flex-end;
  color: ${props => props.theme.colors.white};
  transform: ${props => props.expanded ? 'rotate(-90deg)' : 'rotate(90deg)'};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.themeBackground};
  }
`
