import React from 'react'
import styled from 'styled-components'

import { instanceOneOf } from '../../Theme'

//import ZiiCON from '../../images/ZiiCON.png'
import railscreen from '../../images/railscreen.png'
import epamedia from '../../images/epamedia.png'
//import marty from '../../images/spar.png'
import marty from '../../images/marty.png'
import showcase from '../../images/showcase.png'

function HeaderLogo(props) {
  const handleClick = (event) => {
    event.preventDefault()
    props.handleClick()
  }

  let src
  if(instanceOneOf('epamedia'))
    src = epamedia
  else if(instanceOneOf('marty'))
    src = marty
  else if(instanceOneOf('showcase'))
    src = showcase
  else
    src = railscreen

  return (
    <Wrapper>
      <Button onClick={handleClick}>
        <Image src={src} alt={'logo'} />
      </Button>
    </Wrapper>
  )
}

export default HeaderLogo

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  height: ${(props) => props.theme.header.height};
`

const Button = styled.div`
  cursor: pointer;
  height: ${(props) => props.theme.header.height};
`

const Image = styled.img`
  height: calc(${(props) => props.theme.header.height} - ${(props) => props.theme.header.logoMarginTop} - ${(props) => props.theme.header.logoMarginBottom});
  margin-top: ${(props) => props.theme.header.logoMarginTop};
  margin-bottom: ${(props) => props.theme.header.logoMarginBottom};
  margin-left: ${(props) => props.theme.header.logoMarginLeft};
  user-select: none;
`
