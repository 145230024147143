import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Tooltip from '../shared/Tooltip'
import { cLoopLength } from '../../Theme'
import { StyledText } from '../styling/TextStyling'

import { fromDbTimestamp, getStartOfMonth } from '../../utils/TimestampHelper'

const Load = {
  Booked: 0,
  Reserved: 1,
  Quoted: 2,
}

function LoadMap(props) {
  const { t } = useTranslation()

  const [element, setElement] = useState(null)
  const handleRef = (element) => setElement(element ? element : null)
  const [width, setWidth] = useState(0)
  useLayoutEffect(() => {
    if(element)
      setWidth(element.offsetWidth)
  }, [element])

  const [tooltipIndex, setTooltipIndex] = useState(0)
  const [tooltipData, setTooltipData] = useState([])

  const heatMapColorforValue = (value) => {
    var h = 1.0 - value / 100.0
    h = h * h * 120
    return "hsl(" + h + ", 70%, 50%)";
  }

  //https://dev.to/stories_of_ren/how-to-build-a-simple-stacked-bar-chart-with-html-css-in-react-1jao
  const loads = props.data.map((c, i) => {
    const { booked, vacant, occupancy, quotations, vacancies } = loadData(c, props.maxLoad)

    const toUpper = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
    const format = (text, abs, max) => {
      return [text,
              cLoopLength ? abs : '',
              (cLoopLength ? ' (' : '') +
              Math.round(100 * abs / max) + '%' +
              (cLoopLength ? ')' : '')]
    }

    return (
      <StyledBar
        key={i}
        style={{background: heatMapColorforValue(occupancy)}}
        onMouseEnter={() => {
          const ts = new Date(props.request.start_timestamp)
          switch(props.resolution) {
          case 1:
            ts.setDate(ts.getDate() + 7 * i)
            break
          case 2:
            ts.setTime(getStartOfMonth(ts, i).getTime())
            break
          default:
            ts.setDate(ts.getDate() + i)
          }
          const date = fromDbTimestamp(ts)

          setTooltipIndex(i)
          setTooltipData([
            [date, props.maxLoad, '(100%)'],
            format(t('shared.vacant'), vacant, props.maxLoad),
            vacancies < 1 ? [toUpper(t('shared.quoted')), '', 'n.a'] : format(toUpper(t('shared.quoted')), c[Load.Quoted], vacant),
            format(t('shared.occupied'), booked, props.maxLoad),
            format(toUpper(t('shared.booked')), c[Load.Booked], props.maxLoad),
            format(t('shared.reserved'), c[Load.Reserved], props.maxLoad),
          ])
        }}
      >
        <StyledBarData style={{height:`${vacancies}%`, background: 'transparent'}}/>
        <StyledBarData style={{height:`${quotations}%`, background: 'rgba(0, 0, 0, 0.15)'}}/>
        <StyledBarData style={{height:`${occupancy}%`, background: 'transparent', borderBottom: '1px solid rgba(0, 0, 0, 0.001)'}}/>
        <StyledLabel dark small>{Math.round(occupancy) + '%'}</StyledLabel>
      </StyledBar>
    )
  })

  const tooltipContent = (
    <StyledTable>
      <tbody>
        {tooltipData.map((data, i) => {
          const indented = (i === 2 || 3 < i)
          return (
            <StyledTr key={i}>
              <StyledTd>
                <StyledData bold={i === 0} indented={indented}>
                  {indented && <>&bull;&nbsp;</>}{data[0] + (i === 0 ? '' : ':')}
                </StyledData>
              </StyledTd>
              <StyledTd>
                <StyledData bold={i === 0} right mid>{data[1]}</StyledData>
              </StyledTd>
              <StyledTd>
                <StyledData bold={i === 0} right>{data[2]}</StyledData>
              </StyledTd>
            </StyledTr>
          )
        })}
      </tbody>
    </StyledTable>
  )

  return (
    <div ref={handleRef} style={{width: '100%', height: '100%'}}>
      <Tooltip
        newButton
        noDelay
        suppressHideOnEnter
        height={100}
        offsetY={0}
        offsetX={-tooltipIndex * (width / props.data.length)}
        arrowTopMargin={-38}
        content={tooltipContent}
      >
        <StyledContainer>
          {loads}
        </StyledContainer>
      </Tooltip>
    </div>
  )
}

export default LoadMap

const StyledContainer = styled.div`
  display: flex;
`

const StyledBar = styled.span`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: black;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;

  &:hover {
    filter: brightness(110%);
  }
`

const StyledBarData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledLabel = styled(StyledText)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledTable = styled.table`
  border-collapse: collapse;
  padding: 0px;
`

const StyledTr = styled.tr`
`

const StyledTd = styled.td`
  margin: 0px;
  padding: 0px;
`

const StyledData = styled(StyledText)`
  text-indent: ${props => props.indented ? '10px' : '0'};
  margin-left: ${props => {
    if(!props.right)
      return '0'
    else if(props.mid)
      return '25px'
    else
      return '10px'
  }};
`

export const loadData = (c, maxLoad, load) => {
  const booked = c[Load.Booked] + c[Load.Reserved] + (load ? load : 0)
  const vacant = Math.max(0, maxLoad - booked)
  // The three bars of the bar chart
  const occupancy = 100.0 * (booked / maxLoad)
  const quotations = 100.0 * (Math.min(c[Load.Quoted], vacant) / maxLoad)
  const vacancies = 100.0 - quotations
  // By how much do the quotations exceed the available vacant load?
  const overquotations = vacant < 1 ? -1 : 100.0 * (c[Load.Quoted] / vacant)
  return {
    booked,
    vacant,
    occupancy,
    quotations,
    vacancies,
    overquotations,
  }
}
