import React from 'react'

import { StyledLabel } from '../../styling/InputStyling'

function Clusters(props) {
  const clusters = props.clusters.map((cluster, index) => {
    if(!cluster.player_ids.length)
      return null
    
    return (
      <div key = {cluster.id}>
        <input
          type = 'checkbox'
          checked = {cluster.checked}
          onChange = {() => props.handleClusterChange(index)}
        />
        <StyledLabel>{cluster.name}</StyledLabel>
      </div>
    )
  })

  return (
    <div style = {{
      padding: 10
    }}>
      {clusters}
    </div>
  )
}

export default Clusters
