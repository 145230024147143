import React from 'react'
import { useTranslation } from 'react-i18next'

import Permissions from './Permissions'
import Icon from '../shared/Icon'
import LinkButton from '../shared/LinkButton'
import Select from '../shared/Select'

import Table from '../shared/Table'
import { useDataInterface } from '../shared/DataInterface'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Users() {
  const { t } = useTranslation();
  const { endPointFromQueryPath } = useQueryEndPoint()

  const endPoint = endPointFromQueryPath('admin/users?extended=1')
  const translationPrefix = 'admin.users'

  const teamDataInterface = useDataInterface({ endPoint: 'admin/teams' })
  const groupDataInterface = useDataInterface({ endPoint: 'admin/groups' })

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
    
      columns={[
//        {
//          header: t(translationPrefix + '.avatar'),
//          defaultProperty: 'avatar_url',
//          style: {justifyContent: 'center'},
//        },
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name'
        },
        {
          header: t(translationPrefix + '.email'),
          defaultProperty: 'email'
        },
        {
          header: t(translationPrefix + '.agent'),
          isHidden: () => true,
          defaultProperty: 'agent',
          sortStringFunction: (data) => (data['agent'] === '1' ? 'a' : 'b') + data['name'],
          style: {justifyContent: 'center'},
          visualizationFunction: (data) => {
            if(data['agent'] === 1)
              return <Icon icon={'done'} color={'black'} size={14} />
            else
              return null
          },
        },
        {
          header: t(translationPrefix + '.team'),
          defaultProperty: 'team_name',
          sortStringFunction: (data) => {
            return data['team_name'] ?
              'a' + data['team_name'] + data['name'] : 'b' + data['name']
          },
          visualizationFunction: (data) => {
            return data['team_name'] && (
              <div>
                <LinkButton
                  type={'team'}
                  text={data['team_name']}
                  target={'admin/teams'}
                  param={'id'}
                  value={data['team_id']}
                />
              </div>
            )
          }
        },
        {
          header: t(translationPrefix + '.usergroup'),
          defaultProperty: 'usergroup',
          sortStringFunction: (data) => {
            return data['group_name'] ?
              'a' + data['group_name'] + data['name'] : 'b' + data['name']
          },
          visualizationFunction: (data) => {
            return data['group_name'] && (
              <div>
                <LinkButton
                  type={'usergroup'}
                  text={data['group_name']}
                  target={'admin/groups'}
                  param={'id'}
                  value={data['client_group_id']}
                />
              </div>
            )
          }
        },
        {
          header: t(translationPrefix + '.bookings'),
          defaultProperty: 'bookings',
          style: {justifyContent: 'center'},
        }
      ]}
    
      fields={[
        {
          name: t(translationPrefix + '.name'),
          property: 'name',
          maxLength: 128,
          validator: 'name'
        },
        {
          name: t('shared.gender'),
          property: 'gender',
          defaultValue: 'neutral',
          input: (inputs, handleInputChange) => (
            <Select
              value={inputs['gender']}
              onChange={handleInputChange}
              name='gender'
              options={[
                { value: 'male', text: t('shared.male') },
                { value: 'female', text: t('shared.female') },
                { value: 'neutral', text: t('shared.neutral') },
              ]}
            />
          ),
        },
        {
          name: t(translationPrefix + '.email'),
          property: 'email',
          maxLength: 128,
          validator: 'email'
        },
        {
          name: t(translationPrefix + '.agent'),
          isActive: () => false,
          property: 'agent',
          type: 'int',
          defaultValue: 0,
          input: (inputs, handleInputChange) => (
            <Select
              value={inputs['agent']}
              onChange={handleInputChange}
              name='agent'
              options={[
                { value: 0, text: t('shared.no') },
                { value: 1, text: t('shared.yes') },
              ]}
            />
          ),
        },
        {
          name: t(translationPrefix + '.team'),
          property: 'team_id',
          type: 'int',
          defaultValue: null,
          input: (inputs, handleInputChange) => (
            <Select
              searchable
              value={inputs['team_id']}
              onChange={handleInputChange}
              name='team_id'
              options={[{ value: null, text: t(translationPrefix + '.no_team') }].concat(
                teamDataInterface.data.map(team => ({ value: team.id, text: team.name }))
              )}
            />
          ),
        },
        {
          name: t(translationPrefix + '.usergroup'),
          property: 'client_group_id',
          type: 'int',
          defaultValue: -1,
          validator: 'selected',
          input: (inputs, handleInputChange) => (
            <Select
              searchable
              value={inputs['client_group_id']}
              onChange={handleInputChange}
              name='client_group_id'
              options={groupDataInterface.data.map(group =>
                ({ value: group.id, text: group.name })
              )}
            />
          ),
        },
        {
          name: t(translationPrefix + '.permissions'),
          isActive: (inputs) => false && Number.isNaN(Number.parseInt(inputs['client_group_id'])),
          input: (inputs, handleInputChange) => {
            return Number.isNaN(Number.parseInt(inputs['client_group_id'])) && (
              <Permissions
                inputs={inputs}
                handleInputChange={handleInputChange}
              />
            )
          }
        }
      ]}
    />
  )
}

export default Users
