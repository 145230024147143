import { useCallback, useEffect, useState } from 'react'

import { instanceOneOf } from '../../Theme'
import { useDataInterface } from './DataInterface'

// HACK - kosherPlayers, firstDayOfWeek
const kosherPlayers = instanceOneOf('marty') ? [2] : []

function usePlayerData(auth, usedPlayers, selectedBookingUnitId, filtered) {
  // Per default the queries to the data interfaces use filtered=1
  const query = filtered === undefined || filtered === true ? '?filtered=1' : ''

  // DataInterfaces
  const clusterDataInterface = useDataInterface({ endPoint: 'admin/clusters' + query,
                                                  externalAuth: auth })

  const siteDataInterface = useDataInterface({ endPoint: 'admin/sites' + query,
                                               externalAuth: auth })

  const playerDataInterface = useDataInterface({ endPoint: 'admin/players' + query,
                                                 externalAuth: auth })

  // Data structures
  const [initialized, setInitialized] = useState(false)
  const [dataId, setDataId] = useState(0)
  const [clusters, setClusters] = useState([])
  const [sites, setSites] = useState([])
  const [players, setPlayers] = useState([])
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(1)

  // ATTEMPTED HACK TO FIX CHANGING id
  // changed all occurences of .id to ._id
  // applying this change here as well as in SitesAndPlayers is a HACK but also a working solution

  // Initialize required properties for tree view
  const initRaw = useCallback((data) => {
    data.forEach(item => {
      //item.id = item._id // ATTEMPTED HACK TO FIX CHANGING id
      item['collapsed'] = true
      item['checked'] = false
      item['intermediate'] = false
    })
  },[])

  // Based on the state of the provided players, update the state of firstDayOfWeek
  const updateFirstDayOfWeek = useCallback(players => {
    let hasNonKosher = false
    let hasNonKosherChecked = false
    let hasKosherChecked = false

    players.forEach(player => {
      if(!kosherPlayers.includes(player.id)) {
        hasNonKosher = true
        if(player.checked)
          hasNonKosherChecked = true
      } else {
        if(player.checked)
          hasKosherChecked = true
      }
    })

    setFirstDayOfWeek(!hasNonKosher || (!hasNonKosherChecked && hasKosherChecked) ? 0 : 1)
  }, [])

  // Based on the state of the provided players, update the state of the sites
  const updateSites = useCallback((sites, players) => sites.forEach((site, index) => {
    let count = 0
    let checked = 0
    players.forEach(player => {
      if(player.site_id === site._id) {
        count++
        if(player.checked)
          checked++
      }
    })
    const intermediate = count && checked && checked !== count ? true : false
    site.intermediate = intermediate
    if((!site.checked && count && count === checked) ||
       (site.checked && count && checked === 0))
      site.checked = !site.checked
  }), [])
  
  // Based on the state of the provided players, update the state of the clusters
  const updateClusters = useCallback((clusters, players) => clusters.forEach((cluster, index) => {
    let checked = true
    const clusterPlayerIds = cluster.player_ids
    players.forEach(player => {
      if(clusterPlayerIds.includes(player._id) && !player.checked)
        checked = false
    })
    cluster.checked = checked
  }), [])

  // Upon mount or change of usedPlayers, initialize the data structures
  useEffect(() => {
    // Initialize data strutures once all data interfaces have delivered data
    if(!initialized &&
       0 < clusterDataInterface.dataId &&
       0 < siteDataInterface.dataId &&
       0 < playerDataInterface.dataId) {
      // ATTEMPTED HACK TO FIX CHANGING id
      // Always initialize data strutures, what to do in the else case?
      //setInitialized(true)

      // Init players
      const players = [...playerDataInterface.data]
      initRaw(players)
      players.forEach(player => {
        player.checked = player._id in usedPlayers ? true : false
      })
      setPlayers(players)
      
      // Init sites
      const sites = [...siteDataInterface.data]
      initRaw(sites)
      updateSites(sites, players)
      setSites(sites)

      // Init clusters
      const clusters = [...clusterDataInterface.data]
      initRaw(clusters)
      updateSites(clusters, players)
      setClusters(clusters)

      // Init firstDayOfWeek
      updateFirstDayOfWeek(players)

      // ATTEMPTED HACK TO FIX CHANGING id
      // Increment data id
      //setDataId(new Date().getTime())
    }
    else if(initialized) {
      console.log('TODO ... but what?')
    }
    // Disable warnings for dependencies on data of each data interface - depend
    // on the respective dataId instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedPlayers, initialized, setInitialized, clusterDataInterface.dataId,
      siteDataInterface.dataId, playerDataInterface.dataId, initRaw,
      updateSites, updateClusters, updateFirstDayOfWeek])

  // Update usedPlayers based on the state of players
  const updateUsedPlayers = useCallback((player) => {
    const playerId = player._id
    const checked = player.checked
    if(playerId in usedPlayers) {
      usedPlayers[playerId].checked = checked
    }
    else {
      usedPlayers[playerId] = {
        booking_unit_id: selectedBookingUnitId,
        player_id: playerId,
        resolution_id: player.resolution_id,
        isUpdate: false,
        checked: checked
      }
    }
  }, [usedPlayers, selectedBookingUnitId])

  const handleClusterChange = useCallback((index) => {
    const updatedClusters = [...clusters]
    const updatedSites = [...sites]
    const updatedPlayers = [...players]

    // Apply change to cluster
    updatedClusters[index].checked = !updatedClusters[index].checked

    // Update players
    const clusterChecked = updatedClusters[index].checked
    const clusterPlayerIds = updatedClusters[index].player_ids
    updatedPlayers.forEach((player, playerIndex) => {
      if(clusterPlayerIds.includes(player._id) && player.checked !== clusterChecked) {
        if(clusterChecked) {
          player.checked = !player.checked
          updateUsedPlayers(player)
        }
        else {
          let otherClusterChecked = false
          updatedClusters.forEach(cluster => {
            if(cluster.checked && cluster.player_ids.includes(player._id))
              otherClusterChecked = true
          })
          if(!otherClusterChecked) {
            player.checked = !player.checked
            updateUsedPlayers(player)
          }
        }
      }
    })

    // Update sites and clusters
    updateSites(updatedSites, updatedPlayers)
    updateClusters(updatedClusters, updatedPlayers)

    // Save update to state
    setClusters(updatedClusters)
    setSites(updatedSites)
    setPlayers(updatedPlayers)
    updateFirstDayOfWeek(updatedPlayers)
    setDataId(new Date().getTime())
  }, [clusters, sites, players, updateUsedPlayers, updateSites,
      updateClusters, updateFirstDayOfWeek, setDataId])
  
  const handleSiteChange = useCallback((index) => {
    const updatedClusters = [...clusters]
    const updatedSites = [...sites]
    const updatedPlayers = [...players]

    const siteChecked = !sites[index].checked
    const siteId = sites[index]._id
    updatedPlayers.forEach((player, playerIndex) => {
      if(player.site_id === siteId && player.checked !== siteChecked) {
        player.checked = !player.checked
        updateUsedPlayers(player)
      }
    })

    // Update sites and clusters
    updateSites(updatedSites, updatedPlayers)
    updateClusters(updatedClusters, updatedPlayers)

    // Save update to state
    setClusters(updatedClusters)
    setSites(updatedSites)
    setPlayers(updatedPlayers)
    updateFirstDayOfWeek(updatedPlayers)
    setDataId(new Date().getTime())
  }, [clusters, sites, players, updateUsedPlayers, updateSites,
      updateClusters, updateFirstDayOfWeek, setDataId])

  const handlePlayerChange = useCallback((index) => {
    const updatedClusters = [...clusters]
    const updatedSites = [...sites]
    const updatedPlayers = [...players]
    
    updatedPlayers[index].checked = !updatedPlayers[index].checked
    updateUsedPlayers(updatedPlayers[index])
    
    // Update sites and clusters
    updateSites(updatedSites, updatedPlayers)
    updateClusters(updatedClusters, updatedPlayers)

    // Save update to state
    setClusters(updatedClusters)
    setSites(updatedSites)
    setPlayers(updatedPlayers)
    updateFirstDayOfWeek(updatedPlayers)
    setDataId(new Date().getTime())
  }, [clusters, setClusters, players, setPlayers, sites, setSites,
      updateUsedPlayers, updateSites, updateClusters, updateFirstDayOfWeek,
      setDataId])

  const handleSiteExpanderClick = useCallback((index) => {
    const updatedSites = [...sites]
    updatedSites[index].collapsed = !updatedSites[index].collapsed
    // ATTEMPTED HACK TO FIX CHANGING id
    //console.log("DATA " + JSON.stringify(siteDataInterface.data))
    //console.log("SITES " + JSON.stringify(updatedSites))
    //console.log("UPDATE " + JSON.stringify(updatedSites))
    setSites(updatedSites)
    setDataId(new Date().getTime())
  }, [sites, setSites])
  // ATTEMPTED HACK TO FIX CHANGING id
  //}, [sites, setSites, siteDataInterface.data])

  return {
    playerDataId: dataId,
    clusters: clusters,
    sites: sites,
    players: players,
    firstDayOfWeek: firstDayOfWeek,
    handleClusterChange: handleClusterChange,
    handleSiteChange: handleSiteChange,
    handlePlayerChange: handlePlayerChange,
    handleSiteExpanderClick: handleSiteExpanderClick
  }
}


function useResolutions(players, hasUpload, canUpload) {
  const [resolutions, setResolutions] = useState(hasUpload ? [] : undefined)

  useEffect(() => {
    if(hasUpload) {
      if(!canUpload) {
        setResolutions([])
      } else {
        const reducer = (accumulator, player) => {
          if (player.checked && !accumulator.has(player.resolution_id))
            accumulator.set(player.resolution_id, player.resolution)
          return accumulator
        }
        const map = players.reduce(reducer, new Map())
        setResolutions(
          Array.from(map, ([key, value]) => ({
            resolutionId: key,
            resolution: value,
          }))
        )
      }
    }
  }, [players, hasUpload, canUpload])

  return resolutions
}


export { usePlayerData, useResolutions, kosherPlayers }
