import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import InputContainer from '../../shared/InputContainer'
import { StyledText } from '../../styling/TextStyling'

import { localePrice } from '../../../utils/Locale'

function InvoicePreview(props) {
  const { t } = useTranslation()
  const { dark, showsDiscounted, header, hasZeroPrice, price, spotOrigin } = props

  const priceText = t('shared.ad_fee' + (hasZeroPrice ? '_none' : showsDiscounted ? '_discounted' : ''))
  let adTaxText = t('reporting.accounting.ad_tax')
  adTaxText = adTaxText.substring(0, adTaxText.length - 1)

  const adTax = 0.05 * price
  const handlingFee = !spotOrigin || spotOrigin === 'reuse' ? 0.0 : 20.0
  const productionFee = !spotOrigin || spotOrigin !== 'production' ? 0.0 : 30.0
  const net = price + adTax + handlingFee + productionFee
  const vat = 0.2 * net
  const gross = net + vat

  return (
    <InputContainer
      dark={dark}
      marginTop={showsDiscounted ? undefined : '20px'}
      marginBottom={'20px'}
      header={header}
    >
      <StyledTable>
        <tbody>
          <StyledTr>
            <StyledLeft>
              <StyledText large dark>{priceText}</StyledText>
            </StyledLeft>
            <StyledRight>
              <StyledText large dark right>{localePrice(price, 0)}</StyledText>
            </StyledRight>
          </StyledTr>
          {!hasZeroPrice && (
            <StyledTr>
              <StyledLeft>
                <StyledText large dark>{adTaxText}</StyledText>
              </StyledLeft>
              <StyledRight>
                <StyledText large dark right>{localePrice(adTax, 0)}</StyledText>
              </StyledRight>
            </StyledTr>
          )}
          {0 < handlingFee && (
            <StyledTr>
              <StyledLeft>
                <StyledText large dark>{t('shared.handling_fee')}</StyledText>
              </StyledLeft>
              <StyledRight>
                <StyledText large dark right>{localePrice(handlingFee, 0)}</StyledText>
              </StyledRight>
            </StyledTr>
          )}
          {0 < productionFee && (
            <StyledTr>
              <StyledLeft>
                <StyledText large dark>{t('shared.production_fee')}</StyledText>
              </StyledLeft>
              <StyledRight>
                <StyledText large dark right>{localePrice(productionFee, 0)}</StyledText>
              </StyledRight>
            </StyledTr>
          )}
          <StyledTr>
            <StyledLeft>
              <StyledText large dark bold>{t('reporting.accounting.net')}</StyledText>
            </StyledLeft>
            <StyledRight>
              <StyledText large dark bold right>{localePrice(net, 0)}</StyledText>
            </StyledRight>
          </StyledTr>
          <StyledTr>
            <StyledLeft>
              <StyledText large dark>{t('reporting.accounting.vat')}</StyledText>
            </StyledLeft>
            <StyledRight>
              <StyledText large dark right>{localePrice(vat, 0)}</StyledText>
            </StyledRight>
          </StyledTr>
          <StyledTr>
            <StyledLeft>
              <StyledText large dark bold>{t('reporting.accounting.total')}</StyledText>
            </StyledLeft>
            <StyledRight>
              <StyledText large dark bold right>{localePrice(gross, 0)}</StyledText>
            </StyledRight>
          </StyledTr>
        </tbody>
      </StyledTable>
    </InputContainer>
  )
}

export default InvoicePreview

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  padding: 15px;
`

const StyledTr = styled.tr`
  width: 100%;
`

const StyledLeft = styled.td`
  width: 50%;
`

const StyledRight = styled.td`
  width: 50%;
`
