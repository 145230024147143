import React, { useCallback, useState } from 'react'
import { useModal } from "react-modal-hook"

import DataEdit from './DataEdit'
import ReactModalWrapper from './ReactModalWrapper'
import { initDefaults } from './DataProcessing'

function useDataInput(params) {
  const {
    translationPrefix,
    headerTranslationPostfix,
    fields,
    handleSaveEdit,
    customProcessBeforeSaveEdit,
    layout
  } = params

  const [editData, setEditData] = useState({})
  const [editIsUpdate, setEditIsUpdate] = useState(false)

  const intermediateHandleSaveEdit = (method, data) => {
    handleSaveEdit(method, data)
    hideModal()
  }
  
  // DataEdit as a modal dialog.
  const [showModal, hideModal] = useModal(() => (
    <ReactModalWrapper
      layout={layout}
      onRequestClose={hideModal}
    >
      <DataEdit
        isUpdate={editIsUpdate}
        fields={fields}
        data={editData}
        translationPrefix={translationPrefix}
        headerTranslationPostfix={headerTranslationPostfix}
        handleSaveEdit={intermediateHandleSaveEdit}
        handleCancelEdit={hideModal}
        customProcessBeforeSaveEdit={customProcessBeforeSaveEdit}
      />
    </ReactModalWrapper>
  ), [editData])
  
  // To be called by parent component to collect user input for the creation of
  // a new data item.
  const handleNew = useCallback(() => {
    setEditIsUpdate(false)
    setEditData(initDefaults(fields))
    showModal()
  }, [setEditIsUpdate, setEditData, fields, showModal])
  
  // To be called by parent component to edit an existing data item.
  const handleEdit = useCallback((rowData) => {
    setEditIsUpdate(true)
    setEditData(rowData)
    showModal()
  }, [setEditIsUpdate, setEditData, showModal])

  return {
    handleNew: handleNew,
    handleEdit: handleEdit
  }
}

export { useDataInput }
