import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { currencySymbol } from './Locale'
import { instanceConfig } from '../Theme'

export function useInvoiceStrings() {
  const { t } = useTranslation();

  const strings = useMemo(() => {
    return {
      booking_id: t('shared.booking_id'),
      booked: t('shared.booked'),
      campaign: t('shared.campaign'),
      customer: t('shared.customer'),
      details: t('shared.details'),
      discount: t('shared.discount'),
      discount_disclaimer: t('reporting.accounting.discount_disclaimer'),
      discount_info: t('reporting.accounting.discount_info'),
      invoice: t('shared.invoice'),
      item: t('shared.item'),
      handling_fee: t('shared.handling_fee'),
      list_price: t('shared.list_price'),
      net: t('reporting.accounting.net'),
      num_spots: t('reporting.accounting.num_spots'),
      number_abbreviated: t('shared.number_abbreviated'),
      page: t('shared.page'),
      period: t('shared.period'),
      play_count: t('shared.play_count'),
      players: t('shared.player') + ' / ' + t('shared.sites'),
      price: t('shared.price'),
      price_after_discount: t('shared.price_after_discount'),
      production_fee: t('shared.production_fee'),
      quotation: t('shared.quotation'),
      repeats_per_day: t('reporting.accounting.repeats_per_day'),
      resolution: t('shared.resolution'),
      seconds: t('shared.seconds'),
      spot_length: t('reporting.accounting.spot_length'),
      total: t('reporting.accounting.total'),
      total_amount: t('shared.total_amount'),
      ad_tax: t('reporting.accounting.ad_tax'),
      vat: t('reporting.accounting.vat'),
      currency: currencySymbol(),
      instance_config: instanceConfig(),
    }
  }, [t])

  return strings;
}
