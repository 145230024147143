import { nanoid } from 'nanoid'
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import request from './request'

const logUploadedFile = (num, color = 'green') => {
  const msg = `%cUploaded ${num} files.`
  const style = `color:${color};font-weight:bold;`
  console.log(msg, style)
}

const SpotContext = React.createContext({})

SpotContext.displayName = 'SpotContext'

// Constants
const LOADED = 'LOADED'
const INIT = 'INIT'
const PENDING = 'PENDING'
const FILES_UPLOADED = 'FILES_UPLOADED'
const UPLOAD_ERROR = 'UPLOAD_ERROR'

const MAX_DONE_FILES = 10

const debug = false
const debugSpot = {
  id: 'h0ZVReGlp2i1VqIQScDl0',
  bookingId: 57,
  bookingIdStr: 'Buchung 20200501-24',
  bookingName: 'WASM Test',
  bookingUnitId: undefined,
  metadata: {
    width: 604,
    height: 324,
    name: 'graz.mov',
  },
}
const debugSpots = [
  debugSpot,
  {
    ...debugSpot,
    id: 'h0ZVReGlp2i1VqIQScDl1',
    metadata: {
      name:
        'another_and_very_much_extremely_long_file_name_that_is_longer_than_normal.mov',
      width: 324,
      height: 604,
    },
  },
]

const initialState = {
  files: [],                           // files loaded for submission (cleared by onSubmit & onDeleteLoaded)
  loaded: [],                          // files loaded within session (cleared by onDeleteLoaded)
  pending: !debug ? [] : debugSpots,
  next: null,
  uploading: false,
  uploaded: {},
  lastBookingId: null,
  status: 'idle',
  done: !debug ? [] : debugSpots,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        files: action.files,
        loaded: action.loaded,
        status: LOADED,
      }
    case 'submit':
      return {
        ...state,
        files: [],
        pending: action.files,
        uploading: true,
        status: INIT,
      }
    case 'next':
      return {
        ...state,
        next: action.next,
        lastBookingId: null,
        status: PENDING,
      }
    case 'delete-loaded':
      return {
        ...state,
        files: [],
        loaded: [],
      }
    case 'file-uploaded':
      return {
        ...state,
        next: null,
        pending: action.pending,
        done: action.doneArray,
        uploaded: {
          ...state.uploaded,
          [action.prev.id]: action.prev,
        },
        lastBookingId: action.prev.bookingId,
      }
    case 'files-uploaded':
      return { ...state, uploading: false, lastBookingId: null, status: FILES_UPLOADED }
    case 'set-upload-error':
      return { ...state, uploadError: action.error, status: UPLOAD_ERROR }
    default:
      return state
  }
}

function SpotProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [message, setMessage] = useState(undefined)

  const [progress, setProgress] = useState(0)

  const isHtmlFileType = (file) => {
    return (
      file.type.includes('text') &&
      (file.name.endsWith('.html') || file.name.endsWith('.htm'))
    )
  }
  const isZiiconFileType = (file) => {
    return (
      file.type === '' &&
      (file.name.endsWith('.zfx') || file.name.endsWith('.zdc'))
    )
  }
  const ziiconFileType = (file) => {
    return file.name.endsWith('.zfx') ? 'ziicon/zfx' : 'ziicon/zdc'
  }

  const onSubmit = useCallback(
    (bookingId, bookingIdStr, bookingName, bookingUnitId, bookingUnitData) => {
      if (state.files.length) {
        const files = [...state.files]
        files.forEach(file => {
          file.bookingId = bookingId
          file.bookingIdStr = bookingIdStr
          file.bookingName = bookingName
          file.bookingUnitId = bookingUnitId
          file.bookingUnitData = bookingUnitData
        })
        dispatch({ type: 'submit', files })
      } else if (message !== undefined) {
        window.alert(message)
      }
    },
    [state.files, message]
  )

  const onChange = async (e, key, handleFile, resolutionId) => {
    if(e.target.files.length) {
      const arrFiles = Array.from(e.target.files)

      const promises = arrFiles.map(async (file, index) => {
        const src = window.URL.createObjectURL(file)

        const id = nanoid()

        if(file.type.includes('video')) {
          return extendMetaWithVideoDims(file, src, id, resolutionId)
        } else if(file.type.includes('image')) {
          return extendMetaWithImageDims(file, src, id, resolutionId)
        } else if(isHtmlFileType(file)) {
          return extendMetaWithTextDims(file, src, id, resolutionId)
        } else if(isZiiconFileType(file)) {
          return extendMetaWithZiiCONDims(file, src, id, resolutionId)
        } else {
          window.alert('Unsupported file type uploaded')
        }
      })

      const newFiles = await Promise.all(promises)

      if(handleFile)
        handleFile(key, newFiles[0].id, newFiles[0].metadata.name)

      const files = [...state.files, ...newFiles]
      const newLoaded = newFiles.map(file => ({ name: file.metadata.name, id: file.id }))
      const loaded = [...state.loaded, ...newLoaded]

      dispatch({ type: 'load', files, loaded })
    }
  }

  const onDeleteLoaded = async () => {
    dispatch({ type: 'delete-loaded' })
  }

  const onDelete = async (id) => {
    const fileIdx = state.files.findIndex((file) => file.id === id)
    const files = [...state.files]
    files.splice(fileIdx, 1)
    const loadedIdx = state.loaded.findIndex((loaded) => loaded.id === id)
    const loaded = [...state.loaded]
    loaded.splice(loadedIdx, 1)
    dispatch({ type: 'load', files, loaded })
  }

  const limitFilename = (filename) => {
	 const N = 64
	 const n = filename.length
	 if(N < n)
		return filename.substring(0, N/2 - 1) + "..." + filename.substring(n - (N/2 - 2))
    else
	   return filename
  }

  async function extendMetaWithVideoDims(file, src, id, resolutionId) {
    const video = document.createElement('video')
    video.id = `video-${id}`
    video.src = src
    video.style.visibility = 'hidden'

    document.body.appendChild(video)

    let videoEl = document.getElementById(`video-${id}`)

    const metaData = {
      mediaType: file.type,
      resolution_id: resolutionId === undefined ? null : resolutionId,
      name: limitFilename(file.name),
      src,
    }
    const fileData = {
      spot: file,
      id,
    }

    const promise = new Promise((resolve, reject) => {
      const onLoadedMetadata = (ev) => {
        if(timeout)
          clearTimeout(timeout)

        document.body.removeChild(videoEl)
        resolve({
          metadata: {
            ...metaData,
            height: video.videoHeight,
            width: video.videoWidth,
          },
          ...fileData,
        })
      }

      // If event loadedmetadata does not support video format, e.g. Microsoft WMV
      var timeout = setTimeout(function() {
        video.removeEventListener('loadedmetadata', onLoadedMetadata)
        resolve({
          metadata: {
            ...metaData,
            height: 0,
            width: 0,
          },
          ...fileData,
        })
      }, 100)

      videoEl.addEventListener('loadedmetadata', onLoadedMetadata)
    })

    return await Promise.resolve(promise)
  }

  async function extendMetaWithImageDims(file, src, id, resolutionId) {
    var image = new Image()
    image.src = src

    const promise = new Promise((resolve) => {
      image.addEventListener('load', () => {
        resolve({
          metadata: {
            height: image.height,
            width: image.width,
            mediaType: file.type,
            resolution_id: resolutionId === undefined ? null : resolutionId,
            name: limitFilename(file.name),
            src,
          },
          spot: file,
          id,
        })
      })
    })

    return await Promise.resolve(promise)
  }

  async function extendMetaWithTextDims(file, src, id, resolutionId) {
    const promise = new Promise((resolve) => {
      resolve({
        metadata: {
          height: 0,
          width: 0,
          mediaType: file.type,
          resolution_id: resolutionId === undefined ? null : resolutionId,
          name: limitFilename(file.name),
          src,
        },
        spot: file,
        id,
      })
    })

    return await Promise.resolve(promise)
  }

  async function extendMetaWithZiiCONDims(file, src, id, resolutionId) {
    const promise = new Promise((resolve) => {
      resolve({
        metadata: {
          height: 0,
          width: 0,
          mediaType: ziiconFileType(file),
          resolution_id: resolutionId === undefined ? null : resolutionId,
          name: limitFilename(file.name),
          src,
        },
        spot: file,
        id,
      })
    })

    return await Promise.resolve(promise)
  }

  // Sets the next file when it detects that its ready to go
  useEffect(() => {
    if (state.pending.length && state.next == null) {
      const next = state.pending[0]
      dispatch({ type: 'next', next })
    }
  }, [state.next, state.pending])

  const countRef = useRef(0)

  // Processes the next pending thumbnail when ready
  useEffect(() => {
    // Do not start upload when debugging with pending requests
    if(debug && state.pending.length)
      return

    if (state.pending.length && state.next) {
      let doneArray = [...state.done]

      const sendToService = async () => {
        try {
          let spotForm = new FormData()
          spotForm.append('spot', state.next.spot)
          spotForm.append(
            'metadata',
            new Blob([JSON.stringify(state.next.metadata)], {
              type: 'application/json',
            })
          )
          spotForm.append(
            'bookingUnitData',
            new Blob([JSON.stringify(state.next.bookingUnitData)], {
              type: 'application/json',
            })
          )

          setProgress(1)

          await request('/spot', {
            data: spotForm,
            method: 'POST',
            params: { bookingId: state.next.bookingId, bookingUnitId: state.next.bookingUnitId },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                const { loaded, total } = progressEvent

                let percentCompleted = loaded === total ? 100 : Math.round((loaded * 100) / total)

                setProgress(percentCompleted)
              }
            },
          })

          setProgress(0)

          const prev = state.next
          logUploadedFile(++countRef.current)
          const pending = state.pending.slice(1)

          doneArray.unshift(prev)
          doneArray.splice(MAX_DONE_FILES)

          dispatch({ type: 'file-uploaded', prev, doneArray, pending })
        } catch (error) {
          dispatch({ type: 'set-upload-error', error })
        }
      }

      sendToService()
    }
  }, [state.next, state.pending, state.done])

  // Ends the upload process
  useEffect(() => {
    if (!state.pending.length && state.uploading) {
      dispatch({ type: 'files-uploaded' })
    }
  }, [state.pending.length, state.uploading])

  return (
    <SpotContext.Provider
      value={{
        ...state,
        progress,
        setMessage,
        onSubmit,
        onChange,
        onDeleteLoaded,
        onDelete,
      }}
    >
      {props.children}
    </SpotContext.Provider>
  )
}

function useSpot() {
  const context = React.useContext(SpotContext)
  if (context === undefined) {
    throw new Error(`useSpot must be used within a SpotProvider`)
  }

  return context
}

export default SpotProvider

export { useSpot }
