import React from 'react'
import { useTranslation } from 'react-i18next'

import LinkButton from '../shared/LinkButton'
import Table from '../shared/Table'

import { StyledText } from '../styling/TextStyling'

import { useAuth } from '../auth/Authentication'
import { useDataInterface } from '../shared/DataInterface'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

import { fromDbTimestamp, toBookingId } from '../../utils/TimestampHelper'
import { instanceConfig } from '../../Theme'

function Reports() {
  const { t } = useTranslation();
  const { authCall, userHasPermissions } = useAuth();
  const { endPointFromQueryPath } = useQueryEndPoint()

  const endPoint = endPointFromQueryPath('reporting/reports')
  const translationPrefix = 'reporting.reports'
  
  // Data interfaces for customers, clients, and sectors
  const customerDataInterface = useDataInterface({ endPoint: 'admin/customers' })
  const idToName = (dataInterface, id) => {
    const item = dataInterface.data.find(item => item.id ===  id)
    return item ? item.name : ''
  }

  const handleReportingRequest = (id, data, action) => {
    const bookingId = toBookingId(data['creation_timestamp'], id)

    const options = {
      method: 'POST',
      responseType: 'blob',
      headers: { 'content-type': 'application/json' },
      data: {
        id: id,
        page: t('shared.page'),
        title: t('shared.playout_report'),
        customer: t('shared.customer'),
        campaign: t('shared.campaign'),
        booking_id: t('shared.booking_id'),
        player: t('shared.player') + ' / ' + t('shared.site'),
        resolution: t('shared.resolution'),
        period: t('shared.period'),
        play_count: t('shared.play_count'),
        booked: t('shared.booked'),
        actual: t('shared.actual'),
        ratio: t('shared.ratio'),
        instance_config: instanceConfig(),
      },
    }

    authCall('/reporting/final', [], options)
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        if(action === 'view') {
          const pdfWindow = window.open()
          pdfWindow.location.href = fileURL
        }
        else {
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.download = 'reporting_' + bookingId + '.pdf'
          fileLink.click()
        }

        URL.revokeObjectURL(fileURL)
      })
  }

  const customActions = [
    {
      type: 'view',
      permissionsOk: userHasPermissions('reporting/reports', 'view'),
      handleClick: (id, data) => handleReportingRequest(id, data, 'view')
    },
    {
      type: 'download',
      permissionsOk: userHasPermissions('reporting/reports', 'view'),
      handleClick: (id, data) => handleReportingRequest(id, data, 'download')
    }
  ]

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      rowHeight={110}
      noNewItemButton={true}
      standardActions={[]}
      customActions={customActions}

      columns={[
        {
          header: t(translationPrefix + '.idName'),
          style: {justifyContent: 'center'},
          defaultProperty: 'name',
          visualizationFunction: (data) => {
            const id = toBookingId(data['creation_timestamp'], data['id'])
            return (
              <div>
                <StyledText dark centered>{id}</StyledText>
                <br />
                <StyledText dark centered>{data['name']}</StyledText>
              </div>
            )
          }
        },
        {
          header: t(translationPrefix + '.customer'),
          defaultProperty: 'customer_id',
          sortStringFunction: (data) => {
            return idToName(customerDataInterface, data['customer_id'])
          },
          visualizationFunction: (data) => {
            const customer = idToName(customerDataInterface, data['customer_id'])
            
            return (
              <div>
                {(customer !== '') && 
                  <LinkButton
                    type={'customer'}
                    text={customer}
                    target={'admin/customers'}
                    param={'id'}
                    value={data['customer_id']}
                  />
                }
              </div>
            )
          }
        },
        {
          header: t(translationPrefix + '.period'),
          defaultProperty: 'start_timestamp',
          visualizationFunction: (data) => {
            const start = fromDbTimestamp(data['start_timestamp'])
            const end = fromDbTimestamp(data['end_timestamp'])
            return (<div>{start + ' - ' + end}</div>)
          }
        },
        {
          header: t(translationPrefix + '.playCount'),
          defaultProperty: 'play_count_target',
          visualizationFunction: (data) => {
            const target = data['play_count_target']
            const actual = data['play_count_actual']
            return (<div>{target + ' / ' + actual}</div>)
          }
        },
        {
          header: t(translationPrefix + '.ratio'),
          defaultProperty: 'play_count_target',
          sortStringFunction: (data) => {
            const target = data['play_count_target']
            const actual = data['play_count_actual']
            return (actual/target).toFixed(2)
          },
          visualizationFunction: (data) => {
            let target = data['play_count_target']
            let actual = 100 * data['play_count_actual']
            const ratio = target === 0 ? '100.00' : (actual/target).toFixed(2)
            return (<div>{ratio + '%'}</div>)
          }
        }
      ]}
    />
  )
}

export default Reports
