import React from 'react'
import { useTranslation } from 'react-i18next'

import Permission from './Permission'

import { navPermissionsWithCategories } from '../nav/Items'

import { PermissionsTable } from '../styling/PermissionsStyling'

function Permissions(props) {
  const { t } = useTranslation();

  const { inputs, handleInputChange } = props

  const permissions = navPermissionsWithCategories()

  const indexToName = (i) => {
    switch(i) {
    case 0: return 'permission_view'
    case 1: return 'permission_edit'
    case 2: return 'permission_add'
    default: return 'permission_delete'
    }
  }

  const handleHeaderClick = (name, index) => {
    const inputName = indexToName(index)
    let value = inputs[inputName]

    // If any permission under the header is unchecked, check all,
    // otherwise uncheck all
    const setItems = permissions.filter(permission => {
      return permission.name.startsWith(name + '.') && !(value & permission.flag)
    })
    const check = setItems.length === 0

    // Set or unset all bits matching the header
    permissions.forEach(permission => {
      if(permission.name.startsWith(name + '.')) {
        if(check)
          value &= ~permission.flag
        else
          value |= permission.flag
      }
    })

    const event = {
      persist: () => {},
      target: {
        name: inputName,
        value: value,
        type: 'int',
        checked: false
      }
    }

    handleInputChange(event)
  }
  
  const handlePermissionChange = (flag, index) => {
    const name = indexToName(index)
    const value = inputs[name] & flag ? inputs[name] & ~flag : inputs[name] | flag
    const event = {
      persist: () => {},
      target: {
        name: name,
        value: value,
        type: 'int',
        checked: false
      }
    }
    
    handleInputChange(event)
  }
  
  return (
    <PermissionsTable>
      {permissions.map((permission, index) => {
        const p = [
          0 < (inputs['permission_view'] & permission.flag),
          0 < (inputs['permission_edit'] & permission.flag),
          0 < (inputs['permission_add'] & permission.flag),
          0 < (inputs['permission_delete'] & permission.flag),
        ]

        return (
          <Permission
            key = {index}
            isHeader = {!permission.name.includes('.')}
            title = {t(permission.name + '.title')}
            changeAllText = {t('shared.change_all')}
            permissions = {p}
            handleHeaderClick = {(i) => handleHeaderClick(permission.name, i)}
            handleCellChange = {(i) => handlePermissionChange(permission.flag, i)}
          />
        )
      })}
    </PermissionsTable>
  )
}

export default Permissions
