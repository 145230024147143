import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import LoadMap from './LoadMap'
import { instanceOneOf } from '../../Theme'
import NewItemButton from '../shared/NewItemButton'
import Table from '../shared/Table'

import { useAuth } from '../auth/Authentication'

import {
  dateToString,
  fromDbTimestamp,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth
} from '../../utils/TimestampHelper'

function Load(props) {
  const { t } = useTranslation()
  const { clientId } = useAuth()

  // HACK - firstDayOfWeek
  const firstDayOfWeek = instanceOneOf('marty') && clientId === 17 ? 0 : 1

  const [request, setRequest] = useState(() => {
    const start = new Date()
    const end = new Date(start)
    end.setDate(end.getDate() + 21)
    return {
      resolution: 0,
      anchor_timestamp: start,
      start_timestamp: getStartOfWeek(start, 0, firstDayOfWeek),
      end_timestamp: getEndOfWeek(end, 0, firstDayOfWeek),
    }
  })

  const endPoint = 'content/load?filtered=1' +
        '&resolution=' + request.resolution +
        '&start_timestamp=' + dateToString(request.start_timestamp) +
        '&end_timestamp=' + dateToString(request.end_timestamp)
  const translationPrefix = 'content.load'

  const handleResolutionChange = (resolution, step) => {
    setRequest(prev => {
      let anchor = new Date(prev.anchor_timestamp)
      if(step !== undefined) {
        if(step < 0 && anchor.getMonth() === 0)
          anchor = new Date(anchor.getFullYear() + -1, 11, 1)
        else if(0 < step && anchor.getMonth() === 11)
          anchor = new Date(anchor.getFullYear() + 1, 0, 1)
        else
          anchor = new Date(anchor.getFullYear(), anchor.getMonth() + step, 1)
      }
      let start = new Date(anchor)
      let end = new Date(start)

      switch(resolution) {
      case 0:
        end.setDate(end.getDate() + 30)
        break

      case 1:
        end.setDate(end.getDate() + 24 * 7)
        start = getStartOfWeek(start, 0, firstDayOfWeek)
        end = getEndOfWeek(end, 0, firstDayOfWeek)
        break

      default:
        start = getStartOfMonth(start)
        end.setDate(start.getDate() + 365 - 15)
        end = getEndOfMonth(end)
      }

      return {
        resolution: resolution,
        anchor_timestamp: anchor,
        start_timestamp: start,
        end_timestamp: end,
      }
    })
  }

  const headerItems = (
    <>
      {Array(3).fill({}).map((x, i) => {
        const tag = i === 0 ? 'day' : i === 1 ? 'week' : 'month'
        return (
          <NewItemButton
            key={i}
            selected={request.resolution === i}
            selectedColor={'themeHighlightColor'}
            icon={'calendar_' + tag}
            text={t(translationPrefix + '.resolution_' + tag)}
            handleClick={() => handleResolutionChange(i)}
          />
        )
      })}
      <div style={{width: 1, height: '100%', background: 'rgba(0, 0, 0, 0.4)'}}/>
      <NewItemButton
        icon={'arrow_back_ios'}
        text={t(translationPrefix + '.step_back')}
        handleClick={() => handleResolutionChange(request.resolution, -1)}
      />
      <NewItemButton
        icon={'arrow_forward_ios'}
        text={t(translationPrefix + '.step_forward')}
        handleClick={() => handleResolutionChange(request.resolution, 1)}
      />
    </>
  )

  const loadHeader = t(translationPrefix + '.resolution_' + (request.resolution === 0 ? 'day' : request.resolution === 1 ? 'week' : 'month')) + ": " + fromDbTimestamp(request.start_timestamp) + " - " + fromDbTimestamp(request.end_timestamp)

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      noNewItemButton={true}
      categoryHeaderItem={headerItems}
      actionsHidden={true}
      rowHeight={40}
      standardActions={[]}

      columns = {[
        {
          header: t(translationPrefix + '.site'),
          defaultProperty: 'name',
          style: {width: 200},
        },
        {
          header: loadHeader,
          defaultProperty: 'players',
          style: {padding: 0},
          sortStringFunction: (data) => {
            return Math.max.apply(Math, data['load'][0])
          },
          visualizationFunction: (data) => {
            return (
              <LoadMap
                request={request}
                resolution={request.resolution}
                maxLoad={data['loop_length_secs']}
                data={data['load']}
              />
            )
          }
        }
      ]}

      fields = {[]}
    />
  )
}

export default Load
