import { parseDateRange } from '../../utils/TimestampHelper'


export function initDefaults(fields) {
  const value = { id: -1 }
  fields && fields.forEach(field => {
    if(field.property) {
      if(field.defaultValue === undefined)
        value[field.property] =  ''
      else
        value[field.property] = field.defaultValue
    }
  })
  return value
}
  
export function applyDefaults(data, fields) {
  fields && fields.forEach(field => {
    if(data[field.property] === null || data[field.property] === undefined) {
      if(field.defaultValue !== undefined)
        data[field.property] = field.defaultValue
      else
        data[field.property] = ''
    }
  })
}

export function parseFloatUsingLocale(val) {
  if(val === undefined)
    return 0
  if(typeof val === 'string' && (val.match(/,/g) || []).length === 1)
    val = val.replace(',','.')
  return parseFloat(val)
}

export function processInputs(data, fields) {
  fields.forEach(field => {
    if(field.type !== undefined) {
      var value
      switch(field.type) {
      case 'int':
        value = parseInt(data[field.property])
        data[field.property] = !isNaN(value) ? value : null
        break
        
      case 'float':
        value = parseFloatUsingLocale(data[field.property])
        data[field.property] = !isNaN(value) ? value : null
        break
        
      case 'array':
        data[field.property] = JSON.parse("[" + data[field.property] + "]")
        break
        
      default:
        if(typeof data[field.property] === 'string')
          data[field.property] = data[field.property].trim()
      }
    }
    // The default type is assumed to be string
    else {
      if(data[field.property] !== null
      && data[field.property] !== undefined
      && typeof data[field.property] !== 'string')
        data[field.property] = '' + data[field.property]
    } // end if
  })
}

function isValid(value, isString, validator) {
  switch(validator) {
  case 'date_range':
    const { start, end } = parseDateRange(value)
    return start && end && start <= end

  case 'email':
    // eslint-disable-next-line no-control-regex
    const e = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return isString && Array.isArray(value.match(e))

  case 'float':
    if(isString)
      return !isNaN(parseFloatUsingLocale(value))
    else
      return Number.isFinite(value)

  case 'float>0':
    if(isString) {
      const f = parseFloatUsingLocale(value)
      return !isNaN(f) && 0.0 < f
    } else {
      return Number.isFinite(value) && 0.0 < value
    }

  case 'int':
    if(isString)
      return !isNaN(parseInt(value))
    else
      return Number.isFinite(value) && Math.round(value) === value

  case 'int>0':
    if(isString) {
      const i = parseInt(value)
      return !isNaN(i) && 0 < i
    } else {
      return Number.isFinite(value) && 0 < value && Math.round(value) === value
    }

  case 'name':
    return (isString && 0 < value.trim().length) || (typeof value === 'number')

  case 'percent':
    if(isString) {
      //const p = /^0$|^[1-9][0-9]?$|^100$/
      //if(Array.isArray(value.match(p)))
      // Allow percent to have decimal places
      const f = parseFloatUsingLocale(value)
      return !isNaN(f) && 0.0 <= f && f <= 100.0
    } else {
      return Number.isFinite(value) && 0.0 <= value && value <= 100.0
    }

  case 'selected':
    return (isString && parseInt(value) !== -1) || (!isString && value && 0 < value)

  case 'text':
    return (isString && 0 < value.trim().length) || (typeof value === 'number')

  case 'time':
    const t = /^(24:00:00|(20|21|22|23|1[0-9]|0[0-9]):[0-5][0-9]:[0-5][0-9])$/
    return isString && Array.isArray(value.match(t))

  default:
    console.log('WARNING: unknown validator')
    return false
  }
}

function isValidDiscountInfo(data, property, value) {
  // If discount is updated (property === 'discount'), we need to check whether info is valid
  // in case discount is not zero
  const disc = property && property === 'discount' ? value : data['discount']
  const discIsString = typeof disc === 'string'
  const info = property && property === 'info' ? value : data['info']
  const infoIsString = typeof info === 'string'

  if(isValid(disc, discIsString, 'percent')) {
    const p = discIsString ? parseInt(disc) : disc
    return (p === 0 && infoIsString) ||
      isValid(info, infoIsString, 'text')
  }
  else {
    return infoIsString
  }
}

export function validateInputs(data, fields, property, value, hideErrors) {
  let valid = true

  fields.forEach(field => {
    // In case property matches field.property, we process an update of the
    // current field and need to process the incoming value, otherwise we
    // validate the value that was previously stored in data.
    const val = field.property === property ? value : data[field.property]
    const isString = typeof val === 'string'

    if(field.validator === 'discountInfo') {
      if(property === undefined || property === 'discount' || property === 'info') {
        if(!isValidDiscountInfo(data, property, value)) {
          field['valid'] = hideErrors !== undefined
          valid = false
        }
        else {
          field['valid'] = true
        }
      }
    }
    else if(!(isString && field.optional && val === '') &&
       field.validator !== undefined &&
       !isValid(val, isString, field.validator)) {
      field['valid'] = hideErrors !== undefined
      valid = false
    }
    else {
      field['valid'] = true
    }
  })

  return valid
}
