import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { processBookingUnitInputs, weekdaysAllMask } from '../../utils/BookingUnitHelper'
import { cBlocksOfPlayers, cDefaultSpotLength, cInvoicingEnabled, instanceOneOf } from '../../Theme'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'
import { useAuth } from '../auth/Authentication'
import CategoryHeader from '../nav/CategoryHeader'
import { useDataInterface } from '../shared/DataInterface'
import { processInputs } from '../shared/DataProcessing'
import { usePlayerData, useResolutions } from '../shared/PlayerData'
import { useSpot } from '../shared/SpotProvider'
import TextButton from '../shared/TextButton'
import { StyledColumnHeader } from '../styling/InputStyling'
import { StyledText } from '../styling/TextStyling'
import BookingUnitEdit from './booking/BookingUnitEdit'
import { useMetaDataInput } from './booking/MetaData'
import { MetaDataFlag } from './booking/MetaDataFields'
import { toBookingId } from '../../utils/TimestampHelper'

/*
When the user clicks save three things will happen subsequently:

1) - handleSave() will create a new booking using the bookingDataInterface
2) - bookingDataInterface.handleNewIds() prepares the data for the booking unit
     and stores it to the state in bookingUnitData
3) - useEffect() on bookingUnitData handles the change of data under 2) and creates
     the booking unit using bookingUnitsDataInterface
 */

function NewBooking(props) {
  const { t } = useTranslation()
  const { queryPath } = useQueryEndPoint()

  // Set to true to use defaults values
  const debug = false

  const [showPricing, setShowPricing] = useState(false)
  const {
    metaDataFields,
    inputFields,
    canSave,
    inputs,
    updateInput,
  } = useMetaDataInput(MetaDataFlag.IsNewBooking |
                       (showPricing ? MetaDataFlag.ShowPricing : 0) |
                       (instanceOneOf('marty') || !cInvoicingEnabled ? MetaDataFlag.ExcludeModeField : 0),
                      debug)
  const isQuotation = useCallback(() => inputs['state'] === 'quoted', [inputs])

  // Disable warning for setUsedPlayers being used - usePlayerData requires
  // usedPlayers to have state
  // eslint-disable-next-line no-unused-vars
  const [usedPlayers, setUsedPlayers] = useState([])
  const {
    clusters,
    sites,
    players,
    firstDayOfWeek,
    handleClusterChange,
    handleSiteChange,
    handlePlayerChange,
    handleSiteExpanderClick,
  } = usePlayerData(useAuth(), usedPlayers, /*selectedBookingUnitId*/ -1)
  const resolutions = useResolutions(players, true, !isQuotation())

  const [completed, setCompleted] = useState(false)
  const [editData, setEditData] = useState(() =>
    debug
      ? {
          spot_length_secs: cDefaultSpotLength,
          date_input_mode: 'range_and_individual',
          use_range: true,
          start_timestamp: new Date(2021, 10, 12, 0, 0, 0, 0),
          end_timestamp: new Date(2021, 10, 12, 23, 59, 59, 0),
          individual_dates: [new Date(2021, 10, 12, 0, 0, 0, 0)],
          weekdays: weekdaysAllMask(),
          hours: 17825791,
          repeats_per_day: !cBlocksOfPlayers ? 200 : 0,
          price: 0,
          zones: null,
        }
      : {
          spot_length_secs: cDefaultSpotLength,
          date_input_mode: 'range_and_individual',
          use_range: true,
          start_timestamp: null,
          end_timestamp: null,
          individual_dates: [],
          weekdays: weekdaysAllMask(),
          hours: 17825791,
          repeats_per_day: 0,
          price: 0,
          zones: null,
        }
  )

  // Booking data interface
  const bookingDataInterface = useDataInterface({
    endPoint: 'content/booking',
    autoUpdate: false,
    omitRefreshDataOnNewIds: true,
    handleNewIds: (data) => {
      // Process booking data
      const bookingData = { ...inputs }
      metaDataFields.customProcessBeforeSaveEdit(bookingData)
      processInputs(bookingData, metaDataFields.fields)

      // Complete and process booking unit data
      const bookingUnitData = { ...editData }
      bookingUnitData['booking_id'] = data.id
      bookingUnitData['bookingtype_id'] = bookingData.bookingtype_id
      bookingUnitData['bookingattributes'] = bookingData.bookingattributes
      bookingUnitData['sector_id'] = bookingData.sector_id
      bookingUnitData['state'] = bookingData.state
      if (typeof bookingUnitData['spot_length_secs'] === 'string')
        bookingUnitData['spot_length_secs'] = parseInt(
          bookingUnitData['spot_length_secs']
        )

      // Process data for web-service
      processBookingUnitInputs(bookingUnitData, usedPlayers)

      // Submit data
      setBookingUnitData(bookingUnitData)
    },
  })

  const spotHandling = useSpot()

  // Booking unit data interface
  const [bookingUnitData, setBookingUnitData] = useState(undefined)
  const [bookingUnitDataSaved, setBookingUnitDataSaved] = useState(false)
  const bookingUnitsDataInterface = useDataInterface({
    endPoint: 'content/bookingUnits',
    autoUpdate: false,
    omitRefreshDataOnNewIds: true,
    handleNewIds: async (data) => {
      const bookingId = bookingUnitData['booking_id']

      if(isQuotation()) {
        // Show quotations filtered by new bookingId
        queryPath('content/quotations', 'id', bookingId)
      } else {
        const bookingIdStr = t('shared.booking') + toBookingId(Date.now(), bookingId)
        const bookingName = inputs['name']
        const bookingUnitId = data.id
        const mergedBookingUnitData = { ...bookingUnitData, ...data }
        spotHandling.onSubmit(bookingId, bookingIdStr, bookingName,
                              bookingUnitId, mergedBookingUnitData)
        spotHandling.onDeleteLoaded()
        queryPath('content/bookings/booking', 'id', bookingId)
      }
    },
  })
  useEffect(() => {
    if (bookingUnitData && !bookingUnitDataSaved) {
      setBookingUnitDataSaved(true)
      bookingUnitsDataInterface.handleSaveEdit('POST', bookingUnitData)
    }
  }, [bookingUnitData, bookingUnitDataSaved, bookingUnitsDataInterface])

  // On submit by user
  const handleSave = (e) => {
    // Process meta data for creation of booking
    const bookingData = { ...inputs }
    metaDataFields.customProcessBeforeSaveEdit(bookingData)
    processInputs(bookingData, metaDataFields.fields)

    delete bookingData.id

    bookingDataInterface.handleSaveEdit('POST', bookingData)
  }

  // Transfer booking unit price to booking
  useEffect(() => {
    if (inputs['list_price'] !== editData.price) {
      updateInput('userEditedPrice', undefined)
      updateInput('list_price', editData.price)
    }
    // Enable pricing field once a price can be calculated
    if (!showPricing && 0 < editData.price) setShowPricing(true)
  }, [inputs, updateInput, editData.price, showPricing])

  // Visualization
  const saveButtonText = isQuotation()
        ? t('content.new.save_quotation')
        : t('content.new.save_booking')

  return (
    <Container>
      <StyledTextButtonContainer visible={canSave && completed}>
        <StyledTextButton medium bold upper green handleClick={handleSave}>
          {saveButtonText}
        </StyledTextButton>
      </StyledTextButtonContainer>
      <CategoryHeader items={[t('content.title'), instanceOneOf('marty') ? t('content.new.title_vacancy') : t('content.new.title')]} />
      <OuterMargin>
        <MetaDataContainer>
          <StyledColumnHeader completed={canSave}>
            <StyledText medium bold upper>
              {t('content.new.metadata')}
            </StyledText>
          </StyledColumnHeader>
          {inputFields}
        </MetaDataContainer>
        <BookingUnitContainer>
          <BookingUnitEdit
            newBooking
            leftEnabled={canSave}
            inputs={editData}
            setInputs={setEditData}
            clusters={clusters}
            handleClusterChange={handleClusterChange}
            sites={sites}
            handleSiteExpanderClick={handleSiteExpanderClick}
            handleSiteChange={handleSiteChange}
            handleCompletedChanged={setCompleted}
            players={players}
            firstDayOfWeek={firstDayOfWeek}
            resolutions={resolutions}
            handlePlayerChange={handlePlayerChange}
            translationPrefix={'content.booking.bookingUnits'}
          />
        </BookingUnitContainer>
      </OuterMargin>
    </Container>
  )
}

export default NewBooking

const Container = styled.div`
  width: 100%;
  margin: 0px 0px;
  overflow-y: auto;
`

const OuterMargin = styled.div`
  display: flex;
  width: 100%;
  margin: 0px 0px;
`

const MetaDataContainer = styled.div`
  width: calc(33.3% - 40px);
  padding: 20px;
`

const BookingUnitContainer = styled.div`
  width: 66.6%;
`

const StyledTextButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  visibility: ${(props) => (props.visible ? 'auto' : 'hidden')};
`

const StyledTextButton = styled(TextButton)`
  position: absolute;
  right: 0;
  height: ${(props) => props.theme.header.height};
`
