import React, { useState } from 'react'
import styled from 'styled-components'

import Highlight from './Highlight'
import Tutorials from './Tutorials'

import { useAuth, UserPrefs, testUserPref } from '../auth/Authentication'

function AppTutorials(props) {
  const { userPrefs, toggleUserPref } = useAuth()

  const visible = testUserPref(userPrefs, UserPrefs.TutorialVisible)
  const tutorialWidth = '300px'

  const [highlight, setHighlight] = useState({
    hidden: true,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  })

  return (
    <StyledColumns>
      <StyledAppContainer
        tutorialWidth={visible ? tutorialWidth : '0px'}
      >
        {props.children}
        {visible && !highlight.hidden && (
          <Highlight
            t={highlight.top}
            l={highlight.left}
            w={highlight.width}
            h={highlight.height}
            b={'8px'}
          />
        )}
      </StyledAppContainer>
      {visible && (
        <StyledTutorialContainer tutorialWidth={tutorialWidth}>
          <Tutorials
            appTutorial
            setHighlight={setHighlight}
            handleClose={() => toggleUserPref(UserPrefs.TutorialVisible)}
          />
        </StyledTutorialContainer>
      )}
    </StyledColumns>
  )
}

export default AppTutorials

const StyledColumns = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledAppContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: calc(100vw - ${props => props.tutorialWidth});
  height: 100%;
`

const StyledTutorialContainer = styled.div`
  display: flex;
  width: ${props => props.tutorialWidth};
  height: 100%;
  background: black;
  padding: 20px;
`
