import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PendingTable from "./PendingTable"
import HeaderWithCancelButton from "../shared/HeaderWithCancelButton"
import ReactModalWrapper from "../shared/ReactModalWrapper"

import { StyledText } from "../styling/TextStyling"

import { toBookingId } from "../../utils/TimestampHelper"

function Pending(props) {
  const { t } = useTranslation();
  const { authCall, bookingId, hideModal } = props

  const [data, setData] = useState({
    customer: '',
    booking: '',
    booking_id: 0,
    creation_timestamp: '',
    names: [],
    dates: [],
    totals: [],
    total_actual: 0
  })

  useEffect(() => {
    authCall('reporting/pending?booking_id=' + bookingId)
      .then(data => {
        //console.log(JSON.stringify(data))
        setData(data)
      })
  }, [authCall, bookingId])

  const metaData = (
    <div style={{marginTop: 20, marginBottom: 20}}>
      <StyledText medium>{t('shared.customer') + ': ' + (data.customer ? data.customer : 'N/A')}</StyledText>
      <StyledText medium>{t('shared.campaign') + ': ' + data.booking}</StyledText>
      <StyledText medium>{t('shared.booking_id') + ': ' + toBookingId(data.creation_timestamp, data.booking_id)}</StyledText>
    </div>
  )

  const dataValid = data.names && data.names.length && data.dates && data.dates.length && data.totals && data.totals.length && 0 < data.total

  const content = (
    !dataValid ?
      <div style={{marginTop: 20}}>
        <StyledText>{t('reporting.report.no_data')}</StyledText>
      </div> :
      <div>
        {metaData}
        <PendingTable
          data={data}
        />
      </div>
  )

  return (
    <ReactModalWrapper
      layout={'fullscreen'}
      onRequestClose={hideModal}
    >
      {/* Display current booking proof image */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '40%',
        bottom: '0px',
        border: '0px',
        padding: '20px'
      }}>
      </div>
      {/* User input */}
      <div style={{
        position: 'absolute',
        top: '0px',
        left: '60%',
        right: '0px',
        bottom: '0px',
        border: '0px',
        background: 'rgba(0, 0, 0, 0.9)',
        padding: '20px'
      }}>
        <HeaderWithCancelButton
          title={t('reporting.report.title')}
          handleCancel={hideModal}
        />
        {content}
      </div>
    </ReactModalWrapper>
  )
}

export default Pending
