import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { loadData } from './LoadMap'
import { addressSortFunction, addressDisplayFunction } from '../admin/Sites'
import Icon from '../shared/Icon'
import Table from '../shared/Table'

import { StyledText } from '../styling/TextStyling'

function SearchResults(props) {
  const { t } = useTranslation();

  const translationPrefix = 'content.search'

  const load = (data) => data['load'][0] + data['load'][1] + props.load

  return (
    <Table
      endPoint={''}
      externalData={props.searchResults}
      translationPrefix={translationPrefix}
      rowHeight={90}
      fitHeightToItems={true}
      categoryHeaderHidden={true}
      clearQueryButtonHidden={true}
      searchAndActionsHidden={true}
      standardActions={[]}
      customActions={[
        {
          type: "delete",
          permissionsOk: true,
          handleClick: (id, data) => {
          },
        },
      ]}

      columns={[
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name',
        },
        {
          header: t(translationPrefix + '.site'),
          defaultProperty: 'country',
          sortStringFunction: (data) => addressSortFunction(data, 'info'),
          visualizationFunction: (data) => addressDisplayFunction(data, 'info'),
        },
        {
          header: t(translationPrefix + '.load'),
          defaultProperty: 'load',
          style: {justifyContent: 'center'},
          sortStringFunction: (data) => load(data),
          visualizationFunction: (data) => {
            const load = typeof props.load === 'string' ? parseInt(props.load) : props.load
            const { vacant, occupancy, overquotations } = loadData(data['load'], data['loop_length_secs'], load)
            let text
            if(vacant === 0) {
              text = t('shared.fully_booked')
            } else {
              const loadStr = occupancy.toFixed(0).replace('.', ',')
              const loadQuoteStr = overquotations.toFixed(0).replace('.', ',')
              text = loadStr + '% ' + t('shared.booked') + ' / ' + loadQuoteStr + '% ' + t('shared.exceeded')
            }
            return (
              <LoadFrame>
                <LoadIndicator load={occupancy} />
                <StyledText dark centered>{text}</StyledText>
              </LoadFrame>
            )
          }
        },
//        {
//          header: t(translationPrefix + '.quotations'),
//          defaultProperty: 'discount',
//          style: {justifyContent: 'center'},
//          sortStringFunction: (data) => 0,
//          visualizationFunction: (data) => <StyledText dark>{'n.a.'}</StyledText>,
//        },
//        {
//          header: t(translationPrefix + '.reservations'),
//          defaultProperty: 'players',
//          style: {justifyContent: 'center'},
//          sortStringFunction: (data) => 0,
//          visualizationFunction: (data) => <StyledText dark>{'n.a.'}</StyledText>,
//        },
        {
          header: t(translationPrefix + '.availability'),
          style: {justifyContent: 'center'},
          sortStringFunction: (data) => load(data),
          visualizationFunction: (data) => {
            if(data['load'][0] + props.load <= data['loop_length_secs'])
              return <Icon icon={'done'} color={'green'} size={24} />
            else
              return <Icon icon={'clear'} color={'red'} size={24} />
          }
        },
      ]}
    />
  )
}

export default SearchResults

const LoadFrame = styled.div`
  width: 80%;
  height: 12px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: ${props => props.theme.colors.lightgrey};
`

const LoadIndicator = styled.div`
  width: ${props => Math.min(props.load, 100) + '%'};
  height: 100%;
  background: ${props => {
    if(props.load <= 50)
      return props.theme.colors.green;
    else if(props.load <= 75)
      return props.theme.colors.yellow;
    else if(props.load <= 100)
      return props.theme.colors.orange;
    else
      return props.theme.colors.red;
  }};
`
