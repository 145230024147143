import styled from 'styled-components'

export const FullscreenContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`

export const FullscreenNavbar = styled.nav`
  display: flex;
  flex: ${props => props.isLandingPage ? '2 1 auto' : '0 1 auto'};
  width: ${props => props.isLandingPage ? '100%' : 'auto'};
`;

export const FullscreenContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  width: 100%;

  overflow-y: hidden;
`

export const RowContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export const RowContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  width: 100%;

  overflow-y: hidden;
`
