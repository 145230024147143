import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ActionButton from '../../shared/ActionButton'
import { useSpot } from '../../shared/SpotProvider'

import { StyledFileInput } from '../../styling/InputStyling'

export default function BookingUnitSpotUpload(props) {
  const { t } = useTranslation()
  const { onChange, onDelete } = useSpot()

  const [fileNames, setFileNames] = useState(new Map())

  const handleFile = (resolution, id, fileName) => {
    setFileNames(new Map(fileNames).set(resolution, {id, fileName}))
  }

  const handleDelete = (resolution) => {
    onDelete(fileNames.get(resolution).id)
    const updatedFileNames = new Map(fileNames)
    updatedFileNames.delete(resolution)
    setFileNames(updatedFileNames)
  }

  return (
    <>
      {props.resolutions.map(({ resolutionId, resolution }) => {
        const key = 'BookingUnitSpotUpload-' + resolution
        const fileSelected = fileNames.has(resolution) &&
              fileNames.get(resolution).fileName !== ''
        let label = resolution + ": "

        if(!fileSelected) {
          label += t('shared.pleaseSelectFile') + ' ' + t('shared.optional')

          return (
            <SpotUploadWrapper key={key}>
              <StyledLabel htmlFor={key}>{label}</StyledLabel>
              <StyledFileInput id={key} type="file" name={resolution}
                onChange={(e) => onChange(e, resolution, handleFile, resolutionId)}
              />
            </SpotUploadWrapper>
          )
        } else {
          label += fileNames.get(resolution).fileName

          return (
            <SpotUploadWrapper key={key}>
              <StyledLabel noHover>{label}</StyledLabel>
              <ActionButton
                type={'delete_noconfirm'}
                style = {{marginRight: 8, background: 'rgba(0, 0, 0, 0.2)'}}
                handleClick={() => handleDelete(resolution)}
              />
            </SpotUploadWrapper>
          )
        }
      })}
    </>
  )
}

const SpotUploadWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledLabel = styled.label`
  cursor: ${(props) => props.noHover ? 'auto' : 'pointer'};
  width: 100%;
  user-select: none;
  white-space: nowrap;
  padding: 15px;

  &:hover {
    background: ${(props) => props.noHover ? 'transparent' : 'rgba(0, 0, 0, 0.1)'};
  }
`
