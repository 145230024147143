import React from 'react'

import { useDataInterface } from '../shared/DataInterface'

import { StyledText } from '../styling/TextStyling'

function ListButtonSource(props) {
  const { data } = useDataInterface({endPoint: props.endPoint})
  
  return (
    <div>
      {data.map((item, index) => {
        return <StyledText key={index}>{item.name}</StyledText>
      })}
    </div>
  )
}

export default ListButtonSource
