import Axios from 'axios'

import { getAuthToken } from '../auth/Authentication'

import { backendUrl } from '../../utils/UrlHelper'
import { versionStr } from '../../utils/Version'

/**
 * request function
 * @param {string} path API Endpoint to fetch
 * @param {object} userOptions User options for method, baseUrl, body, headers etc..
 */
export default function request(path, userOptions = {}) {
  const defaultOptions = {
    url: path,
    baseURL: backendUrl(),
    method: 'GET',
  }

  const defaultHeaders = {
    Version: versionStr(),
    Authorization: getAuthToken(),
  }

  const options = {
    ...defaultOptions,
    ...userOptions,
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
    },
    // Decode JSON timestamps to Javascript Date
    // See https://github.com/axios/axios#request-config
    transformResponse: transformResponse,
    // Encode Javascript Date to UTC timestamp: Remove the timezone, because 
    // the players should play it at local time identical to browser time.
    // We currently use Date.Prototype.toJSON instead - not sure if this is
    // safe or conflicts with the underlying library. TODO - Check this.
    //transformRequest: [transformRequest, ...Axios.defaults.transformRequest],
  }

  if(process.env.NODE_ENV !== 'production')
    console.log('API call with options ', options)
  else
    console.log('API call with options ', { ...defaultOptions, ...userOptions })

  let response = null;

  return Axios.request(options)
    .then((responseObject) => {
      response = responseObject;
      return response.data;
    })
    .catch((error) => {
      // Throw custom API error
      // If response exists it means HTTP error occured
      if(error.response) {
        if(error.response.status === 426) {
          window.location.reload(true);
          throw new ApiError("Force browser reload", null, "REQUEST_FAILED");
        } else {
          throw new ApiError(
            `Request failed with status ${error.response.status}.`,
            error,
            error.response.status
          );
        }
      } else {
        throw new ApiError(error.toString(), null, "REQUEST_FAILED");
      }
    });
}

function ApiError(message, data, status) {
  let response = null;
  let isObject = false;

  // We are trying to parse response
  try {
    response = JSON.parse(data);
    isObject = true;
  } catch (e) {
    response = data;
  }

  this.response = response;
  this.message = message;
  this.status = status;
  this.toString = function () {
    return `${this.message}\nResponse:\n${
      isObject ? JSON.stringify(this.response, null, 2) : this.response
    }`;
  };
}


// When encoding Javascript Date to JSON drop the timezone, because the players
// should player content at local time identical to browser time.
// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function() {
  return new Date(this.getTime() - this.getTimezoneOffset() * 60000).toISOString()
}


function dateParser(key, value) {
  if(key.endsWith("timestamp") && value !== null) {
    const date = new Date(value)
    if(!isNaN(date.getTime()))
      return date
  }

  return value
}


// See https://github.com/axios/axios/blob/master/lib/defaults.js
function transformResponse(data) {
  /*eslint no-param-reassign:0*/
  if(typeof data === 'string') {
    try {
      data = JSON.parse(data, dateParser);
    } catch (e) { /* Ignore */ }
  }
  return data;
}
