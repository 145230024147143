import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { useTranslation } from 'react-i18next'

import ColumnHeader from './ColumnHeader'
import BookingUnit from './BookingUnit'
import BookingUnitEdit from './BookingUnitEdit'
import { cDefaultSpotLength } from '../../../Theme'
import SearchResults from '../SearchResults'
import CancelSave from '../../shared/CancelSave'
import HeaderWithCancelButton from '../../shared/HeaderWithCancelButton'
import ReactModalWrapper from '../../shared/ReactModalWrapper'
import { StyledText } from '../../styling/TextStyling'

import { useAuth } from '../../auth/Authentication'
import { usePlayerData } from '../../shared/PlayerData'
import { useRequestToken } from '../../shared/RequestToken'

import {
  convertIndividualDatesFromDb,
  getBookingType,
  processBookingUnitInputs,
  bookingUnitRequiresSearchRequest,
} from '../../../utils/BookingUnitHelper'

function BookingUnits(props) {
  const { t } = useTranslation();
  const auth = useAuth();

  const translationPrefix = 'content.booking.bookingUnits'

  const {
    selectedBookingUnitId, handleSelectedBookingUnitId, usedPlayers,
    revertUsedPlayers
  } = props

  const {
    dataId, data, handleDuplicate, handleDelete, handleSaveEdit
  } = props.dataInterface

  const {
    clusters, sites, players, firstDayOfWeek, handleClusterChange,
    handleSiteChange, handlePlayerChange, handleSiteExpanderClick
  } = usePlayerData(auth, usedPlayers, selectedBookingUnitId)

  const {
    requestToken,
    setRequestToken,
    clearRequestToken,
    releaseRequestToken,
    requestInvalid,
    requestValidUntil,
  } = useRequestToken('content/bookingUnits')

  // ATTEMPTED HACK TO FIX CHANGING id
  //useEffect(() => {
  //  console.log("BookingUnits " + JSON.stringify(usedPlayers))
  //}, [playerDataId])
  //
  //useEffect(() => {
  //  sites.forEach(site => {
  //    if(site.id !== site._id)
  //      console.log('WARNING: ' + site.name + ' changed id ' + site._id + ' -> ' + site.id)
  //  })
  //}, [sites])

  const allowedSpotLengths = useMemo(() => {
    const bookingType = getBookingType(props.booking)
    if(['subscription', 'reservation'].includes(bookingType))
      return Array(15).fill({}).map((x, i) => (i + 1) * cDefaultSpotLength)
    else
      return [cDefaultSpotLength / 2, cDefaultSpotLength]
  }, [props.booking])

  const requiresLoadCheck = bookingUnitRequiresSearchRequest(props.booking)
  const [editData, setEditData] = useState({})
  const [editDataValid, setEditDataValid] = useState(false)
  const [editIsUpdate, setEditIsUpdate] = useState(true)
  const [lastSelectedBookingUnitId, setLastSelectedBookingUnitId] = useState(-1)
  const [searchResults, setSearchResults] = useState([])

  const [showModal, hideModal] = useModal(() => (
    <ReactModalWrapper
      doubleColumn
      onRequestClose={handleCancelEdit}
    >
      <div>
        <HeaderWithCancelButton
          title={t(translationPrefix + (editIsUpdate ? '.editExisting' : '.addNew'))}
          handleCancel={handleCancelEdit}
        />
        <br />
        {searchResults.length === 0 ? (
          <form>
            <BookingUnitEdit
              dark
              allowedSpotLengths={allowedSpotLengths}
              inputs={editData}
              setInputs={setEditData}
              clusters={clusters}
              handleClusterChange={handleClusterChange}
              sites={sites}
              handleSiteExpanderClick={handleSiteExpanderClick}
              handleSiteChange={handleSiteChange}
              handleCompletedChanged={setEditDataValid}
              players={players}
              firstDayOfWeek={firstDayOfWeek}
              handlePlayerChange={handlePlayerChange}
              translationPrefix={translationPrefix}
            />
            <CancelSave
              handleCancel={handleCancelEdit}
              canSave={editDataValid}
              saveLabel={requiresLoadCheck ? t('content.search.search') : undefined }
              handleSave={intermediateHandleSaveEdit}
            />
          </form>
        ) : (
          <form>
            <SearchResults
              load={editData['spot_length_secs']}
              searchResults={searchResults}
            />
            <CancelSave
              isCloseButton={requestInvalid}
              cancelLabel={t('content.search.back')}
              handleCancel={() => {
                releaseRequestToken()
                setSearchResults([])
              }}
              saveLabel={(
                <>
                  <div style={{paddingRight: 8}}>
                    {t('content.search.save_subset')}
                  </div>
                  <StyledText small>
                    {' (' + t('content.search.reserved') + ' ' + requestValidUntil + ')'}
                  </StyledText>
                </>
              )}
              handleSave={intermediateHandleSaveEdit}
            />
          </form>
        )}
      </div>
    </ReactModalWrapper>
  ), [editData, editDataValid, setEditDataValid, clusters, sites, players, usedPlayers, releaseRequestToken, searchResults, requestValidUntil])

  const handleNew = useCallback(() => {
    setLastSelectedBookingUnitId(selectedBookingUnitId)
    handleSelectedBookingUnitId(-1)
    setEditIsUpdate(false)
    setEditData({
      booking_id: props.bookingId,
      spot_length_secs: cDefaultSpotLength,
      date_input_mode: 'range_and_individual',
      use_range: true,
      start_timestamp: null,
      end_timestamp: null,
      individual_dates: [],
      weekdays: 255,
      hours: 17825791,
      repeats_per_day: 0,
      bookingtype_id: props.booking.bookingtype_id,
      bookingattributes: props.booking.bookingattributes,
      sector_id: props.booking.sector_id,
      state: props.booking.state,
    })
    showModal()
  }, [setLastSelectedBookingUnitId, selectedBookingUnitId,
      handleSelectedBookingUnitId, setEditIsUpdate, setEditData,
      props.bookingId, props.booking, showModal])

  const handleEdit = (id) => {
    const bookingUnit = {...data.find(r => r.id === id)}
    const useRange = bookingUnit['individual_dates'] === null
    bookingUnit['date_input_mode'] = useRange ? 'range' : 'individual'
    bookingUnit['use_range'] = useRange
    if(!useRange)
      convertIndividualDatesFromDb(bookingUnit)
    setEditIsUpdate(true)
    setEditData(bookingUnit)
    showModal()
  }

  const intermediateHandleSaveEdit = () => {
    const data = {...editData}
    processBookingUnitInputs(data, usedPlayers, /*trackUsedPlayers*/requiresLoadCheck)

    if(requestToken !== '') {
      data['request_token'] = requestToken
      clearRequestToken()
      setSearchResults([])
    }
    data['requires_load_check'] = requiresLoadCheck

    handleSaveEdit(editIsUpdate ? 'PUT' : 'POST', data, (response) => {
      if(!response || response.error !== 'network_load') {
        hideModal()
        setEditIsUpdate(true)
      } else {
        setRequestToken(response.request_token, response.request_timestamp)
        setSearchResults(response.data)
      }
    })
  }

  const handleCancelEdit = () => {
    hideModal()
    releaseRequestToken()
    setSearchResults([])
    if(editIsUpdate) {
      revertUsedPlayers()
    }
    else {
      handleSelectedBookingUnitId(lastSelectedBookingUnitId)
      setEditIsUpdate(true)
    }
  }

  // Automatically select first booking unit on data update if none is selected
  useEffect(() => {
    // Update selectedBookingUnitId on new valid data, unless a new booking unit
    // being created (editIsUpdate == false)
    if(editIsUpdate && 0 < data.length
    && (  selectedBookingUnitId === -1
       || !data.find(elem => elem.id === selectedBookingUnitId))
    ) {
      handleSelectedBookingUnitId(data[0].id)
    }
    // Disable warning for dependency on data - depend on dataId instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataId, selectedBookingUnitId, handleSelectedBookingUnitId])

  useEffect(() => {
    if(props.booking && props.newBookingCreated)
      handleNew()
  }, [props.booking, props.newBookingCreated, handleNew])

  const bookingUnits = data.map((bookingUnit, index) => {
    return (
      <BookingUnit
        key={bookingUnit.id}
        data={bookingUnit}
        selected={selectedBookingUnitId === bookingUnit.id}
        translationPrefix={translationPrefix}
        userCanEdit={props.userCanEdit}
        handleClick={handleSelectedBookingUnitId}
        handleEdit={handleEdit}
        handleDuplicate={handleDuplicate}
        handleDelete={handleDelete}
      />
    )
  })

  return (
    <div>
      <ColumnHeader
        text={t(translationPrefix + '.title')}
        translationPrefix={translationPrefix}
        hasNewItemButton={props.userCanEdit}
        handleNew={handleNew}
      />
      {bookingUnits}
    </div>
  )
}

export default BookingUnits
