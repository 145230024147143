import React from 'react'
import styled from 'styled-components'

import Icon from '../shared/Icon'

const Background = styled.div`
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  width: calc(${(props) => props.theme.header.height} - 1px);
  padding-top: 34px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`

function SettingsHidden(props) {
  return (
    <Background
      onClick={props.handleClick}
    >
      <Icon
        icon={'settings'}
        size={16}
      />
    </Background>
  )
}

export default SettingsHidden
