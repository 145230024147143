import React from 'react'
import ReactModal from "react-modal"

import Background from '../../images/background.jpg'

function ReactModalWrapper(props) {
  let customStyles = {}

  const overlayColor = props.overlayColor ? props.overlayColor : 'rgba(0, 0, 0, 0.5)'
  
  switch(props.layout) {
  case 'auth':
    customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(' + Background + ') no-repeat center center fixed',
        backgroundSize: 'cover',
        zIndex: 100
      },
      content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: 0,
        background: 'transparent',
        overflow: 'hidden',
        WebkitOverflowScrolling: 'touch',
        borderRadius: 0,
        outline: 'none',
        padding: 0
      }
    }
    break

  case 'booking':
    customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: overlayColor,
        zIndex: 100
      },
      content: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        right: '20px',
        bottom: '20px',
        border: '0px',
        background: '#000',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '20px'
      }
    }
    break

  case 'fullscreen':
    customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: overlayColor,
        zIndex: 100
      },
      content: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        border: '0',
        background: 'transparent',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0',
        outline: 'none',
        padding: '0'
      }
    }
    break

  case 'dialog':
    customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: overlayColor,
        zIndex: 100
      },
      content: {
        position: 'absolute',
        top: '20%',
        left: '30%',
        right: '30%',
        bottom: '20%',
        border: '0',
        background: 'transparent',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0',
        outline: 'none',
        padding: '0'
      }
    }
    break

  case 'select':
    customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent',
        zIndex: 100
      },
      content: {
        position: 'absolute',
        top: props.contentRect.top,
        left: props.contentRect.left,
        width: props.contentRect.width,
        minWidth: 'max-content',
        height: 'min(calc(100vh - 20px - ' + props.contentRect.top + 'px), ' + props.contentRect.height + 'px)',
        border: 0,
        background: 'transparent',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: 0,
        outline: 'none',
        padding: 0,
        boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 18px',
      }
    }
    break

  default:
    customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: overlayColor,
        zIndex: 100
      },
      content: {
        position: 'absolute',
        top: '0px',
        left: props.fullScreen ? '0%' : !props.doubleColumn ? '60%' : '40%',
        right: '0px',
        bottom: '0px',
        border: '0px',
        background: 'rgba(0, 0, 0, 0.9)',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '20px'
      }
    }
  }

  const shouldCloseOnOverlayClick = props.shouldCloseOnOverlayClick !== undefined && props.shouldCloseOnOverlayClick

  return (
    <ReactModal
      isOpen
      style={customStyles}
      onRequestClose={props.onRequestClose}
      keyboard={true}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {props.children}
    </ReactModal>
  )
}

export default ReactModalWrapper
