import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Background from './Background'
import request from '../shared/request'
import TextButton from '../shared/TextButton'

import { AuthForm, AuthInputBackground } from '../styling/AuthStyling'
import { StyledInput } from '../styling/InputStyling'
import { StyledText } from '../styling/TextStyling'

import { landingPageUrl } from '../../utils/UrlHelper'
import { VersionElement } from '../../utils/Version'

function Password(props) {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [inputs, setInputs] = useState({ password: '', confirm_password: '' })

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = async () => {
    const config = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: JSON.stringify({
        authcode: authcode,
        password: inputs["password"],
      }),
    }
    await request("/pwd", config)

    window.open(landingPageUrl(), "_self")
    props.hideModal()
    props.showModal()
  }

  const queryEmail = async (authcode) => {
    const response = await request('/authcodeEmail?authcode=' + authcode)
    if(Array.isArray(response) && response.length === 1)
      setEmail(response[0].email)
    else
      setEmail('invalid')
  }

  const [authcode, setAuthcode] = useState("")
  useEffect(() => {
    const search = window.location.search
    if(search.match(
      /authcode=[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/
    )) {
      const authcode = search.substring(10)
      setAuthcode(authcode)
      queryEmail(authcode)
    } else {
      setEmail('invalid')
    }
  }, [])

  // eslint-disable-next-line
  const pwdOk = (pwd) => pwd.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_=+{};:,<.>\[\]\\\|\/\-])[\w!@#$%^&*()_=+{};:,<.>\[\]\\\|\/\-]{8,128}$/)
  const passwordValid = email.length && pwdOk(inputs.password) && inputs.password === inputs.confirm_password

  return (
    <Background>
      <VersionElement auth />
      <StyledText large fontSize={32}>{t('authentication.set_password')}</StyledText>
      <br/>
      {(email !== 'invalid') ? (
        <>
        <AuthForm>
          <input
            type="text"
            autoComplete="username"
            value={inputs.email}
            name="email"
            placeholder={t('authentication.email')}
            onChange={handleInputChange}
            style={{visibility: 'hidden'}}
          />
          <AuthInputBackground margin={1}>
            <StyledInput
              type="password"
              autoComplete="new-password"
              value={inputs.password}
              name="password"
              placeholder={t('authentication.password')}
              onChange={handleInputChange}
              autoFocus
            />
          </AuthInputBackground>
          <AuthInputBackground margin={10}>
            <StyledInput
              type="password"
              autoComplete="new-password"
              value={inputs.confirm_password}
              name="confirm_password"
              placeholder={t('authentication.confirm_password')}
              onChange={handleInputChange}
            />
          </AuthInputBackground>
          <TextButton
            disabled={!passwordValid}
            handleClick = {handleSubmit}
          >
            <StyledText>{t('authentication.submit_password')}</StyledText>
          </TextButton>
        </AuthForm>
        <br/>
        <br/>
        <StyledText large centered style={{width: 380}}>{t('authentication.password_requirements')}</StyledText>
        </>
      ) : (
        <StyledText large centered style={{width: 380}}>{t('authentication.error')}</StyledText>
      )}
    </Background>
  )
}

export default Password;
