import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Bit from './Bit'

import { hourDefaultMask, weekdayDefaultMask } from '../../utils/BookingUnitHelper'

function BitSelector(props) {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false)
  const [editedBits, setEditedBits] = useState(false)
  const [startIndex, setStartIndex] = useState(-1)
  const [activating, setActivating] = useState(false)

  const useWeekdays = props.type === 'weekdays'
  const bitMask = (index) => {
    return (useWeekdays ? weekdayDefaultMask() : hourDefaultMask()) >> (index + 1)
  }

  const handleOnMouseDown = (index) => {
    const mask = bitMask(index)
    setStartIndex(index)
    setActivating(!(props.bits & mask))
    setEditedBits(props.bits ^ mask)
    setEditing(true)
  }

  const handleOnMouseEnter = (index) => {
    if(editing) {
      var bits = props.bits
      for(var i = Math.min(startIndex, index); i <= Math.max(startIndex, index); i++) {
        const mask = bitMask(i)
        if(!(props.disabledBits & mask)) {
          if(activating)
            bits |= mask
          else
            bits &= ~mask
        }
      }
      setEditedBits(bits)
    }
  }
  
  const handleOnMouseUp = (index) => {
    props.onChange(editedBits)
    setEditing(false)
  }
  
  const cols = useWeekdays ? 7 : 24
  const colBreak = 12
  
  const usedBits = !editing ? props.bits : editedBits
  const bits = Array(cols).fill({}).map((x, index) => {
    const mask = bitMask(index)
    
    return (
      <Bit
        key = {index}
        index = {index}
        row = {Math.floor(index / colBreak) + 1}
        column = {(index % colBreak) + 1}
        text = {useWeekdays ? t('shared.weekday' + index) : String(index).padStart(2, '0')}
        disabled = {props.disabledBits & mask}
        active = {usedBits & mask}
        readOnly = {props.readOnly}
        handleOnMouseDown = {() => handleOnMouseDown(index)}
        handleOnMouseEnter = {() => handleOnMouseEnter(index)}
        handleOnMouseUp = {() => handleOnMouseUp(index)}
      />
    )
  })

  const style = {
    ...props.style,
    display: 'grid',
    gap: 0,
    width: '100%'
  }
  
  return (
    <div style = {style}>
      {bits}
    </div>
  )
}

export default BitSelector
