import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StyledText } from '../../styling/TextStyling'

import { fromDbTimestampWithTime } from '../../../utils/TimestampHelper'


function SpotInfo(props) {
  const { t } = useTranslation()
  const { spot } = props

  const translationPrefix = 'content.booking.spots'
  const ts = parseInt(spot.spot.substring(0, 10)) * 1000
  const timestamp = new Date(ts)

  return (
    <div>
      <StyledText bold>
        {t(translationPrefix + '.info')}
      </StyledText>
      <StyledTable>
        <tbody>
          <StyledTr>
            <StyledTd leftCol>
              <StyledData leftCol>{t(translationPrefix + '.org_filename') + ": "}</StyledData>
            </StyledTd>
            <StyledTd>
              <StyledData>{spot.org_filename ? spot.org_filename : 'n.a.'}</StyledData>
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <StyledTd leftCol>
              <StyledData leftCol>{t(translationPrefix + '.uploaded') + ": "}</StyledData>
            </StyledTd>
            <StyledTd>
              <StyledData>{fromDbTimestampWithTime(timestamp)}</StyledData>
            </StyledTd>
          </StyledTr>
        </tbody>
      </StyledTable>
    </div>
  )
}

export default SpotInfo


const StyledTable = styled.table`
  border-collapse: collapse;
  padding: 0px;
  width: 300px;
`

const StyledTr = styled.tr`
`

const StyledTd = styled.td`
  vertical-align: top;
  margin: 0px;
  width: ${props => props.leftCol ? '30%' : '70%'};
`

const StyledData = styled(StyledText)`
  vertical-align: top;
  white-space: pre-wrap;
  overflow-wrap: ${props => props.leftCol ? 'normal' : 'anywhere'};;
`

