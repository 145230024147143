const defaultSize = 160

export const getResolutionDisplaySize = (resolution) => {
  let width = defaultSize
  let height = width
  if(resolution && resolution.width && resolution.height) {
    if(resolution.height < resolution.width)
      height = Math.round((resolution.height / resolution.width) * width)
    else
      width = Math.round((resolution.width / resolution.height) * height)
  }
  else {
    height = Math.round((1080 / 1920) * width)
  }

  return [width, height]
}


export const getSpotDisplaySize = (spot, width = defaultSize, height = defaultSize) => {
  if(spot && spot.width && spot.height) {
    if(spot.width < 0 || spot.height < 0) {
      width = undefined
      height = undefined
    }
    else if(spot.height < spot.width) {
      height = Math.round((spot.height / spot.width) * width)
    }
    else {
      width = Math.round((spot.width / spot.height) * height)
    }
  }
  else {
    height = Math.round((1080 / 1920) * width)
  }

  return [width, height]
}


export const getDefaultDisplaySize = (metadata) => {
  let width = defaultSize
  let height = defaultSize
  
  if(metadata && metadata.width && metadata.height) {
    if(metadata.height < metadata.width)
      height = (1080 / 1920) * width
    else
      width = (1080 / 1920) * height
  } else {
    height = (1080 / 1920) * width
  }

  return [width, height]
}
