import styled from 'styled-components'

export const StyledProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => `${progress}%`};
  height: 5px;
  background: ${props => props.bgColor === 'white' ? props.theme.colors.white : props.theme.colors.green};
  transition: width 2s ease-in;
`
