import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import HeaderWithCancelButton from '../../../shared/HeaderWithCancelButton'
import InputContainer from '../../../shared/InputContainer'
import TextButton from '../../../shared/TextButton'
import UploadProgressBar from '../../../../utils/UploadProgressBar'

import { useSpot } from '../../../shared/SpotProvider'

import { StyledText } from '../../../styling/TextStyling'

import { useQueryEndPoint } from '../../../../utils/QueryEndPoint'

function PendingUploads(props) {
  const { t } = useTranslation()
  const { queryPath } = useQueryEndPoint()

  const translationPrefix = 'content.booking.spotUpload.'

  const { done, progress, next, pending } = useSpot()

  const handleClick = (bookingId, i) => {
    props.handleClose()
    // TODO - should reflect one of bookingTemplateFromUrl in ../MetaDataFields.js
    queryPath('content/bookings/booking', 'id', bookingId)
  }

  const pendingFiles = pending.map(
    ({ id, bookingId, bookingIdStr, bookingName, metadata: { name } }, index) => {
      return (
        <ProgressContainer key={id} >
          <PaddedText dark navigates
            index={index}
            onClick={() => handleClick(bookingId)}
          >
            {next && next.id === id && (
              <UploadProgressBar progress={progress} />
            )}
            {bookingIdStr + " | " + bookingName + ": " + name}
          </PaddedText>
        </ProgressContainer>
      )
    }
  )

  const doneFiles = done.map(
    ({ id, bookingId, bookingIdStr, bookingName, metadata: { name } }, index) => {
      return (
        <PaddedText dark navigates
          key={id}
          index={index}
          onClick={() => handleClick(bookingId)}
        >
          {bookingIdStr + " | " + bookingName + ": " + name}
        </PaddedText>
      )
    }
  )

  const none_pending = t(translationPrefix + 'no_pending_uploads')
  const none_finished = t(translationPrefix + 'no_recent_uploads')

  return (
    <div>
      <HeaderWithCancelButton
        title={t(translationPrefix + 'pending')}
        handleCancel={props.handleClose}
      />
      <br />
      <InputContainer left
        header={t(translationPrefix + 'pending_uploads')}
        marginBottom={'20px'}
      >
        {pending.length === 0 ?
          <PaddedText dark centered>{none_pending}</PaddedText> :
          pendingFiles
        }
      </InputContainer>
      <InputContainer left
        header={t(translationPrefix + 'recent_uploads')}
        marginBottom={'20px'}
      >
        {done.length === 0 ?
          <PaddedText dark centered>{none_finished}</PaddedText> :
          doneFiles
        }
      </InputContainer>
      <TextButton handleClick={props.handleClose}>
        {t('shared.close')}
      </TextButton>
    </div>
  )
}

export default PendingUploads

const PaddedText = styled(StyledText)`
  padding: 15px 15px;
  cursor: ${({ navigates }) => (navigates ? 'pointer' : 'auto')};
  background: ${(props) => (props.index % 2 === 0 ? '#fff' : '#f9f9f9')};
  overflow-x: hidden;

  &:hover {
    background: ${({ navigates }) => (navigates ? '#ccc' : '#fff')};
  }
`

const ProgressContainer = styled.div`
  position: relative;
`
