import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StyledText } from '../styling/TextStyling'

function CopyToClipboard(props) {
  const { t } = useTranslation()

  const timeout = useRef(null)
  const height = 20;
  const canCopy = props.copyText !== undefined
  const [cursorVisible, setCursorVisible] = useState(false)
  const [cursorPos, setCursorPos] = useState({x: 0, y: 0})
  const [copied, setCopied] = useState(false)

  const cancelTimeout = () => {
    if(timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
    }
  }

  const showCursor = () => {
    if(!timeout.current)
      timeout.current = setTimeout(() => setCursorVisible(true), 600)
  }

  const hideCursor = () => {
    setCursorVisible(false)
    setCopied(false)
    cancelTimeout()
  }

  const mouseMoveHandler = (e) => {
    //e.preventDefault()
    const rect = e.currentTarget.getBoundingClientRect()
    if(rect.x < e.pageX && rect.y < e.pageY && e.pageX < rect.x + rect.width && e.pageY < rect.y + rect.height) {
      showCursor()
      setCursorPos({x: e.pageX, y: e.pageY - height / 2.0})
    } else {
      hideCursor(false)
    }
  }

  const copyHandler = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(props.copyText).then(() => {
      setCopied(true)
      //console.log('CopyToClipboard: ', props.copyText)
    }, (err) => {
      console.error('CopyToClipboard: Could not copy text: ', err)
    })
  }

  useEffect(() => () => cancelTimeout(), [])

  return (
    <Handler
      canCopy={canCopy}
      showCursor={cursorVisible}
      onMouseEnter={showCursor}
      onMouseLeave={hideCursor}
      onMouseMove={mouseMoveHandler}
      onClick={copyHandler}
    >
      {cursorVisible && (
        <Cursor
          dark
          style={{top: cursorPos.y, left: cursorPos.x}}
        >
          {!copied ? t('shared.copy_to_clipboard') : t('shared.copied_to_clipboard')}
        </Cursor>
      )}
      {props.children}
    </Handler>
  )
}

export default CopyToClipboard

const Handler = styled.div`
  display: flex;
  cursor: ${props => props.showCursor ? 'none' : 'auto'};
  pointer-events: ${props => props.canCopy ? 'auto' : 'none'};
`

const Cursor = styled(StyledText)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  padding: 5px;
  text-transform: none;
  line-height: normal;
  height: 20px;
`
