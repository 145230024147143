import React, { useState } from 'react'

import Icon from './Icon'
import Tooltip from './Tooltip'

import { StyledText } from '../styling/TextStyling'

function StandardButton(props) {
  const normalColor = props.normalColor === undefined ? "#FFF" : props.normalColor
  const hoverColor = props.hoverColor === undefined ? "#BBB" : props.hoverColor

  const [color, setColor] = useState(normalColor)
  const style = {
    ...standardButtonStyle,
    background: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    ...props.style,
  }

  return (
    <Tooltip standardButton
      style={style}
      content={props.text && <StyledText>{props.text}</StyledText>}
      handleClick={props.handleClick}
      handleOnMouseEnter={() => setColor(hoverColor)}
      handleOnMouseLeave={() => setColor(normalColor)}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Icon
          icon={props.icon}
          color={color}
          size={16}
        />
      </div>
    </Tooltip>
  )
}

export default StandardButton

export const standardButtonStyle = {
  margin: 2,
  padding: 4,
  width: 24,
  height: 20,
  borderRadius: 3,
}
