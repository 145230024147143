import React from 'react'
import { useTranslation } from 'react-i18next'

import { addressSortFunction, addressDisplayFunction } from './Sites'
import { useAuth } from '../auth/Authentication'
import Select from '../shared/Select'
import Table from '../shared/Table'
import { cExtendedCustomer } from '../../Theme'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Customers() {
  const { t } = useTranslation();
  const { clientId } = useAuth()
  const { endPointFromQueryPath } = useQueryEndPoint()

  const endPoint = endPointFromQueryPath('admin/customers')
  const translationPrefix = 'admin.customers'
  
  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      columns={customerColumns(t, translationPrefix)}
      fields={customerFields(t, translationPrefix, clientId)}
    />
  )
}

export default Customers


function customerColumns(t, translationPrefix) {
  if(cExtendedCustomer) {
    return [
      {
        header: t(translationPrefix + '.name'),
        defaultProperty: 'name'
      },
      {
        header: t(translationPrefix + '.address'),
        defaultProperty: 'country',
        sortStringFunction: (data) => addressSortFunction(data, 'vatin'),
        visualizationFunction: (data) => addressDisplayFunction(data, 'vatin'),
      },
      {
        header: t(translationPrefix + '.contact'),
        defaultProperty: 'contact',
        visualizationFunction: (data) => {
          const title = data['gender'] === 'male' ? t('shared.mr') :
                data['gender'] === 'female' ? t('shared.mrs') : ''
          return (
            <div>{title + ' ' + data['contact']}</div>
          )
        },
      },
      {
        header: t(translationPrefix + '.email'),
        defaultProperty: 'email'
      },
      {
        header: t(translationPrefix + '.info'),
        defaultProperty: 'info'
      },
//      {
//        header: t(translationPrefix + '.agent'),
//        defaultProperty: 'agent_client_id'
//      },
      {
        header: t(translationPrefix + '.bookings'),
        defaultProperty: 'bookings',
        style: {width: '80px', justifyContent: 'center'},
      }
    ]
  } else {
    return [
      {
        header: t(translationPrefix + '.name'),
        defaultProperty: 'name',
      },
      {
        header: t(translationPrefix + '.email'),
        defaultProperty: 'email',
      },
      {
        header: t(translationPrefix + '.info'),
        defaultProperty: 'info',
      },
//      {
//        header: t(translationPrefix + '.agent'),
//        defaultProperty: 'agent_client_id',
//      },
      {
        header: t(translationPrefix + '.bookings'),
        defaultProperty: 'bookings',
        style: {justifyContent: 'center'},
      },
    ]
  }
}

export function customerFields(t, translationPrefix, clientId) {
  if(cExtendedCustomer) {
    return [
      {
        name: t(translationPrefix + '.name'),
        property: 'name',
        maxLength: 128,
        validator: 'name'
      },
      {
        name: t(translationPrefix + '.contact'),
        property: 'contact',
        maxLength: 128,
        validator: 'text',
      },
      {
        name: t('shared.gender'),
        property: 'gender',
        defaultValue: 'neutral',
        input: (inputs, handleInputChange) => (
          <Select
            value={inputs['gender']}
            onChange={handleInputChange}
            name='gender'
            options={[
              { value: 'male', text: t('shared.male') },
              { value: 'female', text: t('shared.female') },
              { value: 'neutral', text: t('shared.neutral') },
            ]}
          />
        ),
      },
      {
        name: t(translationPrefix + '.email'),
        property: 'email',
        maxLength: 128,
        validator: 'email',
      },
      {
        name: t(translationPrefix + '.street'),
        property: 'address',
        maxLength: 128,
        validator: 'text',
      },
      {
        name: t(translationPrefix + '.postcode'),
        property: 'postcode',
        maxLength: 8,
        validator: 'name',
      },
      {
        name: t(translationPrefix + '.city'),
        property: 'city',
        maxLength: 128,
        validator: 'name',
      },
      {
        name: t(translationPrefix + '.province'),
        property: 'province',
        maxLength: 128,
        validator: 'name',
        optional: true
      },
      {
        name: t(translationPrefix + '.country'),
        property: 'country',
        maxLength: 128,
        validator: 'name',
        optional: true
      },
      {
        name: t(translationPrefix + '.vatin'),
        property: 'vatin',
        maxLength: 16,
        validator: 'text',
        optional: true,
      },
      {
        name: t(translationPrefix + '.info'),
        property: 'info',
        maxLength: 1024,
        validator: 'text',
        optional: true,
      },
      {
        property: 'client_id',
        type: 'int',
        isActive: () => false,
        defaultValue: clientId,
      }
    ]
  } else {
    return [
      {
        name: t(translationPrefix + '.name'),
        property: 'name',
        maxLength: 128,
        validator: 'name'
      },
      {
        name: t(translationPrefix + '.email'),
        property: 'email',
        maxLength: 128,
        validator: 'email',
        optional: true
      },
      {
        name: t(translationPrefix + '.info'),
        property: 'info',
        maxLength: 1024,
        validator: 'text',
        optional: true
      },
    ]
  }
}
