import React, { useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { useModal } from 'react-modal-hook'
import { useTranslation } from 'react-i18next'
import { DayPicker, addToRange } from 'react-day-picker'
import { isSameDay } from 'date-fns'
import styled from 'styled-components'

import StandardButton from './StandardButton'

import { StyledInput, StyledPicker } from '../styling/InputStyling'

import {
  fromDbTimestamp,
  getStartOfWeek,
  getEndOfWeek,
  toDbTimestampStrFromDate,
  uniqueDates,
} from '../../utils/TimestampHelper'

const Arrow = styled.div`
  position: fixed;
  z-index: 2000;
  width: 0;
  height: 0;
  left: ${props => props.left + 'px'};
  top: ${props => props.top + 'px'};
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FFF;
`

function TimestampPicker(props) {
  const { start, end, firstDayOfWeek, fullWeeksOnly, handleRangeChange, dates, handleMultiChange } = props

  const { t } = useTranslation();

  const type = props.single !== undefined ? 'single' : props.range !== undefined ? 'range' : props.multiple !== undefined ? 'multiple' : props.type

  const [autoClose, setAutoClose] = useState(false)
  const [inputElement, setInputElement] = useState(null)
  const [textInput, setTextInput] = useState('')
  const [isTextInputValid, setIsTextInputValid] = useState(true)
  const [isTextInputActive, setIsTextInputActive] = useState(false)
  const [defaultMonth, setDefaultMonth] = useState(undefined)
  const [minDate,] = useState(start ? start : new Date().setHours(0, 0, 0, 0))
  const [disabledDaysBefore, ] = useState((type === 'range' && start) || (type === 'multiple' && dates && dates.length) ? null : { before: new Date() })
  const disabledDays = useMemo(() => {
    if(!disabledDaysBefore)
      return null
    else
      return {
        before: !fullWeeksOnly ? new Date() : getStartOfWeek(new Date(new Date().setHours(12, 0, 0)).setSeconds(0, 0), 7, firstDayOfWeek)
      }
  }, [disabledDaysBefore, firstDayOfWeek, fullWeeksOnly])
  const [selectedDates, setSelectedDates] = useState(type !== 'multiple' ? undefined : [])
  const [range, setRange] = useState({from: null, to: null})
  const [modifiers, setModifiers] = useState({start: null, end: null})
  const [positioning, setPositioning] = useState({top: 0, left: 0, anchor: 0, numMonths: 2})
  const [modalVisible, setModalVisible] = useState(false)
  const [numClicks, setNumClicks] = useState(0)

  const handleRef = (element) => setInputElement(element ? element : null)

  const dateCompare = (a, b) => a.getTime() - b.getTime()

  const closeModal = () => {
    setNumClicks(0)
    setModalVisible(false)
    hideModal()
  }

  const showTimestampPicker = () => {
    switch(type) {
    case 'single':
      break
    case 'range':
      //const isValid = (t) => t !== null && t !== undefined
      //const validEnd = isValid(start) && !isValid(end) ? start : end
      // USE validEnd for initialization of r1 - NOT TESTED
      const r0 = addToRange(start)
      const r1 = addToRange(end, r0)
      const { from, to } = r1
      setAutoClose(start === null && end === null)
      setDefaultMonth(start ? start : disabledDays ? disabledDays.before : new Date())
      setSelectedDates([from, {from, to}])
      setRange(r1)
      setModifiers({start: from, end: to})
      break
    case 'multiple':
      setDefaultMonth(dates.length ? dates[0] : disabledDays ? disabledDays.before : new Date())
      setSelectedDates(dates)
      break
    default:;
    }

    const rect = inputElement.getBoundingClientRect()
    const estPickerWidth = 307
    setPositioning({
      top: rect.top + rect.height + 10,
      left: rect.left < window.innerWidth - estPickerWidth ? rect.left : rect.right - estPickerWidth,
      anchor: rect.left < window.innerWidth - estPickerWidth ? rect.left + 2 : rect.right - 20 - 2,
      numMonths: Math.min(Math.max(1, Math.floor(rect.width / estPickerWidth)), 3),
    })
    setModalVisible(true)
    showModal()
  }

  const setTime = (range) => {
    if(range.from)
      range.from.setHours(0, 0, 0)
    else
      range.from = null
    if(range.to)
      range.to.setHours(23, 59, 59)
    else
      range.to = null
    return range
  }

  const addDayToRange = (day) => {
    const r = addToRange(day, range)
    const { from, to } = setTime(r)
    setSelectedDates([from, r])
    setRange(r)
    setModifiers({start: from, end: to})
    handleRangeChange(from, to)
    return r
  }

  const handleDayClick = (day, { selected }) => {
    switch(type) {
    case 'single':
      const date = []
      if(!selected)
        date.push(day)
      setSelectedDates(date)
      handleMultiChange(date)
      if(!selected)
        closeModal()
      break
    case 'range':
      if(!fullWeeksOnly) {
        const { from, to } = addDayToRange(day)
        if(autoClose && from && to)
          closeModal()
      } else {
        let r = { from: getStartOfWeek(day, 0, firstDayOfWeek),
                  to: getEndOfWeek(day, 0, firstDayOfWeek) }
        if(disabledDays && r.from < disabledDays.before)
          return
        if(numClicks === 1) {
          if(r.from < range.from)
            r.to = range.to
          else if(range.to < r.to)
            r.from = range.from
        }
        const { from, to } = setTime(r)
        setSelectedDates([from, r])
        setModifiers({start: from, end: to})
        setRange(r)
        handleRangeChange(from, to)
        if(numClicks === 0)
          setNumClicks(1)
        else
          closeModal()
      }
      break
    case 'multiple':
      const dates = [...selectedDates]
      if(!fullWeeksOnly) {
        if(selected) {
          const index = dates.findIndex(d => isSameDay(d, day))
          dates.splice(index, 1)
        } else {
          dates.push(day)
        }
      } else {
        if(disabledDays && getStartOfWeek(day, 0, firstDayOfWeek) < disabledDays.before)
          return
        for(let offset = 0; offset < 7; offset += 1) {
          const weekday = getStartOfWeek(day, offset, firstDayOfWeek)
          if(selected) {
            const index = dates.findIndex(d => isSameDay(d, weekday))
            dates.splice(index, 1)
          } else {
            dates.push(weekday)
          }
        }
      }
      dates.sort(dateCompare)
      setSelectedDates(dates)
      handleMultiChange(dates)
      break
    default:;
    }

    setTextInput('')
    setIsTextInputValid(true)
  }

  //const handleWeekClick = (weekNumber, days, e) => {
  //  //console.log('week', fullWeeksOnly)
  //  //if(!fullWeeksOnly)
  //    return
  //
  //  switch(type) {
  //  case 'single':
  //    //setSelectedDates(selected ? undefined : day)
  //    break
  //  case 'range':
  //    console.log('week', days)
  //    days.forEach((day, index) => {
  //      const { from, to } = addDayToRange(day)
  //      console.log(index, day, from, to)
  //      if(index === 6 && autoClose && from && to)
  //        closeModal()
  //    })
  //    break
  //  case 'multiple':
  //    //const dates = [...selectedDates]
  //    //if(selected) {
  //    //  const index = dates.findIndex(d => isSameDay(d, day))
  //    //  dates.splice(index, 1)
  //    //} else {
  //    //  dates.push(day)
  //    //}
  //    //dates.sort(dateCompare)
  //    //setSelectedDates(dates)
  //    //handleMultiChange(dates)
  //    break
  //  default:;
  //  }
  //
  //  setTextInput('')
  //  setIsTextInputValid(true)
  //}

  const [showModal, hideModal] = useModal(() => {
    const customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent',
        zIndex: 100
      },
      content: {
        position: 'absolute',
        top: positioning.top + 'px',
        left: positioning.left + 'px',
        right: 'auto',
        height: 'max-content',
        border: '1px',
        background: 'white',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '3px',
        outline: 'none',
        padding: '0px'
      }
    }

    const handleCloseRequest = () => {
      // In case the user selected only a single day in range mode and then
      // clicks outside to close the picker, we call handleRangeChange to
      // set end equal to start. We need to clone start here!
      if(type === 'range' && start !== null && end === null)
        addDayToRange(new Date(start))

      closeModal()
    }

    return (
      <div>
        <Arrow
          top={positioning.top - 10}
          left={positioning.anchor}
        />
        <ReactModal
          isOpen
          style={customStyles}
          onRequestClose={handleCloseRequest}
          keyboard={true}
        >
          <StyledPicker>
            <DayPicker
              weekStartsOn={firstDayOfWeek !== undefined ? firstDayOfWeek : 1}
              showWeekNumber={true}
              numberOfMonths={positioning.numMonths}
              className={type === 'range' ? 'Selectable' : undefined}
              defaultMonth={defaultMonth}
              disabled={disabledDays}
              selected={selectedDates}
              modifiers={modifiers}
              onDayClick={handleDayClick}
              //onWeekClick={handleWeekClick}
            />
          </StyledPicker>
        </ReactModal>
      </div>
    )}, [defaultMonth, disabledDays, selectedDates, modifiers, positioning])

  const formatAsText = useMemo(() => {
    if(isTextInputActive || !isTextInputValid)
      return textInput

    switch(type) {
    case 'range':
      const startStr = start ? fromDbTimestamp(start) : ''
      const endStr = end ? fromDbTimestamp(end) : ''

      if(startStr === '')
        return ''
      else if(endStr === '' || startStr === endStr)
        return startStr
      else
        return startStr + ' - ' + endStr
    case 'single':
    case 'multiple':
      if(dates === undefined || dates === null)
        return ''
      else
        return dates.reduce((a,c) => a + (a === '' ? '' : ', ') + fromDbTimestamp(c), '')
    default:
      return ''
    }
  }, [isTextInputActive, isTextInputValid, textInput, type, start, end, dates])

  const handleInputChange = (event) => {
    event.persist()
    const input = event.target.value
    let isValid = true

    switch(type) {
    case 'range':
      const dates = input.split('-')
      const hasEnd = 1 < dates.length;
      const start = toDbTimestampStrFromDate(dates[0].trim())
      const end = hasEnd ? toDbTimestampStrFromDate(dates[1].trim()) : new Date(start)
      const { from, to } = setTime({from: start, to: end})
      if(input === '' || (from === null || from < minDate || (hasEnd && (to === null || to < minDate || to < from)))) {
        isValid = false
        setRange({from: null, to: null})
        handleRangeChange(null, null)
      } else {
        isValid = true
        setRange({from: from, to: to})
        handleRangeChange(from, to)
      }
      break
    case 'single':
    case 'multiple':
      const dateStrings = input.split(',')
      const inputDates = []
      dateStrings.forEach((dateString, index) => {
        if(isValid) {
          const date = toDbTimestampStrFromDate(dateString.trim())
          if(date)
            inputDates.push(date)
          else
            isValid = false
        }
      })
      isValid = isValid && inputDates.length
      setIsTextInputValid(isValid)
      if(isValid) {
        inputDates.sort(dateCompare)
        handleMultiChange(uniqueDates(inputDates))
      } else {
        handleMultiChange([])
      }
      break
    default:
      return ''
    }

    setIsTextInputActive(true)
    setIsTextInputValid(isValid)
    setTextInput(input)
  }

  const handleFocusIn = () => {
    if(!modalVisible)
      showTimestampPicker()
  }

  const handleFocusOut = () => {
    if(!modalVisible && isTextInputValid)
      setTextInput('')

    setIsTextInputActive(false)
  }

  const clearInput = () => {
    switch(type) {
    case 'range':
      handleRangeChange(null, null)
      break
    case 'single':
    case 'multiple':
      handleMultiChange([])
      break
    default:;
    }

    setTextInput('')
    setIsTextInputActive(false)
  }

  return (type === '' ? <div>{'TimestampPicker type not set'}</div> :
    <div style={{
      display: 'flex',
      alignItems: 'center',
      background: isTextInputValid ? 'transparent' : '#FFA5A5',
      ...props.style
    }}>
      <StyledInput
        ref={handleRef}
        type='text'
        value={formatAsText}
        placeholder={props.optional ? t('shared.optional') : t('shared.pleaseSelect')}
        onFocus={handleFocusIn}
        onBlur={handleFocusOut}
        onClick={showTimestampPicker}
        onChange={handleInputChange}
      />
      <StandardButton
        style={{background: 'tranparent'}}
        normalColor='grey'
        hoverColor='darkgrey'
        icon='clear'
        handleClick={clearInput}
      />
    </div>
  )
}

export default TimestampPicker
