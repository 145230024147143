import React from 'react'
import { useTranslation } from 'react-i18next'

import ListButton from '../shared/ListButton'
import Table from '../shared/Table'
import { useDataInterface } from '../shared/DataInterface'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'

function Teams() {
  const { t } = useTranslation()
  const { queryPath } = useQueryEndPoint()

  const endPoint = 'admin/teams'
  const translationPrefix = 'admin.teams'
  
  // Data interface for users
  const userDataInterface = useDataInterface({ endPoint: 'admin/users' })

  return (
    <Table
      endPoint = {endPoint}
      translationPrefix = {translationPrefix}
    
      columns = {[
        {
          header: t(translationPrefix + '.name'),
          defaultProperty: 'name'
        },
        {
          header: t(translationPrefix + '.users'),
          defaultProperty: 'users',
          style: {justifyContent: 'center'},
          sortStringFunction: (data) => {
            return userDataInterface.data.filter(user => user['team_id'] === data.id).length
          },
          visualizationFunction: (data) => {
            const userNames = userDataInterface.data
                  .filter(user => user['team_id'] === data.id)
                  .map(user => user.name)
            return (
              <ListButton
                users
                data = {userNames}
                handleClick = {() => queryPath('admin/users', 'team_id', data.id)}
              />
            )
          }
        }
      ]}
    
      fields = {[
        {
          name: t(translationPrefix + '.name'),
          property: 'name',
        },
      ]}
    />
  )
}

export default Teams
