import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Spot from './Spot'
import InputContainer from '../../shared/InputContainer'

import { getResolutionDisplaySize } from '../../../utils/SpotHelper'

const StyledMarker = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.themeBackground};
  top: 0;
  width: 4px;
  height: 100%;
  z-index: 2;
`

function SpotRotation(props) {
  const { t } = useTranslation();

  const [dropIndex, setDropIndex] = useState(undefined)
  const [isDragOrigin, setIsDragOrigin] = useState(false)
  const [markerX, setMarkerX] = useState(0)

  const spots = props.spotsPerResolution &&
        props.spotIndex < props.spotsPerResolution.length ?
        props.spotsPerResolution[props.spotIndex] : []
  const numSpots = spots.length
  const [width, height] = getResolutionDisplaySize(props.resolution)

  const handleDragOver = (event) => {
    event.preventDefault()

    // Determine maximum drop index. If drag happens within the same spot
    // rotation, the spot cannot be appended at the end, so the maximum drop
    // index is numSpots - 1.
    const maxIdx = isDragOrigin ? numSpots - 1 : numSpots

    // Determine the dropIndex (which will the spot's new rotation_index).
    const targetX = event.currentTarget.getBoundingClientRect().x
    const x = event.clientX - targetX
    const idx = Math.max(0, Math.min(Math.floor(x / width), maxIdx))
    setDropIndex(idx)
    setMarkerX(idx * width)
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
    setDropIndex(undefined)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setDropIndex(undefined)

	 const origin = event.dataTransfer.getData('origin')
	 let spot = JSON.parse(event.dataTransfer.getData('spot'))

    const rotationRatio = null // TODO - determine rotation ratio based on user input

    // Handle the three different types of origin
    // 1 - drag from the library and drop into this spot rotation
    // 2 - drag and drop within this spot rotation
    // 3 - drag from another spot rotation into this spot rotation
    if(origin === 'library') {
      let spotMoves = []
      const numSpotsToMove = numSpots - dropIndex
      if(0 < numSpotsToMove) {
        spotMoves.push({
          booking_unit_id: props.bookingUnitId,
          resolution_id: props.resolution.id,
          direction: 1, // Move spots up, because we insert before them
          from_rotation_index: numSpots - 1, // Start at the end
          num_spots: numSpotsToMove
        })
      }
      
      spot['booking_unit_id'] = props.bookingUnitId
      spot['resolution_id'] = props.resolution.id
      spot['seconds'] = props.bookingUnit.seconds
      spot['approved'] = 0 // TODO - Set approved based on spot spot.status, where spot.status is set in the backend (or by moderator)
      spot['spotrules'] = 0 // TODO - How to set spotrules?
      spot['spotchange_id'] = props.spotChangeId
      spot['activation_timestamp'] = props.spotChangeActivationTimestamp
      spot['rotation_index'] = dropIndex
      spot['rotation_ratio'] = rotationRatio
      spot['spottriggers'] = 0 // TODO - How to set spottriggers?
      spot['spot_moves'] = spotMoves

      //console.log('DROP FROM LIBRARY', spot)
      //console.log(JSON.stringify(spotMoves))
      //return

      props.handleSaveEdit('POST', spot)
    }
    // Type of origin 2 (see above - within same spot rotation)
    else if(parseInt(origin) === props.resolution.id) {
      // Handle only when rotation_index changed, i.e. the spot was actually
      // moved to a valid new index. handleDragOver ensures that dropIndex
      // lies between 0 and numSpotsInRotation.
      if(dropIndex !== spot['rotation_index']) {
        const offset = spot['rotation_index'] - dropIndex
        // Move spots up or down, depending direction of drag
        const dir = Math.sign(offset)
        // When items are moved up (dir == 1), we start with neighbor to the
        // left of the dragged item, otherwise (dir == -1) with the neighbor
        // to the right.
        const from = spot['rotation_index'] - dir
        const spotMoves = [{
          booking_unit_id: props.bookingUnitId,
          resolution_id: props.resolution.id,
          direction: dir,
          from_rotation_index: from,
          num_spots: Math.abs(offset)
        }]

        spot['rotation_index'] = dropIndex
        spot['rotation_ratio'] = rotationRatio
        spot['spot_moves'] = spotMoves

        //console.log('DROP WITHIN RESOLUTION', spot)
        //console.log(JSON.stringify(spotMoves))
        //return

        props.handleSaveEdit('PUT', spot)
      }
    }
    // Type of origin 3 (see above - from different spot rotation)
    else {
      let spotMoves = []

      // Handle resolution that spot is dropped to
      let numSpotsToMove = numSpots - dropIndex
      if(0 < numSpotsToMove) {
        spotMoves.push({
          booking_unit_id: props.bookingUnitId,
          resolution_id: props.resolution.id,
          direction: 1, // Move spots up, because we insert before them
          from_rotation_index: numSpots - 1, // Start at the end
          num_spots: numSpotsToMove
        })
      }

      // Handle resolution that spot is dragged from
      numSpotsToMove = spot['num_spots_in_rotation'] - spot['rotation_index'] - 1
      if(0 < numSpotsToMove) {
        spotMoves.push({
          booking_unit_id: props.bookingUnitId,
          resolution_id: spot.resolution_id,
          direction: -1, // Move spots down, because item before was taken out
          from_rotation_index: spot['rotation_index'] + 1, // Start with right neighbor
          num_spots: numSpotsToMove
        })
      }

      spot['resolution_id'] = props.resolution.id
      spot['rotation_index'] = dropIndex
      spot['rotation_ratio'] = rotationRatio
      spot['spot_moves'] = spotMoves

      //console.log('DROP FROM ONE RESOLUTION TO ANOTHER', spot)
      //console.log(JSON.stringify(spotMoves))
      //return

      props.handleSaveEdit('PUT', spot)
    }
  }

  const userCanDrag = props.userCanEdit &&
        (props.multipleResolutionsAvailable || 1 < numSpots)

  const spotComponents = spots.map(spot => {
    return (
      <Spot
        key = {spot.id}
        width = {width}
        height = {height}
        spot = {spot}
        translationPrefix = {props.translationPrefix}
        dragAndDropOrigin = {props.resolution.id}
        numSpotsInRotation = {spots.length}
        userCanDrag = {userCanDrag}
        handleDragStart = {() => setIsDragOrigin(true)}
        handleDragEnd = {() => setIsDragOrigin(false)}
        userCanEdit = {props.userCanEdit}
        handleDelete = {props.handleDelete}
      />
    )
  })

  return (
    <InputContainer
      header = {t(props.translationPrefix + '.spotRotation') + ' ' + props.resolution.name}
      marginBottom={props.marginBottom}
      background={'rgba(0, 0, 0, 0.1)'}
    >
      <div
        style = {{
          display: 'flex',
          position: 'relative',
          height: height,
          minHeight: '2em'
        }}
	     onDragOver = {handleDragOver}
	     onDragLeave = {handleDragLeave}
        onDrop = {handleDrop}
      >
        {spotComponents}
        {(dropIndex !== undefined) &&
          <StyledMarker style = {{left: markerX}} />
        }
      </div>
    </InputContainer>
  )
}

export default SpotRotation
