import React, { useState } from 'react'
import { DayPicker } from 'react-day-picker'
import ReactModal from 'react-modal'
import styled from 'styled-components'

import CancelSave from '../../shared/CancelSave'

import { StyledPicker } from '../../styling/InputStyling'

const Arrow = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  left: ${props => props.left + 'px'};
  top: ${props => props.top + 'px'};
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FFF;
`

function SpotChangeEdit(props) {
  const { onRequestClose, handleSave, editData } = props

  const [selectedDate, setSelectedDate] = useState(editData.selected)

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'transparent',
      zIndex: 100
    },
    content: {
      position: 'absolute',
      top: editData.top + 'px',
      left: editData.left + 'px',
      width: '290px',
      height: 'max-content',
      border: '1px',
      background: 'white',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '3px',
      outline: 'none',
      padding: '0px'
    }
  }

  const internalHandleSave = () => {
    const date = selectedDate !== undefined ? new Date(selectedDate.getTime()) : undefined
    if(date !== undefined)
      date.setHours(0, 0, 0)
    handleSave(date)
    onRequestClose()
  }

  return (
    <div>
      <Arrow
        top={editData.top - 10}
        left={editData.anchor}
      />
      <ReactModal
        isOpen
        style={customStyles}
        onRequestClose={onRequestClose}
        keyboard={true}
      >
        <StyledPicker>
          <DayPicker
            firstDayOfWeek={1}
            defaultMonth={editData.initial}
            disabled={editData.disabled}
            selected={selectedDate}
            onDayClick={(day, { selected }) => setSelectedDate(selected ? undefined : day)}
          />
          <CancelSave
            handleCancel={onRequestClose}
            handleSave={internalHandleSave}
            canSave={selectedDate !== undefined}
          />
        </StyledPicker>
      </ReactModal>
    </div>
  )
}

export default SpotChangeEdit
