import React, { useState } from 'react'

import IntroColumn from './IntroColumn'

import { FullscreenNavbar } from '../styling/Fullscreen'

import { useAuth } from '../auth/Authentication'

function Home() {
  const { canViewAdmin, canViewContent, canViewReporting } = useAuth();

  const [selectedColumnIdx, setSelectedColumnIdx] = useState(-1)
  const handleClick = (index) => {
    setSelectedColumnIdx(selectedColumnIdx === index ? -1 : index)
  }

  const categories = [
    { path: 'admin', canView: canViewAdmin },
    { path: 'content', canView: canViewContent },
    { path: 'reporting', canView: canViewReporting }
  ]

  const columns = categories.map((category, index) => {
    return !category.canView ? null : (
      <IntroColumn
        key={index}
        path={category.path}
        selected={selectedColumnIdx === index}
        handleClick={() => handleClick(index)}
      />
    )
  })

  return (
    <FullscreenNavbar isLandingPage={true}>
      {columns}
    </FullscreenNavbar>
  )
}

export default Home
