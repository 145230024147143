import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  cBlocksOfPlayers,
  cDefaultSpotLength,
  cDailyResolution,
  cHourlyResolution,
  cImplicitPlayers,
  cInvoicingEnabled,
  cPricingUnit,
  cSpotFees,
  cZonesEnabled,
} from '../../../Theme'
import {
  disabledHours,
  disabledWeekdays,
  disabledWeekdaysFromRange,
  hourDefaultMask,
  weekdayDefaultMask,
} from '../../../utils/BookingUnitHelper'
import { currencyUnit, localePrice } from '../../../utils/Locale'
import {
  activeDays,
  applyHourTargeting,
  pricePerSecond,
  weekdaysFromDates,
} from '../../../utils/PlayerHelper'
import BitSelector from '../../shared/BitSelector'
import InputContainer from '../../shared/InputContainer'
import {
  StyledColumnHeader,
  StyledDisabledContainer,
  StyledDisabledOverlay,
  StyledInput,
} from '../../styling/InputStyling'
import { StyledText } from '../../styling/TextStyling'
import BookingUnitSpotUpload from './BookingUnitSpotUpload'
import Clusters from './Clusters'
import DateInput from './DateInput'
import InvoicePreview from './InvoicePreview'
import MaxBlocksInput from './MaxBlocksInput'
import ReservationTeamInput from './ReservationTeamInput'
import RotationInput from './RotationInput'
import SitesAndPlayers from './SitesAndPlayers'
import SpotLengthInput from './SpotLengthInput'
import SpotOriginInput from './SpotOriginInput'
import SubscriptionCustomerInput from './SubscriptionCustomerInput'
import Zones from './Zones'

function BookingUnitEdit(props) {
  const { t } = useTranslation()
  const { inputs, setInputs, handleCompletedChanged, searchBooking } = props

  const [leftCompleted, setLeftCompleted] = useState(true)
  const [rightCompleted, setRightCompleted] = useState(false)

  // Handle standard input fields
  const handleInputChange = (event) => {
    event.persist()
    const { name, value, type, checked } = event.target
    setInputs((inputs) => ({
      ...inputs,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    }))
  }

  // Update the price of the booking unit on user input
  useEffect(() => {
    const days = inputs.use_range
      ? activeDays(
          inputs.start_timestamp,
          inputs.end_timestamp,
          inputs.weekdays
        )
      : inputs.individual_dates.length

    let price = 0.0
    let anyPlayerChecked = false

    if(!cBlocksOfPlayers) {
      // TODO - handle discounts
      // inputs.hours is not needed for the calculation of secsTotal, instead
      // it has an impact on inputs.repeats_per_day via RotationInput.js.
      const secsTotal = inputs.repeats_per_day * inputs.spot_length_secs * days

      props.players.forEach((player) => {
        if(player.checked) {
          anyPlayerChecked = true
          if(Number.isFinite(secsTotal))
            price += pricePerSecond(player, props.sites, props.clusters) * secsTotal
        }
      })
    } else {
      props.sites.forEach((site) => {
        if(cPricingUnit !== 'week')
          throw new Error(`cPricingUnit !== 'week'`)
        const stdUnits = (inputs.spot_length_secs < cDefaultSpotLength ? (2*57.0/95.0) : 1) *
              (inputs.spot_length_secs / cDefaultSpotLength) * (days / 7)
        if(site.checked) {
          anyPlayerChecked = true
          if(Number.isFinite(stdUnits)) {
            let maxBlocks = inputs.repeats_per_day
            maxBlocks = typeof maxBlocks === 'string' ? parseInt(maxBlocks) : maxBlocks
            const numBlocks = maxBlocks === 0
                  ? site.num_blocks
                  : Math.min(site.num_blocks, maxBlocks)
            price += site.price_per_second * stdUnits * numBlocks
          }
        }
      })
    }

    price = applyHourTargeting(price, inputs.hours)

    const leftSideOk = anyPlayerChecked && (!cZonesEnabled || inputs.zones !== 0)
    var rightSideOk
    if(!searchBooking) {
      rightSideOk = ((inputs.use_range &&
                      inputs.start_timestamp !== null &&
                      inputs.end_timestamp !== null) ||
                     (!inputs.use_range &&
                      0 < inputs.individual_dates.length)) &&
        0 < (inputs.weekdays & (weekdayDefaultMask() - 1)) &&
        0 < (inputs.hours & (hourDefaultMask() - 1)) &&
        ((!cBlocksOfPlayers && 0 < inputs.repeats_per_day) || cBlocksOfPlayers) &&
        0 < inputs.spot_length_secs
    } else {
      const isTargeted = (!props.excludeBudgetAndImpressions
                          && (0 < inputs.budget || 0 < inputs.impressions))
      rightSideOk = (true || isTargeted || anyPlayerChecked) &&
        (isTargeted || ((inputs.use_range &&
                         inputs.start_timestamp !== null &&
                         inputs.end_timestamp !== null) ||
                        (!inputs.use_range &&
                         0 < inputs.individual_dates.length))) &&
        (isTargeted || 0 < (inputs.weekdays & (weekdayDefaultMask() - 1))) &&
        (isTargeted || 0 < (inputs.hours & (hourDefaultMask() - 1))) &&
        (isTargeted || ((!cBlocksOfPlayers && 0 < inputs.repeats_per_day) || cBlocksOfPlayers)) &&
        (0 < inputs.spot_length_secs) &&
        (!props.includeReservationTeam || 0 < inputs.bookingattributes)
    }

    let completedChanged = false
    if(leftSideOk !== leftCompleted) {
      setLeftCompleted(leftSideOk)
      completedChanged = true
    }
    if(rightSideOk !== rightCompleted) {
      setRightCompleted(rightSideOk)
      completedChanged = true
    }
    if(completedChanged && handleCompletedChanged)
      handleCompletedChanged(leftSideOk && rightSideOk)

    setInputs((inputs) => ({ ...inputs, price: price }))
  }, [
    props.clusters,
    props.sites,
    props.players,
    props.excludeBudgetAndImpressions,
    props.includeReservationTeam,
    inputs.budget,
    inputs.impressions,
    inputs.start_timestamp,
    inputs.end_timestamp,
    inputs.use_range,
    inputs.individual_dates,
    inputs.weekdays,
    inputs.hours,
    inputs.repeats_per_day,
    inputs.spot_length_secs,
    inputs.bookingattributes,
    inputs.zones,
    setInputs,
    leftCompleted,
    rightCompleted,
    handleCompletedChanged,
    searchBooking,
  ])

  return (
    <OuterContainer>
      {/* Left column */}
      <LeftColumnContainer newBooking={props.newBooking}>
        {props.newBooking && (
          <StyledDisabledContainer hidden={props.leftEnabled || searchBooking}>
            <StyledDisabledOverlay />
          </StyledDisabledContainer>
        )}
        {props.newBooking && (
          <StyledColumnHeader completed={leftCompleted}>
            <StyledText medium bold upper>
              {(!cImplicitPlayers ? t('content.new.network') : t('content.new.network_implicit_players')) +
               (cZonesEnabled ? ' / ' + t('shared.zones') : '')}
            </StyledText>
          </StyledColumnHeader>
        )}

        <InputContainer
          dark={props.dark}
          marginBottom={'20px'}
          header={t('shared.clusters')}
          tutorialId={'BookingUnitEdit.clusters'}
        >
          <Clusters
            clusters={props.clusters}
            handleClusterChange={props.handleClusterChange}
          />
        </InputContainer>

        <InputContainer
          dark={props.dark}
          marginTop={'20px'}
          marginBottom={'20px'}
          header={t('shared.sites') + (cImplicitPlayers ? '' : ' / ' + t('shared.players'))}
          tutorialId={'BookingUnitEdit.sites'}
        >
          <SitesAndPlayers
            sites={props.sites}
            handleSiteExpanderClick={props.handleSiteExpanderClick}
            handleSiteChange={props.handleSiteChange}
            players={props.players}
            handlePlayerChange={props.handlePlayerChange}
          />
        </InputContainer>

        {cZonesEnabled && (
          <InputContainer
            dark={props.dark}
            marginTop={'20px'}
            marginBottom={'20px'}
            header={t('shared.zones')}
            tutorialId={'BookingUnitEdit.zones'}
          >
            <Zones
              inputs={inputs}
              setInputs={setInputs}
            />
          </InputContainer>
        )}
      </LeftColumnContainer>

      {/* Right column */}
      <RightColumnContainer newBooking={props.newBooking}>
        {props.newBooking && (
          <StyledDisabledContainer hidden={(props.leftEnabled && leftCompleted) || searchBooking}>
            <StyledDisabledOverlay />
          </StyledDisabledContainer>
        )}
        {props.newBooking && (
          <StyledColumnHeader completed={rightCompleted}>
            <StyledText medium bold upper>
              {cBlocksOfPlayers ? t('content.new.schedule_blocks') : t('content.new.schedule')}
            </StyledText>
          </StyledColumnHeader>
        )}

        {props.includeReservationTeam && (
          <ReservationTeamInput
            inputs={inputs}
            handleInputChange={handleInputChange}
          />
        )}

        {props.includeSubscriptionCustomer && (
          <SubscriptionCustomerInput
            inputs={inputs}
            setInputs={setInputs}
          />
        )}

        {searchBooking && !props.excludeBudgetAndImpressions && (
          <div style={{display: 'flex', width: '100%'}}>
            <InputContainer dark left
              style={{flexGrow: 1, flexBasis: 0}}
              header={t('shared.budget') + currencyUnit()}
              marginBottom={'20px'}
            >
              <StyledInput
                type='number'
                min='0'
                value={inputs['budget']}
                name={'budget'}
                placeholder = {t('shared.optional')}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer dark left
              style={{flexGrow: 1, flexBasis: 0}}
              header={t('shared.impressions')}
              marginBottom={'20px'}
            >
              <StyledInput
                type='number'
                min='0'
                value={inputs['impressions']}
                name={'impressions'}
                placeholder = {t('shared.optional')}
                onChange={handleInputChange}
              />
            </InputContainer>
          </div>
        )}

        <DateInput
          fullWeeksOnly={!cDailyResolution}
          firstDayOfWeek={props.firstDayOfWeek}
          optional={false && searchBooking}
          inputs={inputs}
          setInputs={setInputs}
          tutorialId={'BookingUnitEdit.dates'}
        />

        {cDailyResolution && (
          <InputContainer
            dark={props.dark}
            marginTop={'20px'}
            marginBottom={'20px'}
            header={t('shared.weekdays')}
          >
            <BitSelector
              type={'weekdays'}
              disabledBits={
                inputs['use_range']
                  ? disabledWeekdaysFromRange(inputs['start_timestamp'], inputs['end_timestamp'])
                  : disabledWeekdays()
              }
              bits={
                inputs['use_range']
                  ? inputs['weekdays']
                  : weekdaysFromDates(inputs['individual_dates'])
              }
              readOnly={!inputs['use_range']}
              onChange={(bits) =>
                setInputs((inputs) => ({ ...inputs, weekdays: bits }))
              }
            />
          </InputContainer>
        )}

        {cHourlyResolution && (
          <InputContainer
            dark={props.dark}
            marginTop={'20px'}
            marginBottom={'20px'}
            header={t('shared.hours')}
          >
            <BitSelector
              type={'hours'}
              disabledBits={disabledHours()}
              bits={inputs['hours']}
              onChange={(bits) =>
                setInputs((inputs) => ({ ...inputs, hours: bits }))
              }
            />
          </InputContainer>
        )}

        {!cBlocksOfPlayers ? (
          <div style={{ display: 'flex' }}>
            <InputContainer
              left
              dark={props.dark}
              style={{ flexGrow: 1, flexBasis: 0 }}
              marginTop={'0px'}
              marginBottom={'0px'}
              header={t('shared.repeatsPerDay')}
            >
              <StyledInput
                type="number"
                value={inputs['repeats_per_day']}
                name={'repeats_per_day'}
                onChange={handleInputChange}
              />
            </InputContainer>

            <RotationInput inputs={inputs} setInputs={setInputs} />
          </div>
        ) : (
          <MaxBlocksInput
            inputs={inputs}
            handleInputChange={handleInputChange}
          />
        )}

        {!props.excludeSpotLengthInput && (
          <SpotLengthInput
            inputs={inputs}
            handleInputChange={handleInputChange}
            options={props.allowedSpotLengths}
          />
        )}

        {props.newBooking === undefined && (
          <InputContainer
            dark={props.dark}
            marginTop={'20px'}
            marginBottom={'20px'}
            header={t('shared.list_price')}
          >
            <StyledText
              dark
              style={{
                fontSize: 14,
                padding: 15,
              }}
            >
              {localePrice(inputs['price'], 0)}
            </StyledText>
          </InputContainer>
        )}

        {props.searchBooking && cSpotFees && !props.excludeSpotOrigin && (
          <SpotOriginInput
            inputs={inputs}
            handleInputChange={handleInputChange}
            tutorialId={'BookingUnitEdit.spot_origin'}
          />
        )}

        {cInvoicingEnabled && props.searchBooking && !props.includeReservationTeam && (
          <InvoicePreview
            dark={props.dark}
            header={t('shared.invoice_preview')}
            hasZeroPrice={props.hasZeroPrice}
            price={props.hasZeroPrice ? 0.0 : inputs['price']}
            spotOrigin={inputs['spot_origin']}
          />
        )}

        {props.newBooking && props.resolutions && (
          <InputContainer
            style={{visibility: props.resolutions.length ? 'visible' : 'hidden'}}
            dark={props.dark}
            marginTop={'20px'}
            marginBottom={'20px'}
            header={t('content.booking.spotUpload.title')}
          >
            <StyledText dark large>
              {<BookingUnitSpotUpload resolutions={props.resolutions} />}
            </StyledText>
          </InputContainer>
        )}
      </RightColumnContainer>
    </OuterContainer>
  )
}

export default BookingUnitEdit

const OuterContainer = styled.div`
  display: flex;
`

const LeftColumnContainer = styled.div`
  width: 50%;
  padding: ${(props) => (props.newBooking ? '20px' : '0px 10px 20px 0px')};
`

const RightColumnContainer = styled.div`
  width: 50%;
  padding: ${(props) => (props.newBooking ? '20px' : '0px 0px 20px 10px')};
`
