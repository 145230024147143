import React from 'react'

import { AuthInner, AuthLogo, AuthOuter } from '../styling/AuthStyling'

import ZiiCON from '../../images/ZiiCON.png'

function Background(props) {
  return (
    <AuthOuter>
      <AuthInner>
        {props.children}
      </AuthInner>
      <AuthLogo src = {ZiiCON}/>
    </AuthOuter>
  )
}

export default Background
