import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { cBlocksOfPlayers, cHourlyResolution } from '../../Theme'
import BitSelector from '../shared/BitSelector'
import Clear from '../shared/Clear'

import { StyledText } from '../styling/TextStyling'

import {
  convertIndividualDatesFromDb,
  disabledWeekdays,
  disabledWeekdaysFromRange,
  disabledHours
} from '../../utils/BookingUnitHelper'
import { weekdaysFromDates } from '../../utils/PlayerHelper'
import { fromDbTimestamp } from '../../utils/TimestampHelper'

function BookingOverview(props) {
  const { t } = useTranslation();

  const { booking, dark, bold } = props

  const disabledWeekdayBits = useMemo(() => {
    if(!booking.hasOwnProperty('booking_id')
       || (booking.hasOwnProperty('use_range') && !booking['use_range'])
       || booking['individual_dates'] !== null)
      return disabledWeekdays()
    else
      return disabledWeekdaysFromRange(booking['start_timestamp'], booking['end_timestamp'])
  }, [booking])

  const weekdayBits = useMemo(() => {
    if(!booking.hasOwnProperty('booking_id')
       || (booking.hasOwnProperty('use_range') && !booking['use_range'])
       || booking['individual_dates'] === null) {
      return booking.weekdays
    } else {
      const data = { individual_dates: booking.individual_dates }
      convertIndividualDatesFromDb(data)
      return weekdaysFromDates(data.individual_dates)
    }
  }, [booking])

  return (
    <Container>
      {(booking.booking_unit_count !== undefined && 1 < booking.booking_unit_count) &&
       <StyledText dark={dark} style = {{margin: '0 0 2px 0'}}>
          {t('content.bookings.multiple', {count: booking.booking_unit_count})}
        </StyledText>
      }
      <div style = {{display: 'inline'}}>
        <StyledText dark={dark} bold={bold} style = {{margin: '4px 0', float: 'left'}}>
          {fromDbTimestamp(booking.start_timestamp) + ' - ' +
           fromDbTimestamp(booking.end_timestamp)}
        </StyledText>
        <StyledText dark={dark} bold={bold} style={{margin: '4px 0', float: 'right'}}>
          {(cBlocksOfPlayers
            ? (booking.repeats_per_day === 0
               ? t('shared.all') + ' ' + t('shared.blocks')
               : (booking.repeats_per_day === 1
                  ? 1 + ' ' + t('shared.block')
                  : booking.repeats_per_day + ' ' + t('shared.blocks')
                 )
              )
            : booking.repeats_per_day + 'x') +
           ' / ' + booking.spot_length_secs + 's'}
        </StyledText>
        <Clear />
      </div>
      <BitSelector readOnly
        style={{marginBottom: 4}}
        type={'weekdays'}
        disabledBits={disabledWeekdayBits}
        bits={weekdayBits}
      />
      {cHourlyResolution && (
        <BitSelector
          readOnly
          type={'hours'}
          disabledBits={disabledHours()}
          bits={booking.hours}
        />
      )}
    </Container>
  )
}

export default BookingOverview

const Container = styled.div`
  width: 100%;
`
