import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModal } from 'react-modal-hook'
import { useNavigate } from 'react-router-dom'

import BookingOverview from './BookingOverview'
import BookingProof from './BookingProof'
import BookingSpecial from './BookingSpecial'
import Pending from '../reporting/Pending'
import LinkButton from '../shared/LinkButton'
import ListButton from '../shared/ListButton'
import NewItemButton from '../shared/NewItemButton'
import Table from '../shared/Table'

import { useBookingActions } from './BookingActions'
import { useMetaDataInterface } from './booking/MetaData'
import { cImplicitPlayers, cInvoicingEnabled } from '../../Theme'
import { useAuth } from '../auth/Authentication'
import { useDataInterface } from '../shared/DataInterface'

import { StyledText } from '../styling/TextStyling'

import { localePrice } from '../../utils/Locale'
import { roundDecimal } from '../../utils/Math'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'
import { toBookingId } from '../../utils/TimestampHelper'

function TableOfBookings(props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { endPointFromQueryPath, queryPath } = useQueryEndPoint()
  const auth = useAuth()
  const { authCall, userHasPermissions } = auth

  // Endpoint
  const category = props.category ? props.category : 'bookings'
  const bookingType = category === 'vacancies' ? 'vacancy' : category.slice(0, -1)
  const endPoint = 'content/' + props.category
  const tableDataEndPoint = endPointFromQueryPath(endPoint + (props.query ? props.query : ''))
  const translationPrefix = 'content.bookings'
  const isQuotations = category === 'quotations'

  // Data interfaces for customers, clients, and sectors
  const customerDataInterface = useDataInterface({ endPoint: 'admin/customers' })
  const clientDataInterface = useDataInterface({ endPoint: 'admin/users?include_deleted=1' })
  const sectorDataInterface = useDataInterface({ endPoint: 'admin/sectors' })
  const idToName = (dataInterface, id) => {
    const item = dataInterface.data.find((item) => item.id === id)
    return item ? item.name : ''
  }

  // Edit booking meta data
  const { editBooking, editBookingRefreshId } = useMetaDataInterface()

  const standardActions = cInvoicingEnabled
        ? (!['quotations', 'reservations', 'vacancies'].includes(category) ? ['edit'] : ['edit', 'delete'])
        : ['duplicate', 'edit', 'delete']
  const standardActionEndPoints = new Map()
  standardActionEndPoints.set('duplicate', 'content/bookings')
  standardActionEndPoints.set('edit', 'content/bookings')
  const standardActionPermissionOk = cInvoicingEnabled ? new Map() : undefined
  if(cInvoicingEnabled)
    standardActionPermissionOk.set('delete', (b) => ['quote_cancelled', 'free'].includes(b['state']))
  else
    standardActionEndPoints.set('delete', 'content/bookings')

  // Custom actions
  const [clickedBookingId, setClickedBookingId] = useState(-1)
  const [clickedBooking, setClickedBooking] = useState({})
  const [showModalReporting, hideModalReporting] = useModal(() => {
    return (
      <Pending
        authCall={authCall}
        bookingId={clickedBookingId}
        hideModal={hideModalReporting}
      />
    )
  }, [authCall, clickedBookingId])
  const [showModalBookingProof, hideModalBookingProof] = useModal(() => {
    return (
      <BookingProof
        auth={auth}
        bookingId={clickedBookingId}
        hideModal={hideModalBookingProof}
      />
    )
  }, [auth, clickedBookingId])
  const [showModalBookingSpecial, hideModalBookingSpecial] = useModal(() => {
    return (
      <BookingSpecial
        auth={auth}
        bookingId={clickedBookingId}
        booking={clickedBooking}
        hideModal={hideModalBookingSpecial}
        canAdd={userHasPermissions(endPoint, 'add')}
        canEdit={userHasPermissions(endPoint, 'edit')}
        canDelete={userHasPermissions(endPoint, 'delete')}
      />
    )
  }, [auth, clickedBookingId, userHasPermissions])

  const customActions = (['bookings', 'vacancies'].includes(category) ? [
    {
      type: 'reporting',
      permissionsOk: userHasPermissions('reporting/reports', 'view'),
      handleClick: (id) => {
        setClickedBookingId(id)
        showModalReporting()
      },
    },
  ] : []).concat(category !== 'bookings' ? [] : [
    {
      type: 'booking_proof',
      permissionsOk: userHasPermissions(endPoint, 'view'),
      handleClick: (id) => {
        setClickedBookingId(id)
        showModalBookingProof()
      },
    },
    {
      type: 'booking_special',
      permissionsOk: userHasPermissions(endPoint, 'view'),
      handleClick: (id, data) => {
        setClickedBookingId(id)
        setClickedBooking(data)
        showModalBookingSpecial()
      },
    },
    //{
    //  type: 'booking_problem',
    //  permissionsOk: userHasPermissions(endPoint, 'view'),
    //  handleClick: () => console.log('Show bookingProblem'),
    //},
  ]).concat(useBookingActions(!isQuotations ? 'ordered' : 'quoted'))

  const newItemButton = (
    <NewItemButton
      text={t('content.' + category + '.new')}
      handleClick={() => navigate('/content/new/' + bookingType)}
    />
  )

  return (
    <Table
      endPoint={tableDataEndPoint}
      title={t('content.' + category + '.title')}
      translationPrefix={translationPrefix}
      rowHeight={155}
      noNewItemButton={true}
      categoryHeaderItem={newItemButton}
      standardActions={standardActions}
      standardActionEndPoints={standardActionEndPoints}
      standardActionPermissionOk={standardActionPermissionOk}
      customActions={customActions}
      customHandleEdit={editBooking}
      refreshId={editBookingRefreshId}

      columns={[
        {
          header: t(translationPrefix + '.idName'),
          style: {justifyContent: 'center'},
          defaultProperty: 'name',
          visualizationFunction: (data) => {
            const id = toBookingId(data['creation_timestamp'], data['id'])
            return (
              <div>
                <StyledText dark>{id}</StyledText>
                <br />
                <StyledText dark>{data['name']}</StyledText>
              </div>
            )
          },
        },
        {
          header: t(translationPrefix + '.customerUserSector'),
          defaultProperty: 'customer_id',
          sortStringFunction: (data) => {
            const customer = idToName(customerDataInterface, data['customer_id'])
            const client = idToName(clientDataInterface, data['client_id'])
            const sector = idToName(sectorDataInterface, data['sector_id'])
            return customer + 'ZZZ' + client + 'ZZZ' + sector
          },
          visualizationFunction: (data) => {
            const customer = idToName(customerDataInterface, data['customer_id'])
            const client = idToName(clientDataInterface, data['client_id'])
            const sector = idToName(sectorDataInterface, data['sector_id'])

            return (
              <div>
                {customer !== '' && (
                  <LinkButton
                    type={'customer'}
                    text={customer}
                    target={'admin/customers'}
                    param={'id'}
                    value={data['customer_id']}
                  />
                )}
                {customer !== '' && <div style={{ height: 6 }} />}
                {client !== '' && (
                  <LinkButton
                    type={'user'}
                    text={client}
                    target={'admin/users'}
                    param={'id'}
                    value={data['client_id']}
                  />
                )}
                {client !== '' && <div style={{ height: 6 }} />}
                {sector !== '' && (
                  <LinkButton
                    type={'sector'}
                    text={sector}
                    target={'admin/sectors'}
                    param={'id'}
                    value={data['sector_id']}
                  />
                )}
              </div>
            )
          },
        },
        {
          header: t(translationPrefix + '.periodRepeats'),
          defaultProperty: 'start_timestamp',
          style: {width: '260px'},
          visualizationFunction: (data) => {
            if(data.booking_unit_count === undefined || data.booking_unit_count === 0)
              return null
            else
              return <BookingOverview dark booking={data} />
          },
        },
        {
          header: t(translationPrefix + (cImplicitPlayers ? '.sitesSpots' : '.playersSpots')),
          defaultProperty: 'player_count',
          visualizationFunction: (data) => {
            return (
              <div>
                <ListButton
                  counter={data['player_count']}
                  source={'admin/players?booking_id=' + data.id}
                  handleClick={() => {
                    if(!cImplicitPlayers)
                      queryPath('admin/players', 'booking_id', data.id)
                    else
                      queryPath('admin/sites', 'booking_id', data.id)
                  }}
                />
                <div style={{ height: 6 }} />
                <ListButton
                  spots
                  disabledCount={-1}
                  counter={data['spot_count']}
                  data={[t(translationPrefix + '.spotManagement')]}
                  handleClick={() => queryPath(endPoint + '/' + bookingType, 'id', data.id)}
                />
              </div>
            )
          },
        },
        {
          header: t('shared.price') + ' / ' + t('shared.discount'),
          defaultProperty: 'price',
          style: {justifyContent: 'center'},
          visualizationFunction: (data) => {
            const price = localePrice(data['price'])
            const discount = 0 < data['discount']
                  ? roundDecimal(data['discount']) + ' % ' + t('shared.discount') : ''
            const info = discount !== '' ? data['info'] : ''
            const state = t('shared.booking_state') + ': ' + t('shared.' + data['state'])

            return (
              <div style={{maxHeight: 130, overflowY: 'auto'}}>
                <StyledText bold dark centered>{price}</StyledText>
                <br />
                {discount !== '' && <StyledText dark>{discount}</StyledText>}
                {info !== '' && <StyledText dark>{info}</StyledText>}
                <br />
                <StyledText dark centered>{state}</StyledText>
              </div>
            )
          },
        },
      ]}
    />
  )
}

export default TableOfBookings
