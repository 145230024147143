import React from 'react'
import { useTranslation } from 'react-i18next'
import { useModal } from 'react-modal-hook'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
//import { PulseShadow } from '../../utils/Animations'
import { cTutorialEnabled } from '../../Theme'
import { useAuth, UserPrefs } from '../auth/Authentication'
import PendingUploads from '../content/booking/spot/PendingUploads'
import Dashboard from '../dashboard/Dashboard'
import ReactModalWrapper from '../shared/ReactModalWrapper'
import Select from '../shared/Select'
import { useSpot } from '../shared/SpotProvider'
import { FullscreenContainer } from '../styling/Fullscreen'
import HeaderButton from './HeaderButton'
import HeaderLogo from './HeaderLogo'
import SimUser from './SimUser'

function Header(props) {
  const { t, i18n } = useTranslation()
  const auth = useAuth()
  const { logout, username, toggleUserPref } = props.externalAuth ? props.externalAuth : auth
  const navigate = useNavigate()
  const isDashboard = props.dashboard !== undefined

  const changeLanguage = (lng) => i18n.changeLanguage(lng)

  const [showModalDashboard, hideModalDashboard] = useModal(() => (
    <ReactModalWrapper
      layout={'fullscreen'}
      onRequestClose={hideModalDashboard}
    >
      <FullscreenContainer>
        <Header
          dashboard
          externalAuth={auth}
          handleCancel={hideModalDashboard}
        />
        <Dashboard
          auth={auth}
          close={hideModalDashboard}
        />
      </FullscreenContainer>
    </ReactModalWrapper>
  ), [auth])

  const [showModalUploads, hideModalUploads] = useModal(() => (
    <ReactModalWrapper onRequestClose={hideModalUploads}>
      <PendingUploads handleClose={hideModalUploads} />
    </ReactModalWrapper>
  ))

  const homeClicked = () => {
    if(isDashboard)
      props.handleCancel()
    else
      navigate('/')
  }

  const { uploading } = useSpot()

  return (
    <Wrapper>
      <HeaderLogo handleClick={homeClicked} />
      {process.env.NODE_ENV !== 'production' && !isDashboard &&
        <SimUser />
      }
      <HeaderButton
        icon={isDashboard ? 'cancel_presentation' : 'dashboard'}
        title={isDashboard ? t('dashboard.leave') : t('dashboard.title')}
        handleClick={isDashboard ? props.handleCancel : showModalDashboard}
      />
      <HeaderButton
        icon={'cloud_upload'}
        title={t('content.booking.spotUpload.pending')}
        handleClick={showModalUploads}
        animated={uploading}
      />
      {!isDashboard && cTutorialEnabled && (
        <HeaderButton
          icon={'help_center'}
          title={t('tutorial.title')}
          handleClick={() => toggleUserPref(UserPrefs.TutorialVisible)}
        />
      )}
      <HeaderButton
        icon={'exit_to_app'}
        title={t('shared.logout', { name: username })}
        handleClick={logout}
      />
      <StyledSelect
        bold
        noArrow
        value={i18n.language}
        onChange={(e) => changeLanguage(e.target.value)}
        options={[{ value: 'en', text: 'EN' }, {value: 'de', text: 'DE' }]}
      />
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.themeHeaderBackground};
`

const StyledSelect = styled(Select)`
  width: ${(props) => props.theme.header.height};
  height: ${(props) => props.theme.header.height};
  color: ${(props) => props.theme.colors.themeHeaderColor};

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.colors.themeHighlightColor};
  }
`
