import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import { navItemFlags, navItems, navPermissionAction } from "./Items";
import Icon from "../shared/Icon";

import { StyledText } from "../styling/TextStyling";

import { useAuth } from "../auth/Authentication";

function IntroColumn(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const { userHasPermissions } = useAuth();

  const items = props.items ? props.items : navItems(props.path);

  const fontSize = 17;
  const icon =
    props.path === "admin"
      ? "settings"
      : props.path === "content"
      ? "perm_media"
      : "pie_chart";

  let sectionTitle = undefined
  const links = items.map((item, index) => {
    let title = t(props.path + '.' + item.path + '.' + (item.title ? t(item.title) : 'title'))

    // In case the item starts a new section, we need to store the section title
    if(item.section !== undefined) {
      sectionTitle = item.section ? t(item.section) : ''
      // In case the new section title is identical to the title, use a default for the title.
      if(title === sectionTitle)
        title = t("shared.management")
    }

    // Check if the user has the permissions to view the current item
    const path = props.path + "/" + item.path
    const action = navPermissionAction(item)
    if (item.flags & navItemFlags.HideFromNav || !userHasPermissions(path, action)) return null;

    // In case there is a pending section title, we display it and clear the
    // the pending status by setting sectionTitle = undefined
    const currentSectionTitle = sectionTitle
    sectionTitle = undefined

    const color = location.pathname.startsWith("/" + path) ? "#E2002A" : "#FFF"

    return (
      <div key={index}>
        {currentSectionTitle !== undefined && (
          <SectionText large bold upper centered>
            {currentSectionTitle}
          </SectionText>
        )}
        <StyledNavLink to={"/" + path} >
          <Left />
          <Center large light upper centered fontSize={15}>{title}</Center>
          <Right>
            <Icon
              icon={item.icon}
              size={fontSize}
              color={color}
            />
          </Right>
        </StyledNavLink>
      </div>
    );
  });

  return (
    <Column selected={props.selected} onClick={props.handleClick}>
      {props.selected ? (
        <ColumnItem selected={props.selected}>
          <LinkColumn>{links}</LinkColumn>
        </ColumnItem>
      ) : (
        <ColumnItem>
          <Header>
            <Icon
              icon={icon}
              size={48}
            />
            <VerticalSpacer />
            <StyledText bold large upper>
              {t(props.path + ".title")}
            </StyledText>
          </Header>
        </ColumnItem>
      )}
    </Column>
  );
}

export default IntroColumn;

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0;

  box-sizing: border-box;
  background: ${(props) =>
    props.selected ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.1)"};
  border-left: 1px solid
    rgba(255, 255, 255, ${(props) => (props.selected ? 0.1 : 0.0)});
  border-right: 1px solid
    rgba(255, 255, 255, ${(props) => (props.selected ? 0.1 : 0.0)});

  padding: 40px;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;

  ${(props) => {
    if (props.selected) {
      return `transform: rotateY(-180deg);`;
    }
  }}

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const ColumnItem = styled.div`
  position: relative;
  backface-visibility: hidden;

  ${(props) => {
    if (props.selected) {
      return `transform: rotateY(180deg);`;
    }
  }}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.themeColor};
`;

const VerticalSpacer = styled.div`
  height: 30px;
`;

const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
//  &:not(:first-child) {

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  height: 50px;
  background: ${(props) => props.theme.colors.themeBackground};
  color: ${(props) => props.theme.colors.themeColor};
  text-decoration: none;
  margin: 0px 0px 4px 10px;
  padding: 0px 20px;
  overflow: hidden;

  &:hover {
    background: rgba(0, 0, 0, 0.9);
  }
`;


const SectionText = styled(StyledText)`
  margin: 10px;
`;

const Left = styled.div`
  width: 20%;
`

const Center = styled(StyledText)`
  width: 60%;
  white-space: nowrap;
`

const Right = styled(Left)`
  display: flex;
  justify-content: flex-end;
`
