import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactModal from 'react-modal'
import { ModalProvider } from 'react-modal-hook'

import 'react-day-picker/dist/style.css'
import './App.css'
import Theme from './Theme'

import Header from './components/nav/Header'
import Navbar from './components/nav/Navbar'
import AppTutorials from './components/tutorial/AppTutorials'
import TutorialProvider from './components/tutorial/TutorialProvider'

import Authentication from './components/auth/Authentication'
import ItemRoutes from './components/nav/ItemRoutes'

import {
  FullscreenContainer,
  FullscreenContent,
  RowContainer,
} from './components/styling/Fullscreen'

import { VersionElement } from './utils/Version'

import Background from './images/background.jpg'
import SpotProvider from './components/shared/SpotProvider'

// Loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>Loading...</div>
  </div>
)

ReactModal.setAppElement('#root')

function App() {
  const background = {
    background:
      'linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(' +
      Background +
      ') no-repeat center center fixed',
    backgroundSize: 'cover',
  }

  return (
    <Theme>
      <Router>
        <SpotProvider>
          <TutorialProvider>
            <ModalProvider>
              <Suspense fallback={<Loader />}>
                <Authentication>
                  <AppTutorials>
                    <FullscreenContainer style={background}>
                      <VersionElement />
                      <Header />
                      <FullscreenContent>
                        <RowContainer>
                          <ItemRoutes />
                          <Navbar />
                        </RowContainer>
                      </FullscreenContent>
                    </FullscreenContainer>
                  </AppTutorials>
                </Authentication>
              </Suspense>
            </ModalProvider>
          </TutorialProvider>
        </SpotProvider>
      </Router>
    </Theme>
  )
}

export default App
