import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StyledText } from "../styling/TextStyling"

import { fromDbTimestampWithWeekday } from "../../utils/TimestampHelper"

const Container = styled.div`
  background: ${props => props.theme.colors.lightBackground};
  height: calc(100vh - 194px);
  padding: 20px;
  overflow: scroll;
`

const TrHead = styled.tr`
  background: ${props => {
    if(props.themed)
      return props.theme.colors.themeBackground
    else if(props.total)
      return props.theme.colors.green
    else
      return props.theme.colors.grey
  }};
  padding: 10px;
`

const Th = styled.th`
  padding: 10px;
  white-space: nowrap;
`

const Thl = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
`

const Td = styled.td`
  background: ${props => {
    if(props.grey)
      return props.theme.colors.grey
    else
      return 'transparent'
  }};
  padding: 10px;
`

const Tdc = styled.td`
  background: ${props => {
    if(props.grey)
      return props.theme.colors.grey
    else
      return 'transparent'
  }};
  padding: 10px;
  text-align: center;
`

function PendingTable(props) {
  const { t } = useTranslation();
  const { data } = props

  const headerTopRow = data.names.map((name, index) => {
    return (<Th key={index}><StyledText>{name}</StyledText></Th>)
  })

  const headerBottomRow = data.names.map((name, index) => {
    return (<Th key={index}><StyledText dark>{t('reporting.report.repeats_per_day')}</StyledText></Th>)
  })

  const rows = data.dates.map((date, index) => {
    const dateStr = fromDbTimestampWithWeekday(date.timestamp, t)
    const weekday = date.timestamp.getDay()
    const grey = weekday === 0 || weekday === 6 ? true : false

    return (
      <tr key={index}>
        <Td grey={grey}><StyledText dark>{dateStr}</StyledText></Td>
        {date.play_count.map((count, index) => {
          return (
              <Tdc key={index} grey={grey}><StyledText dark>{count}</StyledText></Tdc>
          )
        })}
      </tr>
    )
  })

  const footerTopRow = data.totals.map((total, index) => {
    return (<Th key={index}><StyledText dark>{total}</StyledText></Th>)
  })

  const footerBottomRow = data.totals.map((total, index) => {
    return (<Th key={index}><StyledText dark>{t('shared.seconds')}</StyledText></Th>)
  })

  return (
    <Container>
      <table style={{borderSpacing: 0}}>
        <thead>
          <TrHead themed>
           <Thl><StyledText>{t('shared.player')}</StyledText></Thl>
           {headerTopRow}
          </TrHead>
          <TrHead>
           <Thl><StyledText dark>{t('shared.date')}</StyledText></Thl>
           {headerBottomRow}
          </TrHead>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <tfoot>
          <TrHead>
           <Thl><StyledText dark>{t('reporting.report.play_count')}</StyledText></Thl>
           {footerTopRow}
          </TrHead>
          <TrHead>
           <Thl><StyledText dark>{t('reporting.report.spot_length')}</StyledText></Thl>
           {footerBottomRow}
          </TrHead>
          <TrHead total>
           <Thl><StyledText>{t('reporting.report.play_count_total')}</StyledText></Thl>
           <Th><StyledText>{data.total}</StyledText></Th>
          </TrHead>
        </tfoot>
      </table>
    </Container>
  )
}

export default PendingTable
