import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
  cursor: ${(props) =>
    props.disabled ? "" : props.cursor ? props.cursor : "pointer"};
  &:hover {
    z-index: 2;
  }
`;
//  flex: 1;
//  min-width: 20%;

const ListTooltip = styled.div`
  position: absolute;
  z-index: 3;
  background: #000;
  border-radius: 3px;
  transform: translate(-100%, -50%) translate(-4px, -14px);
  padding: 10px 16px;
  white-space: nowrap;
`;
//  border: 1px solid #333;

const ButtonTooltip = styled.div`
  position: absolute;
  z-index: 3;
  background: #000;
  border-radius: 3px;
  top: calc(-20% + ${props => props.offsetY ? props.offsetY : '0px'});
  right: calc(110% + ${props => props.offsetX ? props.offsetX : '0px'});
  padding: 10px 16px;
  white-space: nowrap;
`;
//  border: 1px solid #333;

const ClickTooltip = styled.div`
  position: absolute;
  z-index: 3;
  background: transparent;
  top: 0;
  right: 0;
  padding: 0;
  white-space: nowrap;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const NewTooltip = styled.div`
  position: absolute;
  z-index: 3;
  background: #000;
  border-radius: 3px;
  top: ${({ offsetY }) => offsetY !== undefined ? offsetY + 'px' : '8px'};
  height: ${({ height }) => height !== undefined ? height + 'px' : '23px'};
  ${({ showRight, offsetX }) => showRight === undefined ? `
    right: calc(100% + 5px ${offsetX !== undefined ? ' + ' + offsetX + 'px' : ''});
  ` : `
    left: calc(100% + 5px);
  `}
  padding: 8px 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ suppressHideOnEnter }) => suppressHideOnEnter === undefined ? 'auto' : 'none'};
`;
//  border: 1px solid #333;

const Content = styled.div`
  display: inline-block;
  color: #fff;
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  left: 100%;
  top: 50%;
  margin-top: ${({ marginTop }) => marginTop !== undefined ? marginTop + 'px' : '-5px'};
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
`;

const LeftArrow = styled(Arrow)`
  left: auto;
  right: 100%;
  transform: rotate(180deg);
`;

function Tooltip(props) {
  const timeout = useRef(null);
  const [isShowingTooltip, setIsShowingTooltip] = useState(false)

  const delay = !props.clickArea ? 600 : 200

  const handleOnMouseEnter = () => {
    if(props.disabled)
      return

    if(props.handleOnMouseEnter)
      props.handleOnMouseEnter()

    if(!isShowingTooltip && props.content) {
      if(!props.noDelay)
        timeout.current = setTimeout(() => setIsShowingTooltip(true), delay)
      else
        setIsShowingTooltip(true)
    }
  }

  const handleOnMouseLeave = useCallback(() => {
    if(props.disabled)
      return

    if(props.handleOnMouseLeave)
      props.handleOnMouseLeave()

    if(isShowingTooltip)
      setIsShowingTooltip(false)

    if(timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
    }
  }, [props, isShowingTooltip])

  useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
    }
  }, [])

  useEffect(() => {
    props.dragged && handleOnMouseLeave()
  }, [props.dragged, handleOnMouseLeave])

  return (
    <Wrapper
      style={props.style}
      disabled={props.disabled}
      onClick={props.handleClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {props.children}
      {isShowingTooltip && props.listButton !== undefined && (
        <ListTooltip
          onMouseEnter={handleOnMouseLeave}
        >
          <Content style={{ minWidth: 100 }}>{props.content}</Content>
          <Arrow />
        </ListTooltip>
      )}
      {isShowingTooltip && props.standardButton !== undefined && (
        <ButtonTooltip
          offsetY={props.standardButtonOffsetY}
          offsetX={props.standardButtonOffsetX}
          onMouseEnter={handleOnMouseLeave}
        >
          <Content>{props.content}</Content>
          <Arrow />
        </ButtonTooltip>
      )}
      {isShowingTooltip && props.newButton !== undefined && (
        <NewTooltip
          showRight={props.showRight}
          height={props.height}
          offsetX={props.offsetX}
          offsetY={props.offsetY}
          onMouseOver={handleOnMouseLeave}
          suppressHideOnEnter={props.suppressHideOnEnter}
        >
          <Content>{props.content}</Content>
          {props.showRight ? <LeftArrow /> : <Arrow marginTop={props.arrowTopMargin}/>}
        </NewTooltip>
      )}
      {props.clickArea !== undefined && (
        <ClickTooltip
          visible={isShowingTooltip}
        >
          <Content>{props.content}</Content>
        </ClickTooltip>
      )}
    </Wrapper>
  );
}

export default Tooltip;
