import React from 'react'

import Icon from '../shared/Icon'

import { StyledText } from '../styling/TextStyling'

function PriorityIndicator(props) {
  const p = props.priorities.find(p => p.id === props.priority)
  const color = props.inactive ? '#BBB' : p.color

  return (
    <div style={{ display: 'flex', ...props.style}}>
      <Icon
        icon={p.icon}
        color={color}
        size={16}
      />
      <StyledText style={{
        marginTop: -6,
        marginLeft: 2,
        marginRight: 10,
        color: color,
        fontSize: 12
      }}>
        {props.count}
      </StyledText>
    </div>
  )
}

export default PriorityIndicator
