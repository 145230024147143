import React from 'react'
import { useTranslation } from 'react-i18next'

import StandardButton from './StandardButton'
import ConfirmButton from './ConfirmButton'

function ActionButton(props) {
  const { t } = useTranslation();
    
  let icon = props.icon
  let text = props.text
  let confirm = false
  let hoverColor = props.hoverColor

  if(props.type !== undefined && (icon === undefined || text === undefined)) {
    switch(props.type) {
    // STANDARD
    case 'add':
      icon = 'add'
      text = t('shared.add')
      break

    case 'edit':
      icon = 'edit'
      text = t('shared.edit')
      break

    case 'delete':
      icon = 'delete'
      text = t('shared.delete')
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'delete_noconfirm':
      icon = 'delete'
      text = t('shared.delete')
      hoverColor = '#E2002A'
      break

    case 'disable':
      icon = 'delete'
      text = t('shared.disable')
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'duplicate':
      icon = 'file_copy'
      text = t('shared.duplicate')
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'restore':
      icon = 'restore_from_trash'
      text = t('shared.restore')
      confirm = true
      hoverColor = '#E2002A'
      break

    // CUSTOM
    case 'activate_quote_cancelled':
    case 'activate_order_cancelled':
      icon = 'add'
      text = t('shared.' + props.type)
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'approve':
      icon = 'verified'
      text = t('content.approval.approve')
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'booking_problem':
      icon = 'warning'
      text = t('content.booking_problem.title')
      break

    case 'booking_proof':
      icon = 'camera_alt'
      text = t('content.booking_proof.title')
      break

    case 'booking_special':
      icon = 'add_to_queue'
      text = t('content.booking_special.title')
      break

    case 'cancel_quoted':
    case 'cancel_ordered':
    case 'cancel_invoiced':
    case 'cancel_paid':
    case 'cancel_invoice':
      icon = 'close'
      text = props.type === 'cancel_invoice' ? t('reporting.invoices.cancel_invoice') : t('shared.' + props.type)
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'download':
      icon = 'save_alt'
      text = t('shared.download')
      break

    case 'email':
    case 'email_quote':
    case 'email_invoice':
      icon = 'email'
      text = t('shared.' + props.type)
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'mark_paid':
      icon = 'price_check'
      text = t('reporting.invoices.mark_paid')
      break

    case 'preview':
      icon = 'preview'
      text = t('shared.preview')
      break

    case 'publish':
      icon = 'verified'
      text = t('content.bookings.publish')
      confirm = true
      hoverColor = '#E2002A'
      break

    case 'reporting':
      icon = 'insert_chart_outlined'
      text = t('reporting.title')
      break

    case 'view':
    case 'view_invoiced':
    case 'view_ordered':
    case 'view_quoted':
    case 'view_paid':
      icon = 'preview'
      text = t('shared.' + props.type)
      break

    default:
      debugger
      icon = 'error'
      text = 'unsupported action type: ' + props.type
    }
  }

  if(!confirm) {
    return (
      <StandardButton
        icon = {icon}
        text = {text}
        hoverColor = {hoverColor}
        handleClick = {props.handleClick}
        style = {props.style}
      />
    )
  }
  else {
    const confirmMessage = props.confirmMessage !== undefined ?
          props.confirmMessage : "no confirm message provided"
    return (
      <ConfirmButton
        icon = {icon}
        text = {text}
        hoverColor = {hoverColor}
        confirmMessage = {confirmMessage}
        handleClick = {props.handleClick}
        style = {props.style}
      />
    )
  }
}

export default ActionButton
