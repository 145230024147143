import React from 'react'
import styled from 'styled-components'

import CopyToClipboard from '../shared/CopyToClipboard'

function CategoryHeader(props) {
  const items = props.items.map((item, index) => {
    return (
      <ItemContainer key={index}>
        <div>{item}</div>
        {(index < props.items.length - 1) &&
          <Separator>{'|'}</Separator>
        }
      </ItemContainer>
    )
  })

  return (
    <Container>
      {props.copyText !== undefined ? (
        <CopyToClipboard
          copyText={props.copyText}
        >
          {items}
        </CopyToClipboard>
      ) : (
        <>{items}</>
      )}
      <Spacer />
      {props.children}
    </Container>
  )
}

export default CategoryHeader

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.themeNavBackground};
  color: ${(props) => props.theme.colors.themeNavColor};
  font-family: Roboto-Bold;
  font-size: 12px;
  text-transform: uppercase;
  line-height: ${(props) => props.theme.header.height};
  padding: 0px 0px 0px 20px;
`

const ItemContainer = styled.div`
  display: flex;
  user-select: none;
`

const Separator = styled.div`
  margin: 0px 10px;
`

const Spacer = styled.div`
  flex: 1 0 auto;
`
