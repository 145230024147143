export const toDbTimestampStrFromDate = (str) => {
  // TODO - handle different locales
  const parts = str.split('.')
  if(parts.length !== 3)
    return null

  const day = parseDateInt(parts[0])
  const month = parseDateInt(parts[1])
  const year = parseDateInt(parts[2])

  // Sanity check for day and month
  if(isNaN(day) || day < 1 || 31 < day || isNaN(month) || month < 1 || 12 < month)
    return null
  // Sanity check for year
  const fullYear = isNaN(year) ? 0 : (new Date()).getFullYear()
  if(year < fullYear - 10 || fullYear + 100 < year)
    return null

  const date = new Date(year, month - 1, day)
  if(isNaN(date.getTime()))
    return null
  else
    return date
}


export const fromDbTimestamp = (date) => {
  if(isDate(date)) {
    return getDay(date) + '.' +
      getMonth(date) + '.' +
      date.getFullYear()
  }
  else {
    return ''
  }
}


export const dateToString = (date) => {
  if(isDate(date)) {
    return date.getFullYear() + '-' +
      getMonth(date) + '-' +
      getDay(date)
  }
  else {
    return ''
  }
}


export const fromDbTimestampToDayMonth = (date) => {
  if(isDate(date)) {
    return getDay(date) + '.' +
      getMonth(date) + '.'
  }
  else {
    return ''
  }
}


export const fromDbTimestampWithTime = (date) => {
  if(isDate(date)) {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const hour = date.getHours()
    const min = date.getMinutes()
    return (day < 10 ? '0' + day : day) + '.' +
      (month < 10 ? '0' + month : month) + '.' +
      date.getFullYear() + ', ' +
      (hour < 10 ? '0' + hour : hour) + ':' +
      (min < 10 ? '0' + min : min)
  }
  else {
    return ''
  }
}


export const fromDbTimestampWithWeekday = (date, t) => {
  if(isDate(date)) {
    const day = date.getDate()
    const month = date.getMonth() + 1
    let weekday = date.getDay() - 1
    weekday = weekday < 0 ? 6 : weekday
    return (day < 10 ? '0' + day : day) + '.' +
      (month < 10 ? '0' + month : month) + '.' +
      date.getFullYear() + ' (' +
      t('shared.weekday' + weekday) + ')'
  }
  else {
    return ''
  }
}


export const timeFromDbTimestamp = (date) => {
  if(isDate(date)) {
    const hour = date.getHours()
    const min = date.getMinutes()
    return (hour < 10 ? '0' + hour : hour) + ':' +
      (min < 10 ? '0' + min : min)
  }
  else {
    return ''
  }
}


export const toBookingId = (date, id) => {
  if(isDate(date))
    return date.getFullYear().toString() + getMonth(date) + getDay(date) + '-' + id
  else
    return ''
}


function isDate(date) {
  return date && date instanceof Date
}


function getDay(date) {
  if(isDate(date)) {
    const day = date.getDate()
    return day < 10 ? '0' + day : day.toString()
  }
  else {
    return ''
  }
}


function getMonth(date) {
  if(isDate(date)) {
    const month = date.getMonth() + 1
    return month < 10 ? '0' + month : month.toString()
  }
  else {
    return ''
  }
}


function parseDateInt(str) {
  const dateInt = parseInt(str)
  const numStr = str.replace(/^0+/, '')
  return isNaN(dateInt) || dateInt.toString() !== numStr ? NaN : dateInt
}


export function uniqueDates(arr) {
  let unique = []
  arr.forEach(date => {
    if(unique.find(el => el.getTime() === date.getTime()) === undefined)
      unique.push(date)
  })
  return unique
}


function dayOfWeek(date, firstDayOfWeek) {
  // Per default, our week starts on Monday, otherwise it start as defined by firstDayOfWeek
  return firstDayOfWeek !== undefined && firstDayOfWeek === 0
    ? date.getDay() + 1
    : (date.getDay() || 7)
}


export function getStartOfWeek(date, offset, firstDayOfWeek) {
  date = new Date(date)
  const days = dayOfWeek(date, firstDayOfWeek) - 1 - (offset === undefined ? 0 : offset)
  const diff = date.getDate() - days
  return new Date(date.setDate(diff))
}


export function getEndOfWeek(date, offset, firstDayOfWeek) {
  date = new Date(date)
  const days = 7 - dayOfWeek(date, firstDayOfWeek)
  const diff = date.getDate() + days
  return new Date(date.setDate(diff))
}


export function getStartOfMonth(date, offset) {
  date = new Date(offset === undefined ? date : date.setDate(date.getDate() + offset * 31))
  return new Date(date.getFullYear(), date.getMonth(), 1)
}


export function getEndOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}


//function getTime(date) {
//  const hour = date.getHours()
//  const min = date.getMinutes()
//  return (hour < 10 ? '0' + hour : hour) + ':' +
//    (min < 10 ? '0' + min : min) + ':00'
//}


export function dateRange() {
  const date     = new Date()
  var start      = ['2021', '9']
  var end        = ['' + date.getFullYear(), '' + (date.getMonth() + 1)]
  var startYear  = parseInt(start[0]);
  var endYear    = parseInt(end[0]);
  var dates      = [];

  for(var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1])-1 : 0;
    for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
      var month = j+1;
      var displayMonth = month < 10 ? '0'+month : month;
      dates.push([displayMonth, i]);
    }
  }

  // Add entry for 'all available'
  dates.push([-1, -1])

  return dates.reverse();
}


export function parseDateRange(str) {
  const dates = str.split('-')
  const hasEnd = 1 < dates.length
  const start = toDbTimestampStrFromDate(dates[0].trim())
  const end = hasEnd ? toDbTimestampStrFromDate(dates[1].trim()) : new Date(start)
  return { start, end }
}
