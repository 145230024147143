export function currencyUnit() {
  return ' (' + currencySymbol() + ')'
}

export function currencySymbol() {
  return '\u20AC'
}

export function localePrice(val, defaultVal) {
  if(val === null || !isFinite(val))
    val = defaultVal === undefined ? undefined : defaultVal
  return val === undefined ? '' : (toAmount(val) + ' ' + currencySymbol())
}

export function localePricePerSecond(val) {
  if(typeof(val) !== 'number') {
    return ''
  } else {
    if(val * 100 === Math.round(val * 100)) {
      return toAmount(val) + ' ' + currencySymbol()
    } else {
      return (val + ' ' + currencySymbol()).replace('.', ',')
    }
  }
}

function toAmount(val) {
  const str = val.toFixed(2).replace('.', ',')
  const n = str.length
  const p = 6
  if(p < n) {
    return str.substring(0, n - p) + "." + str.substring(n - p, n)
  } else {
    return str
  }
}
