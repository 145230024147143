import styled from 'styled-components'

export const AuthOuter = styled.div`
  display: flex;
`

export const AuthInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px;
  left: 60px;
  right: 60px;
  bottom: 60px;
  background: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  WebkitOverflowScrolling: touch;
  padding: 0
`

export const AuthForm = styled.form`
  width: 270px;
`

export const AuthInputBackground = styled.div`
  width: 100%;
  background: #FFF;
  margin-bottom: ${props => props.margin + 'px'};
`

export const AuthLogo = styled.img`
  position: absolute;
  width: 60px;
  height: 60px;
  right: 0;
  bottom: 0;
  border: 0;
`
