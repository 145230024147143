import React, { useState } from 'react'

import ClickArea from '../../shared/ClickArea'

import { StyledEditHeader } from '../../styling/PositionStyling'
import { StyledText } from '../../styling/TextStyling'

import { fromDbTimestamp } from '../../../utils/TimestampHelper'

function SpotChange(props) {
  const title = fromDbTimestamp(props.data.activation_timestamp ?
                                props.data.activation_timestamp :
                                props.startTimestamp)

  const [element, setElement] = useState(null)
  const handleRef = (element) => setElement(element ? element : null)

  const handleClick = () => {
    props.handleClick(props.data.id, props.data.activation_timestamp)
  }

  const handleEdit = () => {
    const rect = element.getBoundingClientRect()
    props.handleEdit(props.index, rect, 10)
  }

  return (
    <div ref={handleRef}>
      <ClickArea
        style = {{
          margin: '0 1px 0 0',
          minHeight: 24,
          padding: 0
        }}
        selectedColor = {'transparent'}
        normalColor = {'transparent'}
        hoverColor = {'rgba(255, 255, 255, 0.25)'}
        selected = {props.selected}
        translationPrefix = {props.translationPrefix}
        handleClick = {handleClick}
        userCanEdit = {props.allowEditAndDelete}
        handleEdit = {handleEdit}
        userCanDelete = {props.allowEditAndDelete}
        handleDelete = {() => props.handleDelete(props.data.id)}
      >
        <StyledEditHeader style={{height: 24}}>
          <StyledText medium bold centered style={{width: '100%'}}>
            {title}
          </StyledText>
        </StyledEditHeader>
      </ClickArea>
    </div>
  )
}

export default SpotChange
