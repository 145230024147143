import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import BookingSpecialTable from './BookingSpecialTable'
import CancelSave from '../shared/CancelSave'
import HeaderWithCancelButton from '../shared/HeaderWithCancelButton'
import ReactModalWrapper from '../shared/ReactModalWrapper'

import { useDataInterface } from '../shared/DataInterface'

import { StyledText } from '../styling/TextStyling'

import { getDuration, getRotation, repeatsPerDay } from '../../utils/BookingSpecialHelper'

function BookingSpecial(props) {
  const { t } = useTranslation()
  const { auth, bookingId, hideModal, canAdd, canEdit, canDelete } = props

  const translationPrefix = 'content.booking_special'

  const [isEditing, setIsEditing] = useState(false)
  const [editData, setEditData] = useState({})

  const bookingSpecialDataInterface = useDataInterface({
    endPoint: 'content/bookingSpecial?booking_id=' + bookingId,
    externalAuth: auth,
    handleSaveEditNotification: () => {
      setIsEditing(false)
      setEditData({})
    },
  })

  // Data interface for player names
  const playerDataInterface = useDataInterface({
    endPoint: 'admin/players?booking_id=',
    externalAuth: auth,
  })
  const playerIdToValue = (id, key) => {
    const item = playerDataInterface.data &&
          playerDataInterface.data.find((item) => item.id === id);
    return item ? item[key] : (key === 'name' ? '' : -1);
  }

  const handleInputChange = (event, data) => {
    event.persist()
    const { name, value } = event.target;
    if(name === 'duration' && data['start_timestamp']) {
      setEditData({
        ...data,
        [name]: value,
        end_timestamp: new Date(data['start_timestamp'].getTime() + value * 60000),
      })
    } else {
      setEditData({
        ...data,
        [name]: value,
      })
    }
  }

  const handleNew = () => {
    const duration = getDuration(t, {}).min
    const rotation = getRotation(t, {}).sec
    const currentDateTime = new Date()

    setIsEditing(true)
    setEditData({
      // State of editing
      isEditing: true,
      isAddingNew: true,
      index: bookingSpecialDataInterface.data.length,
      // Input helpers
      players: playerDataInterface.data,
      time: currentDateTime.getHours(),
      duration: duration,
      rotation: rotation,
      handleInputChange: handleInputChange,
      // Booking unit data
      player_id: -1,
      booking_id: bookingId,
      // TODO - Set spot_length_secs to min(booking.spot_length_secs and actual length of spot)?
      spot_length_secs: props.booking.spot_length_secs,
      start_timestamp: currentDateTime,
      end_timestamp: new Date(currentDateTime.getTime() + duration * 60 * 1000),
      individual_dates: null,
      weekdays: 255,
      hours: 33554431,
      repeats_per_day: repeatsPerDay(duration, rotation),
      entire_network: 0,
      price: 0.0,
      bookingtype_id: 1,                  // Standard booking
      bookingattributes: 16,              // Special booking
      theme_id: props.booking.theme_id,
      sector_id: props.booking.sector_id,
      info: -1,
      // approved: 1,                     // handled by backend in content/bookingSpecial.go
      state: 'paid',
    })
  }

  const handleEdit = (rowData) => {
    setIsEditing(true)
    const editData = {...rowData}
    editData['isEditing'] = true
    editData['isAddingNew'] = false
    // Input helpers
    editData['players'] = playerDataInterface.data
    editData['time'] = editData['start_timestamp'].getHours()
    editData['duration'] = getDuration(t, editData).min
    editData['rotation'] = getRotation(t, editData).sec
    editData['handleInputChange'] = handleInputChange
    setEditData(editData)
  }

  const handleSave = () => {
    const playerId = typeof editData['player_id'] === 'string' ?
          parseInt(editData['player_id']) : editData['player_id']
    const resolutionId = playerIdToValue(playerId, 'resolution_id')
    const hasInfo = editData['info'] !== -1 && editData['info'] !== '-1'

    if(playerId !== -1 && !isNaN(playerId) && resolutionId !== -1 && hasInfo
    && editData['start_timestamp']
    && !isNaN(editData['start_timestamp'].getTime())) {
      const method = editData['isAddingNew'] ? 'POST' : 'PUT'
      const data = {...editData}
      data.start_timestamp.setHours(data.time, 0, 0)
      data.end_timestamp = new Date(data.start_timestamp.getTime() + data.duration * 60 * 1000)
      data.repeats_per_day = repeatsPerDay(data.duration, data.rotation)
      data['deleted_players'] = []
      // Do not alter associated player, when editing a special booking
      data['added_players'] = editData['isAddingNew'] ? [playerId, resolutionId] : []
      delete data.isEditing
      delete data.isAddingNew
      delete data.index
      delete data.players
      delete data.time
      delete data.duration
      delete data.rotation
      delete data.handleInputChange
      delete data.player_id

      bookingSpecialDataInterface.handleSaveEdit(method, data)
    } else {
      // TODO - notify user about invalid inputs
      console.log(editData)
    }
  }

  const handleDelete = (id) => {
    bookingSpecialDataInterface.handleDelete(id)
  }

  const handleCancel = () => {
    if(isEditing) {
      setIsEditing(false)
      setEditData({})
    } else {
      hideModal()
    }
  }

  const showTable = isEditing || 0 < bookingSpecialDataInterface.data.length
  const cancelLabel = isEditing ? t('shared.cancel') : t('shared.close')
  const saveLabel = isEditing ? t('shared.save') : t(translationPrefix + '.addNew')

  return (
    <ReactModalWrapper fullScreen onRequestClose={hideModal}>
      <HeaderWithCancelButton
        title={t('content.booking_special.title')}
        handleCancel={hideModal}
      />
      <br />
      {!showTable &&
        <StyledText>{t(translationPrefix + '.noneExisting')}</StyledText>
      }
      {showTable &&
        <BookingSpecialTable
          translationPrefix={translationPrefix}
          bookingSpecialDataInterface={bookingSpecialDataInterface}
          playerIdToValue={playerIdToValue}
          canEdit={!isEditing && canEdit}
          handleEdit={handleEdit}
          canDelete={!isEditing && canDelete}
          handleDelete={handleDelete}
          editData={editData}
          isEditing={isEditing}
        />
      }
      <form>
        <br />
        <CancelSave
          cancelLabel={cancelLabel}
          handleCancel={handleCancel}
          canSave={canAdd}
          saveLabel={saveLabel}
          handleSave={isEditing ? handleSave : handleNew}
        />
      </form>
    </ReactModalWrapper>
  )
}

export default BookingSpecial
