import React from 'react'
import { useTranslation } from 'react-i18next'

import InputContainer from '../../shared/InputContainer'
import Select from '../../shared/Select'

function MaxBlocksInput(props) {
  const { t } = useTranslation()
  const { inputs, handleInputChange } = props

  return (
    <InputContainer
      dark={props.dark}
      marginTop={'20px'}
      marginBottom={'20px'}
      header={t('content.booking.maxBlocks')}
      tutorialId={'MaxBlocksInput'}
    >
      <Select
        type='number'
        value={inputs['repeats_per_day']}
        onChange={handleInputChange}
        name='repeats_per_day'
        options={[{value: 0, text: t('shared.all')}].concat(
          Array(10).fill({}).map((x, i) => ({ value: i + 1, text: i + 1 }))
        )}
      />
    </InputContainer>
  )
}

export default MaxBlocksInput
