import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { cInvoicingEnabled } from '../../Theme'
import Select from '../shared/Select'

import { useAuth } from '../auth/Authentication'
import { useTutorialRef } from '../tutorial/TutorialProvider'

function SearchTypeSelection(props) {
  const { t } = useTranslation()
  const { userHasPermissions } = useAuth()
  const navigate = useNavigate()

  const ref = useTutorialRef('SearchTypeSelection')

  const handleChange = (e) => {
    const template = e.target.value
    navigate('/content/new/' + template, { replace: true })
  }

  const options = useMemo(() => {
    const options = []
    if(cInvoicingEnabled && userHasPermissions('content/quotations', 'add'))
      options.push({ value: 'quotation', text: t('content.quotations.new_title') })
    if(userHasPermissions('content/bookings', 'add'))
      options.push({ value: 'booking', text: t('content.bookings.new_title') })
    if(userHasPermissions('content/subscriptions', 'add'))
      options.push({ value: 'subscription', text: t('content.subscriptions.new_title') })
    if(userHasPermissions('content/reservations', 'add'))
      options.push({ value: 'reservation', text: t('content.reservations.new_title') })
    if(userHasPermissions('content/vacancies', 'add'))
      options.push({ value: 'vacancy', text: t('content.vacancies.new_title') })
    return options
  }, [userHasPermissions, t])

  return (
    <div ref={ref}>
      <Select
        style={{ height: '60%' }}
        themed
        arrowLeft
        dropDownWidth={ 200 }
        categoryHeader
        value={props.bookingTemplate}
        onChange={handleChange}
        options={options}
      />
    </div>
  )
}


export default SearchTypeSelection


export const useBookingTemplateFromUrl = (state) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [bookingTemplate, setBookingTemplate] = useState(['quotation', 'quotations', 'search'])

  useEffect(() => {
    const parts = location.pathname.split('/')
    const template = parts.length < 4 ? cInvoicingEnabled ? 'quotation' : 'booking' : parts[3]

    // Update url according to state
    let path = '/content/new/' + template
    if(state === 'results')
      path += '/resolve'
    else if(state === 'save')
      path += '/checkout'
    if(location.pathname !== path)
      navigate(path, { replace: true })

    // Map template to endPoint
    switch(template) {
    case 'booking':
    case 'fillin':
    case 'reservation':
    case 'subscription':
      setBookingTemplate([template, template + 's'])
      break
    case 'vacancy':
      setBookingTemplate([template, 'vacancies'])
      break
    default:
      setBookingTemplate(['quotation', 'quotations'])
    }
  }, [navigate, location, state])

  return bookingTemplate
}
