import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-modal-hook'
import { useTranslation } from 'react-i18next'

import ReactModalWrapper from '../shared/ReactModalWrapper'
import TextInput from '../shared/TextInput'

import { StyledText } from '../styling/TextStyling'

function Select(props) {
  const ref = useRef(null)
  const { t } = useTranslation()
  const { value, onChange, name, options, placeholder, searchable, ...otherProps } = props

  const itemHeight = 45
  const showSearch = searchable && (6 < options.length)

  const [state, setState] = useState({
    expanded: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    search: '',
    options: options,
  })

  const [showModal, hideModal] = useModal(() => (
    <ReactModalWrapper
      layout={'select'}
      contentRect={{
        top: state.top + 0 * 16,
        left: state.left - 0 * 10,
        width: props.dropDownWidth ? props.dropDownWidth : state.width,
        height: state.options.length * (itemHeight + 1) + (showSearch ? state.height + 1 : 0),
      }}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
    >
      <StyledExpanded>
        {showSearch && (
          <TextInput
            style={{ height: state.height }}
            value={state.search}
            placeholder={t('shared.search')}
            onChange={handleSearch}
            onEnterKey={handleEnterKey}
          />
        )}
        {state.options.map((option, i) => {
          return (
            <StyledOption
              key={i}
              dark
              style={{ height: 45, padding: '0 15px 0 15px' }}
              bold={option.value === value}
              colorIndex={i % 2 === 0}
              onClick={() => handleSelection(option.value)}
            >
              {option.text}
            </StyledOption>
          )
        })}
      </StyledExpanded>
    </ReactModalWrapper>
  ), [state, showSearch])

  const handleExpansion = () => {
    if(!state.expanded) {
      const rect = ref.current.getBoundingClientRect()
      setState(prev => ({
        expanded: true,
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        search: '',
        options: options,
      }))
      showModal()
    }
  }

  const handleClose = () => {
    setState(prev => ({ ...prev, expanded: false }))
    hideModal()
  }

  const handleSelection = (value) => {
    const event = {
      persist: () => {},
      target: {
        name,
        value,
        type: '',
      }
    }
    onChange(event)
    handleClose()
  }

  const handleSearch = (search) => {
    if(state.search === '' && search === '') {
      handleClose()
    } else {
      const regexp = new RegExp(search, 'ig')
      const data = options.filter(o => regexp.test(o.text))
      setState(prev => ({
        ...prev,
        search,
        options: data,
      }))
    }
  }

  const handleEnterKey = () => {
    if(0 < state.options.length)
      handleSelection(state.options[0].value)
  }

  const selected = options.find(option => option.value === value)
  const text = selected
        ? selected.text
        : (placeholder ? placeholder : t('shared.pleaseSelect'))

  useEffect(() => {
    if(!selected && options && options.length === 1)
      handleSelection(options[0].value)
  })

  return (
    <StyledOption
      ref={ref}
      color={selected ? undefined : '#888'}
      style={{ padding: props.noArrow ? '0 0 0 0px' : props.categoryHeader ? '0 0 0 15px' : '15px 0 15px 15px' }}
      {...otherProps}
      bold={props.categoryHeader || props.bold}
      onClick={handleExpansion}
    >
      {text}
      {!props.noArrow && (
        <StyledArrow {...otherProps} />
      )}
    </StyledOption>
  )
}

export default Select


const StyledOption = styled(StyledText)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.noArrow ? 'center' : 'space-between'};
  ${props => props.arrowLeft !== undefined &&
    `
    flex-direction: row-reverse;
  `}
  color: ${(props) => props.color ? props.color : props.themed ? props.theme.colors.themeHighlightColor : (props.categoryHeader || props.light ? props.theme.colors.darkColor : props.theme.colors.lightColor)};
  font-size: ${props => props.categoryHeader ? '12px' : '14px'};
  text-transform: ${props => props.categoryHeader ? 'uppercase' : 'auto'};
  ${props => props.colorIndex !== undefined &&
    `
    &:hover {
      background: #EEE;
    }
    &:not(:first-child) {
      border-top: 1px solid lightgrey;
    }
  `}
`

const StyledArrow = styled.div`
  border: solid ${(props) => props.color ? props.color : props.themed ? props.theme.colors.themeHighlightColor : (props.categoryHeader || props.light ? props.theme.colors.darkColor : props.theme.colors.lightColor)};
  border-width: 0 1px 1px 0;
  display: inline-block;
  margin: 0 12px 0 0;
  padding: 3px;
  transform: rotate(45deg);
  transform-origin: bottom right;
`

const StyledExpanded = styled.div`
  background: ${props => props.theme.colors.darkColor};
  border-radius: 2px;
`
