import React from 'react'

import HideButton from "./HideButton";

import { StyledText } from "../styling/TextStyling";

function HeaderWithCancelButton(props) {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <StyledText header style={{flexGrow: 1}}>
        {props.title}
      </StyledText>
      <HideButton
        handleClick={props.handleCancel}
      />
    </div>
  )
}

export default HeaderWithCancelButton
