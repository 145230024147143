import { instanceOneOf, cInvoicingEnabled } from '../../Theme'


//export const categories = [
//  'admin',
//  'content',
//  'reporting',
//]


export const navItemFlags = {
  HideFromNav:         1<<0,
  HideFromPermissions: 1<<1,
  StartsSection:       1<<2,
  PermissionActionAdd: 1<<4,
}


export const items = [
  /* Admin */
  { category: 'admin',     path: 'sites',        icon: 'place',                  permission: 1<<0,  section: 'admin.site_management' },
  { category: 'admin',     path: 'clusters',     icon: 'public',                 permission: 1<<1,  },
  { category: 'admin',     path: 'discounts',    icon: 'local_offer',            permission: 1<<2,  flags: navItemFlags.HideFromNav },
  { category: 'admin',     path: 'players',      icon: 'tv',                     permission: 1<<3,  instances: ['kevin', 'railscreen', 'showcase'], },
  { category: 'admin',     path: 'resolutions',  icon: 'theaters',               permission: 1<<4,  instances: ['kevin', 'railscreen', 'showcase'], },
  { category: 'admin',     path: 'customers',    icon: 'perm_contact_calendar',  permission: 1<<5,  section: 'admin.customer_management' },
  { category: 'admin',     path: 'sectors',      icon: 'business',               permission: 1<<6,  },
  { category: 'admin',     path: 'users',        icon: 'person',                 permission: 1<<7,  section: 'admin.access_management' },
  { category: 'admin',     path: 'teams',        icon: 'group',                  permission: 1<<8,  instances: ['marty', 'showcase'] },
  { category: 'admin',     path: 'groups',       icon: 'lock',                   permission: 1<<9,  },

  /* Content */
  { category: 'content',   path: 'new',          icon: 'add',                    permission: 1<<10, section: 'content.bookings.title', flags: navItemFlags.PermissionActionAdd },
  { category: 'content',   path: 'add',          icon: 'add_to_queue',           permission: 1<<10, instances: ['showcase'] },
  { category: 'content',   path: 'quotations',   icon: 'import_contacts',        permission: 1<<11, enabled: cInvoicingEnabled },
  { category: 'content',   path: 'bookings',     icon: 'radio_button_unchecked', permission: 1<<12, },
  { category: 'content',   path: 'fillins',      icon: 'restore',                permission: 1<<14, instances: ['railscreen', 'kevin'] },
  { category: 'content',   path: 'subscriptions',icon: 'subscriptions',          permission: 1<<15, instances: ['marty', 'showcase'] },
  { category: 'content',   path: 'reservations', icon: 'timelapse',              permission: 1<<16, instances: ['marty', 'showcase'] },
  { category: 'content',   path: 'vacancies',    icon: 'shuffle',                permission: 1<<17 },
//{ category: 'content',   path: 'overrule',     icon: 'warning',                permission: 1<<16 },
  { category: 'content',   path: 'load',         icon: 'grid_on',                permission: 1<<18, instances: ['marty', 'showcase'], section: 'shared.management' },
  { category: 'content',   path: 'approval',     icon: 'verified',               permission: 1<<19, instances: ['marty', 'showcase'] },

  /* Reporting */
  { category: 'reporting', path: 'reports',      icon: 'insert_chart',           permission: 1<<20, section: 'reporting.title' },
  { category: 'reporting', path: 'accounting',   icon: 'receipt',                permission: 1<<21, flags: navItemFlags.HideFromPermissions, instances: ['kevin', 'railscreen'], },
  { category: 'reporting', path: 'programmatic', icon: 'mediation',              permission: 1<<22, instances: ['kevin', 'railscreen'], },
  { category: 'reporting', path: 'invoices',     icon: 'receipt',                permission: 1<<23, enabled: cInvoicingEnabled },
]


const flagNotSet = (item, flag) => item.flags === undefined || !(item.flags & flag)


export const canViewItem = (item, flag) => {
  return (item.enabled === undefined || item.enabled) &&
    (item.instances === undefined || instanceOneOf(...item.instances)) &&
    (flag === undefined || flagNotSet(item))
}


export const navItems = (path) => {
  return items.filter((item, index) => item.category === path && canViewItem(item))
}


export const navPermissions = () => {
  let permissions = []

  items.forEach(item => {
    if(canViewItem(item, navItemFlags.HideFromPermissions))
      permissions.push({ name: item.category + '.' + item.path, flag: item.permission })
  })

  return permissions
}


export const navPermissionsWithCategories = () => {
  let currentCategory = ''
  let permissions = []

  items.forEach(item => {
    if(canViewItem(item, navItemFlags.HideFromPermissions)) {
      if(currentCategory !== item.category) {
        permissions.push({ name: item.category })
        currentCategory = item.category
      }

      permissions.push({ name: currentCategory + '.' + item.path, flag: item.permission })
    }
  })

  return permissions
}


export const navPermissionFlag = (endPoint) => {
  if(endPoint === 'admin.playerformats')
    endPoint = 'admin.resolutions'

  const item = items.find(item => {
    return canViewItem(item) && (item.category + "." + item.path) === endPoint
  })

  if(item === undefined) {
    debugger
    return 0
  } else {
    return item.permission
  }
}


export const navPermissionAction = (item) => {
  return flagNotSet(navItemFlags.PermissionActionAdd) ? 'view' : 'add'
}

//const printNavPermission = () => {
//  //const endPoints = []
//  const endPoints = ['admin/sectors', 'admin/customers', 'content/quotations', 'content/bookings', 'content/booking', 'content/fillin', 'content/vacancy']
//  let flags = 0
//  items.forEach(item => {
//    if((endPoints.length === 0 || endPoints.includes(item.category + "/" + item.path))
//    && (item.flags === undefined || !(item.flags & navItemFlags.HideFromPermissions)))
//      flags |= item.permission
//  })
//  console.log('printNavPermission', flags)
//}
