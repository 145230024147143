import styled from "styled-components";
import PropTypes from "prop-types";

export const StyledText = styled.div`
  ${({ stretch }) =>
    stretch &&
    `
    flex: 1 0 auto;
  `}

  color: ${(props) => {
    if (props.light) return props.theme.colors.darkColor;
    else if (props.dark) return props.theme.colors.lightColor;
    else return props.theme.colors.white;
  }};
  
  font-family: ${(props) => {
    if (props.bold || props.header) return "Roboto-Bold";
    else if (props.medium) return "Roboto-Medium";
    else return "Roboto-Light";
  }}, sans-serif;
  
  font-size: ${(props) => {
    if (props.fontSize) return props.fontSize + "px";
    else if (props.header) return "22px";
    else if (props.large) return "14px";
    else if (props.medium) return "12px";
    else if (props.small) return "10px";
    else return "12px";
  }};

  text-transform: ${(props) => (props.upper ? "uppercase" : "none")};
  text-align: ${(props) => (props.centered ? "center" : props.right ? "right" : "left")};

  user-select: none;
`;

StyledText.propTypes = {
  light: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  upper: PropTypes.bool,
  centered: PropTypes.bool,
  dark: PropTypes.bool,
  bold: PropTypes.bool,
  header: PropTypes.bool,
  fontSize: PropTypes.number,
};

export const StyledTextLink = styled(StyledText)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLink = styled.div`
  padding: "4px";
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;
