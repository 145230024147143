import { css, keyframes } from 'styled-components'

export const ColorAnimation = keyframes`
    0% {
      color: black;
    }
    50% {
      color: #E2002A;
    }
    100% {
      color: black;
    }
  `

export const PulseAnimation = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1
    }
  `

export const animation = css`
  border-radius: 50%;
  transform: scale(1);
  animation: ${(props) => props.animation} 2500ms infinite;
`

export const PulseShadow = keyframes`
   0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`
