import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import SearchResults from './SearchResults'
import SearchTypeSelection, { useBookingTemplateFromUrl } from './SearchTypeSelection'
import { cBlocksOfPlayers, cDefaultSpotLength, cAllowedSpotLengths } from '../../Theme'
import BookingUnitEdit from './booking/BookingUnitEdit'
import MetaDataEdit from './booking/MetaDataEdit'
import { useMetaDataInput } from './booking/MetaData'
import { MetaDataFlag } from './booking/MetaDataFields'
import { useAuth } from '../auth/Authentication'
import CategoryHeader from '../nav/CategoryHeader'
import TextButton from '../shared/TextButton'
//import { useBrowserEvents } from '../shared/BrowserEvents'
import { useDataInterface } from '../shared/DataInterface'
import { processInputs } from '../shared/DataProcessing'
import { usePlayerData, useResolutions } from '../shared/PlayerData'
import { useRequestToken } from '../shared/RequestToken'
import { useSpot } from '../shared/SpotProvider'
import { useTutorialRef } from '../tutorial/TutorialProvider'

import { StyledText } from '../styling/TextStyling'

import {
  processBookingUnitInputs,
  hoursAllMask,
  weekdaysAllMask
} from '../../utils/BookingUnitHelper'
import { useQueryEndPoint } from '../../utils/QueryEndPoint'
import { fromDbTimestamp, toBookingId } from '../../utils/TimestampHelper'

/*
When the user clicks save three things will happen subsequently:

1) - handleSave() will create a new booking using the bookingDataInterface
2) - bookingDataInterface.handleNewIds() prepares the data for the booking unit
     and stores it to the state in bookingUnitData
3) - useEffect() on bookingUnitData handles the change of data under 2) and creates
     the booking unit using bookingUnitsDataInterface
 */

function Search(props) {
  const { t } = useTranslation()
  const { queryPath } = useQueryEndPoint()
  const confirmButtonRef = useTutorialRef('Search.confirm_button')
  //useBrowserEvents(() => console.log("FWD"), () => {console.log("BWD"); history.go(1)})

  // Set to true to use defaults values
  const debug = false

  const [state, setState] = useState('search')
  const [bookingTemplate, bookingEndpoint] = useBookingTemplateFromUrl(state)
  const [searchResults, setSearchResults] = useState([])
  const [editDataCompleted, setEditDataCompleted] = useState(false)
  const [editData, setEditData] = useState(() =>
    debug
      ? {
          budget: 5000,
          impressions: 5000000,
          spot_length_secs: 90,
          spot_origin: 'reuse',
          date_input_mode: 'range_and_individual',
          use_range: true,
          start_timestamp: new Date(2022, 1, 10, 0, 0, 0, 0),
          end_timestamp: new Date(2022, 2, 27, 23, 59, 59, 0),
          individual_dates: [new Date(2021, 11, 12, 0, 0, 0, 0)],
          weekdays: weekdaysAllMask(),
          hours: hoursAllMask(),
          repeats_per_day: !cBlocksOfPlayers ? 200 : 0,
          price: 0,
          zones: null,
        }
      : {
          budget: '',
          impressions: '',
          spot_length_secs: cDefaultSpotLength,
          spot_origin: 'reuse',
          date_input_mode: 'range_and_individual',
          use_range: true,
          start_timestamp: null,
          end_timestamp: null,
          individual_dates: [],
          weekdays: weekdaysAllMask(),
          hours: hoursAllMask(),
          repeats_per_day: 0,
          price: 0,
          zones: null,
        }
  )

  const canBeSubsciptionBooking = ['booking', 'vacancy'].includes(bookingTemplate)
  const isSubscriptionBooking = canBeSubsciptionBooking && 0 < editData['subscription_booking_id']
  const isReservationBooking = bookingTemplate === 'reservation'
  const hasZeroPrice = isSubscriptionBooking || isReservationBooking
  const noSpotOrigin = ['subscription', 'reservation'].includes(bookingTemplate)
  const bookingState =
        isSubscriptionBooking
        ? 'ordered'
        : (isReservationBooking
           ? 'free'
           : (bookingTemplate === 'quotation'
              ? 'quote_created'
              : 'ordered'
             )
          )
  const allowedSpotLengths = useMemo(() => (
    ['subscription', 'reservation'].includes(bookingTemplate)
    ? Array(15).fill({}).map((x, i) => (i + 1) * cDefaultSpotLength)
    : cAllowedSpotLengths
  ), [bookingTemplate])
  useEffect(() => {
    if(!allowedSpotLengths.includes(editData['spot_length_secs']))
      setEditData(prev => ({
        ...prev,
        spot_length_secs: cDefaultSpotLength,
      }))
  }, [allowedSpotLengths, editData])

  const { authCall, userHasPermissions } = useAuth()
  const {
    requestToken,
    setRequestToken,
    clearRequestToken,
    releaseRequestToken,
    requestInvalid,
    requestValidUntil,
  } = useRequestToken('content/bookingUnits')

  const {
    metaDataFields,
    inputFields,
    canSave,
    inputs,
    updateInput,
  } = useMetaDataInput(MetaDataFlag.IsNewBooking | MetaDataFlag.ShowPricing |
                       MetaDataFlag.IsSearchBooking | MetaDataFlag.ExcludeModeField |
                       (noSpotOrigin ? 0 : MetaDataFlag.ShowSpotOrigin) |
                       (isSubscriptionBooking ? MetaDataFlag.CustomerReadOnly : 0),
                       debug, bookingTemplate)

  // Disable warning for setUsedPlayers being used - usePlayerData requires
  // usedPlayers to have state
  // eslint-disable-next-line no-unused-vars
  const [usedPlayers, setUsedPlayers] = useState([])
  const {
    clusters,
    sites,
    players,
    firstDayOfWeek,
    handleClusterChange,
    handleSiteChange,
    handlePlayerChange,
    handleSiteExpanderClick,
  } = usePlayerData(useAuth(), usedPlayers, /*selectedBookingUnitId*/ -1)
  const hasSpotUpload = ['booking', 'vacancy'].includes(bookingTemplate)
  const resolutions = useResolutions(players, hasSpotUpload, true)

  // Booking data interface
  const bookingDataInterface = useDataInterface({
    endPoint: 'content/booking',
    autoUpdate: false,
    omitRefreshDataOnNewIds: true,
    handleNewIds: (data) => {
      // Process booking data
      const bookingData = { ...inputs }
      metaDataFields.customProcessBeforeSaveEdit(bookingData)
      processInputs(bookingData, metaDataFields.fields)

      // Associate booking unit with request token and add relevant booking data
      const bookingUnitData = {
        request_token: requestToken,
        booking_id: data.id,
        bookingtype_id: bookingData.bookingtype_id,
        bookingattributes: bookingData.bookingattributes,
        sector_id: bookingData.sector_id,
      }

      // Submit data
      setBookingUnitData(bookingUnitData)
    },
  })

  const spotHandling = useSpot()

  // Booking unit data interface
  const [bookingUnitData, setBookingUnitData] = useState(undefined)
  const [bookingUnitDataSaved, setBookingUnitDataSaved] = useState(false)
  const bookingUnitsDataInterface = useDataInterface({
    endPoint: 'content/bookingUnits',
    autoUpdate: false,
    omitRefreshDataOnNewIds: true,
    handleNewIds: async (data) => {
      const bookingId = bookingUnitData['booking_id']

      if(hasSpotUpload) {
        const bookingIdStr = t('shared.booking') + toBookingId(Date.now(), bookingId)
        const bookingName = inputs['name']
        const bookingUnitId = data.id
        const mergedBookingUnitData = { ...bookingUnitData, ...data }
        spotHandling.onSubmit(bookingId, bookingIdStr, bookingName,
                              bookingUnitId, mergedBookingUnitData)
      }
      spotHandling.onDeleteLoaded()

      queryPath('content/' + bookingEndpoint, 'id', bookingId)
    },
  })
  useEffect(() => {
    if(bookingUnitData && !bookingUnitDataSaved) {
      setBookingUnitDataSaved(true)
      bookingUnitsDataInterface.handleSaveEdit('POST', bookingUnitData)
      clearRequestToken()
    }
  }, [bookingUnitData, bookingUnitDataSaved, bookingUnitsDataInterface, clearRequestToken])

  // On submit by user
  const handleSave = () => {
    switch(state) {
    case 'search':
      const data = {...editData}
      processBookingUnitInputs(data, usedPlayers, /*trackUsedPlayers*/true)
      // We require the load check even for vacancy, and let this be handled by
      // the backend by setting bookingtype_id and bookingattributes accordingly.
      data['requires_load_check'] = true
      if(bookingTemplate === 'vacancy') {
        data['bookingtype_id'] = 1
        data['bookingattributes'] = 4
      }
      data['booking_id'] = -1
      if(!canBeSubsciptionBooking)
        data['subscription_booking_id'] = null
      if(0 < data['subscription_booking_id'] || isReservationBooking)
        data['price'] = 0.0
      if(noSpotOrigin)
        data['spot_origin'] = null
      data['state'] = bookingState

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: data,
      }
      authCall("content/bookingUnits", null, options).then(
        (response) => {
          if(response === null)
            return

          setRequestToken(response.request_token, response.request_timestamp)

          updateInput('userEditedPrice', undefined)
          updateInput('list_price', data['price'])
          updateInput('spot_origin', data['spot_origin'])
          updateInput('state', bookingState)
          if(canBeSubsciptionBooking)
            updateInput('customer_id', data['subscription_customer_id'])
          if(isReservationBooking)
            updateInput('bookingattributes', data['bookingattributes'])

          if(response.error === 'network_load') {
            setSearchResults(response.data)
            setState('results')
          } else {
            setState('save')
          }
        }
      )
      break

    case 'results':
      setState('save')
      break

    case 'save':
      // Process meta data for creation of booking
      const bookingData = { ...inputs }
      metaDataFields.customProcessBeforeSaveEdit(bookingData)
      processInputs(bookingData, metaDataFields.fields)
      delete bookingData.id

      bookingDataInterface.handleSaveEdit('POST', bookingData)
      break

    default:
      break
    }
  }

  // Visualization
  const rightButtonDisabled = (state === 'search' && !editDataCompleted)
        || (state === 'results' && requestInvalid)
        || (state === 'save' && (!canSave || requestInvalid))
  const timeoutText = state === 'search' || requestValidUntil === 'invalid' ? '' :
        requestValidUntil === '' ? t('content.search.expired') :
        t('content.search.reserved') + ' ' + requestValidUntil

  const period = () => {
    return editData.use_range
      ? fromDbTimestamp(editData.start_timestamp) + ' - ' + fromDbTimestamp(editData.end_timestamp)
      : fromDbTimestamp(editData.individual_dates[0]) + ' - ' + fromDbTimestamp(editData.individual_dates[editData.individual_dates.length - 1])
  }

  const headerItems = [
    t('content.title'),
    t('content.new.title'),
    (state === 'search'
     ? <SearchTypeSelection
         bookingTemplate={bookingTemplate}
       />
     : t('content.' + bookingEndpoint + '.new_title')
    ),
    ...state === 'search' ? [] :
      [period(),
       //editData.repeats_per_day + ' ' + t('shared.play_count'),
       editData.spot_length_secs + ' ' + t('shared.seconds')
      ]
  ]

  return userHasPermissions('content/' + bookingEndpoint, 'add') && (
    <Container>
      <StyledTextButtonContainer>
        {(state === 'results' || state === 'save') && (
          <StyledTextButton
            medium bold upper
            handleClick={() => releaseRequestToken(() => setState('search'))}
          >
            {state === 'results' ? t('content.search.back') : t('shared.cancel')}
          </StyledTextButton>
        )}
        <StyledWrapper ref={confirmButtonRef}>
          <StyledTextButton
            medium bold upper green
            disabled={rightButtonDisabled}
            handleClick={handleSave}
          >
            {state === 'search'
             ? t('content.search.search')
             : state === 'results'
             ? t('content.search.select')
             : t('content.search.save_' + bookingTemplate)}
          </StyledTextButton>
          <StyledTimeout disabled={rightButtonDisabled}>{timeoutText}</StyledTimeout>
        </StyledWrapper>
      </StyledTextButtonContainer>
      <CategoryHeader items={headerItems} />
      <ContentContainer>
        {state === 'search' && (
          <BookingUnitEdit
            newBooking
            searchBooking
            excludeBudgetAndImpressions
            hasZeroPrice={hasZeroPrice}
            includeReservationTeam={isReservationBooking}
            includeSubscriptionCustomer={canBeSubsciptionBooking}
            excludeSpotOrigin={noSpotOrigin}
            allowedSpotLengths={allowedSpotLengths}
            inputs={editData}
            setInputs={setEditData}
            clusters={clusters}
            handleClusterChange={handleClusterChange}
            sites={sites}
            handleSiteExpanderClick={handleSiteExpanderClick}
            handleSiteChange={handleSiteChange}
            handleCompletedChanged={setEditDataCompleted}
            players={players}
            firstDayOfWeek={firstDayOfWeek}
            handlePlayerChange={handlePlayerChange}
            translationPrefix={'content.booking.bookingUnits'}
          />
        )}
        {state === 'results' && (
          <SearchResults
            load={editData['spot_length_secs']}
            searchResults={searchResults}
          />
        )}
        {state === 'save' && (
          <MetaDataEdit
            metaDataFields={metaDataFields.fields}
            hasZeroPrice={hasZeroPrice}
            inputFields={inputFields}
            inputs={inputs}
            canSave={canSave}
            bookingUnitData={editData}
            resolutions={resolutions}
          />
        )}
      </ContentContainer>
    </Container>
  )
}

export default Search

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0px 0px;
`

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  margin: 0px 0px;
  overflow-y: auto;
`

const StyledTextButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 0;
`

const StyledWrapper = styled.div`
  position: relative;
  height: ${(props) => props.theme.header.height};
`

const StyledTextButton = styled(TextButton)`
  min-width: 140px;
  height: ${(props) => props.theme.header.height};
`

const StyledTimeout = styled(StyledText)`
  position: absolute;
  bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
  color: ${(props) => props.disabled ? 'lightgrey' : 'darkgreen'};
`
