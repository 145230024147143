import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";

import InputContainer from "./InputContainer";

import { applyDefaults, validateInputs } from "./DataProcessing";

import { StyledInput } from "../styling/InputStyling";

function useDataFields(params) {
  const {
    defaultData,
    fieldData,
    highlightErrors,
    continuouslyUpdateCanSave,
  } = params;

  const { t } = useTranslation();

  const [canSave, setCanSave] = useState(continuouslyUpdateCanSave === undefined ?
                                         true : !continuouslyUpdateCanSave)
  const [inputs, setInputs] = useState(() => {
    const data = { ...defaultData }
    applyDefaults(data, fieldData)
    return data
  })
  const [inputsChanged, setInputsChanged] = useState(false)

  const runInputValidation = useCallback((inputs, name, input) => {
    setInputsChanged(true)
    if(highlightErrors)
      setCanSave(validateInputs(inputs, fieldData, name, input))
    else if(continuouslyUpdateCanSave)
      setCanSave(validateInputs(inputs, fieldData, name, input, /*hideErrors*/true))
  }, [fieldData, highlightErrors, continuouslyUpdateCanSave])

  const handleInputChange = useCallback((event) => {
    event.persist();
    const { name, value, type, checked } = event.target;
    const input = type === "checkbox" ? (checked ? 1 : 0) : value
    setInputs((inputs) => {
      const updatedInputs = {
        ...inputs,
        [name]: input,
      }
      runInputValidation(updatedInputs, name, input)
      return updatedInputs
    });
  }, [runInputValidation])

  const updateInput = useCallback((name, input) => {
    if(input !== undefined) {
      setInputs((inputs) => {
        const updatedInputs = {
          ...inputs,
          [name]: input,
        }
        runInputValidation(updatedInputs, name, input)
        return updatedInputs
      });
    }
    else {
      setInputs((inputs) => {
        const updatedInputs = {...inputs}
        delete updatedInputs[name]
        runInputValidation(updatedInputs, name, input);
        return updatedInputs
      });
    }
  }, [setInputs, runInputValidation])

  const fields = useMemo(() => {
    return (
      fieldData &&
      fieldData.map((field, index) => {
        const placeholder = field.type === 'time'
              ? '00:00:00'
              : field.optional ? t('shared.optional') : ''

        return (field.isActive === undefined || field.isActive(inputs)) && (
          !field.customInput ? (
            <InputContainer
              key={index}
              header={field.name}
              warning={field.valid !== undefined && !field.valid}
              marginBottom={'20px'}
              tutorialId={field.tutorialId}
            >
              {field.input === undefined ? (
                <StyledInput
                  type="text"
                  maxLength={field.maxLength ? field.maxLength.toString() : "1024"}
                  value={inputs[field.property]}
                  name={field.property}
                  placeholder={placeholder}
                  onChange={handleInputChange}
                />
              ) : (
                field.input(inputs, handleInputChange)
              )}
            </InputContainer>
          ) : (
            field.customInput(index, inputs, handleInputChange, fieldData)
          )
        );
      })
    )
  }, [fieldData, inputs, handleInputChange, t])

  return {
    fields: fields,
    canSave: canSave,
    setCanSave: setCanSave,
    inputs: inputs,
    inputsChanged: inputsChanged,
    updateInput: updateInput,
  }
}

export { useDataFields }
