import React from 'react'
import { useTranslation } from "react-i18next";

import PriorityIndicator from './PriorityIndicator'

import { StyledText } from '../styling/TextStyling'

function Setting(props) {
  const { t } = useTranslation();
  const { styling, priorities, channels, setting, handleSettingsChange } = props

  const channelCheckboxes = channels.map((channel, index) => {
    return (
      <td key={index} style={styling.tableStyle}>
        <input
          type="checkbox"
          style={{
            marginTop: styling.iconTopMargin,
            marginLeft: styling.tableHeaderInnerMargin,
            marginRight: index === channels.length - 1 ?
              styling.tableHeaderOuterMargin : styling.tableHeaderInnerMargin
          }}
          disabled={setting.channels_enabled & (1 << index) ? undefined : true}
          checked={channel.id & setting.channels}
          onChange={() => handleSettingsChange(setting.id, channel.id)}
        />
      </td>
    )
  })

  const title = t('dashboard.items.' + setting.name + '.title')

  return (
    <tr>
      <td style={{...styling.tableStyle, paddingLeft: 14}}>
        <PriorityIndicator priorities={priorities} priority={setting.priority} />
      </td>
      <td style={{...styling.tableHeaderStyle, width: '100%', paddingTop: 9}}>
        <StyledText>{title}</StyledText>
      </td>
      {channelCheckboxes}
    </tr>
  )
}

export default Setting
