import React from 'react'
import styled from 'styled-components'

import { StyledText } from '../styling/TextStyling'

import PropTypes from 'prop-types'

const Button = styled(StyledText)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0px 30px;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};

  background: ${(props) =>
    props.green
      ? props.theme.colors.green
      : props.theme.colors.themeBackground};
  white-space: nowrap;

  &:hover {
    filter: ${(props) =>
      props.disabled ? 'brightness(50%)' : 'brightness(120%)'};
  }

  filter: ${(props) =>
    props.disabled ? 'brightness(50%)' : 'brightness(100%)'};
`

function TextButton(props) {
  const { handleClick, children, ...otherProps } = props

  const intermediateHandleClick = (event) => {
    event.preventDefault()
    if (props.disabled === undefined || !props.disabled) handleClick()
  }

  return (
    <Button {...otherProps} onClick={intermediateHandleClick}>
      {children}
    </Button>
  )
}

TextButton.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
}

export default TextButton
