import React from 'react'
import { useTranslation } from 'react-i18next'

import Table from '../shared/Table'

import { StyledText } from '../styling/TextStyling'

import { useDataInterface } from '../shared/DataInterface'

import { localePricePerSecond } from '../../utils/Locale'

function Programmatic(props) {
  const { t } = useTranslation();

  const endPoint = 'reporting/programmatic'
  const translationPrefix = 'reporting.programmatic'

  // Data interface for players
  const playerDataInterface = useDataInterface({ endPoint: 'admin/players' })

  const customActions = [
    //{
    //  type: 'view',
    //  permissionsOk: userHasPermissions(endPoint, 'view'),
    //  handleClick: (id, data) => handleReportingRequest(id, data, 'view')
    //},
    //{
    //  type: 'download',
    //  permissionsOk: userHasPermissions(endPoint, 'view'),
    //  handleClick: (id, data) => handleReportingRequest(id, data, 'download')
    //}
  ]

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      standardActions={[]}
      customActions={customActions}

      columns = {[
        {
          header: t(translationPrefix + '.period'),
          defaultProperty: 'period_timestamp',
          visualizationFunction: (data) => {
            const d = data['period_timestamp']
            const text = t('shared.month' + d.getMonth()) + ' ' + d.getFullYear()

            return (
              <StyledText dark>{text}</StyledText>
            )
          },
        },
        {
          header: t(translationPrefix + '.player'),
          defaultProperty: 'player_unique_id',
          sortStringFunction: (data) => {
            const player = playerDataInterface.data
                  .find(player => player.unique_id === data.player_unique_id)
            return player ? player.name : ""
          },
          visualizationFunction: (data) => {
            const player = playerDataInterface.data
                  .find(player => player.unique_id === data.player_unique_id)
            return (
              <StyledText dark>{player ? player.name : ""}</StyledText>
            )
          }
        },
        {
          header: t(translationPrefix + '.playCount'),
          defaultProperty: 'play_count',
          style: { justifyContent: 'flex-end'},
        },
        {
          header: t(translationPrefix + '.avgPlayTime'),
          defaultProperty: 'avg_play_time',
          style: { justifyContent: 'flex-end'},
        },
        {
          header: t(translationPrefix + '.playTime'),
          defaultProperty: 'play_time',
          style: { justifyContent: 'flex-end'},
        },
        {
          header: t('shared.list_price'),
          defaultProperty: 'list_price',
          style: { justifyContent: 'flex-end'},
          visualizationFunction: (data) => {
            return <div>{localePricePerSecond(data['list_price'])}</div>
          }
        },
      ]}
    />
  )
}

export default Programmatic
