import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CancelSave from "./CancelSave";
import HeaderWithCancelButton from "./HeaderWithCancelButton";

import { useDataFields } from './DataFields'
import { processInputs, validateInputs } from "./DataProcessing";

function DataEdit(props) {
  const { t } = useTranslation();

  const [highlightErrors, setHighlightErrors] = useState(false)
  const {
    fields, canSave, setCanSave, inputs, inputsChanged
  } = useDataFields({
    defaultData: props.data,
    fieldData: props.fields,
    highlightErrors: highlightErrors,
  })

  const handleSaveEdit = () => {
    if(validateInputs(inputs, props.fields)) {
      console.log("HANDLESAVEEDIT YES")
      const method = props.isUpdate ? "PUT" : "POST";
      const data = { ...inputs };

      if (!props.isUpdate) delete data.id;

      if (props.customProcessBeforeSaveEdit)
        props.customProcessBeforeSaveEdit(data);

      processInputs(data, props.fields);

      props.handleSaveEdit(method, data);
    }
    else {
      console.log("HANDLESAVEEDIT NO")
      setCanSave(false)
      setHighlightErrors(true)
    }
  };

  const handleSave = () => {
    if(inputsChanged || !props.isUpdate)
      handleSaveEdit()
    else
      handleCancel()
  }

  const handleCancel = () => {
    // Reset input validation flags for next invocation
    props.fields.forEach(field => field.valid = true)
    props.handleCancelEdit()
  }

  const header = t(props.translationPrefix +
                   (props.isUpdate ? '.editExisting' : '.addNew') +
                   (props.headerTranslationPostfix ? props.headerTranslationPostfix : '')
                  )
  

  return (
    <div>
      <HeaderWithCancelButton
        title={header}
        handleCancel={handleCancel}
      />
      <br />
      <form>
        {fields}
        <br />
        <CancelSave
          canSave = { !highlightErrors || canSave }
          handleCancel = {handleCancel}
          handleSave = {handleSave}
        />
      </form>
    </div>
  );
}

export default DataEdit;
