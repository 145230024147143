import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import BookingProofUpload from './BookingProofUpload'
import ActionButton from '../shared/ActionButton'
import HeaderWithCancelButton from '../shared/HeaderWithCancelButton'
import ReactModalWrapper from '../shared/ReactModalWrapper'
import TextButton from '../shared/TextButton'

import { useDataInterface } from '../shared/DataInterface'

import { StyledText } from '../styling/TextStyling'

import { fromDbTimestampWithTime } from '../../utils/TimestampHelper'
import { bookingProofUrl } from '../../utils/UrlHelper'

function BookingProof(props) {
  const { t } = useTranslation()
  const { auth, bookingId, hideModal } = props

  const buttonHeight = 60

  const { data, handleDelete, refreshData } = useDataInterface({
    endPoint: 'content/bookingProof?booking_id=' + bookingId,
    externalAuth: auth,
  })

  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  useEffect(() => {
    setSelectedImageIndex(data.length ? data.length - 1 : 0)
  }, [data])

  const selectedImageFile =
    selectedImageIndex < data.length ? data[selectedImageIndex].file : null
  const selectedImage = selectedImageFile && (
    <img
      src={bookingProofUrl(selectedImageFile)}
      alt={selectedImageFile}
      width={'100%'}
      height={'100%'}
      style={{ objectFit: 'contain' }}
    />
  )

  const images = data.map(({ id, file, upload_timestamp }, index) => {
    const date = fromDbTimestampWithTime(upload_timestamp)
    const bold = selectedImageIndex === index ? true : undefined

    //const bookingProofSrc = bookingProofUrl(file);
    const handleDownload = () => {
      const options = {
        method: 'GET',
        responseType: 'blob',
        headers: { 'content-type': 'image/jpeg' },
      }

      auth.authCall('content/bookingProof?filename=' + file, [], options)
        .then((data) => {
          const file = new Blob([data], { type: 'image/jpeg' })
          const fileURL = URL.createObjectURL(file)
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.download = 'booking_proof_' + id + '.jpg'
          fileLink.click()
          URL.revokeObjectURL(fileURL)
        })
    }

    return (
      <TextButton
        key={id}
        style={{ height: buttonHeight, marginBottom: 10 }}
        handleClick={() => setSelectedImageIndex(index)}
      >
        <ItemContainer>
          <StyledText>{index + 1}</StyledText>
          <StyledText bold={bold}>
            {t('content.booking_proof.uploaded', { date: date })}
          </StyledText>
          <ButtonContainer>
            <ActionButton
              type={'download'}
              handleClick={handleDownload}
            />
            <ActionButton
              type={'delete'}
              confirmMessage={t('content.booking_proof.confirmDelete')}
              handleClick={() => handleDelete(id)}
            />
          </ButtonContainer>
        </ItemContainer>
      </TextButton>
    )
  })

  return (
    <ReactModalWrapper layout={'fullscreen'} onRequestClose={hideModal}>
      <StyledSelectedImage>{selectedImage}</StyledSelectedImage>

      <StyledUserInput>
        <HeaderWithCancelButton
          title={t('content.booking_proof.title')}
          handleCancel={hideModal}
        />
        <br />
        {data.length === 0 ? (
          <StyledText>{t('content.booking_proof.noneExisting')}</StyledText>
        ) : (
          images
        )}
        <br />
        <BookingProofUpload
          refreshData={refreshData}
          buttonHeight={buttonHeight}
          bookingId={bookingId}
          handleCancel={hideModal}
        />
      </StyledUserInput>
    </ReactModalWrapper>
  )
}

export default BookingProof

const StyledSelectedImage = styled.div`
  display: flex;
  alignitems: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 40%;
  bottom: 0px;
  border: 0px;
  padding: 20px;
`

const StyledUserInput = styled.div`
  position: absolute;
  top: 0px;
  left: 60%;
  right: 0px;
  bottom: 0px;
  border: 0px;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`
