import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import QueryLink from './QueryLink'
import StandardButton from '../shared/StandardButton'

import { StyledText } from '../styling/TextStyling'

import { fromDbTimestampWithTime } from '../../utils/TimestampHelper.js'

function SimpleItem(props) {
  const { t } = useTranslation();
  const { view, subCategory, itemId, dataId, dataName, dataTimestamp, handleItemHidden, close } = props

  const [showHideButton, setShowHideButton] = useState(false)

  const timestamp = fromDbTimestampWithTime(dataTimestamp)
  const parts = t('dashboard.items.' + subCategory + '.message').split('%', 2)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
      onMouseEnter = {() => setShowHideButton(true)}
      onMouseLeave = {() => setShowHideButton(false)}
    >
      <StyledText dark
        style={{display: 'flex', padding: '7px 25px'}}
      >
        {timestamp + ': ' + parts[0]}
        <QueryLink
          view={view}
          id={dataId}
          text={dataName}
          close={close}
        />
        {' ' + parts[1]}
      </StyledText>
      <StandardButton
        style={{visibility: showHideButton ? 'visible' : 'hidden'}}
        icon={'delete'}
        text={t('shared.delete')}
        hoverColor={'red'}
        handleClick={() => handleItemHidden(itemId)}
      />
    </div>
  )
}

export default SimpleItem
