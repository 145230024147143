import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Table from '../shared/Table'

import { StyledInput } from '../styling/InputStyling'
import { StyledText } from '../styling/TextStyling'

import { useAuth } from '../auth/Authentication'

import { localePrice } from '../../utils/Locale'
import { useInvoiceStrings } from '../../utils/RequestTranslationStrings'

function Accounting(props) {
  const { t } = useTranslation();
  const { authCall, userHasPermissions } = useAuth();

  const endPoint = 'reporting/accounting'
  const translationPrefix = 'reporting.accounting'

  const [invoiceNumber, setInvoiceNumber] = useState('')

  const invoiceStrings = useInvoiceStrings()
  const handleReportingRequest = (id, data, action) => {
    const period_timestamp = data['period_timestamp']
    const options = {
      method: 'POST',
      responseType: 'blob',
      headers: { 'content-type': 'application/json' },
      data: {
        period_timestamp: period_timestamp,
        invoice_number: invoiceNumber,
        title: t('reporting.accounting.title'),
        booking_id: t('shared.booking_id'),
        disclaimer: t('shared.ad_tax_disclaimer') + '\n\n' + t('reporting.accounting.disclaimer'),
        ...invoiceStrings,
      },
    }

    authCall(endPoint, [], options)
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        if(action === 'view') {
          const pdfWindow = window.open()
          pdfWindow.location.href = fileURL
        }
        else {
          const period = t('shared.month' + period_timestamp.getMonth()) + '_' +
                period_timestamp.getFullYear()

          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.download = 'accounting_' + period + '.pdf'
          fileLink.click()
        }

        URL.revokeObjectURL(fileURL)
      })
  }

  const customActions = [
    {
      type: 'view',
      permissionsOk: userHasPermissions(endPoint, 'view'),
      handleClick: (id, data) => handleReportingRequest(id, data, 'view')
    },
    {
      type: 'download',
      permissionsOk: userHasPermissions(endPoint, 'view'),
      handleClick: (id, data) => handleReportingRequest(id, data, 'download')
    }
  ]

  const invoiceNumberInput = (
    <StyledInvoiceNumber>
      <StyledInvoiceNumberInput
        type="text"
        maxLength={"12"}
        value={invoiceNumber}
        placeholder={t(translationPrefix + '.enter_invoice_number')}
        onChange={(e) => setInvoiceNumber(e.target.value)}
      />
    </StyledInvoiceNumber>
  )

  return (
    <Table
      endPoint={endPoint}
      translationPrefix={translationPrefix}
      categoryHeaderItem={invoiceNumberInput}
      standardActions={[]}
      customActions={customActions}
      noNewItemButton={true}

      columns = {[
        {
          header: t(translationPrefix + '.period'),
          defaultProperty: 'period_timestamp',
          visualizationFunction: (data) => {
            const d = data['period_timestamp']
            const text = t('shared.month' + d.getMonth()) + ' ' + d.getFullYear()

            return (
              <StyledText dark>{text}</StyledText>
            )
          },
        },
        {
          header: t(translationPrefix + '.num_bookings'),
          defaultProperty: 'num_bookings',
          style: { justifyContent: 'center'},
        },
        {
          header: t(translationPrefix + '.amount'),
          defaultProperty: 'amount',
          style: { justifyContent: 'flex-end'},
          visualizationFunction: (data) => {
            const amount = localePrice(data['amount'])

            return (
              <StyledText dark>{amount}</StyledText>
            )
          }
        },
        {
          header: t(translationPrefix + '.share'),
          defaultProperty: 'amount',
          style: { justifyContent: 'flex-end'},
          visualizationFunction: (data) => {
            const amount = localePrice(0.5 * data['amount'])

            return (
              <StyledText dark>{amount}</StyledText>
            )
          }
        },
      ]}
    />
  )
}

export default Accounting

const StyledInvoiceNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  height: 100%;
`

const StyledInvoiceNumberInput = styled(StyledInput)`
  color: ${props => props.theme.colors.white};
  font-family: Roboto-Bold;
  font-size: 12px;
  &::placeholder {
    color: ${props => props.theme.colors.white};
    opacity: 0.6;
  }
`
