import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledText } from '../styling/TextStyling'

function HardwareID(props) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false)

  const handleClick = (event) => {
    event.preventDefault()
    // Copy hardware id to clipboard without hyphens
    navigator.clipboard.writeText(props.hardwareId)
    setCopied(true)
  }

  const s = props.hardwareId
  const id = s.substr(0,4) + '-' + s.substr(4,4) + '-' +
        s.substr(8,4) + '-' + s.substr(12,4)

  return (
    <StyledButton
      onClick = {handleClick}
    >
      <StyledText dark style={{flex: 1}}>
        {id}
      </StyledText>
      {copied && (
        <StyledText dark bold upper>
          {t('shared.copied_to_clipboard')}
        </StyledText>
      )}
      {copied && (
        <div style={{flex: 1}}></div>
      )}
    </StyledButton>
  )
}

export default HardwareID


const StyledButton = styled.button`
  background: #4ED94A;
  textAlign: center;
  width: 100%;
  padding: 10px;
  border: none;
  display: flex;
  gap: 40px;
  cursor: grab;

  &:hover {
    filter: brightness(0.8);
  }
`
