import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Routes, Route } from 'react-router-dom'

import Home from './Home'
import Category from './Category'
import { items, canViewItem, navPermissionAction } from './Items'

import Sites from '../admin/Sites'
import Clusters from '../admin/Clusters'
import Players from '../admin/Players'
import Resolutions from '../admin/Resolutions'
import Sectors from '../admin/Sectors'
import Customers from '../admin/Customers'
import Users from '../admin/Users'
import Groups from '../admin/Groups'
import Teams from '../admin/Teams'

import TableOfBookings from '../content/TableOfBookings'
import Booking from '../content/booking/Booking'
import NewBooking from '../content/NewBooking'
import Search from '../content/Search'
//import Overrule from '../content/Overrule'
import Load from '../content/Load'
import Approval from '../content/Approval'

import Accounting from '../reporting/Accounting'
import Invoices from '../reporting/Invoices'
import Programmatic from '../reporting/Programmatic'
import Reports from '../reporting/Reports'

import { StyledText } from '../styling/TextStyling'

import { instanceOneOf } from '../../Theme'
import { useAuth, LogoutOnSetPassword } from '../auth/Authentication'


function ItemRoutes() {
  const { t } = useTranslation()
  const { canViewAdmin, canViewContent, canViewReporting, userHasPermissions } = useAuth()

  // Default item to display when the current route is not available
  const d = () => (
    <div style={{height: 58, padding: 18, width: '100%'}}>
      <StyledText large bold>{t('info.access_denied')}</StyledText>
    </div>
  )

  // Navigate home upon request of an invalid route
  const h = () => (<Navigate to='/' replace={true} />)

  // Map that defines if a route is available for the current user
  const p = useMemo(() => {
    const map = new Map()

    items.forEach(i => {
      const path = i.category + '/' + i.path
      const canView = canViewItem(i) && userHasPermissions(path, navPermissionAction(i))
      map.set('/' + path, canView)
    })

    return map
  }, [userHasPermissions])

  return (
    <Routes>
      <Route path='/'                                           element={<Home />} />
      <Route path='/password'                                   element={<LogoutOnSetPassword />} />
      <Route path='/admin'                                      element={canViewAdmin ?                     <Category path={'admin'} />                                          : d()} >
        <Route path='/admin/sites/*'                            element={p.get('/admin/sites') ?            <Sites />                                                            : d()} />
        <Route path='/admin/clusters'                           element={p.get('/admin/clusters') ?         <Clusters />                                                         : d()} />
        <Route path='/admin/discounts'                          element={h()} />
        <Route path='/admin/players/*'                          element={p.get('/admin/players') ?          <Players />                                                          : d()} />
        <Route path='/admin/resolutions'                        element={p.get('/admin/resolutions') ?      <Resolutions />                                                      : d()} />
        <Route path='/admin/customers/*'                        element={p.get('/admin/customers') ?        <Customers />                                                        : d()} />
        <Route path='/admin/sectors/*'                          element={p.get('/admin/sectors') ?          <Sectors />                                                          : d()} />
        <Route path='/admin/users/*'                            element={p.get('/admin/users') ?            <Users />                                                            : d()} />
        <Route path='/admin/teams'                              element={p.get('/admin/teams') ?            <Teams />                                                            : d()} />
        <Route path='/admin/groups/*'                           element={p.get('/admin/groups') ?           <Groups />                                                           : d()} />
        <Route path='/admin/*'                                  element={h()} />
      </Route>
      <Route path='/content'                                    element={canViewContent ?                   <Category path={'content'} />                                        : d()} >
        <Route path='/content/new/*'                            element={p.get('/content/new') ?            (instanceOneOf('marty') ? <Search /> : <NewBooking />)               : d()} />
        <Route path='/content/add'                              element={p.get('/content/add') ?            <NewBooking />                                                       : d()} />
        <Route path='/content/quotations/:id?'                  element={p.get('/content/quotations') ?     <TableOfBookings category={'quotations'} />                          : d()} />
        <Route path='/content/quotations/quotation/:id?'        element={p.get('/content/quotations') ?     <Booking endPoint={'quotations/quotation'} />                        : d()} />
        <Route path='/content/bookings/:id?'                    element={p.get('/content/bookings') ?       <TableOfBookings category={'bookings'} query={'?attribute=1'} />     : d()} />
        <Route path='/content/bookings/booking/:id?'            element={p.get('/content/bookings') ?       <Booking endPoint={'bookings/booking'} />                            : d()} />
        <Route path='/content/fillins/:id?'                     element={p.get('/content/fillins') ?        <TableOfBookings category={'fillins'} query={'?attribute=2'} />      : d()} />
        <Route path='/content/fillins/fillin/:id?'              element={p.get('/content/fillins') ?        <Booking endPoint={'fillins/fillin'} />                              : d()} />
        <Route path='/content/subscriptions/:id?'               element={p.get('/content/subscriptions') ?  <TableOfBookings category={'subscriptions'} query={'?type_id=4'} />  : d()} />
        <Route path='/content/subscriptions/subscription/:id?'  element={p.get('/content/subscriptions') ?  <Booking endPoint={'subscriptions/subscription'} />                  : d()} />
        <Route path='/content/reservations/:id?'                element={p.get('/content/reservations') ?   <TableOfBookings category={'reservations'} query={'?type_id=5'} />   : d()} />
        <Route path='/content/reservations/reservation/:id?'    element={p.get('/content/reservations') ?   <Booking endPoint={'reservations/reservation'} />                    : d()} />
        <Route path='/content/vacancies/:id?'                   element={p.get('/content/vacancies') ?      <TableOfBookings category={'vacancies'} query={'?attribute=4'} />    : d()} />
        <Route path='/content/vacancies/vacancy/:id?'           element={p.get('/content/vacancies') ?      <Booking endPoint={'vacancies/vacancy'} />                           : d()} />
        <Route path='/content/overrule'                         element={d()} />
        <Route path='/content/load'                             element={p.get('/content/load') ?           <Load />                                                             : d()} />
        <Route path='/content/approval'                         element={p.get('/content/approval') ?       <Approval />                                                         : d()} />
        <Route path='/content/*'                                element={h()} />
      </Route>
      <Route path='/reporting'                                  element={canViewReporting ?                 <Category path={'reporting'} />                                      : d()} >
        <Route path='/reporting/reports/:id?'                   element={p.get('/reporting/reports') ?      <Reports />                                                          : d()} />
        <Route path='/reporting/accounting'                     element={p.get('/reporting/accounting') ?   <Accounting />                                                       : d()} />
        <Route path='/reporting/programmatic'                   element={p.get('/reporting/programmatic') ? <Programmatic />                                                     : d()} />
        <Route path='/reporting/invoices'                       element={p.get('/reporting/invoices') ?     <Invoices />                                                         : d()} />
        <Route path='/reporting/*'                              element={h()} />
      </Route>
      <Route path='/*'                                          element={h()} />
    </Routes>
  )
}

export default ItemRoutes
