import React from 'react'

function Clear() {
  return (
    <div
      style = {{
        content: '',
        display: 'table',
        clear: 'both'
      }}
    />
  )
}

export default Clear
