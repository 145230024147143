import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { cInvoicingEnabled } from '../../../Theme'
import BookingUnitSpotUpload from './BookingUnitSpotUpload'
import InvoicePreview from './InvoicePreview'
import InputContainer from '../../shared/InputContainer'
import { validateInputs } from '../../shared/DataProcessing'

import { StyledColumnHeader } from '../../styling/InputStyling'
import { StyledText } from '../../styling/TextStyling'

function MetaDataEdit(props) {
  const { t } = useTranslation()
  const { metaDataFields, hasZeroPrice, inputFields, inputs, canSave, bookingUnitData } = props

  // Split fields into two columns (left 1:m, right m+1:n)
  const n = inputFields.length
  const m = n - 2

  // Validate left and right column to highlight column headers
  const [canSaveLeft, setCanSaveLeft] = useState(canSave)
  const [canSaveRight, setCanSaveRight] = useState(canSave)
  useEffect(() => {
    const fieldData = [...metaDataFields]
    let left = true
    let right = true

    validateInputs(inputs, fieldData)
    fieldData.forEach((field, i) => {
      if(!field['valid']) {
        if(i < m)
          left = false
        else
          right = false
      }
    })

    setCanSaveLeft(left)
    setCanSaveRight(right)
  }, [metaDataFields, canSave, inputs, m])

  const price = hasZeroPrice ? 0.0
        : bookingUnitData['price'] * (1 - inputs['discount'] / 100.0)

  return (
    <OuterContainer>
      {/* Left column */}
      <LeftColumnContainer>
        <StyledColumnHeader completed={canSaveLeft}>
          <StyledText medium bold upper>
            {t('content.search.metadata')}
          </StyledText>
        </StyledColumnHeader>
        {inputFields.slice(0, m)}
      </LeftColumnContainer>

      {/* Right column */}
      <RightColumnContainer>
        <StyledColumnHeader completed={canSaveRight}>
          <StyledText medium bold upper>
            {t('content.search.pricing')}
          </StyledText>
        </StyledColumnHeader>
        {!hasZeroPrice && inputFields.slice(m)}
        {cInvoicingEnabled && (
          <InvoicePreview
            showsDiscounted
            header={t('shared.invoice_preview')}
            hasZeroPrice={hasZeroPrice}
            price={price}
            spotOrigin={inputs['spot_origin']}
          />
        )}
        {props.resolutions && (
          <InputContainer
            style={{visibility: props.resolutions.length ? 'visible' : 'hidden'}}
            dark={props.dark}
            marginTop={cInvoicingEnabled ? '20px' : 0}
            marginBottom={'20px'}
            header={t('content.booking.spotUpload.title')}
            tutorialId={'MetaDataEdit.spot_upload'}
          >
            <StyledText dark large>
              <BookingUnitSpotUpload resolutions={props.resolutions} />
            </StyledText>
          </InputContainer>
        )}
      </RightColumnContainer>
    </OuterContainer>
  )
}

export default MetaDataEdit

const OuterContainer = styled.div`
  display: flex;
`

const LeftColumnContainer = styled.div`
  width: 50%;
  padding: 20px;
`

const RightColumnContainer = styled.div`
  width: 50%;
  padding: 20px;
`
