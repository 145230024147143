import React from 'react'
import { useTranslation } from 'react-i18next'

import SubCategoryHeader from './SubCategoryHeader'
import SimpleItem from './SimpleItem'

import { toBookingId } from '../../utils/TimestampHelper'

function SubCategory(props) {
  const { t } = useTranslation();
  const { subCategory, priorities, handleItemHidden, close } = props

  let title = t('dashboard.items.' + subCategory.name + '.title')
  let subitems = []
  
  switch(subCategory.view) {
  case "booking_problem":
    subitems = subCategory.items.map(item => {
      const msg = t('dashboard.items.' + subCategory.view + '.message')
      return <div key={item.id}>{msg}</div>
    })
    break

  case "booking_total":
    title += ': ' + subCategory.items[0].data.total
    subitems = null
    break

  default:
    title += ' (' + subCategory.items.length + ')'
    subitems = subCategory.items.map(item => {
      function dataId() {
        switch(subCategory.view) {
        case 'booking_proof':
        case 'missing_spot':
        case 'problem':
        case 'reporting':
          return 'booking_id'
        default:
          return 'id'
        }
      }

      function dataName() {
        switch(subCategory.view) {
        case 'booking_proof':
        case 'missing_spot':
        case 'reporting':
          return toBookingId(item.data.creation_timestamp, item.data.booking_id)
        case 'player_status':
          return item.data.name
        default:
          return ''
        }
      }

      return (!item.hidden &&
        <SimpleItem
          key={item.id}
          view={subCategory.view}
          subCategory={subCategory.name}
          itemId={item.id}
          dataId={item.data[dataId()]}
          dataName={dataName()}
          dataTimestamp={item.data.item_timestamp}
          handleItemHidden={handleItemHidden}
          close={close}
        />
      )
    })
    break
  }

  return (
    <div style={{background: '#FFF'}}>
      <SubCategoryHeader
        priorities={priorities}    
        priority={subCategory.priority_id}
        title={title}
      />
      {subitems}
    </div>
  )
}

export default SubCategory
