import styled from 'styled-components'

export const StyledCheckbox = styled.input`
`

export const StyledInput = styled.input`
  background: transparent;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-family: Roboto-Light;
  font-size: 14px;
`

export const StyledLabel = styled.label`
  font-family: Roboto-Light;
  font-size: 14px;
  user-select: none;
`

export const StyledPicker = styled.div`
  background: #FFF;
`

export const StyledColumnHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  background: ${props => props.completed === true ? props.theme.colors.green : props.theme.colors.themeBackground};
  align-items: center;
  justify-content: center;
`

export const StyledDisabledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  visibility: ${props => props.hidden === true ? 'hidden' : 'auto'};
`

export const StyledDisabledOverlay = styled.div`
  position: absolute;
  top: -20px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100vh - ${(props) => props.theme.header.height} - ${(props) => props.theme.header.height});
  background: rgba(255, 255, 255, 0.3);
`

export const StyledFileInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`
