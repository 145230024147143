import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-modal-hook'

import { instanceOneOf } from '../../Theme'
import Spot from '../content/booking/Spot'
import HeaderWithCancelButton from '../shared/HeaderWithCancelButton'
import ReactModalWrapper from '../shared/ReactModalWrapper'
import { kosherPlayers } from '../shared/PlayerData'

import { StyledText } from '../styling/TextStyling'

import { dateToString, fromDbTimestamp, getStartOfWeek } from '../../utils/TimestampHelper'

function usePreview(authCall, t) {
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])

  const [showModal, hideModal] = useModal(() => {
    const spots = Array.isArray(data) && 0 < data.length ? data.map((spot, i) => {
      return (
        <Spot
          key={i}
          spot={spot}
        />
      )
    }) : <StyledText>{t('reporting.report.no_data')}</StyledText>

    return (
      <ReactModalWrapper
        layout={'fullscreen'}
        onRequestClose={hidePreview}
      >
        <StyledDialog>
          <HeaderWithCancelButton
            title={title}
            handleCancel={hidePreview}
          />
          <StyledContainer>
            {spots}
          </StyledContainer>
        </StyledDialog>
      </ReactModalWrapper>
    )
  }, [title, data])

  const hidePreview = useCallback(() => {
    setTitle('')
    setData([])
    hideModal()
  }, [hideModal])

  const showPreview = useCallback((siteId, data) => {
    var ts = new Date()
    if(instanceOneOf('marty')) {
      const firstDayOfWeek = kosherPlayers.includes(siteId) ? 0 : 1
      ts = getStartOfWeek(new Date(), 7, firstDayOfWeek)
    }

    setTitle(data['name'] + '  -  ' + fromDbTimestamp(ts))
    showModal()

    const date = dateToString(ts)
    const endPoint = 'content/preview?site_id=' + siteId + '&timestamp=' + date

    authCall(endPoint).then(
      (data) => setData(data)
    )
  }, [showModal, authCall])

  return {
    showPreview,
  }
}

export { usePreview }

const StyledDialog = styled.div`
  margin: ${props => props.theme.header.height};
  padding: 40px;
  background: #000;
`

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: calc(100vh - ${props => props.theme.header.height} - ${props => props.theme.header.height} - 80px - 30px - 20px);
  margin-top: 20px;
  gap: 10px;
  overflow-y: auto;
`
