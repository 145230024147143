import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Background from './Background'
import { instanceOneOf } from '../../Theme'
import request from "../shared/request";
import TextButton from '../shared/TextButton'

import { AuthForm, AuthInputBackground } from '../styling/AuthStyling'
import { StyledInput } from '../styling/InputStyling'
import { StyledText } from '../styling/TextStyling'

import { VersionElement } from '../../utils/Version'


function Login(props) {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState({ email: '', password: '' })
  const [state, setState] = useState('login')

  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  const handleSubmit = async () => {
    switch(state) {
    case 'login':
      props.handleLogin(inputs)
      break

    case 'reset':
      const config = {
        method: "POST",
        headers: { "content-type": "application/json" },
      };
      await request("/resetPwd?email=" + inputs["email"], config);

      setState('confirm')
      break

    default:
      setState('login')
    }
  }

  const handleQuestionButton = () => {
    setState(prev => prev === 'login' ? 'reset' : 'login')
  }

  const subtitle = state === 'login' ?
        t('authentication.login_required') :
        state === 'reset' ?
        t('authentication.reset_password') :
        t('authentication.reset_successful')
  const buttonText = state === 'login' ?
        t('authentication.login') :
        state === 'reset' ?
        t('authentication.reset_password') :
        t('authentication.return_to_login')
  const questionText = state === 'login' ?
        t('authentication.reset_password') + '?' :
        t('authentication.return_to_login')

  return (
    <Background>
      <VersionElement auth />
      <StyledText upper fontSize={30}>
        {instanceOneOf('marty') ? 'MartyPlanner' : t('shared.product')}
      </StyledText>
      <StyledText large fontSize={16}>
        {subtitle}
      </StyledText>
      <br/>

      <AuthForm>
        {state !== 'confirm' &&
          <AuthInputBackground margin={state === 'login' ? 1 : 10}>
            <StyledInput
              type="text"
              autoComplete="username"
              value={inputs.email}
              name="email"
              placeholder={t('authentication.email')}
              onChange={handleInputChange}
            />
          </AuthInputBackground>
        }

        {state === 'login' &&
          <AuthInputBackground margin={10}>
            <StyledInput
              type="password"
              autoComplete="current-password"
              value={inputs.password}
              name="password"
              placeholder={t('authentication.password')}
              onChange={handleInputChange}
            />
          </AuthInputBackground>
        }

        {state === 'confirm' &&
          <div>
            <StyledText bold upper centered>
              {t('authentication.reset_instructions')}
            </StyledText>
            <br />
          </div>
        }

        <TextButton handleClick = {handleSubmit}>
          <StyledText>{buttonText}</StyledText>
        </TextButton>

        {state !== 'confirm' &&
          <StyledQuestionButton centered
            onClick={handleQuestionButton}
          >
            {questionText}
          </StyledQuestionButton>
        }
      </AuthForm>
    </Background>
  )
}


export default Login


const StyledQuestionButton = styled(StyledText)`
  margin: 10px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.themeBackground}
  }
`
